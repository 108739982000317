import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  customValidator,
} from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import FileUpload from "components/Common/FileUpload.js";
export default function CreateCategory() {
  const [categoryData, setCategoryData] = useState({
    title: "",
    subTitle: "",
    startColor: "",
    endColor: "",
    selectedImage: "",
  });
  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };
  const Createcategory = async (e) => {
    e.preventDefault();
    categoryData.selectedImage = selectedImage;
    const validator = customValidator(
      [
        {
          field: "title",
          errorMsg: "Title is required",
        },
        // {
        //   field: "subTitle",
        //   errorMsg: "Please enter subtitle",
        // },
        {
          field: "startColor",
          errorMsg: "Please enter start color",
        },
        {
          field: "endColor",
          errorMsg: "Please enter end color",
        },
      ],
      categoryData
    );
    if (!validator.success) {
      toast.error(validator.message);
      return;
    }

    setStartLoader(true);
    setLoading(true);

    const formData = new FormData();
    const selectedImageForm = new FormData();
    selectedImageForm.append("image", categoryData.selectedImage);
    const selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    );

    setStartLoader(false);
    formData.append("title", categoryData.title);
    formData.append("subTitle", categoryData.subTitle);
    formData.append("startColor", categoryData.startColor);
    formData.append("endColor", categoryData.endColor);
    if (selectedImageRes?.data?.url) {
      formData.append("image", selectedImageRes.data.url);
    } else {
      console.error("Image URL not found in the response.");


      
    }
    const response = await postRequestForm("venue/category/add", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/category");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...categoryData };
    list[index] = value;
    setCategoryData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Category Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Category Management</li>:{" "}
              <li className="breadcrumb-item">Create Category</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Category </h4>
                <Form onSubmit={Createcategory}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Sub Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("subTitle", e.target.value)
                              }
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Color
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              onChange={(e) =>
                                updateValue("startColor", e.target.value)
                              }
                              name="startColor"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Color
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="color"
                              onChange={(e) =>
                                updateValue("endColor", e.target.value)
                              }
                              name="endColor"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
