import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Modal,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequest } from "../../components/Common/Utils.js";

export default function AddActivityRatingReply({
  showReply,
  closeReplyModal,
  rowData,
}) {
  let history = useHistory();
  
  const [previewCover, setPreviewCover] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [activityRatingdata, setActivityRatingdata] = useState();

  const [loading, setLoading] = useState(false);

  const [selectedCover, setSelectedCover] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setActivityRatingdata(rowData);
  }, [rowData]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const addactivityratingreply = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await postRequest("review/reply/addUpdate", {reviewId : activityRatingdata._id, reply : activityRatingdata.reply});
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        // window.location.reload();
        history.push("/activityRating");
        closeReplyModal();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...activityRatingdata };
    list[index] = value;
    setActivityRatingdata(list);
  };

  return (
    <Modal
      isOpen={showReply}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <React.Fragment>
        <ToastContainer />

        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Send Reply
          </h5>
          <button
            onClick={() => {
              closeReplyModal();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {/* header */}                          

        {/* form */}                                   
        <Row>
        <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
              {loading ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody>
                  <h4 className="card-title"> Add Reply </h4>
                  <Form onSubmit={addactivityratingreply}>
                    <Row>
                      <Col lg={12} className="ms-lg-auto">
                        <div className="mt-5 mt-lg-4">
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Admin Reply
                            </label>
                            <div className="col-sm-6">
                              <textarea
                                onChange={(e) =>
                                  updateValue("reply", e.target.value)
                                }
                                value={activityRatingdata?.reply}
                                name="reply"
                                className="form-control"
                              ></textarea>
                            </div>
                          </div>

                          <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                              <div className="col-sm-9">
                                <div>
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-md"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </Modal>
  );
}
