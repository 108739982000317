import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from "reactstrap";

export default function ImagesReorderModal({ 
  show, 
  closeModal, 
  imageUrls = [], 
  onReorderImages 
}) {
  // Local state: keep a local copy so we don't affect the parent state until "Save"
  const [localImages, setLocalImages] = useState([...imageUrls]);

  useEffect(() => {
    // Whenever the modal is opened, sync local images with parent images
    setLocalImages([...imageUrls]);
  }, [imageUrls, show]);

  // Move item up or down in the array
  const moveItem = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= localImages.length) return;

    // Create a shallow copy of the array
    const updatedList = [...localImages];
    // Remove the item
    const [movedItem] = updatedList.splice(fromIndex, 1);
    // Insert it at new position
    updatedList.splice(toIndex, 0, movedItem);
    setLocalImages(updatedList);
  };

  const saveChanges = () => {
    onReorderImages(localImages);
    closeModal();
  };

  return (
    <Modal isOpen={show} toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal}>Reorder Images</ModalHeader>
      <ModalBody>
        {localImages.length === 0 ? (
          <p>No images to reorder.</p>
        ) : (
          <Table bordered>
            <thead>
              <tr>
                <th>Preview</th>
                <th>Current Index</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {localImages.map((url, index) => (
                <tr key={index}>
                  <td style={{ width: "150px" }}>
                    <img
                      src={url}
                      style={{ width: "100px", height: "100px" }}
                      alt={`Img-${index}`}
                    />
                  </td>
                  <td style={{ verticalAlign: "middle" }}>{index + 1}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    <Button
                      color="primary"
                      size="sm"
                      className="me-2"
                      onClick={() => moveItem(index, index - 1)}
                      disabled={index === 0}
                    >
                      Move Up
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => moveItem(index, index + 1)}
                      disabled={index === localImages.length - 1}
                    >
                      Move Down
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button color="primary" onClick={saveChanges}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
