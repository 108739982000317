import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import { useHistory } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import Select from "react-select";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import { postRequest, postRequestForm } from "../../components/Common/Utils.js";
import { useDebounceEffect } from "pages/VenueManagement/useDebounceEffect.js";
import { canvasPreview } from "pages/VenueManagement/canvasPreview.js";
import Lightbox from "react-image-lightbox";
import FileUpload from "../../components/Common/FileUpload";
export default function CreateEventOrganizerData() {
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [logoSelected, setLogoSelected] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [eventData, setEventData] = useState({
    name: "",
    phone: "",
    email: "",
    cover: "",
    logo: "",
    password: "",
    website: "",
    description: "",
  });
  const blobUrlRef = useRef("");
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  const [completedCrop, setCompletedCrop] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });
  const [selectedCover, setSelectedCover] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);

  let history = useHistory();

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };
  const showLightBox = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox(true);
  };

  const showLightBox2 = (previewCover) => {
    setPreviewCover(previewCover);
    setOpenLightBox2(true);
  };
  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;

    // Allow any input and handle validation separately
    updateValue("email", enteredEmail);
  };

  const handleBlur = () => {
    // Validate email separately when input loses focus
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(eventData.email);

    if (!isValidEmail) {
      // Email format is invalid, you can show a popup or handle the error
      toast.error("Please enter valid Email");
      return;
    }
  };

  const handleWebsiteChange = (e) => {
    const enteredWebsite = e.target.value;
    updateValue("website", enteredWebsite);
  };

  const handleWebsiteBlur = () => {
    // Validate website separately when input loses focus
    const isValidWebsite = /^(ftp|http|https):\/\/[^ "]+$/.test(
      eventData.website
    );

    if (!isValidWebsite) {
      // Website format is invalid, you can show a popup or handle the error
      toast.error("Please enter valid Website");
    }
  };
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      // convert blob to file

      const file = new File([blob], eventData.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const [itemsdata, setItems] = useState([]);

  const Createeventorganizer = async (e) => {
    e.preventDefault();

    if (!eventData.name) {
      toast.error("Please enter event organizer name");
      return;
    }

    if (!eventData.description) {
      toast.error("Please enter event organizer description");
      return;
    }

    if (!eventData.phone) {
      toast.error("Please enter event organizer phone");
      return;
    }

    // phone number not contain any character other than + and between 8-15 digit

    const phone = eventData.phone?.trim();
    if (!phone.match(/^[+]?[0-9]{8,15}$/)) {
      toast.error("Please enter valid phone number 8-15 digit");
      return;
    }

    if (!eventData.email) {
      toast.error("Please enter event organizer email");
      return;
    }
    // check for email pattern

    const email = eventData.email?.trim();

    if(!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)){
      toast.error("Please enter valid email");
      return;
    }

    if (!eventData.password) {
      toast.error("Please enter event organizer password");
      return;
    }

    if (!selectedLogo) {
      toast.error("Please select logo");
      return;
    }

    if (!selectedCover) {
      toast.error("Please select cover");
      return;
    }

    const x = new FormData();
    const y = new FormData();
    setStartLoader(true);

    x.append("image", remoteLogo);
    y.append("image", selectedCover);

    const remoteLogoRes = await postRequestForm("comman/logo/upload", x);
    const selectedCoverRes = await postRequestForm("comman/img/upload", y);
    setStartLoader(false);

    const dataObj = {
      name: eventData.name,
      logo: remoteLogoRes?.data.url,
      cover: selectedCoverRes?.data.url,
      description: eventData.description,
      phone: phone,
      email: eventData.email,
      password: eventData.password,
      website: eventData.website,
    };
    setLoading(true);
    const response = await postRequest("event/org/create", dataObj);

    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/eventOrganizer");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...eventData };
    list[index] = value;
    setEventData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Event Organizer</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Event Organizer</li>:{" "}
              <li className="breadcrumb-item">Create Event Organizer</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Event Organizer </h4>
                <Form onSubmit={Createeventorganizer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              name="description"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("phone", e.target.value)
                              }
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={handleEmailChange}
                              onBlur={handleBlur}
                              name="email"
                              value={eventData?.email}
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Password
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="password"
                              onChange={(e) =>
                                updateValue("password", e.target.value)
                              }
                              name="password"
                              className="form-control"
                            />
                          </div>
                        </div> */}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Password
                          </label>
                          <div className="col-sm-6">
                            <span
                              style={{
                                padding: "0",
                                border: "none",
                                background: "none",
                              }}
                              className="input-group-text"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <input
                                type={showPassword ? "text" : "password"}
                                onChange={(e) =>
                                  updateValue("password", e.target.value)
                                }
                                name="password"
                                className="form-control"
                              />
                              <i
                                style={{ marginLeft: 15 }}
                                className={`fa ${
                                  showPassword ? "fa-eye-slash" : "fa-eye"
                                }`}
                              />
                            </span>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Website
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={handleWebsiteChange}
                              onBlur={handleWebsiteBlur}
                              name="website"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              // onChange={(e) =>
                              //   setSelectedLogo(e.target.files[0])
                              // }
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewLogo && (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "720px",
                                  maxHeight: "600px",
                                }}
                              >
                                <img
                                  ref={imgRef}
                                  src={previewLogo}
                                  onClick={() => showLightBox(previewLogo)}
                                />
                              </ReactCrop>
                            )}

                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Cover
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedCover(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewCover && (
                              <img
                                src={previewCover}
                                onClick={() => showLightBox2(previewCover)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewCover}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
