import React, { useState, useEffect } from "react";
import countries from './countries.json';
import { useHistory, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Spinner,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Lightbox from "react-image-lightbox";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";
import FileUpload from "../../components/Common/FileUpload";

import VenueModal from "components/Modal/PromoterModal";

import "./CreateUser.css"; // Import the CSS file

const initialPermissions = {
  dashboard: false,
  logs: false,
  promoter:false,
  ring: false, 
  users: { show: false, create: false, update: false, delete: false },    
  businessManagement: { show: false, create: false, update: false, delete: false },
  appManagement: { show: false, create: false, update: false, delete: false },
  activityManagement:  { show: false, create: false, update: false, delete: false },
  eventManagement:  { show: false, create: false, update: false, delete: false },
  bucketManagement:  { show: false, create: false, update: false, delete: false },
  outingManagement:  { show: false, create: false, update: false, delete: false },
  categoryManagement: { show: false, create: false, update: false, delete: false },
  subscriptionManagement: { show: false, create: false, update: false, delete: false },
  yachtManagement: { show: false, create: false, update: false, delete: false },
  adManagement: { show: false, create: false, update: false, delete: false },
  offerManagement: { show: false, create: false, update: false, delete: false },
  HotDealManagement: { show: false, create: false, update: false, delete: false },
  HomeBlockManagement: { show: false, create: false, update: false, delete: false },
  NotificationManagement: { show: false, create: false, update: false, delete: false },
  adminManagement: { show: false, create: false, update: false, delete: false },
};

const defaultSuperAdminPermissions = {
  dashboard: true,
  logs: true,
  promoter:true,
  ring: true,  
  users: { show: true, create: true, update: true, delete: true },  
  businessManagement: { show: true, create: true, update: true, delete: true },
  activityManagement:  { show: true, create: true, update: true, delete: true },
  eventManagement: { show: true, create: true, update: true, delete: true },
  bucketManagement: { show: true, create: true, update: true, delete: true },
  outingManagement: { show: true, create: true, update: true, delete: true },
  appManagement: { show: true, create: true, update: true, delete: true },
  yachtManagement: { show: true, create: true, update: true, delete: true },
  adManagement: { show: true, create: true, update: true, delete: true },
  categoryManagement: { show: true, create: true, update: true, delete: true },
  subscriptionManagement: { show: true, create: true, update: true, delete: true },
  offerManagement: { show: true, create: true, update: true, delete: true },
  HotDealManagement: { show: true, create: true, update: true, delete: true },
  HomeBlockManagement: { show: true, create: true, update: true, delete: true },
  NotificationManagement: { show: true, create: true, update: true, delete: true },
  adminManagement: { show: true, create: true, update: true, delete: true },
};

export default function CreateUser() {
  const location = useLocation();
  const state = location.state?.row;
  const [model, setModel] = useState(false);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [userData, setUserData] = useState({ type: 'super-admin' });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openLightbox, setOpenLightBox] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [permissions, setPermissions] = useState(defaultSuperAdminPermissions);
  const [permissionsModal, setPermissionsModal] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };

  const togglePermissionsModal = () => {
    setPermissionsModal(!permissionsModal);
  };

  const selectRow = ( data, id) => {

    console.log("data", data)

    setVenueId(id);
    setVenueText(`${data.first_name} ${data.last_name}`);
    setVenueImage(data?.image);
    closeModal();
  };

  const handleTypeChange = (e) => {
    const type = e.target.value;
    setUserData({ ...userData, type });

    let updatedPermissions = { ...initialPermissions };

    switch (type) {
      case 'admin':
        updatedPermissions = { ...defaultSuperAdminPermissions };
        break;
      case 'sub-admin':
        for (let key in updatedPermissions) {
          if (['adminManagement'].includes(key)) {
            continue;
          }
          if (typeof updatedPermissions[key] === 'boolean') {
            updatedPermissions[key] = true;
          } else {
            for (let subKey in updatedPermissions[key]) {
              updatedPermissions[key][subKey] = true;
            }
          }
        }
        break;
      case 'stake-holder':
        updatedPermissions = {
          dashboard: true,
          analytics: true,
        };
        break;
      case 'data-entry':
        updatedPermissions = {
          dashboard: true,
          users: { show: true, create: false, update: false, delete: false },  
          businessManagement: { show: true, create: true, update: true },
          activityManagement:  { show: true, create: true, update: true },
          eventManagement: { show: true, create: true, update: true },
          yachtManagement: { show: true, create: true, update: true },
          adManagement: { show: true, create: true, update: true },
          NotificationManagement: { show: true, create: true, update: true },
          appManagement: { show: true, create: true, update: true },
          categoryManagement: { show: true, create: true, update: true },
          offerManagement: { show: true, create: true, update: true },
          HotDealManagement: { show: true, create: true, update: true },
          HomeBlockManagement: { show: true, create: true, update: true },
        };
        break;
      default:
        updatedPermissions = defaultSuperAdminPermissions;
    }

    setPermissions(updatedPermissions);
  };

  const createUser = async (e) => {
    e.preventDefault();

    if (!userData.first_name) {
      toast.error("Please enter First Name");
      return;
    }
    // if (!userData.last_name) {
    //   toast.error("Please enter Last Name");
    //   return;
    // }
    if (!userData.email) {
      toast.error("Please enter Email");
      return;
    }
    // if (!userData.phone) {
    //   toast.error("Please enter Phone");
    //   return;
    // }    

    if(!userData.country_code){
      toast.error("Please Select country");
      return;
    }

    if(!userData.phone){
      toast.error("Please enter Phone number");
      return;
    }


    if(userData.phone){
      if (userData?.phone?.length < 7 || userData?.phone?.length > 14) {
        toast.error("Please enter a valid Phone");
        return;
      }
    }

    
    if (!userData.password) {
      toast.error("Please enter Password");
      return;
    }
    // if (!userData.address) {
    //   toast.error("Please enter Address");
    //   return;
    // }

    const formData = new FormData();
    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    setStartLoader(true);
    const selectedImageRes = await postRequestForm("comman/img/upload", formData);
    setStartLoader(false);
    
    const payload = {
      ...userData,  
      permissions: userData.type === 'admin' ? null : permissions,
    };

    if(venueId && userData?.canManagePromoter){
      payload.promoterId = venueId;
      payload.permissions.promoterAccess = true;
    }

    if (selectedImageRes?.data?.url) {
      payload.image = selectedImageRes.data.url;
    } else {
      console.error("Image URL not found in the response.");
    }

    setLoading(true);
    const response = await postRequest("user/admin/create", payload);
    setLoading(false);

    if (response.status === 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/roles");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    setUserData(prevState => ({ ...prevState, [index]: value }));
  };

  const updatePermissions = (index, subIndex, value) => {
    setPermissions(prevState => ({
      ...prevState,
      [index]: subIndex
        ? { ...prevState[index], [subIndex]: value }
        : value
    }));
  };

  const renderPermissionField = (permission, subPermissions = null) => (
    <FormGroup key={permission} className="permission-group">
      <Label className="d-block parent-permission">
        {permission.charAt(0).toUpperCase() + permission.slice(1)}
      </Label>
      {subPermissions
        ? Object.keys(subPermissions).map(subPerm => (
            <div key={subPerm} className="form-check form-check-inline child-permission">
              <Input
                type="checkbox"
                className="form-check-input"
                onChange={(e) => updatePermissions(permission, subPerm, e.target.checked)}
                checked={permissions[permission]?.[subPerm] || false}
              />
              <Label className="form-check-label">
                {subPerm.charAt(0).toUpperCase() + subPerm.slice(1)}
              </Label>
            </div>
          ))
        : (
          <Input
            type="checkbox"
            onChange={(e) => updatePermissions(permission, null, e.target.checked)}
            checked={permissions[permission] || false}
          />
        )
      }
    </FormGroup>
  );

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Admin Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Role Access Management</li>
              <li className="breadcrumb-item">Create Access</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Create Role</h4>
                <Form onSubmit={createUser}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            First Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("first_name", e.target.value)
                              }
                              value={userData.first_name || ""}
                              name="first_name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Last Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("last_name", e.target.value)
                              }
                              value={userData.last_name || ""}
                              name="last_name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="email"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              value={userData.email || ""}
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="tel"
                              onChange={(e) =>
                                updateValue("phone", e.target.value)
                              }
                              value={userData.phone || ""}
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Country Code
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("country_code", e.target.value)
                              }
                              value={userData.country_code || ""}
                              name="phone"
                              className="form-control"
                            >
                              <option value="">Select Country Code</option>
                              <option value="+971">UAE (+971)</option>
                              <option value="+966">KSA (+966)</option>
                              <option value="+961">Lebanon (+961)</option>
                              <option value="+91">IND (+91)</option>
                              <option value="+44">England (+44)</option>
                              <option value="+1">USA (+1)</option>                             
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Password
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="password"
                              onChange={(e) =>
                                updateValue("password", e.target.value)
                              }
                              value={userData.password || ""}
                              name="password"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Address
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("address", e.target.value)
                              }
                              value={userData.address || ""}
                              name="address"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={handleTypeChange}
                              value={userData.type || "super-admin"}
                              name="type"
                              className="form-select"
                            >
                             
                              <option value="admin">Admin</option>
                              <option value="sub-admin">Sub Admin</option>
                              <option value="data-entry">Data Entry</option>
                             
                            </select>
                          </div>
                        </div>
                        {userData.type !== 'admin' && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Permissions
                            </label>
                            <div className="col-sm-9">
                              <Button color="primary" onClick={togglePermissionsModal}>
                                Set Permissions
                              </Button>
                            </div>
                          </div>
                        )}

                        {userData.type === 'sub-admin' && (
                          <div className="row mb-4">
                             <label className="col-sm-3 col-form-label">                            
                              Manage Promoter ?
                            </label>
                            <div className="col-sm-6">
                              <select
                                 onChange={(e) =>
                                  updateValue("canManagePromoter", e.target.value)
                                }
                                value={userData.canManagePromoter || "false"}
                                name="type"
                                className="form-select"
                              >
                              
                                <option value="true">Yes</option>
                                <option value="false">No</option>                              
                              </select>
                            </div>
                          </div>
                        )}

                        {userData.canManagePromoter && (
                           <div className="row mb-4">
                           <label className="col-sm-3 col-form-label">
                             Select Promoter
                           </label>

                           <div
                             className="col-sm-6"
                             onClick={() => toggleModel()}
                           >
                             {venueText ? (                               
                               <div class="input-group">
                                 <button
                                   class="btn"
                                   style={{
                                     border: "1px solid #ced4da",
                                     background: "#e60489",
                                     color: "#ffff",
                                   }}
                                   type="button"
                                   id="chooseVenueBtn"
                                   onclick={() => toggleModel()}
                                 >
                                   Choose Promoter
                                 </button>
                                 <div
                                   style={{
                                     border: "1px solid #ced4da",
                                     width: "16px",
                                   }}
                                 ></div>
                                 <img
                                   src={venueImage}
                                   style={{
                                     width: "40px",
                                     height: "40px",
                                     border: "1px solid #ced4da",
                                     borderRight: "none",
                                     borderLeft: "none",
                                   }}
                                 ></img>
                                 <input
                                   type="text"
                                   style={{ borderLeft: "none" }}
                                   class="form-control"
                                   readonly
                                   value={venueText}
                                   id="selectedVenue"
                                   placeholder="No promoter chosen"
                                 />
                               </div>
                             ) : (                               
                               <div class="input-group">
                                 <button
                                   class="btn"
                                   style={{
                                     border: "1px solid #ced4da",
                                     background: "#e60489",
                                     color: "#ffff",
                                   }}
                                   type="button"
                                   id="chooseVenueBtn"
                                   onclick={() => toggleModel()}
                                 >
                                   Choose Promoter
                                 </button>
                                 <input
                                   type="text"
                                   class="form-control"
                                   readonly
                                   id="selectedVenue"
                                   placeholder="No promoter chosen"
                                 />
                               </div>
                             )}
                           </div>
                         </div>
                        )}


                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <Modal isOpen={permissionsModal} toggle={togglePermissionsModal} size="lg">
        <ModalHeader toggle={togglePermissionsModal}>Set Permissions</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12} className="d-flex">
              {['dashboard','logs','promoter','ring'].map(permission => (
                <div className="permission-group-single" key={permission}>
                  {renderPermissionField(permission)}
                </div>
              ))}
            </Col>
          </Row>
          <Row>
            {Object.keys(initialPermissions).filter(permission => !['dashboard', 'logs', 'ring', 'promoter'].includes(permission)).map(permission => 
              typeof initialPermissions[permission] === 'boolean' 
                ? renderPermissionField(permission) 
                : renderPermissionField(permission, initialPermissions[permission])
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={togglePermissionsModal}>
            Save
          </Button>{' '}
          <Button color="secondary" onClick={togglePermissionsModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />
    </React.Fragment>
  );
}
