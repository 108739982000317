import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
//import filter modal
import FilterModal from "../../components/Modal/FilterModal";
import CustomAvatar from "../../components/Common/Avatar.js";

import { useHistory } from 'react-router-dom'; // For React Router v5
import {
  postRequest,
  deleteRequest,
  postRequestForm,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import VenueModal from "../../components/Modal/VenueModalOrg.js";

import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
const filterObj = {
  userName: "",
  venue: "",
  offer: "", 
  qty: "",
  price: "",
  paymentStatus: "",
  startDate: "",
  endDate: ""
};

const OfferReportManagmentlist = (props) => {
  const history = useHistory();
  
    const [reportsData, setReportsData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [currentFPage, setCurrentFPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [sizePerPageOrg, setSizePerPage] = useState(10);
    const [resetSearch, setResetSearch] = useState(false);
    const [sort, setsort] = useState("createdAt");
    const [sortOrder, setsortOrder] = useState("desc");
    const [selectedItems, setselectedItems] = useState([]);  

    const [filterActive, setFilterActive] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [period, setPeriod] = useState("daily"); // default period
    const [filter, setFilter] = useState({
      venue: "",
      offer: "",
      startDate: "",
      viewType: "logs",
      endDate: "",
      sortOrder: "FIFO",
    });
    const [offers, setOffers] = useState([]);
    const [venues, setVenues] = useState([]);
    const [model, setModel] = useState(false);
    const [venueModel, setVenueModel] = useState(false);
    const [venueText, setVenueText] = useState("");
    const [venueImage, setVenueImage] = useState("");
    const [openLightbox, setOpenLightBox] = useState(false);
    const [image, setImage] = useState("");

  useEffect(() => {
    businessManagment();
  }, [currentPage]);

  const businessManagmentSearch = async () => {
    if(currentPage == 1){
      businessManagment();
      return;
    }
    setCurrentPage(1);
  };

  const fetchOffers = async (venue = null) => {
    const param = { page: 1, limit: 100000, sortField: "title", sortOrder: "asc" };
    if(venue){
      param.venueId = venue;
    }
    const theme = await postRequest("venue/offer/list/admin", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, title: item.title };
      });
      setOffers(options);
    }
  };

  const toggleVenueModel = () => {
    setVenueModel(!venueModel);
  };



  useEffect(() => {    
    fetchOffers();
    }, []);


    useEffect(() => {
      fetchOffers(filter.venue);
      }, [filter.venue]);

  useEffect(() => {
    // Reset currentPage to 1 if it's not already 1
    
    //setFilterActive(true);
    
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      // If currentPage is already 1, fetch data
      businessManagment();
    }
  }, [sizePerPageOrg, sort, sortOrder]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const fetchFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "business_name") {
          var filterObject = {
            "business_id.name": filter["business_name"],
          };
        } else {
          var filterObject = {
            [key]: filter[key]?.trim(),
          };
        }

        if(key === "venue"){
          filterObject.type = "venueId";
        } else if(key === "offer"){
          filterObject.type = "offerId";
        } else if(key === "startDate" || key === "endDate"){
          filterObject.type = "date-range";
        } 

        if (key === "userName" || key === "orderName" || key === "qty" || key === "price") {
          filterObject.type = "regex";
        } else if (key === "paymentStatus") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });   
    return filterArgs
  } 

  const applyFilter = () => {    
    setFilterActive(true);


    // wait for 500ms to close the modal

   
    reloadData();

   
        
   
    toggleModel();
    //businessManagment();
  };

  const reloadData = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      // If currentPage is already 1, fetch data
      businessManagment();
    }

    if(currentPage == 1){
      businessManagment();
    }else{
      setCurrentPage(1);
    }
  };


  const resetFilter = () => {
    setFilterActive(false);
    setFilter({
        venue: "",
        offer: "",
        startDate: "",
        endDate: "",
        sortOrder: "FIFO",
    });
    setPeriod("daily");
    setCurrentPage(1);
    businessManagment(true);
    toggleModel();
    setVenueText("");
    setVenueImage("");
  };

  const closeModalVenue = () => {
    setVenueModel(false);
  };

  const selectVenueRow = (id, name, venue) => {
    let filterTemp = { ...filter };
    filterTemp.venue = id;
    setFilter(filterTemp);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModalVenue();
  };

  function actionFormatter(cellContent, row) {
    const handleViewLogs = (paymentStatus) => {

      let url = '/offer/sales/logs';

      if(filter.viewType === "statement"){
        url = '/offer/sales/statement';
      }else if(filter.viewType === "journal"){
        url = '/offer/sales/journal';
      }else if(filter.viewType === "ledger"){
        url = '/offer/sales/ledger';
      }      
      
      
      // For React Router v5
      history.push(url, {
        startDate: row.startDate,
        endDate: row.endDate,
        paymentStatus: paymentStatus,
      });
  
      // For React Router v6
      // navigate('/offerOrder', {
      //   state: {
      //     startDate: row.startDate,
      //     endDate: row.endDate,
      //     paymentStatus: paymentStatus,
      //   },
      // });
    };
  
    return (
      <div>
        <button
          className="btn btn-primary btn-sm"
          onClick={() => handleViewLogs('paid')}
        >
          Completed
        </button>
        <button
          className="btn btn-warning btn-sm"
          onClick={() => handleViewLogs('unpaid')}
          style={{ marginLeft: '5px' }}
        >
          Abandoned 
        </button>
      </div>
    );
  }






  

 


  function getPackageData(row) {
    const packageId = row.packageId;
    const packages = row.offer?.packages || [];
    return packages.find((pkg) => pkg._id === packageId) || null;
  }

 

  

 

  

 


 
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="period">
      <label className="col-sm-3 col-form-label">Period</label>
      <div className="col-sm-6">
        <select
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          className="form-select"
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="annual">Annual</option>
        </select>
      </div>
    </div>,
    <div className="row mb-4" key="venue">
      <label className="col-sm-3 col-form-label">Select Venue</label>
      <div
        className="col-sm-6"
        onClick={() => {
          toggleVenueModel();
        }}
      >
        {venueText ? (
          <div className="input-group">
            <button
              className="btn"
              style={{
                border: "1px solid #ced4da",
                background: "#e60489",
                color: "#ffff",
              }}
              type="button"
              id="chooseVenueBtn"
              onClick={() => toggleVenueModel()}
            >
              Choose Venue
            </button>
            <div
              style={{
                border: "1px solid #ced4da",
                width: "16px",
              }}
            ></div>
            <img
              src={venueImage}
              style={{
                width: "40px",
                height: "40px",
                border: "1px solid #ced4da",
                borderRight: "none",
                borderLeft: "none",
              }}
              alt="Venue"
            ></img>
            <input
              type="text"
              style={{ borderLeft: "none" }}
              className="form-control"
              readOnly
              value={venueText}
              id="selectedVenue"
              placeholder="No venue chosen"
            />
          </div>
        ) : (
          <div className="input-group">
            <button
              className="btn"
              style={{
                border: "1px solid #ced4da",
                background: "#e60489",
                color: "#ffff",
              }}
              type="button"
              id="chooseVenueBtn"
              onClick={() => toggleVenueModel()}
            >
              Choose Venue
            </button>
            <input
              type="text"
              className="form-control"
              readOnly
              id="selectedVenue"
              placeholder="No venue chosen"
            />
          </div>
        )}
      </div>
    </div>,
    <div className="row mb-4" key="offer">
      <label className="col-sm-3 col-form-label">Select Offer</label>
      <div className="col-sm-6">
        <select
          value={filter.offer}
          onChange={(e) => setFilter({ ...filter, offer: e.target.value })}
          className="form-select"
        >
          <option value="">Select Offer</option>
          {offers.map((item) => (
            <option value={item.id} key={item.id}>
              {item.title}
            </option>
          ))}
        </select>
      </div>
    </div>,
    <div className="row mb-4" key="startDate">
      <label className="col-sm-3 col-form-label">Start Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.startDate}
          onChange={(e) => setFilter({ ...filter, startDate: e.target.value })}
          name="startDate"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="endDate">
      <label className="col-sm-3 col-form-label">End Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.endDate}
          onChange={(e) => setFilter({ ...filter, endDate: e.target.value })}
          name="endDate"
          className="form-control"
        />
      </div>
    </div>,
    <div className="row mb-4" key="sortOrder">
      <label className="col-sm-3 col-form-label">Sort Order</label>
      <div className="col-sm-6">
        <select
          value={filter.sortOrder}
          onChange={(e) => setFilter({ ...filter, sortOrder: e.target.value })}
          className="form-select"
        >
          <option value="FIFO">FIFO</option>
          <option value="LIFO">LIFO</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="sortOrder">
    <label className="col-sm-3 col-form-label">View Type</label>
    <div className="col-sm-6">
      <select
        value={filter.viewType}
        onChange={(e) => setFilter({ ...filter, viewType: e.target.value })}
        className="form-select"
      >
        <option value="logs">Logs</option>
        <option value="statement">Statement</option>
        <option value="journal">Journal</option>
        <option value="ledger">Ledger</option>
      </select>
    </div>
    </div>, 

   
    
    

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  // const columns = [
  //   {
  //     text: "S.NO",
  //     formatter: SNoFormatter,
  //     sort: false,
  //   },
  //   {
  //     dataField: "user.name",
  //     formatter: UserFormatter,
  //     text: "User Name",
  //     sort: false,
  //   },

  //   {
  //     dataField: "Item",
  //     formatter: ItemFormatter,
  //     text: "Order Detail",
  //     sort: false,
  //   },
  //   // {
  //   //   dataField: "type",  
  //   //   text: "Type",
  //   //   sort: false,
  //   // },

  //   {
  //     dataField: "qty",  
  //     text: "Qty",
  //     sort: false,
  //   },

  //   {
  //     dataField: "price",  
  //     text: "Price",
  //     sort: false,
  //   },

  //   // {
  //   //   dataField: "Date",  
  //   //   formatter: dateTimeFormatter,
  //   //   text: "Date & Time",
  //   //   sort: false,
  //   // },
  //   {
  //     dataField: "isGift",
  //     formatter: giftFormatter,
  //     text: "Send Gift",     
  //     sort: false,


  //   },
   
  //   {
  //     dataField: "status",
  //     text: "Status",     
  //     sort: false,
  //   },

  //   {
  //     dataField: "createdAt",
  //     formatter: dateFormatter,
  //     text: "Created At",
  //     sort: false,
  //   }
   
  // ];
  
  const columns = [
    {
      dataField: "Day",
      text: "Period",
      sort: false,
    },
    {
      dataField: "Total QTY Sold (paid)",
      text: "Total QTY Sold",
      sort: false,
    },
    {
      dataField: "Selling Price (paid)",
      text: "Gross Sales",
      sort: false,
    },
   
    {
      dataField: "Total Discount Price (paid)",
      text: "Total Discount Price",
      sort: false,
    },
    {
      dataField: "Total Paid Amount (paid)",
      text: "Net Sales",
      sort: false,
    },
    {
      dataField: "Total Failed / Pending Transaction Amount (unpaid)",
      text: "Total Failed/Pending Amount",
      sort: false,
    },
    {
      dataField: 'actions',
      text: 'Transactions',
      formatter: actionFormatter,
      sort: false,
    },
  ];
  
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");   
  };
  useEffect(() => {  
    if(!resetSearch) return;
    if (currentPage == 1) {
      businessManagment([], true);
      return;
    }
    setCurrentPage(1);    
  }, [resetSearch]);
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          {/* <button type="button" className=" btn btn-info  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/updateBusiness/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-pencil fs-5 text-light"></i>
            </Link>{" "}
          </button>
          <button type="button" className=" btn btn-primary  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/resetBusinessPassword/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-retweet fs-5 text-light"></i>
            </Link>{" "}
          </button> */}
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const businessManagment = async (resetFilter = false) => {
    setLoader(true);

  const payload = {
    limit: parseInt(sizePerPageOrg),
    page: currentPage,
    sortField: sort,
    sortOrder: sortOrder === "desc" ? "LIFO" : "FIFO",
    period: period,
    type: "offer",
  };

  // if (filterActive && !resetFilter) {
    
  // }

    if(filter.venue){
      payload.venueId = filter.venue;
    }

    if(filter.offer){
      payload.offerId = filter.offer;
    }

    if(filter.startDate){
      payload.startDate = filter.startDate;
    }

    if(filter.endDate){
      payload.endDate = filter.endDate;
    }

    if(filter.sortOrder){
      payload.sortOrder = filter.sortOrder;
    }

    // payload.venueId = filter.venue;
    // payload.offerId = filter.offer;
    // payload.startDate = filter.startDate;
    // payload.endDate = filter.endDate;
    // payload.sortOrder = filter.sortOrder;

  try {
    const response = await postRequest(`subscription/order/report/list`, payload);
    const data = response.data;
    setTotalPage(data.count || 0);
    setCurrentFPage(data.page || 1);
    setReportsData(data.list || []);
  } catch (error) {
    console.error("Failed to fetch data:", error);
  } finally {
    setLoader(false);
  }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this business",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed && result.value === randomWord) {
        const response = await deleteRequest(`venue/business/delete`, {
          businessId: id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          businessManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const [itemsdata, setItems] = useState([]);

 

  

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("venue/business/delete", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      businessManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    style: { background: "#c8e6c9" },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Offer Sales Report</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Offer Orders</li>:{" "}
                <li className="breadcrumb-item">Report </li>
              </ol>
            </div>
          </Col>
      
          <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={reportsData}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3 d-flex justify-content-between align-items-center">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>
                                  
                               
                                  <div className="filter-item d-flex align-items-center">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    {/* <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        businessManagmentSearch();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger"
                                    >
                                      Reset
                                    </button> */}
                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info mx-2 "
                                    >
                                      Filter
                                    </button>
                                  

                                  </div>
                                  
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => <div>No data available</div>}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    // classes="table-custom-striped"
                                    classes="table-custom-border"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />

<VenueModal
        show={venueModel}
        closeModal={closeModalVenue}
        selectRow={selectVenueRow}
      />

    </>
  );
};

export default OfferReportManagmentlist;
