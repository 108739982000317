import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CreatePromo.css"; // Same stylesheet used by CreatePromo

import { postRequest } from "../../components/Common/Utils.js";

// Modals & Components
import MultipleSelectUserList from "components/Modal/MultipleUserSelect.js";
import MultipleSelectDealList from "components/Modal/MultipleDealSelect.js";
import MultipleSelectOfferList from "components/Modal/MultipleOfferSelect.js";
import MultipleSelectPackageList from "components/Modal/MultipleSelectPackageList.js";

import SelectedUsers from "./SelectedUsers.js";
import SelectedDeals from "./SelectedDeals.js";
import SelectedOffers from "./SelectedOffers.js";
import SelectedPackages from "./SelectedPackages.js";

export default function UpdatePromocode() {
  const history = useHistory();
  const { id: promocodeId } = useParams();

  // -------------------- STATE --------------------
  const [loading, setLoading] = useState(false);

  // The main form data, paralleling your CreatePromo structure:
  const [promoData, setPromoData] = useState({
    // Basic details
    applyOn: "cumulative", // "totalPrice" | "discountedPrice"
    title: "",
    description: "",
    claimCode: "",
    quantity: "",
    userScope: "all", // "all" => public, "specific" => private
    discountType: "flat",
    discount: "",
    maxDiscount: "",
    minPurchase: "",
    maxApplyPerUser: "",
    applyToAllItems: true, // true => target=all, false => target=specific
    startDate: null,
    endDate: null,
  });

  // Real-time Claim Code validation
  const [claimCodeStatus, setClaimCodeStatus] = useState({ status: "", message: "" });

  // If userScope=specific => store selected users
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  // Items selection
  const [selectDeals, setSelectDeals] = useState(false);
  const [selectOffers, setSelectOffers] = useState(false);

  const [selectedDealIds, setSelectedDealIds] = useState([]);
  const [selectedOfferIds, setSelectedOfferIds] = useState([]);
  const [selectedPackageIds, setSelectedPackageIds] = useState([]);

  // Modals
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [dealModalOpen, setDealModalOpen] = useState(false);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [packageModalOpen, setPackageModalOpen] = useState(false);

  // -------------------- EFFECTS: Fetch existing promo --------------------
  useEffect(() => {
    if (promocodeId) {
      fetchPromocodeDetail(promocodeId);
    }
    // eslint-disable-next-line
  }, [promocodeId]);

  const fetchPromocodeDetail = async (id) => {
    try {
      setLoading(true);
      const payload = { promocodeId: id };
      const response = await postRequest("venue/promo-code/detail/admin", payload);
      setLoading(false);

      if (response.status !== 1 || !response.data?.length) {
        toast.error(response.message || "Failed to fetch promocode detail");
        return;
      }

      const promo = response.data[0];

      // MAP the "type" => userScope
      let userScopeValue = "all";
      if (promo.type === "private") {
        userScopeValue = "specific";
      } else if (promo.type === "public") {
        userScopeValue = "all";
      }

      // MAP the "target" => applyToAllItems
      let applyAllItems = true;
      if (promo.target === "specific") {
        applyAllItems = false;
      }

      // Fill form data
      setPromoData((prev) => ({
        ...prev,
        // applyOn => your API might store it or not; if it's stored, set it. Otherwise default:
        applyOn: promo.applyType || "cumulative",
        title: promo.title || "",
        description: promo.description || "",
        claimCode: promo.claimCode || "",
        quantity: promo.qty || "",
        // userScope => derived from promo.type
        userScope: userScopeValue,
        // discount fields if "public"
        discountType: promo.discountType || "flat",
        discount: promo.discount || "",
        maxDiscount: promo.maxDiscount || "",
        minPurchase: promo.minPurchase || "",
        maxApplyPerUser: promo.maxApplyPerUser || "",
        // applyToAllItems => derived from promo.target
        applyToAllItems: applyAllItems,
        // dates
        startDate: promo.startDate ? new Date(promo.startDate) : null,
        endDate: promo.endDate ? new Date(promo.endDate) : null,
      }));

      // If private, load selected users
      if (promo.users?.length) {
        const userArray = promo.users.map((u) => ({
          _id: u.userId,
          first_name: u.first_name,
          last_name: u.last_name,
          email: u.email,
          discountType: u.discountType,
          discount: u.discount,
          maxDiscount: u.maxDiscount,
          minPurchase: u.minPurchase,
          maxApplyPerUser: u.maxApplyPerUser,
        }));
        setSelectedUserIds(userArray);
      }

      // deals
      if (promo.dealIds?.length) {
        setSelectDeals(true); // Because there must be deals selected
        if (promo.deals?.length) {
          const dealsArr = promo.deals.map((d) => ({
            _id: d._id,
            title: d.title,
            actualPrice: d.actualPrice,
          }));
          setSelectedDealIds(dealsArr);
        } else {
          // fallback if only IDs
          const dealsArr = promo.dealIds.map((did) => ({ _id: did }));
          setSelectedDealIds(dealsArr);
        }
      }

      // packages
      if (promo.packageIds?.length) {
        setSelectOffers(true); // Because there must be offers/packages
        if (promo.packages?.length) {
          const packagesArr = promo.packages.map((p) => ({
            _id: p._id,
            title: p.title,
            amount: p.amount,
            venueName: p.venueName,
            offerName: p.offerName,
            description: p.description,
          }));
          setSelectedPackageIds(packagesArr);
        } else {
          const packagesArr = promo.packageIds.map((pid) => ({ _id: pid }));
          setSelectedPackageIds(packagesArr);
        }
      }

      // offers
      if (promo.offers?.length) {
        const offerArr = promo.offers.map((o) => ({
          _id: o._id,
          title: o.title,
          image: o.image,
          venueName: o.venueName,
        }));
        setSelectedOfferIds(offerArr);
      }

      // If either deals or packages are selected, it means applyToAllItems = false
      if (promo.target === "specific") {
        setSelectDeals(!!promo.dealIds?.length);
        setSelectOffers(!!promo.packageIds?.length || !!promo.offers?.length);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast.error("Could not fetch promocode detail!");
    }
  };

  // -------------------- HANDLERS --------------------
  // Update any field in form
  const updateField = (field, value) => {
    setPromoData((prev) => ({ ...prev, [field]: value }));
  };

  // Real-time check for Claim Code
  const handleClaimCodeChange = async (e) => {
    const code = e.target.value;
    updateField("claimCode", code);

    // Clear if empty
    if (!code) {
      setClaimCodeStatus({ status: "", message: "" });
      return;
    }

    // Basic length check
    if (code.length < 4 || code.length > 8) {
      setClaimCodeStatus({
        status: "error",
        message: "Claim code must be 4-8 characters",
      });
      return;
    }

    try {
      const response = await postRequest("venue/promo-code/check", {
        claimCode: code,
      });
      if (response.status === 1) {
        setClaimCodeStatus({ status: "success", message: response.message });
      } else {
        setClaimCodeStatus({ status: "error", message: response.message });
      }
    } catch (err) {
      console.error(err);
      setClaimCodeStatus({
        status: "error",
        message: "Error checking promo code",
      });
    }
  };

  // Submitting => build final payload & update
  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      applyOn,
      title,
      description,
      claimCode,
      quantity,
      userScope,
      discountType,
      discount,
      maxDiscount,
      minPurchase,
      maxApplyPerUser,
      applyToAllItems,
      startDate,
      endDate,
    } = promoData;

    // Basic validation
    if (!title) {
      toast.error("Title is required");
      return;
    }
    if (!description) {
      toast.error("Description is required");
      return;
    }
    if (!claimCode) {
      toast.error("Claim code is required");
      return;
    }
    if (claimCodeStatus.status === "error") {
      toast.error("Claim code is not valid");
      return;
    }
    if (!quantity || quantity < 1) {
      toast.error("Quantity must be at least 1");
      return;
    }
    if (!startDate || !endDate) {
      toast.error("Both start and end dates are required");
      return;
    }
    if (startDate >= endDate) {
      toast.error("Start date cannot be after end date");
      return;
    }

    // userScope => "all" => check discount fields
    // or => "specific" => must select at least 1 user
    let users = [];
    if (userScope === "all") {
      if (!discount || +discount <= 0) {
        toast.error("Discount must be greater than 0");
        return;
      }
      if (!maxDiscount || +maxDiscount <= 0) {
        toast.error("Max discount must be greater than 0");
        return;
      }
      if (!minPurchase || +minPurchase <= 0) {
        toast.error("Minimum purchase must be greater than 0");
        return;
      }
      if (!maxApplyPerUser || +maxApplyPerUser < 1) {
        toast.error("Max usage per user must be at least 1");
        return;
      }
    } else {
      // userScope=specific
      if (!selectedUserIds.length) {
        toast.error("Please select at least one user");
        return;
      }
      // Validate each user’s discount data
      for (let u of selectedUserIds) {
        if (!u.discount || +u.discount <= 0) {
          toast.error(`User [${u.name || u.first_name}] discount invalid`);
          return;
        }
        if (!u.maxDiscount || +u.maxDiscount <= 0) {
          toast.error(`User [${u.name || u.first_name}] max discount invalid`);
          return;
        }
        if (!u.minPurchase || +u.minPurchase <= 0) {
          toast.error(`User [${u.name || u.first_name}] min purchase invalid`);
          return;
        }
        if (!u.maxApplyPerUser || +u.maxApplyPerUser < 1) {
          toast.error(
            `User [${u.name || u.first_name}] max usage per user must be >= 1`
          );
          return;
        }
      }
      // build user data
      users = selectedUserIds.map((u) => ({
        userId: u._id,
        discountType: u.discountType,
        discount: +u.discount,
        maxDiscount: +u.maxDiscount,
        minPurchase: +u.minPurchase,
        maxApplyPerUser: +u.maxApplyPerUser,
      }));
    }

    // applyToAllItems => if false => gather selected deals/offers
    let dealIds = [];
    let packageIds = [];
    if (!applyToAllItems) {
      if (selectDeals) {
        dealIds = selectedDealIds.map((d) => d._id);
      }
      if (selectOffers) {
        packageIds = [
          ...selectedOfferIds.map((o) => o._id),
          ...selectedPackageIds.map((p) => p._id),
        ];
      }
      // If user selected "specific" but has no deals/offers => error
      if (!dealIds.length && !packageIds.length) {
        toast.error("Select at least a deal or an offer/package if not applying to all");
        return;
      }
    }

    // Build final update payload
    // Map back to the original "type" & "target" for the API
    const typeValue = userScope === "all" ? "public" : "private";
    const targetValue = applyToAllItems ? "all" : "specific";

    const finalPayload = {
      promocodeId,
      applyType: applyOn, // either "totalPrice" or "discountedPrice"

      title,
      description,
      claimCode,
      qty: +quantity,

      type: typeValue, // "public" or "private"
      target: targetValue, // "all" or "specific"

      // discount fields if userScope=all
      discountType: userScope === "all" ? discountType : undefined,
      discount: userScope === "all" ? +discount : undefined,
      maxDiscount: userScope === "all" ? +maxDiscount : undefined,
      minPurchase: userScope === "all" ? +minPurchase : undefined,
      maxApplyPerUser: userScope === "all" ? +maxApplyPerUser : undefined,

      users, // only if private

      dealIds,
      packageIds,

      startDate: startDate,
      endDate: endDate,
    };

    setLoading(true);
    try {
      const response = await postRequest("venue/promo-code/update", finalPayload);
      setLoading(false);

      if (response.status === 1) {
        toast.success(response.message || "Promo code updated");
        setTimeout(() => {
          history.push("/promo-code");
        }, 1500);
      } else {
        toast.error(response.message || "Failed to update promo code");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("An error occurred while updating the promo code");
    }
  };

  // -------------------- Modal Toggles / Selections --------------------
  const toggleUserModal = () => setUserModalOpen(!userModalOpen);
  const toggleDealModal = () => setDealModalOpen(!dealModalOpen);
  const toggleOfferModal = () => setOfferModalOpen(!offerModalOpen);
  const togglePackageModal = () => setPackageModalOpen(!packageModalOpen);

  // user selection
  const onSelectedUser = (list) => {
    const enrichedList = list.map((u) => ({
      ...u,
      discountType: "flat",
      discount: "",
      maxDiscount: "",
      minPurchase: "",
      maxApplyPerUser: "",
    }));
    setSelectedUserIds(enrichedList);
  };
  const popSelectedUser = (id) => {
    setSelectedUserIds((prev) => prev.filter((item) => item._id !== id));
  };

  // deals
  const onSelectedDeal = (list) => setSelectedDealIds(list);
  const popSelectedDeal = (id) => {
    setSelectedDealIds((prev) => prev.filter((item) => item._id !== id));
  };

  // offers
  const onSelectedOffer = (list) => setSelectedOfferIds(list);
  const popSelectedOffer = (id) => {
    setSelectedOfferIds((prev) => prev.filter((item) => item._id !== id));
  };

  // packages
  const onSelectedPackage = (list) => setSelectedPackageIds(list);
  const popSelectedPackage = (id) => {
    setSelectedPackageIds((prev) => prev.filter((item) => item._id !== id));
  };

  // -------------------- RENDER --------------------
  return (
    <>
      <ToastContainer />

      <Row className="mb-3 mt-3">
        <Col md={{ size: 8, offset: 2 }}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2>Update Promo Code</h2>
            <Button color="primary" onClick={() => history.goBack()}>
              <i className="fas fa-chevron-left me-1" />
              Back
            </Button>
          </div>

          {loading && (
            <div className="text-center my-4">
              <Spinner color="info" style={{ width: "4rem", height: "4rem" }} />
            </div>
          )}

          {!loading && (
            <Form onSubmit={handleSubmit}>

              {/* Card: "Apply On" Radios */}
              <Card className="shopify-card mb-4">
                <CardBody>
                  <CardTitle tag="h5" className="mb-3">
                    Promo Code Apply On
                  </CardTitle>
                  <div className="mb-2">
                    <Label check>
                      <Input
                        type="radio"
                        name="applyOn"
                        value="cumulative"
                        checked={promoData.applyOn === "cumulative"}
                        onChange={(e) => updateField("applyOn", e.target.value)}
                      />
                      <span className="ms-2">Cumulative</span>
                    </Label>
                  </div>
                  <div className="mb-2">
                    <Label check>
                      <Input
                        type="radio"
                        name="applyOn"
                        value="on-discounted-price"
                        checked={promoData.applyOn === "on-discounted-price"}
                        onChange={(e) => updateField("applyOn", e.target.value)}
                      />
                      <span className="ms-2">On Discounted Price</span>
                    </Label>
                  </div>
                  <div >
                    <Label check>
                      <Input
                        type="radio"
                        name="applyOn"
                        value="override"
                        checked={promoData.applyOn === "override"}
                        onChange={(e) => updateField("applyOn", e.target.value)}
                      />
                      <span className="ms-2">Override</span>
                    </Label>
                  </div>
                </CardBody>
              </Card>

              {/* Card 1: Basic Details */}
              <Card className="shopify-card mb-4">
                <CardBody>
                  <CardTitle tag="h5" className="mb-3">
                    Basic Details
                  </CardTitle>

                  <div className="mb-3">
                    <Label for="title">Title</Label>
                    <Input
                      id="title"
                      value={promoData.title}
                      onChange={(e) => updateField("title", e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Label for="description">Description</Label>
                    <Input
                      id="description"
                      type="textarea"
                      value={promoData.description}
                      onChange={(e) => updateField("description", e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Label for="claimCode">Claim Code</Label>
                    <Input
                      id="claimCode"
                      value={promoData.claimCode}
                      maxLength={8}
                      onChange={handleClaimCodeChange}
                    />
                    {claimCodeStatus.message && (
                      <small
                        style={{
                          color:
                            claimCodeStatus.status === "success"
                              ? "green"
                              : "red",
                        }}
                      >
                        {claimCodeStatus.message}
                      </small>
                    )}
                  </div>


                  <div className="mb-3">
                    <Label for="quantity">Quantity</Label>
                    <Input
                      id="quantity"
                      type="number"
                      min={1}
                      value={promoData.quantity}
                      onChange={(e) => updateField("quantity", e.target.value)}
                    />
                  </div>
                </CardBody>
              </Card>

              {/* Card 2: Select Users */}
              <Card className="shopify-card mb-4">
                <CardBody>
                  <CardTitle tag="h5" className="mb-3">
                    Select Users
                  </CardTitle>

                  {/* userScope => "all" or "specific" */}
                  <div className="mb-3">
                    <Label check>
                      <Input
                        type="radio"
                        name="userScope"
                        value="all"
                        checked={promoData.userScope === "all"}
                        onChange={() => updateField("userScope", "all")}
                      />
                      <span className="ms-2">All Users</span>
                    </Label>
                  </div>

                  <div className="mb-3">
                    <Label check>
                      <Input
                        type="radio"
                        name="userScope"
                        value="specific"
                        checked={promoData.userScope === "specific"}
                        onChange={() => updateField("userScope", "specific")}
                      />
                      <span className="ms-2">Specific Users</span>
                    </Label>
                  </div> <br />

                  {/* If userScope=all => show discount fields */}
                  {promoData.userScope === "all" && (
                    <>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Discount Type</Label>
                            <Input
                              type="select"
                              value={promoData.discountType}
                              onChange={(e) => updateField("discountType", e.target.value)}
                            >
                              <option value="flat">Flat</option>
                              <option value="percentage">Percentage</option>
                            </Input>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Discount</Label>
                            <Input
                              type="number"
                              min={1}
                              value={promoData.discount}
                              onChange={(e) => updateField("discount", e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Max Discount</Label>
                            <Input
                              type="number"
                              min={1}
                              value={promoData.maxDiscount}
                              onChange={(e) =>
                                updateField("maxDiscount", e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Min Purchase</Label>
                            <Input
                              type="number"
                              min={1}
                              value={promoData.minPurchase}
                              onChange={(e) =>
                                updateField("minPurchase", e.target.value)
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-3">
                        <Label>Max Apply Per User</Label>
                        <Input
                          type="number"
                          min={1}
                          value={promoData.maxApplyPerUser}
                          onChange={(e) =>
                            updateField("maxApplyPerUser", e.target.value)
                          }
                        />
                      </div>
                    </>
                  )}

                  {/* If userScope=specific => user modal */}
                  {promoData.userScope === "specific" && (
                    <>
                      <Button
                        color="info"
                        onClick={toggleUserModal}
                        className="mb-3"
                      >
                        <i className="fa fa-plus me-1" />
                        Select Users
                      </Button>

                      {selectedUserIds.length > 0 && (
                        <SelectedUsers
                          selectedUser={selectedUserIds}
                          deleteHandler={popSelectedUser}
                          onUpdate={(updatedData) => setSelectedUserIds(updatedData)}
                        />
                      )}
                    </>
                  )}
                </CardBody>
              </Card>

              {/* Card 3: Select Offer Packages & Deals */}
              <Card className="shopify-card mb-4">
                <CardBody>
                  <CardTitle tag="h5" className="mb-3">
                    Select Offer Packages &amp; Deals
                  </CardTitle>

                  {/* Radio for applyToAllItems */}
                  <div className="mb-2">
                    <Label check>
                      <Input
                        type="radio"
                        name="itemsScope"
                        checked={promoData.applyToAllItems === true}
                        onChange={() => updateField("applyToAllItems", true)}
                      />
                      <span className="ms-2">Apply On All Packages &amp; Deals</span>
                    </Label>
                  </div>
                  <div>
                    <Label check>
                      <Input
                        type="radio"
                        name="itemsScope"
                        checked={promoData.applyToAllItems === false}
                        onChange={() => updateField("applyToAllItems", false)}
                      />
                      <span className="ms-2">Select Packages &amp; Deals</span>
                    </Label>
                  </div>

                  {/* If not applying to all => show checkboxes & selection */}
                  {!promoData.applyToAllItems && (
                    <div className="mt-3">
                      <div className="d-flex gap-4 mb-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={selectDeals}
                            onChange={(e) => setSelectDeals(e.target.checked)}
                          />
                          <span className="ms-2">Deals</span>
                        </Label>

                        <Label check>
                          <Input
                            type="checkbox"
                            checked={selectOffers}
                            onChange={(e) => setSelectOffers(e.target.checked)}
                          />
                          <span className="ms-2">Offers &amp; Packages</span>
                        </Label>
                      </div><br />

                      {/* If "Deals" is checked => show button & selected deals */}
                      {selectDeals && (
                        <div className="mb-3">
                          <Button
                            color="info"
                            onClick={toggleDealModal}
                            className="me-2"
                          >
                            <i className="fa fa-plus me-1" />
                            Select Deals
                          </Button> <br /><br />
                          {selectedDealIds.length > 0 && (
                            <SelectedDeals
                              selectedUser={selectedDealIds}
                              deleteHandler={popSelectedDeal}
                            />
                          )}
                        </div>
                      )}

                      {/* If "Offers & Packages" is checked => show buttons & selected items */}
                      {selectOffers && (
                        <>
                          <div className="mb-2">
                            <Button color="info" onClick={togglePackageModal}>
                              <i className="fa fa-plus me-1" />
                              Select Offer Packages
                            </Button><br /><br />
                          </div>
                          {selectedPackageIds.length > 0 && (
                            <SelectedPackages
                              selectedUser={selectedPackageIds}
                              deleteHandler={popSelectedPackage}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>

              {/* Card 4: Active Dates */}
              <Card className="shopify-card mb-4">
                <CardBody>
                  <CardTitle tag="h5" className="mb-3">
                    Active Dates
                  </CardTitle>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>Start Date</Label>
                        <DatePicker
                          selected={promoData.startDate}
                          onChange={(date) => updateField("startDate", date)}
                          className="form-control"
                          minDate={new Date()}
                          placeholderText="Select start date"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>End Date</Label>
                        <DatePicker
                          selected={promoData.endDate}
                          onChange={(date) => updateField("endDate", date)}
                          className="form-control"
                          minDate={new Date()}
                          placeholderText="Select end date"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {/* Submit */}
              <div className="text-end mb-5">
                <Button
                  color="primary"
                  type="submit"
                  disabled={
                    claimCodeStatus.status === "error" ||
                    !promoData.claimCode ||
                    (promoData.userScope === "specific" && selectedUserIds.length === 0)
                  }
                >
                  Update Promo Code
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>

      {/* Modals */}
      <MultipleSelectUserList
        showUser={userModalOpen}
        onSelectUser={onSelectedUser}
        closeUserModal={() => setUserModalOpen(false)}
      />
      <MultipleSelectDealList
        showUser={dealModalOpen}
        onSelectUser={onSelectedDeal}
        closeUserModal={() => setDealModalOpen(false)}
      />
      <MultipleSelectOfferList
        showUser={offerModalOpen}
        onSelectUser={onSelectedOffer}
        closeUserModal={() => setOfferModalOpen(false)}
      />
      <MultipleSelectPackageList
        showUser={packageModalOpen}
        withPackages={true}
        offerIds={selectedOfferIds.map((item) => item._id)}
        onSelectUser={onSelectedPackage}
        closeUserModal={() => setPackageModalOpen(false)}
      />
    </>
  );
}
