import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import Lightbox from "react-image-lightbox";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import HomeBlockDragModal from "./HomeBlockDragModal";
import HomeBlockWebsiteDragModal from "./HomeBlockWebsiteDragModal";

//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
  deleteConfirmationWords,
  convertTimeToFormattedString,
  pushUpdates,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { current } from "@reduxjs/toolkit";

const filterObj = {
  title: "",
  homeBlockType: "",
  sizeName: "",
  status: "",
  createdAt: "",
};

const HomeBlockManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [homeblockdata, sethomeblockdata] = useState([]);
  const [resetSearch, setResetSearch] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [homeblockdataAll, sethomeblockdataAll] = useState([]); 
  const [homeblockdataWebsite, sethomeblockdataWebsite] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [showOrder, setShowOrder] = useState(false);
  const [showWebsite, setShowWebsite] = useState(false);

  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("order");
  const [sortOrder, setsortOrder] = React.useState("asc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [sizeList, setSizeList] = useState([]);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  const [loader2, setLoader2] = useState(false);

  useEffect(() => {
    homeblockManagment();
  }, [currentPage]);

  const homeblockManagmentSearch = async () => {
    if(currentPage == 1){
      homeblockManagment();
      return;
    }
    setCurrentPage(1);
  };



  useEffect(() => {
    if(currentPage == 1){
    homeblockManagment();
    return;
    }
    setCurrentPage(1);
  }, [sizePerPageOrg, sort, sortOrder]);

  // useEffect(() => {
  //   fetchSizeList();
  // }, []);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;

    const formData = new FormData();
    formData.append("homeblockId", actId);
    formData.append("enable", status ? true : false);

    const res = await postRequest("homeblock/update", {
      id: actId,
      enable: status ? false : true,
    });

    if (res.status == 1) {
      toast.success("HomeBlock Status updated Successfully");
      homeblockManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const GetValueOnChangeShow = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;
    

    const res = await postRequest("homeblock/toggle-website-status", {
      id: actId,
      status: status ? false : true,
    });

    if (res.status == 1) {
      toast.success("HomeBlock Website Status updated Successfully");
      homeblockManagment();
      homeblockManagmentWebsite();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };
  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const fetchSizeList = async () => {
    setLoader(true);
    const response = await postRequest("homeblock/size/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          type: item.type,
          ratio: item.ratio,
        };
      });
      setSizeList(list);
    }
    setLoader(false);
  };
  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);       
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };


  const fetchFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (key === "title") {
          filterObject.type = "regex";
        } else if (key === "homeBlockType") {
          filterObject.type = "eq";
        } else if (key === "sizeName") {
          filterObject.type = "regex";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });   
    return filterArgs
  }  


  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key]?.trim(),
        };

        if (key === "title") {
          filterObject.type = "regex";
        } else if (key === "homeBlockType") {
          filterObject.type = "eq";
        } else if (key === "sizeName") {
          filterObject.type = "regex";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    homeblockManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    homeblockManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  // function activeFormatter(cellContent, row) {
  //   return (
  //     <>
  //       {row.enable == 1 ? (
  //         <>
  //           <button
  //             className="btn btn-active"
  //             type="button"
  //             onClick={() => GetValueOnChange((row.enable = false), row._id)}
  //           >
  //             Active
  //           </button>
  //         </>
  //       ) : (
  //         <>
  //           <button
  //             className="btn btn-deactive"
  //             type="button"
  //             onClick={() => GetValueOnChange((row.enable = true), row._id)}
  //           >
  //             Inactive
  //           </button>
  //         </>
  //       )}
  //     </>
  //   );
  // }
  function dateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.createdAt)}</div>;
  }
  function MediaFormatter(cellContent, row) {
    return (
      <>
        <video width="50" height="50" controls>
          <source src={row.videoUrl} type="video/mp4" />
        </video>
      </>
    );
  }

  function SizeFormatter(cellContent, row) {
    return (
      <>
        <p>
          {" "}
          {row?.size ? row?.size?.type + " - " + row?.size?.ratio : "N.A"}{" "}
        </p>
      </>
    );
  }

  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }

  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.cover}
          onClick={() => showLightBox(row.cover)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }

  function VenueFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.venues?.length}</p>
      </div>
    );
  }
  function OfferFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.offers?.length}</p>
      </div>
    );
  }
  function SliderFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.deals?.length ? row?.deals?.length : 0}</p>
      </div>
    );
  }
  function VideoFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.videos?.length}</p>
      </div>
    );
  }
  function CCFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.customComponents?.length}</p>
      </div>
    );
  }
  function CVFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.customVenues?.length}</p>
      </div>
    );
  }
  function COFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.customOffers?.length}</p>
      </div>
    );
  }
  function EFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.events?.length}</p>
      </div>
    );
  }
  function aFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.activities?.length}</p>
      </div>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function activeFormatter(cellContent, row) {
    const toggleStatus = () => {
      const newStatus = row.enable == true ? true : false; // Invert the status
      GetValueOnChange(newStatus, row?._id);
    };

    

    return (
      <label>
        <Toggle
          defaultChecked={row.enable === true} // Check if status is 0 for "Active"
          onChange={toggleStatus}
          icons={false} // Hide the default icons
        /><br></br>
        {row.enable === true ? "Published" : "UnPublished"}
      </label>
    );
  }

  function activeFormatterShow(cellContent, row) {
    const toggleStatus2 = () => {
      const newStatus = row.showInWebsite == true ? true : false; // Invert the status
      GetValueOnChangeShow(newStatus, row?._id);
    };

    if(row.type == "venue" || row.type == "offer"){
      return (

        <label>
          <Toggle
            defaultChecked={row.showInWebsite === true} // Check if status is 0 for "Active"
            onChange={toggleStatus2}
            icons={false} // Hide the default icons
          /><br></br>
          {row.showInWebsite === true ? "Yes" : "No"}
        </label>
      );

    }
  }
  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.title}
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Type</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("homeBlockType", e.target.value)}
          className="form-select"
          value={filter.homeBlockType}
        >
          <option value="">Select Type</option>
          <option value="video">Video</option>
          <option value="offer">Offer</option>
          <option value="slider">Slider</option>
          <option value="venue">Venue</option>
          <option value="ticket">Rayna Ticket</option>
          <option value="custom-venue">Custom Venue</option>
          <option value="custom-offer">Custom offer</option>
          <option value="custom-components">Custom Components</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Size</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.sizeName}
          onChange={(e) => updateFilter("sizeName", e.target.value)}
          name="sizeName"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "title",
      text: "Title",
      sort: false,
    },

    {
      dataField: "type",
      text: "Type",
      sort: false,
    },
    {
      dataField: "size",
      text: "Size",
      formatter: SizeFormatter,
      sort: false,
    },
    // {
    //   dataField: "venues",
    //   text: "Venues",
    //   formatter: VenueFormatter,
    //   sort: false,
    // },
    // {
    //   dataField: "offers",
    //   text: "Offers",
    //   formatter: OfferFormatter,
    //   sort: false,
    // },

    // {
    //   dataField: "sliders",
    //   text: "Deals",
    //   formatter: SliderFormatter,
    //   sort: false,
    // },

    // {
    //   dataField: "videos",
    //   text: "Videos",
    //   formatter: VideoFormatter,
    //   sort: false,
    // },

    // {
    //   dataField: "customComp",
    //   text: "Custom Components",
    //   formatter: CCFormatter,
    //   sort: false,
    // },

    // {
    //   dataField: "customVenue",
    //   text: "Custom Venue",
    //   formatter: CVFormatter,
    //   sort: false,
    // },

    // {
    //   dataField: "customOffer",
    //   text: "Custom Offer",
    //   formatter: COFormatter,
    //   sort: false,
    // },

    // {
    //   text: "Events",
    //   formatter: EFormatter,
    //   sort: false,
    // },

    // {
    //   text: "Activities",
    //   formatter: aFormatter,
    //   sort: false,
    // },
    {
      dataField: "status",
      text: "Status",
      formatter: activeFormatter,
      sort: false,
    },
    {
      dataField: "showInWebsite",
      text: "Show In Website",
      formatter: activeFormatterShow,
      sort: false,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText("");    
  };
  useEffect(() => {  
    if(!resetSearch) return;
    if (currentPage == 1) {
      homeblockManagment([], true);
      return;
    }
    setCurrentPage(1);    
  }, [resetSearch]);
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
        <Tippy content="Detail">
          <button type="button" className=" btn btn-primary text-light">
            {" "}
            <Link
              to={{
                pathname: `/homeblockdetails/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i className="fal fa-eye text-light"></i>
            </Link>{" "}
          </button>
          </Tippy>
          <Tippy content="Edit">
          <button type="button" className=" btn btn-info  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/updateHomeblock/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-pencil fs-5 text-light"></i>
            </Link>{" "}
          </button>
          </Tippy>
          <Tippy content="Delete">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          </Tippy>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const limit = 10;

  const homeblockManagment = async (filterArgs = [], resetFilter = false) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText?.trim();
      }
    }
    if(filterActive && !filterArgs?.length && !resetFilter){
      payload.filterArgs = fetchFilter()
    }
    try {
      const response = await postRequest(`homeblock/list/admin`, payload);
      const data = response.data;
      if(resetSearch){
        setResetSearch(false);
      }
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      sethomeblockdata(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const homeblockManagmentAll = async (filterArgs = []) => {
    setLoader(true);
    const payload = {
      limit: 100000,
      page: 1,
      sortField: "order",
      sortOrder: "asc",
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }
    try {
      const response = await postRequest(`homeblock/list/admin`, payload);
      const data = response.data;
      sethomeblockdataAll(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const homeblockManagmentWebsite = async (filterArgs = []) => {
    setLoader(true);
    const payload = {
      limit: 100000,
      page: 1,
      sortField: "order",
      sortOrder: "asc",
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }
    try {
      const response = await postRequest(`homeblock/fetch-container-data`, payload);
      const data = response.data;
      sethomeblockdataWebsite(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    homeblockManagmentAll();
    homeblockManagmentWebsite();
  }, []);

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteFunction(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteFunction = async (id) => {
    const response = await deleteRequest("homeblock/delete", { id: id });
    if (response.status) {
      // If the delete is successful, call the pushUpdates function
      const pushUpdatesResponse = await pushUpdates({
        type: "home-block",
        id: id,
        receivers: [],
      });

     
      Swal.fire("Deleted!", "Deleted successfully");
      homeblockManagment();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("homeblock/delete", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      homeblockManagment();
      setselectedItems([]);
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const selectRow = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };

  const updateHomeblockData = async (data) => {
    sethomeblockdataAll(data);
    setShowOrder(false);
    const response = await postRequest(`homeblock/update/order`, {
      data: data,
    });

    if(response.status == 1){
      toast.success("Order updated successfully");
      // wait 3 seconds before reloading the page
      setTimeout(() => {
        homeblockManagment();
      }, 3000);

    }else{
      toast.error("Something went wrong");
    }
    
  };

  const updateHomeblockDataWebsite = async (data) => {
    //sethomeblockdataWebsite(data);
    setShowWebsite(false);
    const response = await postRequest(`homeblock/update/website-order`, {
      ...data
    });

    if(response.status == 1){
      toast.success("Order updated successfully");
      // wait 3 seconds before reloading the page
      setTimeout(() => {
        homeblockManagmentWebsite()
      }, 1000);

    }else{
      toast.error("Something went wrong");
    }
    
  };

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };
  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <DndProvider backend={HTML5Backend}>
          <Row>
            <Col className="12">
              <div className="page-title-box">
                <h4>HomeBlock Management</h4>

                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    HomeBlock Management
                  </li>
                  : <li className="breadcrumb-item">HomeBlock List</li>
                </ol>
              </div>
            </Col>
            <div className="col-lg-6 col-md-6 text-end">
            <Button
                onClick={() => setShowWebsite(true)}
                className="btn btn-info"
                style={{ marginRight: "10px" }}
              >
                <i className="fas fa-grip-vertical"></i> Website Order
              </Button>
              <Button
                onClick={() => setShowOrder(true)}
                className="btn btn-info"
                style={{ marginRight: "10px" }}
              >
                <i className="fas fa-grip-vertical"></i> Order
              </Button>
              <Link to="/createHomeblock">
                {" "}
                <Button>
                  {" "}
                  <i class="fas fa-plus"></i> Create
                </Button>{" "}
              </Link>
              &nbsp;&nbsp;&nbsp;
            </div>
            <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
                {loader ? (
                  <CardBody style={{ height: "100px" }}>
                    <Spinner
                      color="info"
                      style={{
                        height: "4rem",
                        width: "4rem",
                        position: "absolute",
                        left: "50%",
                      }}
                    >
                      Loading...
                    </Spinner>
                  </CardBody>
                ) : (
                  <CardBody className="table-shorting">
                    {
                      <PaginationProvider
                        pagination={paginationFactory({
                          page: currentFPage,
                          sizePerPage: sizePerPageOrg,
                          totalSize: totalPage,
                          custom: true,
                        })}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <>
                            <ToolkitProvider
                              keyField="_id"
                              data={homeblockdata}
                              columns={columns}
                            >
                              {(props) => (
                                <Row>
                                  <Col className="col-lg-12 d-flex mb-3">
                                    <label className="mb-0 text-label">
                                      Page Size
                                    </label>

                                    <select
                                      value={sizePerPageOrg}
                                      onChange={handleChange}
                                      className="form-select"
                                      style={{ width: "7%" }}
                                    >
                                      <option value="10">10</option>

                                      <option value="20">20</option>

                                      <option value="50">50</option>
                                    </select>

                                    <label className="mb-0 text-label ms-3">
                                      Sort By:
                                    </label>
                                    <select
                                      value={sort}
                                      onChange={handlesorting}
                                      className="form-select"
                                      style={{ width: "25%" }}
                                    >
                                      <option value="title"> Title</option>
                                      <option value="type"> Type</option>
                                      <option value="sizeId">Size</option>

                                      <option value="createdAt">
                                        Created At
                                      </option>
                                      <option value="order">Order</option>
                                    </select>
                                    <label className="mb-0 text-label ms-3">
                                      Sort Order:
                                    </label>
                                    <select
                                      value={sortOrder}
                                      onChange={handlesortOrder}
                                      className="form-select"
                                      style={{ width: "25%" }}
                                    >
                                      <option value="asc"> Ascending</option>
                                      <option value="desc">Descending</option>
                                    </select>
                                    <div className="filter-item">
                                      {selectedItems.length > 0 && (
                                        <button
                                          className="btn btn-danger mx-2"
                                          onClick={() => {
                                            handlerdeleteMultiple(
                                              selectedItems
                                            );
                                          }}
                                        >
                                          Delete Selected
                                        </button>
                                      )}
                                      <input
                                        type="search"
                                        placeholder="Search"
                                        value={searchText}
                                        class="form-control"
                                        onChange={(e) =>
                                          setSearchText(e.target.value)
                                        }
                                        style={{ width: "30%" }}
                                      />
                                      <button
                                        onClick={() => {
                                          homeblockManagmentSearch();
                                        }}
                                        type="button"
                                        className="btn btn-primary mx-2"
                                      >
                                        Search
                                      </button>
                                      <button
                                        type="button"
                                        onClick={handleReload}
                                        className="btn btn-danger"
                                      >
                                        Reset
                                      </button>
                                      <button
                                        type="button"
                                        onClick={toggleModel}
                                        className="btn btn-info mx-2"
                                      >
                                        Filter
                                      </button>
                                    </div>
                                  </Col>

                                  <Col className="col-lg-12 text-end">
                                    <BootstrapTable
                                      onTableChange={handleTableChange}
                                      noDataIndication={() => <div>No data available</div>}
                                      remote={true}
                                      selectRow={selectRow}
                                      rowClasses={rowClasses}
                                      // classes="table-custom-striped"
                                      classes="table-custom-border"
                                      {...props.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </ToolkitProvider>

                            <Row>
                              <Col className="col-lg-12 text-end">
                                <div className="text-md-right">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </PaginationProvider>
                    }
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </DndProvider>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}

        <HomeBlockDragModal
          show={showOrder}
          closeModal={() => setShowOrder(false)}
          data={homeblockdataAll}
          setData={updateHomeblockData}
        />

        <HomeBlockWebsiteDragModal
          show={showWebsite}
          closeModal={() => setShowWebsite(false)}
          data={homeblockdataWebsite}
          setData={updateHomeblockDataWebsite}
        />

      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default HomeBlockManagementlist;
