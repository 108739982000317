import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
import Toggle from "react-toggle";
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  pushUpdates,
  putRequest,
  postRequestForm,
  deleteConfirmationWords,
  convertTimeToFormattedString,
} from "../../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

import FilterModal from "../../../components/Modal/FilterModal";

const filterObj = {
  title: "",
  description: "",
  venueName: "",
  days: "",
  startTime: "",
  endTime: "",
  allowWhosIn: "",
  createdAt: "",
};

const OfferManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);
  const [offerdata, setofferdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setselectedItems] = useState([]);
  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);

  const [filter, setFilter] = useState({ ...filterObj });
  const [model, setModel] = useState(false);

  const [loader2, setLoader2] = useState(false);

  useEffect(() => {
    offerManagment();
  }, [currentPage]);

  const offerManagmentSearch = async () => {
    if(currentPage == 1){
      offerManagment();
      return;
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    if(currentPage == 1){
    offerManagment();
    return;
    }
    setCurrentPage(1);

  }, [sizePerPageOrg, sort, sortOrder]);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == true ? false : true;

    const formData = new FormData();
    formData.append("offerId", actId);
    formData.append("isPublished", status);

    const res = await putRequestForm("venue/offer/update", formData);

    if (res.status == 1) {
      toast.success("Offer Status updated Successfully");
      offerManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const fetchFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "venueName") {
          var filterObject = {
            "venue.name": filter["venueName"],
          };
        } else {
          var filterObject = {
            [key]: filter[key]?.trim(),
          };
        }

        if (
          key === "title" ||
          key === "description" ||
          key === "venueName" ||
          key === "days"
        ) {
          filterObject.type = "regex";
        } else if (key === "status" || key === "allowWhosIn") {
          filterObject.type = "eq";
        } else if (
          key === "createdAt" ||
          key === "startTime" ||
          key === "endTime"
        ) {
          filterObject.type = "date-eq";
        }


        filterArgs.push(filterObject);
      }
    });   
    return filterArgs
  }

  const applyFilter = () => {
    setFilterActive(true);
    const filterArgs = [];
    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        if (key === "venueName") {
          var filterObject = {
            "venue.name": filter["venueName"],
          };
        } else {
          var filterObject = {
            [key]: filter[key]?.trim(),
          };
        }

        if (
          key === "title" ||
          key === "description" ||
          key === "venueName" ||
          key === "days"
        ) {
          filterObject.type = "regex";
        } else if (key === "status" || key === "allowWhosIn") {
          filterObject.type = "eq";
        } else if (
          key === "createdAt" ||
          key === "startTime" ||
          key === "endTime"
        ) {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });
    toggleModel();
    offerManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilterActive(false)
    setFilter({ ...filterObj });
    toggleModel();
    offerManagment(null, true);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }


  function activeFormatter(cellContent, row) {
    const toggleStatus = () => {
      const newStatus = row.isPublished == true ? true : false; // Invert the status
      GetValueOnChange(newStatus, row?._id);
    };

    return (
      <label>
        <Toggle
          defaultChecked={row.isPublished === true} // Check if status is 0 for "Active"
          onChange={toggleStatus}
          icons={false} // Hide the default icons
        />
        {row.isPublished === true ? "Published" : "Unpublished"}
      </label>
    );
  }

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }

  function VenueFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.venue?.name}</p>
      </div>
    );
  }

  function offerFormatter(cellContent, row) {
    return (
      <div style={{}}>
        <p>{row?.title}</p>
        {row?.homeblock && (
          <div
            class="badge"
            style={{
              backgroundColor: "#007bff",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
            }}
          >
            HomeBlock
          </div>
        )}
      </div>
    );
  }
  
  function statusFormatter(cellContent, row) {
    return (
      <div style={{}}>
        {row?.offerStatus === "Live" && (
          <div
            class="badge"
            style={{
              backgroundColor: "rgb(19 203 44)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
              margin: "auto",
            }}
          >
            {row?.offerStatus}
          </div>
        )}
        {row?.offerStatus === "Upcoming" && (
          <div
            class="badge"
            style={{
              backgroundColor: "#29BCE3",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
              margin: "auto",
            }}
          >
            {row?.offerStatus}
          </div>
        )}
        {row?.offerStatus === "Expired" && (
          <div
            class="badge"
            style={{
              backgroundColor: "#EC536C",
              color: "white",
              padding: "5px 10px",
              borderRadius: "12px",
              margin: "auto",
            }}
          >
            {row?.offerStatus}
          </div>
        )}
      </div>
    );
  }

  function PackageFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.packages?.length}</p>
      </div>
    );
  }

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };
  function dateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.createdAt)}</div>;
  }

  function sDateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.startTime)}</div>;
  }

  function eDateFormatter(cellContent, row) {
    return <div>{convertTimeToFormattedString(row.endTime)}</div>;
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.title}
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Description</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.description}
          onChange={(e) => updateFilter("description", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Venue</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.venueName}
          onChange={(e) => updateFilter("venueName", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Days</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.days}
          onChange={(e) => updateFilter("days", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Start Time</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.startTime}
          onChange={(e) => updateFilter("startTime", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">End Time</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.endTime}
          onChange={(e) => updateFilter("endTime", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Allow Whosin</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("allowWhosIn", e.target.value)}
          className="form-select"
          value={filter.allowWhosIn}
        >
          <option value="">Select Status</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "title",
      formatter: offerFormatter,
      text: "Title",
      sort: false,
    },

    // {
    //   dataField: "description",
    //   text: "Description",
    //   sort: false,
    // },

    {
      dataField: "image",
      text: "Image",
      formatter: ImgFormatter,
      sort: false,
    },

    {
      dataField: "venueName",
      text: "Venues",
      sort: false,
    },

    {
      dataField: "days",
      text: "Days",
      sort: false,
    },

    {
      dataField: "startTime",
      text: "Start Time",
      sort: false,
      formatter: sDateFormatter,
    },

    {
      dataField: "endTime",
      text: "End Time",
      sort: false,
      formatter: eDateFormatter,
    },
    {
      dataField: "offerStatus",
      formatter: statusFormatter,
      text: "Offer Status",
      sort: false,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: activeFormatter,
      sort: false,
    },

    {
      dataField: "allowWhosIn",
      text: "Allowed Whosin",
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: dateFormatter,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setResetSearch(true);
    setSearchText(""); 
  };
  useEffect(() => {  
    if(!resetSearch) return;
    if (currentPage == 1) {
      offerManagment([], true);
      return;
    }
    setCurrentPage(1);    
  }, [resetSearch]);
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
        {/* <Tippy content="View Tracking">
            <button type="button" className=" btn btn-warning mx-1">
              <Link
                to={{
                   pathname: `/offerGraphdetails/${row._id}`,
                  state: { row },
                }}
              >
                <i className="fal fa-chart-line fs-5 text-light"></i>
              </Link>
            </button>
          </Tippy>            */}
        
        <Tippy content="Edit">
          <button type="button" className=" btn btn-info  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/business-account/updateOffer/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-pencil fs-5 text-light"></i>
            </Link>{" "}
          </button>
          </Tippy>
          <Tippy content="Delete">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          </Tippy>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const offerManagment = async (filterArgs = [], resetFilter = false) => {
    setLoader(true);

    const bid = localStorage.getItem("stored_bussinessId");

    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
      bid: bid,
    };
    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText && resetSearch === false) {
        payload.search = searchText?.trim();
      }
    }
    if(filterActive && !filterArgs?.length && !resetFilter){
      payload.filterArgs = fetchFilter()
    }
    try {
      const response = await postRequest(`venue/offer/list/admin`, payload);
      const data = response.data;
      if(resetSearch){
        setResetSearch(false);
      }
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setofferdata(data.list);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You want to delete this Offer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialog(id);
      }
    });
  };

  const showRandomWordDialog = (id) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteFunction(id);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteFunction = async (id) => {
    const response = await deleteRequest("venue/offer/delete", { offerId: id });
    if (response.status) {
      try {
        const pushUpdatesResponse = await pushUpdates({
          type: "offer",
          id: id,
          receivers: [],
        });
        // Check if the pushUpdates call was successful, handle the response as needed        
        Swal.fire("Deleted!", "Deleted successfully");
        offerManagment();
      } catch (pushUpdatesError) {
        toast.error("Error in pushUpdates");
      }
    } else {
      Swal.fire({
        title: "Offer Contains Order",
        text: "This offer may contain order, Still you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {

          deleteFunction2(id);
          
        }
      });
    }
  };

  const deleteFunction2 = async (id) => {
    const response = await deleteRequest("venue/offer/delete", { offerId: id, isForced: true  });
    if (response.status) {
      // If the update is successful, call the pushUpdates function

      Swal.fire("Deleted!", "Deleted successfully");
     
    } else {      
      // something went wrong
      Swal.fire("Error!", "Something went wrong");     
    }
  };  

  const handlerdeleteMultiple = (ids) => {
    Swal.fire({
      title: "Are you sure delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showRandomWordDialogM(ids);
      }
    });
  };

  const showRandomWordDialogM = (ids) => {
    const randomIndex = Math.floor(
      Math.random() * deleteConfirmationWords.length
    );
    const randomWord = deleteConfirmationWords[randomIndex];

    Swal.fire({
      title: "Confirmation Required",
      text: `To confirm deletion, please enter the word: ${randomWord}`,
      icon: "warning",
      input: "text",
      inputPlaceholder: `Type '${randomWord}' to confirm`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed && result.value === randomWord) {
        deleteMultipleFunction(ids);
      } else if (result.isConfirmed) {
        Swal.fire(
          "Incorrect Entry",
          "Please enter the correct word to confirm.",
          "error"
        );
      }
    });
  };

  const deleteMultipleFunction = async (ids) => {
    const response = await deleteRequest("venue/offer/delete", { ids: ids });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      offerManagment();
      setselectedItems([]);
    } else {
      Swal.fire({
        title: "Offer Contains Order",
        text: "This offer may contain order, Still you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {

          deleteFunction3(ids);
          
        }
      });
    }
  };

  const deleteFunction3 = async (ids) => {
    const response = await deleteRequest("venue/offer/delete", { ids: ids, isForced: true  });
    if (response.status) {
      // If the update is successful, call the pushUpdates function

      Swal.fire("Deleted!", "Deleted successfully");
     
    } else {      
      // something went wrong
      Swal.fire("Error!", "Something went wrong");     
    }
  }; 

  const selectRow = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };
  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Offer Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Offer Management</li>:{" "}
                <li className="breadcrumb-item">Offer List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
          
            <Link to="/business-account/createOffer">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={offerdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>
                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "25%" }}
                                  >
                                    <option value="title"> Title</option>
                                    <option value="description">
                                      {" "}
                                      Description
                                    </option>
                                    <option value="days">Days</option>
                                    <option value="allowWhosIn">
                                      Allowed Whosin
                                    </option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                     value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "20%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )}
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        offerManagmentSearch();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger"
                                    >
                                      Reset
                                    </button>
                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info mx-2"
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    noDataIndication={() => <div>No data available</div>}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    // classes="table-custom-striped"
                                    classes="table-custom-border"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default OfferManagementlist;
