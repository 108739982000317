import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import Lightbox from "react-image-lightbox";
//import filter modal

import CustomAvatar from "../../components/Common/Avatar.js";
import moment from "moment";
import FilterModal from "../../components/Modal/FilterModal";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequestForm,
  putRequest,
  postRequestForm,
  deleteConfirmationWords,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

const filterObj = {
  name: "",
  createdAt: "",
};

const SelectedUsers = ({ selectedUser, deleteHandler, onUpdate }) => {
  const [modal_center, setmodal_center] = useState(false);
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedItems, setselectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [offer, setOffer] = useState();
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });

  // New state variables for Edit Modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [editFormData, setEditFormData] = useState({
    discountType: "",
    discount: "",
    maxDiscount: "",
    minPurchase: "",
    maxApplyPerUser: "",
  });

  useEffect(() => {
    // Fetch user data or handle side effects when selectedUser changes
  }, [selectedUser]);

  const toggleModel = () => {
    setModel(!model);
  };
  const closeBucketModal = () => {
    setModel(false);
  };

  // Handle Edit Button Click
  const handleEditClick = (user) => {
    setEditingUser(user);
    setEditFormData({
      discountType: user.discountType || "",
      discount: user.discount || "",
      maxDiscount: user.maxDiscount || "",
      minPurchase: user.minPurchase || "",
      maxApplyPerUser: user.maxApplyPerUser || "",
    });
    setIsEditModalOpen(true);
  };

  // Toggle Edit Modal
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
    if (!isEditModalOpen) {
      setEditingUser(null);
      setEditFormData({
        discountType: "",
        discount: "",
        maxDiscount: "",
        minPurchase: "",
        maxApplyPerUser: "",
      });
    }
  };

  // Handle Edit Form Input Changes
  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle Edit Form Submission
  const handleEditFormSubmit = async (e) => {
    e.preventDefault();

    const { discountType, discount, maxDiscount, minPurchase, maxApplyPerUser } = editFormData;
    if (!discountType) {
      toast.error("Discount Type is required");
      return;
    }
    if (!discount || discount < 1) {
      toast.error("Discount must be at least 1");
      return;
    }
    if (!maxDiscount || maxDiscount < 1) {
      toast.error("Maximum Discount must be at least 1");
      return;
    }
    if (!minPurchase || minPurchase < 1) {
      toast.error("Minimum Purchase must be at least 1");
      return;
    }
    if (!maxApplyPerUser || maxApplyPerUser < 1) {
      toast.error("Maximum Apply Per User must be at least 1");
      return;
    }



    try {

      let tempUsers = [...selectedUser];
      // fetch the selected users from EditingUser
      const updatedUsers = tempUsers.map((user) => {
        if (user._id === editingUser._id) {
          return {
            ...user,
            discountType,
            discount,
            maxDiscount,
            minPurchase,
            maxApplyPerUser,
          };
        }
        return user;
      });
      
      onUpdate(updatedUsers);
      // Close the modal
      toggleEditModal();


    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("An error occurred while updating the user.");
    }
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page !== currentPage) {
        setCurrentPage(page);
      }
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function CreatedAt(cellContent, row) {
    let createdAt = row.createdAt;
    return (
      <React.Fragment>
        <div>
          <p>{moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")} </p>
        </div>
      </React.Fragment>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="img"
          width={"50px"}
        />
      </>
    );
  }

  function userFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
            <>
              {row.image ? (
                <img
                  src={row.image}
                  onClick={() => showLightBox(row.image)}
                  alt="img"
                  width={"30px"}
                />
              ) : (
                <CustomAvatar
                  iconSize={30}
                  name={
                    row?.first_name + row?.last_name === ""
                      ? "*"
                      : `${row?.first_name} ${row?.last_name} `
                  }
                />
              )}
            </>
            &nbsp;&nbsp;
            {row.first_name} {row.last_name}{" "}
          </p>
        </div>
      </React.Fragment>
    );
  }

  function platformFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="text-center">
          {row.platform === "ios" ? (
            <button type="button" className="btn btn-dark text-light">
              {" "}
              <i className="fab fa-apple text-light"></i>
            </button>
          ) : (
            <>
              {row.platform === "android" ? (
                <button type="button" className="btn btn-success text-light">
                  {" "}
                  <i className="fab fa-android text-light"></i>
                </button>
              ) : (
                <button type="button" className="btn btn-warning text-light">
                  {" "}
                  <i className="fa fa-globe text-light"></i>
                </button>
              )}
            </>
          )}
        </div>
      </React.Fragment>
    );
  }

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex gap-1">
          {/* Edit Button */}
          <button
            type="button"
            className="btn btn-info"
            onClick={() => handleEditClick(row)}
          >
            <i className="fas fa-edit fs-5"></i>
          </button>
          
          {/* Delete Button */}
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteHandler(row._id)}
          >
            <i className="fal fa-trash fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "userName",
      text: "Name",
      formatter: userFormatter,
    },   
    {
      dataField: "email",
      text: "Email",
      sort: false,
    },
    // {
    //   dataField: "phone",
    //   text: "Phone",
    //   sort: false,
    // },

    {
      dataField: "discountType",
      text: "Discount Type",
      sort: false,
    },
    {
      dataField: "discount",
      text: "Discount",
      sort: false,
    },
    {
      dataField: "maxDiscount",
      text: "Max Discount",
      sort: false,
    },
    {
      dataField: "minPurchase",
      text: "Min Purchase",
      sort: false,
    },
    {
      dataField: "maxApplyPerUser",
      text: "Max Apply Per User",
      sort: false,
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    }, 
  ];

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };

  // Existing deletion handlers...

  const selectRow = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: false, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row._id]);
      } else {
        setselectedItems(selectedItems.filter((x) => x !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      const ids = rows.map((r) => r._id);
      setselectedItems(ids);
    },
  };

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  <PaginationProvider
                    pagination={paginationFactory({
                      page: currentFPage,
                      sizePerPage: sizePerPageOrg,
                      totalSize: selectedUser.length,
                      custom: true,
                    })}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <>
                        <ToolkitProvider
                          keyField="_id"
                          data={selectedUser}
                          columns={columns}
                        >
                          {(props) => (
                            <Row>
                              <Col className="col-lg-12 text-end">
                                <BootstrapTable
                                  onTableChange={handleTableChange}
                                  remote={false}
                                  rowClasses={rowClasses}
                                  classes="table-custom-border"
                                  {...props.baseProps}
                                  {...paginationTableProps}
                                />
                              </Col>
                            </Row>
                          )}
                        </ToolkitProvider>

                        <Row>
                          <Col className="col-lg-12 text-end">
                            <div className="text-md-right">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </PaginationProvider>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        {/* Lightbox for Image Preview */}
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}

        {/* Edit User Modal */}
        <Modal isOpen={isEditModalOpen} toggle={toggleEditModal}>
          <Form>
            <ModalHeader toggle={toggleEditModal}>Edit User Discount</ModalHeader>
            <ModalBody>
              <FormGroup>
                <label>Discount Type</label>
                <select
                  name="discountType"
                  className="form-select"
                  value={editFormData.discountType}
                  onChange={handleEditFormChange}
                  required
                >
                  <option value="">Select Discount Type</option>
                  <option value="percentage">Percentage</option>
                  <option value="flat">Flat</option>
                </select>
              </FormGroup>

              <FormGroup>
                <label>Discount</label>
                <input
                  type="number"
                  name="discount"
                  className="form-control"
                  value={editFormData.discount}
                  onChange={handleEditFormChange}
                  min="1"
                  required
                />
              </FormGroup>

              <FormGroup>
                <label>Maximum Discount</label>
                <input
                  type="number"
                  name="maxDiscount"
                  className="form-control"
                  value={editFormData.maxDiscount}
                  onChange={handleEditFormChange}
                  min="1"
                  required
                />
              </FormGroup>

              <FormGroup>
                <label>Minimum Purchase</label>
                <input
                  type="number"
                  name="minPurchase"
                  className="form-control"
                  value={editFormData.minPurchase}
                  onChange={handleEditFormChange}
                  min="1"
                  required
                />
              </FormGroup>

              <FormGroup>
                <label>Maximum Apply Per User</label>
                <input
                  type="number"
                  name="maxApplyPerUser"
                  className="form-control"
                  value={editFormData.maxApplyPerUser}
                  onChange={handleEditFormChange}
                  min="1"
                  required
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleEditModal}>
                Cancel
              </Button>
              <Button color="primary" type="button" onClick={(e) => handleEditFormSubmit(e) }>
                Save Changes
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </React.Fragment>     
    </>
  );
};

export default SelectedUsers;
