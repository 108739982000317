import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Table,
  Button,
} from "reactstrap";

import { postRequest } from "../../components/Common/Utils.js";
import "./PromocodeDetail.css"; // Use your custom table styles here

export default function PromocodeDetail() {
  const { id: promocodeId } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [promo, setPromo] = useState(null);

  useEffect(() => {
    if (promocodeId) fetchPromocodeDetail(promocodeId);
    // eslint-disable-next-line
  }, [promocodeId]);

  const fetchPromocodeDetail = async (id) => {
    try {
      setLoading(true);
      const payload = { promocodeId: id };
      const response = await postRequest("venue/promo-code/detail/admin", payload);

      setLoading(false);

      if (response.status !== 1 || !response.data?.length) {
        toast.error(response.message || "Failed to fetch promocode detail!");
        return;
      }
      setPromo(response.data[0]); // We expect data[0] to be the detail
    } catch (error) {
      console.error("Error fetching promocode detail:", error);
      toast.error("Could not fetch promocode detail!");
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div style={{ position: "relative", height: "300px" }}>
        <Spinner
          style={{
            width: "4rem",
            height: "4rem",
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    );
  }

  if (!promo) {
    return (
      <div className="container mt-5">
        <ToastContainer />
        <h5>No promocode data found!</h5>
      </div>
    );
  }

  // Destructure the fields we need
  const {
    title,
    description,
    claimCode,
    qty,
    discountType,
    discount,
    maxDiscount,
    minPurchase,
    maxApplyPerUser,
    startDate,
    endDate,
    type,          // 'public' or 'private'
    target,        // 'all' or 'specific'
    deals = [],
    packages = [],
    users = [],
    offers = [],
    applyType: applyOn,       // If your API stores it (e.g. 'totalPrice' or 'discountedPrice')
  } = promo;

  // Helper to format date
  const formatDate = (dateStr) => {
    if (!dateStr) return "N/A";
    return new Date(dateStr).toLocaleString();
  };

  return (
    <React.Fragment>
      <ToastContainer />

      {/* Header */}
      <Row className="mb-3">
        <Col lg={10}>
          <h4 className="page-title">Promocode Detail</h4>
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item">Promocode Management</li>
            <li className="breadcrumb-item active">Detail</li>
          </ol>
        </Col>
        <Col lg={2} className="text-end">
          <Button color="primary" onClick={() => history.goBack()}>
            <i className="fas fa-chevron-left"></i> Back
          </Button>
        </Col>
      </Row>

      {/* Main Info Card */}
      <Card className="mb-4">
        <CardBody>
          <CardTitle className="mb-4">Promocode Information</CardTitle>

          <Row className="mb-3">
            <Col md={6}>
              <strong>Title:</strong> {title}
            </Col>
            <Col md={6}>
              <strong>Claim Code:</strong> {claimCode}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <strong>Description:</strong> {description}
            </Col>
            <Col md={6}>
              <strong>Quantity:</strong> {qty}
            </Col>
          </Row>

          {/* If you store applyOn, show it (like "Amount Off Total Price" or "Discounted Price") */}
          {applyOn && (
            <Row className="mb-3">
              <Col md={6}>
                <strong>Apply On:</strong>{" "}
                {applyOn === "cumulative"
                  ?  "Cumulative"
                  : applyOn === "on-discounted-price" ? "On Discounted Price" : "Override"}
              </Col>
              <Col md={6}></Col>
            </Row>
          )}

          <Row className="mb-3">
            <Col md={6}>
              <strong>Type:</strong> {type} {/* public/private */}
            </Col>
            <Col md={6}>
              <strong>Target:</strong> {target} {/* all/specific */}
            </Col>
          </Row>

          {/* If type=public => discount fields */}
          {type === "public" && (
            <>
              <Row className="mb-3">
                <Col md={6}>
                  <strong>Discount Type:</strong> {discountType}
                </Col>
                <Col md={6}>
                  <strong>Discount:</strong> {discount}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <strong>Max Discount:</strong> {maxDiscount}
                </Col>
                <Col md={6}>
                  <strong>Min Purchase:</strong> {minPurchase}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <strong>Max Apply Per User:</strong> {maxApplyPerUser}
                </Col>
              </Row>
            </>
          )}

          <Row className="mb-3">
            <Col md={6}>
              <strong>Start Date:</strong> {formatDate(startDate)}
            </Col>
            <Col md={6}>
              <strong>End Date:</strong> {formatDate(endDate)}
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* If type=private => Show Users */}
      {type === "private" && (
        <Card className="mb-4">
          <CardBody>
            <CardTitle>Users</CardTitle>
            {!users.length ? (
              <div>No Users assigned</div>
            ) : (
              <Table className="table-custom-border" responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Discount Type</th>
                    <th>Discount</th>
                    <th>Max Discount</th>
                    <th>Min Purchase</th>
                    <th>Max Apply</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((usr, index) => {
                    const fullName = `${usr.first_name || ""} ${
                      usr.last_name || ""
                    }`.trim();
                    return (
                      <tr key={usr.userId || index}>
                        <td>{index + 1}</td>
                        <td>{fullName || "N/A"}</td>
                        <td>{usr.email || "N/A"}</td>
                        <td>{usr.phone || "N/A"}</td>
                        <td>{usr.discountType || "N/A"}</td>
                        <td>{usr.discount || "0"}</td>
                        <td>{usr.maxDiscount || "0"}</td>
                        <td>{usr.minPurchase || "0"}</td>
                        <td>{usr.maxApplyPerUser || "0"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      )}

      {/* If target != "all" => we have deals/offers/packages */}
      {target !== "all" && (
        <>
          {/* Offers */}
          <Card className="mb-4">
            <CardBody>
              <CardTitle>Offers</CardTitle>
              {!offers.length ? (
                <div>No Offers found</div>
              ) : (
                <Table className="table-custom-border" responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Venue</th>
                      <th>Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    {offers.map((offerItem, index) => (
                      <tr key={offerItem._id || index}>
                        <td>{index + 1}</td>
                        <td>{offerItem.title || "N/A"}</td>
                        <td>{offerItem.venueName || "N/A"}</td>
                        <td>
                          {offerItem.image ? (
                            <img
                              src={offerItem.image}
                              alt="offer"
                              width="50"
                              height="50"
                              style={{ borderRadius: "4px" }}
                            />
                          ) : (
                            "No Image"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>

          {/* Packages */}
          <Card className="mb-4">
            <CardBody>
              <CardTitle>Packages</CardTitle>
              {!packages.length ? (
                <div>No packages found</div>
              ) : (
                <Table className="table-custom-border" responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Amount</th>
                      <th>Offer Name</th>
                      <th>Venue Name</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {packages.map((pkg, index) => (
                      <tr key={pkg._id || index}>
                        <td>{index + 1}</td>
                        <td>{pkg.title || "N/A"}</td>
                        <td>{pkg.amount || "0"}</td>
                        <td>{pkg.offerName || "N/A"}</td>
                        <td>{pkg.venueName || "N/A"}</td>
                        <td>{pkg.description || "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>

          {/* Deals */}
          <Card className="mb-4">
            <CardBody>
              <CardTitle>Deals</CardTitle>
              {!deals.length ? (
                <div>No deals found</div>
              ) : (
                <Table className="table-custom-border" responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Image</th>
                      <th>Days</th>
                      <th>Price</th>
                      <th>Discounted Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deals.map((dl, index) => (
                      <tr key={dl._id || index}>
                        <td>{index + 1}</td>
                        <td>{dl.title || "No Title"}</td>
                        <td>
                          {dl.image ? (
                            <img
                              src={dl.image}
                              alt="deal"
                              width="50"
                              height="50"
                              style={{ borderRadius: "4px" }}
                            />
                          ) : (
                            "No Image"
                          )}
                        </td>
                        <td>{dl.days || "N/A"}</td>
                        <td>{dl.actualPrice || "N/A"}</td>
                        <td>{dl.discountedPrice || "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </>
      )}
    </React.Fragment>
  );
}
