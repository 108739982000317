import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

import { postRequest } from "../../components/Common/Utils.js";
import "./SpecialOfferSalesManagementlist.scss";

// --------------------
// Utility Helpers
// --------------------
/**
 * Group array of objects by a specific key (like "venueId", "specialOfferId", or "userId").
 */
function groupBy(array, key) {
  return array.reduce((result, item) => {
    const groupKey = item[key];
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(item);
    return result;
  }, {});
}

/**
 * For each group, sum Dr or Cr amounts. 
 * If perspective = "USER", amounts go in Cr; otherwise, Dr.
 */
function getTotalsForGroup(items, perspective) {
  let totalDr = 0;
  let totalCr = 0;
  items.forEach((row) => {
    if (perspective === "USER") {
      totalCr += row.billAmount;
    } else {
      totalDr += row.billAmount;
    }
  });
  return { totalDr, totalCr };
}

// --------------------
// PRINT & CSV Helpers
// --------------------

/**
 * Build the inner HTML for the 5-column journal table (Date | Particulars | LF | Dr(AED) | Cr(AED))
 * perspective => if "USER", amounts go to Cr; else they go to Dr.
 */
function buildJournalPrintTableHTML(data, perspective) {
  let tableHTML = `
    <div class="table-header">
      <div class="th date-column">Date</div>
      <div class="th particulars-column">Particulars</div>
      <div class="th lf-column">LF</div>
      <div class="th dr-column">Dr (AED)</div>
      <div class="th cr-column">Cr (AED)</div>
    </div>`;

  data.forEach((row) => {
    const dateStr = format(new Date(row.createdAt), "dd/MM/yyyy");
    const particulars = `Sale of ${row.offerTitle || "N/A"}<br/>to ${row.userName || "Unknown User"}<br/>at ${row.venueName || "Unknown Venue"}`;
    const absAmount = Math.abs(row.billAmount).toFixed(2);

    let dr = "";
    let cr = "";
    if (perspective === "USER") {
      cr = `<span style="color:red">${absAmount}</span>`;
    } else {
      dr = `<span style="color:green">${absAmount}</span>`;
    }

    tableHTML += `
      <div class="table-row">
        <div class="td date-column">${dateStr}</div>
        <div class="td particulars-column">${particulars}</div>
        <div class="td lf-column"></div>
        <div class="td dr-column">${dr}</div>
        <div class="td cr-column">${cr}</div>
      </div>`;
  });

  return tableHTML;
}

/**
 * Wraps the table HTML in an `<html>` + `<head>` so it can be printed in a new window.
 * Used for a single group or small data set.
 */
function buildSingleJournalPrintHTML(data, perspective) {
  const inlineCSS = `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
    body { font-family: 'Inter', sans-serif; margin: 20px; background: #f2f2f2; }
    .account-statement { width: 80%; margin: 0 auto; }
    .table-header { display: flex; font-weight: 600; font-size: 0.875rem; text-transform: uppercase; background: #f9f9f9; padding: 0.75rem; }
    .th { flex: 1; color: #555; }
    .th.date-column { flex: 1.2; }
    .th.particulars-column { flex: 2.5; }
    .th.lf-column { flex: 0.6; text-align: center; }
    .th.dr-column, .th.cr-column { width: 80px; text-align: right; }
    .table-row { display: flex; align-items: center; border-bottom: 1px solid #eee; padding: 0.75rem; }
    .table-row:last-child { border-bottom: none; }
    .td { flex: 1; font-size: 0.875rem; color: #333; }
    .td.date-column { flex: 1.2; }
    .td.particulars-column { flex: 2.5; white-space: pre-line; }
    .td.lf-column { flex: 0.6; text-align: center; }
    .td.dr-column, .td.cr-column { width: 80px; text-align: right; }
  `;
  let html = `<html><head><meta charset="utf-8"/><title>Journal Entry</title><style>${inlineCSS}</style></head><body>`;
  html += `<div class="account-statement">`;
  html += buildJournalPrintTableHTML(data, perspective);
  html += `</div></body></html>`;
  return html;
}

/**
 * Opens a new window with the given HTML string and triggers the print dialog.
 */
function printDocument(htmlString) {
  const printWindow = window.open("", "_blank");
  if (!printWindow) return;
  printWindow.document.open();
  printWindow.document.write(htmlString);
  printWindow.document.close();
  setTimeout(() => {
    printWindow.focus();
    printWindow.print();
  }, 400);
}

/**
 * Print a group of transactions (single group).
 */
function printGroupJournal(data, perspective) {
  const htmlString = buildSingleJournalPrintHTML(data, perspective);
  printDocument(htmlString);
}

/**
 * Build a CSV row for a single transaction, perspective-based Dr/Cr.
 */
function buildJournalCsvRow(row, perspective) {
  const dateStr = format(new Date(row.createdAt), "dd/MM/yyyy");
  const particulars = `Sale of ${row.offerTitle || "N/A"} to ${row.userName || "Unknown User"} at ${row.venueName || "Unknown Venue"}`;
  const absAmount = Math.abs(row.billAmount).toFixed(2);
  
  let dr = "";
  let cr = "";
  if (perspective === "USER") {
    cr = absAmount;
  } else {
    dr = absAmount;
  }

  // CSV columns: Date, Particulars, LF, Dr(AED), Cr(AED)
  return [dateStr, particulars, "", dr, cr].join(",");
}

/**
 * Download CSV for a single group of data.
 */
function downloadJournalCSV(data, perspective) {
  const headers = ["Date", "Particulars", "LF", "Dr (AED)", "Cr (AED)"].join(",");
  const csvRows = [headers];
  data.forEach((row) => {
    csvRows.push(buildJournalCsvRow(row, perspective));
  });
  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "journal-entries.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Build a big HTML string for printing the full journal:
 * - Whos'in (all data, DR)
 * - Venue groups (DR)
 * - Offer groups (DR)
 * - User groups (USER)
 */
function buildFullJournalPrintHTML(allData) {
  // Group the data
  const venues = groupBy(allData, "venueId");
  const offers = groupBy(allData, "specialOfferId");
  const users = groupBy(allData, "userId");

  const inlineCSS = `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
    body { font-family: 'Inter', sans-serif; margin: 20px; background: #f2f2f2; }
    .account-statement { width: 80%; margin: 0 auto; }
    h2 { font-size: 1.2rem; color: #333; margin-top: 20px; }
    .table-header { display: flex; font-weight: 600; font-size: 0.875rem; text-transform: uppercase; background: #f9f9f9; padding: 0.75rem; }
    .th { flex: 1; color: #555; }
    .th.date-column { flex: 1.2; }
    .th.particulars-column { flex: 2.5; }
    .th.lf-column { flex: 0.6; text-align: center; }
    .th.dr-column, .th.cr-column { width: 80px; text-align: right; }
    .table-row { display: flex; align-items: center; border-bottom: 1px solid #eee; padding: 0.75rem; }
    .table-row:last-child { border-bottom: none; }
    .td { flex: 1; font-size: 0.875rem; color: #333; }
    .td.date-column { flex: 1.2; }
    .td.particulars-column { flex: 2.5; white-space: pre-line; }
    .td.lf-column { flex: 0.6; text-align: center; }
    .td.dr-column, .td.cr-column { width: 80px; text-align: right; } 
  `;
  let html = `<html><head><meta charset="utf-8"/><title>Full Journal</title><style>${inlineCSS}</style></head><body>`;
  html += `<div class="account-statement">`;

  // 1) WHOS'IN Section (DR perspective, all data)
  if (allData.length > 0) {
    html += `<h2>WHOS'IN</h2>`;
    html += buildJournalPrintTableHTML(allData, "WHOSIN"); // treat as DR
  }

  // 2) Group by Venue => DR
  for (const [id, items] of Object.entries(venues)) {
    const name = items[0]?.venueName || "Unknown Venue";
    html += `<h2>Venue: ${name}</h2>`;
    html += buildJournalPrintTableHTML(items, "VENUE"); // DR
  }

  // 3) Group by Offer => DR
  for (const [id, items] of Object.entries(offers)) {
    const name = items[0]?.offerTitle || "Unknown Offer";
    html += `<h2>Offer: ${name}</h2>`;
    html += buildJournalPrintTableHTML(items, "OFFER"); // DR
  }

  // 4) Group by User => USER => CR
  for (const [id, items] of Object.entries(users)) {
    const name = items[0]?.userName || "Unknown User";
    html += `<h2>User: ${name}</h2>`;
    html += buildJournalPrintTableHTML(items, "USER"); // CR
  }

  html += `</div></body></html>`;
  return html;
}

/**
 * Print the entire data set.
 */
function printFullJournal(allData) {
  const htmlString = buildFullJournalPrintHTML(allData);
  printDocument(htmlString);
}

/**
 * Build CSV for the entire data set:
 *  - WHOS'IN
 *  - Venue Groups
 *  - Offer Groups
 *  - User Groups
 */
function buildFullJournalCSV(allData) {
  let csv = "";

  // Helper groups
  const venues = groupBy(allData, "venueId");
  const offers = groupBy(allData, "specialOfferId");
  const users = groupBy(allData, "userId");

  // 1) WHOS'IN => DR perspective
  if (allData.length > 0) {
    csv += `WHOS'IN\n`;
    csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
    allData.forEach((row) => {
      csv += buildJournalCsvRow(row, "WHOSIN") + "\n";
    });
    csv += "\n";
  }

  // 2) Venues => DR
  for (const [id, items] of Object.entries(venues)) {
    const name = items[0]?.venueName || "Unknown Venue";
    csv += `Venue: ${name}\n`;
    csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
    items.forEach((row) => {
      csv += buildJournalCsvRow(row, "VENUE") + "\n";
    });
    csv += "\n";
  }

  // 3) Offers => DR
  for (const [id, items] of Object.entries(offers)) {
    const name = items[0]?.offerTitle || "Unknown Offer";
    csv += `Offer: ${name}\n`;
    csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
    items.forEach((row) => {
      csv += buildJournalCsvRow(row, "OFFER") + "\n";
    });
    csv += "\n";
  }

  // 4) Users => CR
  for (const [id, items] of Object.entries(users)) {
    const name = items[0]?.userName || "Unknown User";
    csv += `User: ${name}\n`;
    csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
    items.forEach((row) => {
      csv += buildJournalCsvRow(row, "USER") + "\n";
    });
    csv += "\n";
  }

  return csv;
}

/**
 * Download the entire journal as CSV.
 */
function downloadFullJournalCSV(allData) {
  const csvString = buildFullJournalCSV(allData);
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "journal-full.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// --------------------
// Transform & Main Component
// --------------------

/**
 * Transform the new API data (which might be "deal", "offer", or "ticket") 
 * into the shape your existing code expects:
 * 
 * - row.billAmount
 * - row.offerTitle
 * - row.venueName
 * - row.userName
 * - row.createdAt
 * - row.venueId
 * - row.specialOfferId (dealId, offerId, or ticketId)
 * - row.userId
 */
function transformApiData(list) {
  return list.map((item) => {
    const price = item.price ?? 0;
    const qty = item.qty ?? 0;
    const billAmount = price * qty;

    let offerTitle = "N/A";
    if (item.type === "deal") {
      offerTitle = item.deal?.title || "N/A";
    } else if (item.type === "offer") {
      offerTitle = item.offer?.title || "N/A";
    } else if (item.type === "ticket") {
      offerTitle = "Ticket";
    }

    const venueName = item.venue?.name || "Unknown Venue";

    const userFirst = item.user?.first_name || "";
    const userLast = item.user?.last_name || "";
    const userName = (userFirst + " " + userLast).trim() || "Unknown User";

    // specialOfferId depends on item.type
    let specialOfferId = item._id;
    if (item.type === "deal") {
      specialOfferId = item.dealId;
    } else if (item.type === "offer") {
      specialOfferId = item.offerId;
    } else if (item.type === "ticket") {
      specialOfferId = item.ticketId;
    }

    return {
      ...item,
      billAmount,
      offerTitle,
      venueName,
      userName,
      specialOfferId,
    };
  });
}

const SpecialOfferSalesJournallist = () => {
  const location = useLocation();
  const [allData, setAllData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  // We'll sort ascending by date after fetching
  const sortField = "createdAt";
  const sortOrder = "desc";

  useEffect(() => {
    fetchJournalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchJournalData() {
    const payload = {
      limit: 10000, // fetch all
      page: 1,
      sortField,
      sortOrder,
    };

    if (location.state) {
      const { startDate, endDate, paymentStatus } = location.state;
      const filterArgs = [
        { paymentStatus, type: "eq" },
        { startDate, type: "date-range" },
        { endDate, type: "date-range" },
      ];
      payload.filterArgs = filterArgs;
    }

    try {
      // Endpoint that returns data about deals/offers/tickets, etc.
      const response = await postRequest("subscription/order/list/admin", payload);
      const data = response.data || {};
      let list = data.list || [];

      // Transform each item for consistent fields
      list = transformApiData(list);

      // Sort ascending by date
      list.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      setAllData(list);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  }

  const toggleRow = (key) => {
    if (expandedRows.includes(key)) {
      setExpandedRows(expandedRows.filter((k) => k !== key));
    } else {
      setExpandedRows([...expandedRows, key]);
    }
  };

  // Group data
  const groupedByVenue = groupBy(allData, "venueId");
  const groupedByOffer = groupBy(allData, "specialOfferId");
  const groupedByUser = groupBy(allData, "userId");

  // Turn objects into arrays for easier mapping
  const venueGroups = Object.entries(groupedByVenue).map(([id, items]) => ({
    id,
    items,
    name: items[0]?.venueName || "Unknown Venue",
  }));
  const offerGroups = Object.entries(groupedByOffer).map(([id, items]) => ({
    id,
    items,
    name: items[0]?.offerTitle || "Unknown Offer",
  }));
  const userGroups = Object.entries(groupedByUser).map(([id, items]) => ({
    id,
    items,
    name: items[0]?.userName || "Unknown User",
  }));

  /**
   * Renders the 5-column journal table (Date | Particulars | LF | Dr(AED) | Cr(AED)).
   * perspective => "WHOSIN", "VENUE", "OFFER" => Dr
   * perspective => "USER" => Cr
   */
  const renderJournalTable = (transactions, perspective) => {
    return (
      <div className="custom-table-container">
        <div className="table-header">
          <div className="th date-column">Date</div>
          <div className="th particulars-column">Particulars</div>
          <div className="th lf-column">LF</div>
          <div className="th dr-column">Dr (AED)</div>
          <div className="th cr-column">Cr (AED)</div>
        </div>

        <div className="table-body">
          {transactions.map((row) => {
            const dateStr = format(new Date(row.createdAt), "dd/MM/yyyy");
            const lines = [
              `Sale of ${row.offerTitle || "N/A"}`,
              `to ${row.userName || "Unknown User"}`,
              `at ${row.venueName || "Unknown Venue"}`,
            ];
            const multiLineParticulars = lines.join("<br/>");

            let drAmount = "";
            let crAmount = "";
            const absAmount = Math.abs(row.billAmount).toFixed(2);

            // If perspective = USER => Cr; else Dr
            if (perspective === "USER") {
              crAmount = `<span style="color:red">${absAmount}</span>`;
            } else {
              drAmount = `<span style="color:green">${absAmount}</span>`;
            }

            return (
              <div className="table-row" key={row._id}>
                <div className="td date-column">{dateStr}</div>
                <div
                  className="td particulars-column"
                  dangerouslySetInnerHTML={{ __html: multiLineParticulars }}
                />
                <div className="td lf-column"></div>
                <div
                  className="td dr-column"
                  dangerouslySetInnerHTML={{ __html: drAmount }}
                />
                <div
                  className="td cr-column"
                  dangerouslySetInnerHTML={{ __html: crAmount }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // Renders a collapsible "Group Card" for Venue / Offer / User, etc.
  const renderGroupCard = (groupId, groupName, items, perspective) => {
    const isOpen = expandedRows.includes(groupId);
    const { totalDr, totalCr } = getTotalsForGroup(items, perspective);
    const drDisplay = Math.abs(totalDr).toFixed(2);
    const crDisplay = Math.abs(totalCr).toFixed(2);

    return (
      <div className="statement-item" key={groupId}>
        <div className="top-row">
          <button className="expand-btn" onClick={() => toggleRow(groupId)}>
            {isOpen ? "–" : "+"}
          </button>
          <div className="left-content">
            <div className="title">{groupName}</div>
          </div>
          <div className="middle-content" />
          <div className="right-content">
            {/* Show Dr in green, Cr in red */}
            <span style={{ color: "green", fontSize: "1.1rem" }}>{drDisplay}</span>
            <span style={{ color: "red", fontSize: "1.1rem", marginLeft: "1rem" }}>
              {crDisplay}
            </span>
          </div>
        </div>

        {/* Group-level actions */}
        <div
          className="group-actions"
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px", margin: "10px 0" }}
        >
          {/* Print this group's transactions */}
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => printGroupJournal(items, perspective)}
            title="Print Group"
          >
            <i className="fas fa-print" style={{ fontSize: "1rem", color: "#d30b7d" }}></i>
          </button>
          {/* Download CSV for this group */}
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => downloadJournalCSV(items, perspective)}
            title="Download Group CSV"
          >
            <i className="fas fa-file-csv" style={{ fontSize: "1rem", color: "#d30b7d" }}></i>
          </button>
        </div>

        {isOpen && (
          <div className="detail-section">
            <div className="detail-card">
              {items.length === 0 ? (
                <div style={{ padding: "1rem" }}>No transactions found.</div>
              ) : (
                renderJournalTable(items, perspective)
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <ToastContainer />
      <Row>
        <Col className="col-12">
          <div className="page-title-box">
            <h4>Journal Entries</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Account Logs</li>
              <li className="breadcrumb-item">Journal Entries</li>
            </ol>
          </div>
        </Col>

        {/* Top-level print/download for the entire journal */}
        <Col className="col-12">
          <div
            className="card text-center"
            style={{ backgroundColor: "#d30b7d", border: "none", marginBottom: "20px" }}
          >
            <div
              className="card-body"
              style={{ display: "flex", justifyContent: "center", gap: "15px" }}
            >
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => printFullJournal(allData)}
                title="Print Full Journal"
              >
                <i className="fas fa-print" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => downloadFullJournalCSV(allData)}
                title="Download Full Journal CSV"
              >
                <i className="fas fa-file-csv" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
            </div>
          </div>
        </Col>

        <Col className="col-12">
          <div className="account-statement">
            {/* 1) WHOS'IN => DR perspective (all data) */}
            {allData.length > 0 &&
              renderGroupCard("WHOSIN", "WHOS'IN", allData, "WHOSIN")}

            {/* 2) Venues => DR perspective */}
            {venueGroups.map((venue) =>
              renderGroupCard(`VENUE-${venue.id}`, `Venue: ${venue.name}`, venue.items, "VENUE")
            )}

            {/* 3) Offers => DR perspective */}
            {offerGroups.map((offer) =>
              renderGroupCard(`OFFER-${offer.id}`, `Offer: ${offer.name}`, offer.items, "OFFER")
            )}

            {/* 4) Users => CR perspective */}
            {userGroups.map((user) =>
              renderGroupCard(`USER-${user.id}`, `User: ${user.name}`, user.items, "USER")
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SpecialOfferSalesJournallist;
