import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { putRequest, postRequest, postRequestForm } from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import CustomTicketModal from "../../components/Modal/CustomTicketModal.js";
import FileUpload from "components/Common/FileUpload.js";
import { isArray } from "lodash";

export default function UpdateBanner() {
  const location = useLocation();
  const state = location.state?.row;
  const history = useHistory();
  const { id } = useParams();

  // Initialize bannerData with default values to avoid nulls.
  const [bannerData, setBannerData] = useState({
    title: "",
    description: "",
    type: "ticket", // default type
    mediaType: "photo", // "photo" or "video"
    category: "",
    cityId: "",
    link: "",
    // For update, you might have media URL stored as media, thumbnail, etc.
    media: "",
    thumbnail: "",
  });

  const [previewImage, setPreviewImage] = useState("");
  const [openLightbox, setOpenLightBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Additional selections
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [cityId, setCityId] = useState("");
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [model, setModel] = useState(false);
  const [offerModel, setOfferModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

  // Lists for dropdowns
  const [venueList, setVenueList] = useState([]);
  const [bannerTypeList, setBannerTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  // When a file is selected, validate file type and create a preview.
  useEffect(() => {
    if (!selectedImage) return;
    if (bannerData.mediaType === "video") {
      if (selectedImage?.type?.split("/")[0] !== "video") {
        toast.error("Please select a video file");
        return;
      }
    } else {
      if (selectedImage?.type?.split("/")[0] !== "image") {
        toast.error("Please select an image file");
        return;
      }
    }
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage, bannerData.mediaType]);

  // When component mounts, if state exists (update mode), load its data.
  useEffect(() => {
    if (state) {
      setBannerData({
        ...bannerData,
        title: state.title || "",
        description: state.description || "",
        type: state.type || "ticket",
        mediaType: state.mediaType || "photo",
        category: state.typeId || "",
        cityId: state.typeId || "",
        link: state.link || "",
        media: state.media || "",
        thumbnail: state.thumbnail || "",
      });
      setVenueId(state.typeId || "");
      setVenueText(state.displayName || "");
      setVenueImage(isArray(state.ticketImage) ? state.ticketImage[0] : state.ticketImage);
      setOfferId(state.offerId || "");
      setOfferText(state.offerName || "");
      setOfferImage(state.offerImage || "");
      // Use media URL for preview; if video use thumbnail
      setPreviewImage(state.mediaType === "video" ? state.thumbnail : state.media);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // Fetch dropdown lists concurrently to avoid multiple loading toggles.
  useEffect(() => {
    async function fetchData() {
      return;
      setLoading(true);
      try {
        const [venueRes, cityRes, categoryRes] = await Promise.all([
          postRequest("venue/list", { limit: 10000000 }),
          postRequest("rayna/ticket/city/list", { limit: 10000000 }),
          postRequest("rayna/category/list", { limit: 10000000 }),
        ]);

        if (venueRes.status === 1) setVenueList(venueRes.data.list);
        if (cityRes.status === 1) setBannerTypeList(cityRes.data.list);
        if (categoryRes.status === 1) {
          const list = categoryRes.data.list.map((item) => ({
            id: item._id,
            name: item.title,
          }));
          setCategoryList(list);
        }
      } catch (error) {
        console.error("Fetch error: ", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  // Modal toggles and callbacks
  const toggleModel = () => setModel(!model);
  const toggleOfferModel = () => setOfferModel(!offerModel);
  const closeModal = () => setModel(false);
  const closeOfferModal = () => setOfferModel(false);

  const selectRow = (selected) => {
    // 'selected' should be an object with _id, title, images
    setVenueId(selected._id);
    setVenueText(selected.title);
    setVenueImage(selected.images);
    closeModal();
  };

  const selectOfferRow = (selected, name, offer) => {
    setOfferId(selected);
    setOfferText(name);
    setOfferImage(offer?.image);
    closeOfferModal();
  };

  // Update a field in bannerData
  const updateValue = (field, value) => {
    setBannerData((prev) => ({ ...prev, [field]: value }));
  };

  // Update banner submission using JSON payload via putRequest
  const updateBanner = async (e) => {
    e.preventDefault();

    // Validation rules similar to the create form:
    if (!bannerData.type) {
      toast.error("Please select banner type");
      return;
    }
    if (!bannerData.title) {
      toast.error("Please enter title");
      return;
    }
    if (bannerData.type === "ticket" && !venueId) {
      toast.error("Please select a ticket");
      return;
    }
    if (bannerData.type === "category" && !bannerData.category) {
      toast.error("Please select category");
      return;
    }
    if (bannerData.type === "city" && !bannerData.cityId) {
      toast.error("Please select city");
      return;
    }
    if (bannerData.type === "link" && !bannerData.link) {
      toast.error("Please enter link");
      return;
    }
    if (!selectedImage && !bannerData.media) {
      toast.error("Please select a file");
      return;
    }

    setLoading(true);
    let payload = {
      bannerId: id, // Pass the bannerId
      title: bannerData.title,
      description: bannerData.description,
      type: bannerData.type,
      mediaType: bannerData.mediaType,
    };

    // For different types, assign typeId accordingly
    if (bannerData.type === "ticket") {
      payload.typeId = venueId;
    } else if (bannerData.type === "category") {
      payload.typeId = bannerData.category;
    } else if (bannerData.type === "city") {
      payload.typeId = bannerData.cityId;
    } else if (bannerData.type === "link") {
      payload.link = bannerData.link;
    }

    // If a new file is selected, upload it first
    if (selectedImage) {
      const selectedImageForm = new FormData();
      selectedImageForm.append("image", selectedImage);
      let ep = bannerData.mediaType === "video" ? "comman/video/upload" : "comman/img/upload";
      setStartLoader(true);
      const selectedImageRes = await postRequestForm(ep, selectedImageForm);
      setStartLoader(false);
      if (selectedImageRes?.data?.url) {
        if (bannerData.mediaType === "video") {
          payload.thumbnail = selectedImageRes.data.thumbnail;
          payload.media = selectedImageRes.data.url;
        } else {
          payload.media = selectedImageRes.data.url;
        }
      } else {
        console.error("File URL not found in response");
      }
    }

    // Now, update via putRequest with JSON payload
    const response = await putRequest("rayna/ticket-banner/update", payload);
    setLoading(false);

    if (response.status === 1) {
      toast.success(response.message);
      setTimeout(() => history.push("/rayna-banner"), 3000);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Rayna Banner Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Rayna Banner Management</li>:{" "}
              <li className="breadcrumb-item">Update Rayna Banner</li>
            </ol>
          </div>
        </div>
      </div>
      <Row>
        <Col className="col-12 user-management-container">
          <Card className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Update Banner</h4>
                <Form onSubmit={updateBanner}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        {/* Title */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Title</label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) => updateValue("title", e.target.value)}
                              value={bannerData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* Description */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Description</label>
                          <div className="col-sm-6">
                            <textarea
                              rows="4"
                              onChange={(e) => updateValue("description", e.target.value)}
                              value={bannerData?.description}
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* Type */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Type</label>
                          <div className="col-sm-6">
                            <select
                              value={bannerData?.type}
                              onChange={(e) => updateValue("type", e.target.value)}
                              className="form-select"
                            >
                              <option>Select Banner Content Type</option>
                              <option value="ticket">Ticket</option>
                              <option value="category">Category</option>
                              <option value="city">City</option>
                              <option value="link">Link</option>
                            </select>
                          </div>
                        </div>
                        {/* Conditional selections */}
                        {bannerData?.type === "ticket" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Select Custom Ticket</label>
                            <div className="col-sm-6" onClick={() => toggleModel()}>
                              {venueText ? (
                                <div className="input-group">
                                  <button
                                    className="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#fff",
                                    }}
                                    type="button"
                                  >
                                    Choose Ticket
                                  </button>
                                  <div style={{ border: "1px solid #ced4da", width: "16px" }}></div>
                                  <img
                                    src={venueImage}
                                    alt="ticket"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                    }}
                                  />
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    className="form-control"
                                    readOnly
                                    value={venueText}
                                    placeholder="No ticket chosen"
                                  />
                                </div>
                              ) : (
                                <div className="input-group">
                                  <button
                                    className="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#fff",
                                    }}
                                    type="button"
                                  >
                                    Choose Ticket
                                  </button>
                                  <input
                                    type="text"
                                    className="form-control"
                                    readOnly
                                    placeholder="No ticket chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {bannerData?.type === "category" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Select Category</label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) => updateValue("category", e.target.value)}
                                value={bannerData?.category}
                                name="category"
                                className="form-select"
                              >
                                <option value="">Select Category</option>
                                {categoryList.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        {bannerData?.type === "city" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Select City</label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) => updateValue("cityId", e.target.value)}
                                value={bannerData?.cityId}
                                name="cityId"
                                className="form-select"
                              >
                                <option value="">Select City</option>
                                {bannerTypeList.map((item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        {bannerData?.type === "link" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">Link</label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                onChange={(e) => updateValue("link", e.target.value)}
                                value={bannerData?.link}
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                        {/* Media Type */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">Media Type</label>
                          <div className="col-sm-6">
                            <select
                              value={bannerData?.mediaType}
                              onChange={(e) => updateValue("mediaType", e.target.value)}
                              className="form-select"
                            >
                              <option>Select</option>
                              <option value="photo">Photo</option>
                              <option value="video">Video</option>
                            </select>
                          </div>
                        </div>
                        {/* File Upload */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">File</label>
                          <div className="col-sm-6">
                            {bannerData?.mediaType === "video" ? (
                              <input
                                type="file"
                                accept="video/*"
                                onChange={(e) => setSelectedImage(e.target.files[0])}
                                name="mediaUrl"
                                className="form-control"
                              />
                            ) : (
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => setSelectedImage(e.target.files[0])}
                                name="mediaUrl"
                                className="form-control"
                              />
                            )}
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                alt="preview"
                                onClick={() => setOpenLightBox(true)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>
                        {/* Submit Button */}
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <button type="submit" className="btn btn-primary w-md">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <CustomTicketModal show={model} closeModal={closeModal} selectRow={selectRow} />
      {/* <OfferModal
        show={offerModel}
        closeOfferModal={closeOfferModal}
        selectRow={selectOfferRow}
      /> */}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
