import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequest } from "../../components/Common/Utils.js";

export default function AddMessage() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  const [previewCover, setPreviewCover] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [ratingData, setRatingData] = useState();
  const [loading, setLoading] = useState(false);

  const [selectedCover, setSelectedCover] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setRatingData(state);    
    setImageIcon(state?.image ? state?.image : "");
  }, [state]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const Addreply = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await postRequest("comman/contact-us/query-reply-admin", {
      conctactUsId: id,
      reply: ratingData.reply,
    });
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/contactUs");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...ratingData };
    list[index] = value;
    setRatingData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Contact Us</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Contact Us</li>:{" "}
              <li className="breadcrumb-item">Add Reply</li>
            </ol>
          </div>
        </div>
      </div>

      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Add Reply </h4>
                <Form onSubmit={Addreply}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Admin Reply
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              onChange={(e) =>
                                updateValue("reply", e.target.value)
                              }
                              value={ratingData?.reply}
                              name="reply"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
