import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, Form, Tabs, Tab } from 'react-bootstrap'

const PricingPurchaseUnit = ({
  raynaTicket,
  tourOption,
  availableCurrencies = ['USD', 'AED'],
  bannerData,
  activeKey,
  setActiveKey,
  OptionImageupload,
  optionimageUrls,
  OptionRemoveImage,
  optionAddImages,
}) => {
  // State for each tour option's pricing and VAT settings
  const [pricingState, setPricingState] = useState({})

  // Default values for inputs (now including discount)
  const defaultPricingState = {
    currency: availableCurrencies[0],
    numAdults: 1,
    numChildren: 0,
    adultPrice: 0,
    childPrice: 0,
    vatIncluded: bannerData?.vat || false,
    vatPercentage: bannerData?.vatPercentage ? parseInt(bannerData.vatPercentage) : 0,
    markupPercentage: bannerData?.markup ? parseInt(bannerData.markup) : 0,
    discount: bannerData?.discount ? parseFloat(bannerData.discount) : 0,
    outputVATPercentage: 0,
  }

  // Default active tab: if no activeKey is provided, default to first option
  useEffect(() => {
    if (!activeKey && bannerData?.PricingPurchase?.length > 0) {
      const firstOption = bannerData.PricingPurchase[0]
      const compositeKey = `${firstOption.tourId}_${firstOption.tourOptionId}_${firstOption.transferId}`
      setActiveKey(compositeKey)
    }
  }, [activeKey, bannerData?.PricingPurchase, setActiveKey])

  // Initialize or update state when activeKey changes
  useEffect(() => {
    if (!pricingState[activeKey]) {
      const selectedOption = bannerData?.PricingPurchase?.find((opt) => {
        const compositeKey = `${opt.tourId}_${opt.tourOptionId}_${opt.transferId}`
        return compositeKey === activeKey
      })
      setPricingState((prev) => ({
        ...prev,
        [activeKey]: {
          ...defaultPricingState,
          adultPrice: selectedOption?.adultPrice || 0,
          childPrice: selectedOption?.childPrice || 0,
          markupPercentage: bannerData?.markup ? parseInt(bannerData.markup) : 0,
          discount: bannerData?.discount ? parseFloat(bannerData.discount) : 0,
        },
      }))
    }
  }, [activeKey, bannerData?.PricingPurchase, bannerData, pricingState, defaultPricingState])

  // Get current pricing state for the active tab
  const currentState = pricingState[activeKey] || defaultPricingState

  // Handle input changes for pricing state
  const handleInputChange = (key, value) => {
    setPricingState((prev) => ({
      ...prev,
      [activeKey]: {
        ...prev[activeKey],
        [key]: value,
      },
    }))
  }

  // Calculation: First apply markup, then discount, then (if vatIncluded is true) add VAT.
  const calculatePurchasingPrice = () => {
    const {
      adultPrice,
      childPrice,
      numAdults,
      numChildren,
      vatPercentage,
      vatIncluded,
      discount,
      markupPercentage,
    } = currentState

    // Base total from adult and child prices
    const totalBase = adultPrice * numAdults + childPrice * numChildren

    // 1st: Apply markup
    const priceAfterMarkup = totalBase * (1 + markupPercentage / 100)

    // 2nd: Apply discount if discount > 0
    const priceAfterDiscount = discount > 0 ? priceAfterMarkup * (1 - discount / 100) : priceAfterMarkup

    // 3rd: If VAT is marked as included, add VAT amount to the price
    const vatRate = vatPercentage / 100
    const vatAmount = vatIncluded ? priceAfterDiscount * vatRate : 0
    const finalTotal = priceAfterDiscount + vatAmount

    return { baseTotal: totalBase, priceAfterMarkup, priceAfterDiscount, vatAmount, finalTotal }
  }

  const calculateSellingPrice = () => {
    const { finalTotal } = calculatePurchasingPrice()
    const { markupPercentage, outputVATPercentage } = currentState

    const finalSellingPrice = finalTotal * (1 + markupPercentage / 100)
    const totalProfitAmount = finalSellingPrice - finalTotal
    const outputVATAmount = finalSellingPrice * (outputVATPercentage / 100)
    const vatPaidToGovt = Math.max(outputVATAmount - calculatePurchasingPrice().vatAmount, 0)

    return { finalSellingPrice, totalProfitAmount, outputVATAmount, vatPaidToGovt }
  }

  // Reusable functions for percentage calculations
  const applyPercentOnAmount = (amount, percent) => {
    amount = parseFloat(amount)
    percent = parseFloat(percent)
    return amount + amount * (percent / 100)
  }

  const getPercentAmount = (amount, percent) => {
    return amount * (percent / 100)
  }

  // Destructure calculated values
  const { baseTotal, vatAmount, finalTotal } = calculatePurchasingPrice()
  const { finalSellingPrice, totalProfitAmount, outputVATAmount, vatPaidToGovt } = calculateSellingPrice()

  return (
    <>
      <Tabs
        id="rayna-options-tabs"
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(k)}
        className="mb-3"
      >
        {bannerData?.PricingPurchase?.map((option, index) => {
          // Create a composite key using tourId, tourOptionId, and transferId
          const compositeKey = `${option.tourId}_${option.tourOptionId}_${option.transferId}`
          return (
            <Tab
              eventKey={compositeKey}
              title={`Option ${index + 1} #${option.tourOptionId}`}
              key={compositeKey}
            >
              <Card className="mt-4 shadow-sm">
                <CardBody>
                  <h5 className="mb-3">Info</h5>
                  <Row className="mb-4">
                    <Col sm={6}>
                      <label className="col-form-label">
                        Transfer Name: {option?.transferName}
                      </label>
                    </Col>
                    <Col sm={6}>
                      <label className="col-form-label">
                        Start Time: {option?.startTime}
                      </label>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="mt-4 shadow-sm">
                <CardBody>
                  <h5 className="mb-3">Images</h5>
                  <Row className="mb-4">
                    <label className="col-sm-3 col-form-label">Images</label>
                    <Col sm={6}>
                      <input
                        type="file"
                        onChange={(e) => OptionImageupload(e, compositeKey)}
                        name="mediaUrl"
                        className="form-control"
                      />
                      <br />
                      <div className="row">
                        {option?.imageUrls?.map((image, idx) => (
                          <div
                            className="d-flex align-items-center flex-column col-md-3"
                            key={idx}
                          >
                            <img
                              src={image}
                              style={{
                                width: '100px',
                                height: '100px',
                                padding: '5px',
                              }}
                              alt={`Preview ${idx + 1}`}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => OptionRemoveImage(compositeKey, idx)}
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col sm={3}>
                      <button
                        onClick={() => optionAddImages(compositeKey, option?.imageUrls)}
                        disabled={!(option?.imageUrls?.length > 0)}
                        type="button"
                        className="btn btn-primary btn-lg"
                      >
                        Add Image
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="mt-4 shadow-sm">
                <CardBody>
                  <h5 className="mb-3">Pricing Purchase & Sales</h5>
                  <Form>
                    <Form.Group className="mb-3" controlId="formOptionName">
                      <Form.Label>Option Name</Form.Label>
                      <Form.Control type="text" value={option?.optionName || ''} readOnly />
                    </Form.Group>
                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group controlId="numAdults">
                          <Form.Label>No. of Adults</Form.Label>
                          <Form.Control
                            type="number"
                            min={1}
                            value={currentState.numAdults}
                            onChange={(e) => handleInputChange('numAdults', Number(e.target.value))}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="numChildren">
                          <Form.Label>No. of Children</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            value={currentState.numChildren}
                            onChange={(e) => handleInputChange('numChildren', Number(e.target.value))}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group controlId="adultPrice">
                          <Form.Label>Adult Price</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            value={applyPercentOnAmount(option.adultPrice, 0).toFixed(2)}
                            readOnly
                            onChange={(e) => handleInputChange('adultPrice', Number(e.target.value))}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="childPrice">
                          <Form.Label>Child Price</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            readOnly
                            value={applyPercentOnAmount(option.childPrice, 0).toFixed(2)}
                            onChange={(e) => handleInputChange('childPrice', Number(e.target.value))}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* New Discount Field */}
                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group controlId="discount">
                          <Form.Label>Discount (%)</Form.Label>
                          <Form.Control
                            type="number"
                            min={0}
                            max={100}
                            value={currentState.discount}
                            onChange={(e) => handleInputChange('discount', Number(e.target.value))}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group controlId="vatIncluded">
                          <Form.Label>VAT Included?</Form.Label>
                          <Form.Control type="text" value={bannerData?.vat ? 'Yes' : 'No'} readOnly />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="vatPercentage">
                          <Form.Label>VAT Percentage (%)</Form.Label>
                          <Form.Control type="number" min={0} value={bannerData.vatPercentage} readOnly />
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={6}>
                        <p>
                          <strong>Adult Price Total :</strong>{' '}
                          {(applyPercentOnAmount(option.adultPrice, bannerData?.markup || 0) * currentState.numAdults).toFixed(2)}
                        </p>
                        <p>
                          <strong>Child Price Total :</strong>{' '}
                          {(applyPercentOnAmount(option.childPrice, bannerData?.markup || 0) * currentState.numChildren).toFixed(2)}
                        </p>
                        <p>
                          <strong>Total (Base) :</strong>{' '}
                          {(
                            applyPercentOnAmount(option.adultPrice, bannerData?.markup || 0) * currentState.numAdults +
                            applyPercentOnAmount(option.childPrice, bannerData?.markup || 0) * currentState.numChildren
                          ).toFixed(2)}
                        </p>
                      </Col>
                      <Col md={6}>
                        <p>
                          <strong>VAT Amount :</strong>{' '}
                          {vatAmount.toFixed(2)}
                        </p>
                        <p>
                          <strong>Final Total :</strong>{' '}
                          {finalTotal.toFixed(2)}
                        </p>
                      </Col>
                    </Row>
                    <hr />
                  </Form>
                </CardBody>
              </Card>
            </Tab>
          )
        })}
      </Tabs>
    </>
  )
}

export default PricingPurchaseUnit
