import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";

import FileUpload from "../../components/Common/FileUpload"; 

import VenueModal from "../../components/Modal/VenueModalOrg.js";
import OfferModal from "../../components/Modal/OfferModal.js";
import YachtModal from "components/Modal/YachtModal.js";
import EventModal from "components/Modal/EventModal.js";
import ActivityModal from "components/Modal/ActivityModal.js";

import PreviewAd from "./PreviewAd.js";
import { set } from "lodash";

export default function CreateAd() {
  const [bannerData, setBannerData] = useState({
    buttonText: "View",
    size: "large",
    description: "",
    title: "",
    subHeading: "",
    subText: "",
    badgeText: "",
    type: "link",
    link: "",
    item: "",
  });
  const [previewImage, setPreviewImage] = useState("");
  const [previewLogo, setPreviewLogo] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [model, setModel] = useState(false);
  const [offerModel, setOfferModel] = useState(false);
  const [yachtId, setYachtId] = useState("");
  const [yachtText, setYachtText] = useState("");
  const [yachtImage, setYachtImage] = useState("");
  const [yachtModel, setYachtModel] = useState(false);
  const [eventId, setEventId] = useState("");
  const [eventText, setEventText] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventModel, setEventModel] = useState(false);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [activityModel, setActivityModel] = useState(false);

  const [activeTab, setActiveTab] = useState("large");

  const handleTabChange = (newTabValue) => {
    setActiveTab(newTabValue);
  };

  let history = useHistory();
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setBannerData({ ...bannerData, background: reader.result });
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
      const tempForm = { ...bannerData };
      tempForm.logo = reader.result;
      setBannerData(tempForm);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const toggleModel = () => {
    setModel(!model);
  };
  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };
  const showLightBox2 = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox2(true);
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };
  const toggleYachtModel = () => {
    setYachtModel(!yachtModel);
  };
  const toggleEventModel = () => {
    setEventModel(!eventModel);
  };
  const toggleActivityModel = () => {
    setActivityModel(!activityModel);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const closeYachtModal = () => {
    setYachtModel(false);
  };
  const closeEventModal = () => {
    setEventModel(false);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };
  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);

    const formInput = { ...bannerData };
    formInput.item = id;
    formInput.subHeading = venue?.name;
    formInput.subText = venue?.address;
    formInput.buttonText = "View";
    formInput.logo = venue?.logo;
    formInput.background = venue?.cover;
    setBannerData(formInput);
    setPreviewLogo(venue?.logo);
    setPreviewImage(venue?.cover);
    closeModal();
  };

  const selectOfferRow = (offer) => {
    setOfferId(offer?._id);
    setOfferText(offer?.title);
    setOfferImage(offer?.image);
    const formInput = { ...bannerData };
    formInput.item = offer?._id;

    if (bannerData.title == "") formInput.title = offer?.title;
    if (bannerData.description == "")
      formInput.description = offer?.description;

    formInput.subHeading = offer?.venue?.name;
    formInput.subText = offer?.venue?.address;
    formInput.buttonText = "View";
    formInput.logo = offer?.venue?.logo;
    formInput.background = offer?.image;

    setBannerData(formInput);
    setPreviewImage(offer?.image);
    setPreviewLogo(offer?.venue?.logo);
    closeOfferModal();
  };

  const selectYachtRow = (yacht) => {
    setYachtId(yacht?._id);
    setYachtText(yacht?.name);
    setYachtImage(yacht?.image);
    const formInput = { ...bannerData };
    formInput.item = yacht?._id;
    if (bannerData.title == "") formInput.title = yacht?.name;
    if (bannerData.description == "") formInput.description = yacht?.about;
    formInput.subHeading = yacht?.yachtClub.name;
    formInput.subText = yacht?.yachtClub?.address;
    formInput.buttonText = "View";
    formInput.logo = yacht?.yachtClub?.logo;
    formInput.background = yacht?.image;
    setBannerData(formInput);
    setPreviewImage(yacht?.image);
    setPreviewLogo(yacht?.yachtClub?.logo);
    closeYachtModal();
  };
  const selectEventRow = (id, name, event) => {
    setEventId(id);
    setEventText(name);
    setEventImage(event?.image);
    const formInput = { ...bannerData };
    formInput.item = id;
    if (bannerData.title == "") formInput.title = name;
    if (bannerData.description == "")
      formInput.description = event?.description;
    formInput.subHeading = event?.venue?.name;
    formInput.subText = event?.venue?.address;
    formInput.buttonText = "View";
    formInput.logo = event?.venue?.logo;
    formInput.background = event?.image;
    setBannerData(formInput);
    setPreviewImage(event?.image);
    setPreviewLogo(event?.venue?.logo);
    closeEventModal();
  };
  const selectActivityRow = (id, name, activity) => {
    setActivityId(id);
    setActivityText(name);
    setActivityImage(activity?.image);
    const formInput = { ...bannerData };
    formInput.item = id;
    if (bannerData.title == "") formInput.title = name;
    if (bannerData.description == "")
      formInput.description = activity?.description;
    formInput.subHeading = activity?.provider?.name;
    formInput.subText = activity?.provider?.address;
    formInput.buttonText = "View";
    formInput.logo = activity?.provider?.logo;
    formInput.background = activity?.galleries?.length
      ? activity?.galleries[0]
      : "";
    setBannerData(formInput);
    setPreviewImage(activity?.galleries?.length ? activity?.galleries[0] : "");
    setPreviewLogo(activity?.provider?.logo);
    closeActivityModal();
  };

  const CreateAd = async (e) => {
    e.preventDefault();
    

    if (!bannerData?.title) {
      toast.error("Please enter headline");
      return;
    }

    if (!bannerData?.logo) {
      toast.error("Please select logo");
      return;
    }
    if (!bannerData?.background) {
      toast.error("Please select background");
      return;
    }
    if (!bannerData?.type) {
      toast.error("Please select banner type");
      return;
    }
    if (bannerData?.type == "venue" && !venueId) {
      toast.error("Please select venue");
      return;
    }
    if (bannerData?.type == "offer" && !offerId) {
      toast.error("Please select offer");
      return;
    }
    if (bannerData?.type == "yacht" && !yachtId) {
      toast.error("Please select yacht");
      return;
    }
    if (bannerData?.type == "event" && !eventId) {
      toast.error("Please select event");
      return;
    }
    if (bannerData?.type == "activity" && !activityId) {
      toast.error("Please select activity");
      return;
    }

    let selectedImageRes = null;
    let selectedImageRes2 = null;

    setStartLoader(true);
    if (selectedImage) {
      const selectedImageForm = new FormData();
      selectedImageForm.append("background", selectedImage);
      selectedImageRes = await postRequestForm(
        "comman/img/upload",
        selectedImageForm
      );
    }

    if (selectedLogo) {
      const selectedImageForm2 = new FormData();
      selectedImageForm2.append("logo", selectedLogo);
      selectedImageRes2 = await postRequestForm(
        "comman/img/upload",
        selectedImageForm2
      );
    }
    setStartLoader(false);

    const payload = {
      headline: bannerData?.title,
      subHeadline: bannerData?.description,
      title: bannerData?.subHeading,
      subTitle: bannerData?.subText,
      type: bannerData?.type,
      badgeText: bannerData?.badgeText,
      item: bannerData?.item,
      size: activeTab,
    };

    if (activeTab === "medium") {
      payload.buttonText = " ";
    } else {
      payload.buttonText = bannerData?.buttonText;
    }

    if (selectedImageRes?.data?.url) {
      payload.background = selectedImageRes.data.url;
    } else {
      payload.background = bannerData?.background;
    }

    if (selectedImageRes2?.data?.url) {
      payload.logo = selectedImageRes2.data.url;
    } else {
      payload.logo = bannerData?.logo;
    }

    if(bannerData.type == "link") {
      payload.item = bannerData.link;
    }

    setLoading(true);
    const response = await postRequest("ad/create", payload);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        // history.push("/banner");
        history.goBack();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Ads Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Ads Management</li>:{" "}
              <li className="breadcrumb-item">Create Ad</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Ad</h4>
                <Form onSubmit={CreateAd}>
                  <Row>
                    <Col lg={7} className="">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Headline
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={bannerData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        {(activeTab === "large" || activeTab === "small") && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Sub Headline
                            </label>
                            <div className="col-sm-9">
                              <textarea
                                type="textarea"
                                onChange={(e) =>
                                  updateValue("description", e.target.value)
                                }
                                value={bannerData?.description}
                                name="description"
                                className="form-control"
                                id="horizontal-password-input"
                              />
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={bannerData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              <option value="link">Link</option>
                              <option value="venue">Venue</option>
                              <option value="offer">Offer</option>
                              <option value="yacht">Yacht</option>
                              <option value="event">Event</option>
                              <option value="activity">Activity</option>
                              {/* <option value="venue">Venue</option> */}
                            </select>
                          </div>
                        </div>

                        {bannerData?.type == "venue" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>
                            <div
                              className="col-sm-9"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
                                //   <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       {venueText}
                                //     </a>
                                //   </div>
                                //   <div>
                                //   <a
                                //     onClick={() => toggleModel()}
                                //     href="javascript:void(0)"
                                //   >
                                //     <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                //   </a>
                                //   </div>
                                // </div>

                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                  {/* <div 
                                  style={{
                                    border: "1px solid #ced4da",
                                    borderRadius: "0.25rem",
                                    padding: "0.375rem",
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                >
                                 
                                  <img src="your-logo.png" alt="Venue Logo" style={{width: "20px", marginRight: "10px"}} />                                 
                                  <span id="selectedVenue">
                                    {venueText || "No venue chosen"}
                                  </span>
                                </div>  */}
                                </div>
                              ) : (
                                //   <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       Select Venue
                                //     </a>
                                //   </div>
                                //   <div>
                                //   <a
                                //     onClick={() => toggleModel()}
                                //     href="javascript:void(0)"
                                //   >
                                //     <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                //   </a>
                                //   </div>
                                // </div>

                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {bannerData?.type == "yacht" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Yacht
                            </label>
                            <div
                              className="col-sm-9"
                              onClick={() => toggleYachtModel()}
                            >
                              {yachtText ? (
                                // <a
                                //   onClick={() => toggleModel()}
                                //   href="javascript:void(0)"
                                // >
                                //   {venueText}
                                // </a>

                                //   <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}

                                //     >
                                //       {yachtText}

                                //     </a>
                                //   </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseyachtBtn"
                                    onclick={() => toggleYachtModel()}
                                  >
                                    Choose Yacht
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={yachtImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={yachtText}
                                    id="selectedYacht"
                                    placeholder="No yacht chosen"
                                  />
                                  {/* <div>
                                <a
                                  onClick={() => toggleYachtModel()}
                                  href="javascript:void(0)"
                                >
                                  <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                </a>
                                </div> */}
                                </div>
                              ) : (
                                //   <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       Select Yacht
                                //     </a>
                                //   </div>
                                //   <div>
                                //   <a
                                //     onClick={() => toggleYachtModel()}
                                //     href="javascript:void(0)"
                                //   >
                                //     <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                //   </a>
                                //   </div>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseyachtBtn"
                                    onclick={() => toggleYachtModel()}
                                  >
                                    Choose Yacht
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={yachtText}
                                    id="selectedYacht"
                                    placeholder="No yacht chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {bannerData?.type == "event" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Event
                            </label>
                            <div
                              className="col-sm-9"
                              onClick={() => toggleEventModel()}
                            >
                              {eventText ? (
                                // <a
                                //   onClick={() => toggleModel()}
                                //   href="javascript:void(0)"
                                // >
                                //   {venueText}
                                // </a>
                                //   <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >

                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       {eventText}
                                //     </a>
                                //   </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseEventBtn"
                                    onclick={() => toggleEventModel()}
                                  >
                                    Choose Event
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={eventImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={eventText}
                                    id="selectedEvent"
                                    placeholder="No event chosen"
                                  />
                                  {/* <div>
                                <a
                                  onClick={() => toggleEventModel()}
                                  href="javascript:void(0)"
                                >
                                  <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                </a>
                                </div> */}
                                </div>
                              ) : (
                                //   <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     Select Event
                                //   </a>
                                // </div>
                                // <div>
                                // <a
                                //   onClick={() => toggleEventModel()}
                                //   href="javascript:void(0)"
                                // >
                                //   <i
                                //       style={{ color: "#5b626b" }}
                                //       class="fa fa-plus"
                                //     ></i>
                                // </a>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseEventBtn"
                                    onclick={() => toggleEventModel()}
                                  >
                                    Choose Event
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={eventText}
                                    id="selectedEvent"
                                    placeholder="No event chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {bannerData?.type == "activity" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Activity
                            </label>
                            <div
                              className="col-sm-9"
                              onClick={() => toggleActivityModel()}
                            >
                              {activityText ? (
                                // <a
                                //   onClick={() => toggleModel()}
                                //   href="javascript:void(0)"
                                // >
                                //   {venueText}
                                // </a>
                                //   <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >

                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     {activityText}
                                //   </a>
                                // </div>
                                // <div>
                                // <a
                                //   onClick={() => toggleActivityModel()}
                                //   href="javascript:void(0)"
                                // >
                                //   <i
                                //       style={{ color: "#5b626b" }}
                                //       class="fa fa-plus"
                                //     ></i>
                                // </a>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseActivityBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Activity
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={activityText}
                                    id="selectedActivity"
                                    placeholder="No activity chosen"
                                  />
                                </div>
                              ) : (
                                //   <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                // <div style={{ minWidth: "100%" }}>
                                //   <a
                                //     href="javascript:void(0)"
                                //     style={{
                                //       textDecoration: "none",
                                //       color: "#5b626b",
                                //       fontSize: "0.8125rem",
                                //       fontWeight: 400,
                                //     }}
                                //   >
                                //     Select Activity
                                //   </a>
                                // </div>
                                // <div>
                                // <a
                                //   onClick={() => toggleActivityModel()}
                                //   href="javascript:void(0)"
                                // >
                                //   <i
                                //       style={{ color: "#5b626b" }}
                                //       class="fa fa-plus"
                                //     ></i>
                                // </a>
                                // </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseActivityBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Activity
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={activityText}
                                    id="selectedActivity"
                                    placeholder="No activity chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {bannerData?.type == "offer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>

                            <div
                              className="col-sm-9"
                              onClick={() => toggleOfferModel()}
                            >
                              {offerText ? (
                                //   <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       {offerText}
                                //     </a>
                                //   </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={offerImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                  {/* <div><a
                                  onClick={() => toggleOfferModel()}
                                  href="javascript:void(0)"
                                >
                                  <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                </a></div> */}
                                </div>
                              ) : (
                                //   <div
                                //   style={{
                                //     display: "flex",
                                //     border: "1px solid #ced4da",
                                //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                //     borderRadius: "0.25rem",
                                //     lineHeight: 1.5,
                                //   }}
                                // >
                                //   <div style={{ minWidth: "100%" }}>
                                //     <a
                                //       href="javascript:void(0)"
                                //       style={{
                                //         textDecoration: "none",
                                //         color: "#5b626b",
                                //         fontSize: "0.8125rem",
                                //         fontWeight: 400,
                                //       }}
                                //     >
                                //       Select Offer
                                //     </a>
                                //   </div>
                                //   <div>
                                //   <a
                                //     onClick={() => toggleOfferModel()}
                                //     href="javascript:void(0)"
                                //   >
                                //     <i
                                //         style={{ color: "#5b626b" }}
                                //         class="fa fa-plus"
                                //       ></i>
                                //   </a>
                                //   </div>
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Offer
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No offer chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {bannerData?.type == "link" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Link
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("link", e.target.value)
                                }
                                value={bannerData?.link}
                                name="title"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("subHeading", e.target.value)
                              }
                              value={bannerData?.subHeading}
                              name="subHeading"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Sub Title
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("subText", e.target.value)
                              }
                              value={bannerData?.subText}
                              name="subText"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedLogo(e.target.files[0])
                              }
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                              // required="required"
                            />
                            <br />
                            {previewLogo && (
                              <img
                                src={previewLogo}
                                onClick={() => showLightBox2(previewLogo)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        </div>

                        {(activeTab === "large" || activeTab === "medium") && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-password-input"
                              className="col-sm-3 col-form-label"
                            >
                              Background
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="file"
                                onChange={(e) =>
                                  setSelectedImage(e.target.files[0])
                                }
                                name="mediaUrl"
                                className="form-control"
                                id="horizontal-password-input"
                                // required="required"
                              />
                              <br />
                              {bannerData?.mediaType == "video"
                                ? null
                                : previewImage && (
                                    <img
                                      src={previewImage}
                                      onClick={() => showLightBox(previewImage)}
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  )}
                            </div>
                          </div>
                        )}

                        {activeTab == "small" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Background Color
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="color"
                                onChange={(e) =>
                                  updateValue("background", e.target.value)
                                }
                                value={bannerData?.background || "#ffffff"}
                                name="background"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                        {activeTab === "large" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Badge Text
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("badgeText", e.target.value)
                                }
                                value={bannerData?.badgeText}
                                name="badgeText"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                        {activeTab === "large" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Button Text
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("buttonText", e.target.value)
                                }
                                value={bannerData?.buttonText}
                                name="buttonText"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}

                        {/* <div className="row mb-4">
                              <label  className="col-sm-3 col-form-label">Start Date</label>
                              <div className="col-sm-6">
                              <input type="date" onChange={ (e) => updateValue('startDate', e.target.value) } name='startDate'  className="form-control"  />

                                                            </div>
                          </div>           

                          <div className="row mb-4">
                              <label  className="col-sm-3 col-form-label">End Date</label>
                              <div className="col-sm-6">
                                  <input type="date" onChange={ (e) => updateValue('endDate', e.target.value) } name='endDate'  className="form-control"  />
                              </div>
                          </div>     */}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={5} className="">
                      <PreviewAd
                        currentForm={bannerData}
                        onTabChange={handleTabChange}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}

      {/* modal */}

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />

      {/* modal */}
      <OfferModal
        show={offerModel}
        closeModal={closeOfferModal}
        selectRow={selectOfferRow}
      />
      <YachtModal
        show={yachtModel}
        closeModal={closeYachtModal}
        selectRow={selectYachtRow}
      />
      <EventModal
        show={eventModel}
        closeEventModal={closeEventModal}
        selectRow={selectEventRow}
      />
      <ActivityModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      />
      <FileUpload message="Logo & Cover Uploading" status={startLoader} />
    </React.Fragment>
  );
}
