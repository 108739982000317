import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

import { postRequest } from "../../components/Common/Utils.js";
import "./SpecialOfferSalesManagementlist.scss"; 

// --------------------
// 1) Utility Helpers
// --------------------
function groupBy(array, key) {
  return array.reduce((result, item) => {
    const groupKey = item[key];
    if (!result[groupKey]) result[groupKey] = [];
    result[groupKey].push(item);
    return result;
  }, {});
}

/**
 * For a given group of items, sum Dr or Cr amounts:
 * If perspective = "USER", amounts go in Cr; otherwise Dr.
 */
function getTotalsForGroup(items, perspective) {
  let totalDr = 0;
  let totalCr = 0;
  items.forEach((row) => {
    const amt = row.billAmount || 0;
    if (perspective === "USER") {
      totalCr += amt;
    } else {
      totalDr += amt;
    }
  });
  return { totalDr, totalCr };
}

/**
 * Transform new API data (deal/offer/ticket) to ledger-friendly objects.
 */
function transformApiData(list) {
  return list.map((item) => {
    const price = item.price || 0;
    const qty = item.qty || 0;
    const billAmount = price * qty;

    let offerTitle = "N/A";
    let specialOfferId = item._id; 
    if (item.type === "deal") {
      offerTitle = item.deal?.title || "N/A";
      specialOfferId = item.dealId;
    } else if (item.type === "offer") {
      offerTitle = item.offer?.title || "N/A";
      specialOfferId = item.offerId;
    } else if (item.type === "ticket") {
      offerTitle = "Ticket";
      specialOfferId = item.ticketId;
    }

    const venueName = item.venue?.name || "Unknown Venue";
    const userFirst = item.user?.first_name || "";
    const userLast = item.user?.last_name || "";
    const userName = (userFirst + " " + userLast).trim() || "Unknown User";

    return {
      ...item,
      billAmount,
      offerTitle,
      venueName,
      userName,
      specialOfferId,
    };
  });
}

// --------------------
// 2) Ledger-Specific Table Helpers
// --------------------
/**
 * Splits items into Dr side and Cr side arrays based on perspective.
 * If perspective = "USER", amounts go in Cr side; else in Dr side.
 */
function splitDrCrRows(items, perspective) {
  const drRows = [];
  const crRows = [];
  items.forEach((row) => {
    const dateStr = format(new Date(row.createdAt), "dd/MM/yyyy");
    const lines = [
      `Sale of ${row.offerTitle || "N/A"}`,
      `to ${row.userName || "Unknown User"}`,
      `at ${row.venueName || "Unknown Venue"}`,
    ];
    const multilineParticulars = lines.join("<br/>");
    const amountStr = Math.abs(row.billAmount).toFixed(2);

    const rowObj = {
      date: dateStr,
      particulars: multilineParticulars,
      amount: amountStr,
    };

    if (perspective === "USER") {
      // user => Cr
      crRows.push(rowObj);
    } else {
      // whosin/venue/offer => Dr
      drRows.push(rowObj);
    }
  });
  return { drRows, crRows };
}

/**
 * Ensures Dr and Cr arrays have the same length so we can pair them row by row.
 */
function mergeLedgerRows(drRows, crRows) {
  const maxLen = Math.max(drRows.length, crRows.length);
  const paddedDr = [...drRows];
  const paddedCr = [...crRows];

  while (paddedDr.length < maxLen) {
    paddedDr.push({ date: "", particulars: "", amount: "" });
  }
  while (paddedCr.length < maxLen) {
    paddedCr.push({ date: "", particulars: "", amount: "" });
  }

  return { paddedDr, paddedCr };
}

/**
 * Renders the 6-column ledger table in React:
 * Dr Date | Dr Particulars | Dr Amount | Cr Date | Cr Particulars | Cr Amount
 */
function renderLedgerTable(items, perspective) {
  const { drRows, crRows } = splitDrCrRows(items, perspective);
  const { paddedDr, paddedCr } = mergeLedgerRows(drRows, crRows);

  return (
    <div className="custom-table-container">
      <div className="table-header">
        <div className="th dr-date">Date</div>
        <div className="th dr-particulars">Particulars (Dr)</div>
        <div className="th dr-amount">Amount</div>
        <div className="th cr-date">Date</div>
        <div className="th cr-particulars">Particulars (Cr)</div>
        <div className="th cr-amount">Amount</div>
      </div>
      <div className="table-body">
        {paddedDr.map((dr, idx) => {
          const cr = paddedCr[idx];
          return (
            <div className="table-row" key={idx}>
              {/* Dr side */}
              <div className="td dr-date">{dr.date}</div>
              <div
                className="td dr-particulars"
                dangerouslySetInnerHTML={{ __html: dr.particulars }}
              />
              <div
                className="td dr-amount"
                style={{ color: dr.amount ? "green" : "inherit" }}
              >
                {dr.amount}
              </div>
              {/* Cr side */}
              <div className="td cr-date">{cr.date}</div>
              <div
                className="td cr-particulars"
                dangerouslySetInnerHTML={{ __html: cr.particulars }}
              />
              <div
                className="td cr-amount"
                style={{ color: cr.amount ? "red" : "inherit" }}
              >
                {cr.amount}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

// --------------------
// 3) PRINT & CSV Functions
// --------------------
/** 
 * Build the ledger table in HTML (for printing), similar to `renderLedgerTable` 
 * but returning a string. 
 */
function buildLedgerPrintTableHTML(items, perspective) {
  const { drRows, crRows } = splitDrCrRows(items, perspective);
  const { paddedDr, paddedCr } = mergeLedgerRows(drRows, crRows);

  let tableHTML = `
    <div class="table-header">
      <div class="th dr-date">Date</div>
      <div class="th dr-particulars">Particulars (Dr)</div>
      <div class="th dr-amount">Amount</div>
      <div class="th cr-date">Date</div>
      <div class="th cr-particulars">Particulars (Cr)</div>
      <div class="th cr-amount">Amount</div>
    </div>
    <div class="table-body">
  `;

  paddedDr.forEach((dr, idx) => {
    const cr = paddedCr[idx];
    // Color styling for amounts
    const drStyle = dr.amount ? 'style="color:green"' : "";
    const crStyle = cr.amount ? 'style="color:red"' : "";
    tableHTML += `
      <div class="table-row">
        <div class="td dr-date">${dr.date}</div>
        <div class="td dr-particulars">${dr.particulars}</div>
        <div class="td dr-amount" ${drStyle}>${dr.amount}</div>
        <div class="td cr-date">${cr.date}</div>
        <div class="td cr-particulars">${cr.particulars}</div>
        <div class="td cr-amount" ${crStyle}>${cr.amount}</div>
      </div>
    `;
  });

  tableHTML += `</div>`; // close .table-body
  return tableHTML;
}

/**
 * Build a complete HTML document (single group) for printing.
 */
function buildSingleLedgerPrintHTML(items, perspective) {
  const inlineCSS = `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
    body { font-family: 'Inter', sans-serif; margin: 20px; background: #f2f2f2; }
    .account-statement { width: 85%; margin: 0 auto; }
    .table-header { display: flex; font-weight: 600; background: #f9f9f9; padding: 0.75rem; }
    .th { flex: 1; font-size: 0.8rem; text-transform: uppercase; }
    .table-row { display: flex; align-items: center; border-bottom: 1px solid #eee; padding: 0.75rem; }
    .td { flex: 1; font-size: 0.8rem; }
    .dr-date, .cr-date { width: 80px; }
    .dr-amount, .cr-amount { width: 70px; text-align: right; }
  `;
  let html = `<html><head><meta charset="utf-8"/><title>Ledger Print</title><style>${inlineCSS}</style></head><body>`;
  html += `<div class="account-statement">`;
  html += buildLedgerPrintTableHTML(items, perspective);
  html += `</div></body></html>`;
  return html;
}

/**
 * Print in new tab.
 */
function printDocument(htmlString) {
  const printWindow = window.open("", "_blank");
  if (!printWindow) return;
  printWindow.document.open();
  printWindow.document.write(htmlString);
  printWindow.document.close();
  setTimeout(() => {
    printWindow.focus();
    printWindow.print();
  }, 400);
}

/**
 * Print a single group of ledger data.
 */
function printLedgerGroup(items, perspective) {
  const htmlString = buildSingleLedgerPrintHTML(items, perspective);
  printDocument(htmlString);
}

/**
 * Build CSV rows for a single group. 
 * We'll have columns: Dr Date, Dr Particulars, Dr Amount, Cr Date, Cr Particulars, Cr Amount.
 * We also strip out HTML tags from the Particulars.
 */
function buildLedgerCsvRows(items, perspective) {
  const stripHtml = (htmlStr) => htmlStr.replace(/<[^>]+>/g, "").trim(); 
  const { drRows, crRows } = splitDrCrRows(items, perspective);
  const { paddedDr, paddedCr } = mergeLedgerRows(drRows, crRows);

  const csvLines = [];
  // Header line
  csvLines.push([
    "Dr Date",
    "Dr Particulars",
    "Dr Amount",
    "Cr Date",
    "Cr Particulars",
    "Cr Amount",
  ].join(","));

  for (let i = 0; i < paddedDr.length; i++) {
    const dr = paddedDr[i];
    const cr = paddedCr[i];
    csvLines.push([
      dr.date || "",
      stripHtml(dr.particulars || ""),
      dr.amount || "",
      cr.date || "",
      stripHtml(cr.particulars || ""),
      cr.amount || "",
    ].join(","));
  }

  return csvLines.join("\n");
}

/**
 * Download CSV for a single group.
 */
function downloadLedgerCSV(items, perspective) {
  const csvString = buildLedgerCsvRows(items, perspective);
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "ledger.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Build a big HTML string for printing the full ledger:
 *  - WHOS'IN (all data, perspective=WHOSIN)
 *  - Venue groups (perspective=VENUE)
 *  - Offer groups (perspective=OFFER)
 *  - User groups (perspective=USER)
 */
function buildFullLedgerPrintHTML(allData) {
  // Pre-group them
  const venues = groupBy(allData, "venueId");
  const offers = groupBy(allData, "specialOfferId");
  const users = groupBy(allData, "userId");

  const inlineCSS = `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
    body { font-family: 'Inter', sans-serif; margin: 20px; background: #f2f2f2; }
    .account-statement { width: 85%; margin: 0 auto; }
    h2 { font-size: 1rem; color: #333; margin-top: 20px; }
    .table-header { display: flex; font-weight: 600; background: #f9f9f9; padding: 0.75rem; }
    .th { flex: 1; font-size: 0.8rem; text-transform: uppercase; }
    .table-row { display: flex; align-items: center; border-bottom: 1px solid #eee; padding: 0.75rem; }
    .td { flex: 1; font-size: 0.8rem; }
    .dr-date, .cr-date { width: 80px; }
    .dr-amount, .cr-amount { width: 70px; text-align: right; }
  `;
  let html = `<html><head><meta charset="utf-8"/><title>Full Ledger</title><style>${inlineCSS}</style></head><body>`;
  html += `<div class="account-statement">`;

  // WHOSIN
  if (allData.length > 0) {
    html += `<h2>WHOS'IN</h2>`;
    html += buildLedgerPrintTableHTML(allData, "WHOSIN");
  }
  // Venues
  for (const [id, items] of Object.entries(venues)) {
    const name = items[0]?.venueName || "Unknown Venue";
    html += `<h2>Venue: ${name}</h2>`;
    html += buildLedgerPrintTableHTML(items, "VENUE");
  }
  // Offers
  for (const [id, items] of Object.entries(offers)) {
    const name = items[0]?.offerTitle || "Unknown Offer";
    html += `<h2>Offer: ${name}</h2>`;
    html += buildLedgerPrintTableHTML(items, "OFFER");
  }
  // Users
  for (const [id, items] of Object.entries(users)) {
    const name = items[0]?.userName || "Unknown User";
    html += `<h2>User: ${name}</h2>`;
    html += buildLedgerPrintTableHTML(items, "USER");
  }

  html += `</div></body></html>`;
  return html;
}

/**
 * Print the entire ledger.
 */
function printFullLedger(allData) {
  const htmlString = buildFullLedgerPrintHTML(allData);
  printDocument(htmlString);
}

/**
 * Build a big CSV string for the entire ledger, grouped by the same categories.
 */
function buildFullLedgerCSV(allData) {
  let csv = "";

  // Group them
  const venues = groupBy(allData, "venueId");
  const offers = groupBy(allData, "specialOfferId");
  const users = groupBy(allData, "userId");

  // WHOSIN
  if (allData.length > 0) {
    csv += "WHOS'IN\n";
    csv += buildLedgerCsvRows(allData, "WHOSIN") + "\n\n";
  }
  // Venues
  for (const [id, items] of Object.entries(venues)) {
    const name = items[0]?.venueName || "Unknown Venue";
    csv += `Venue: ${name}\n`;
    csv += buildLedgerCsvRows(items, "VENUE") + "\n\n";
  }
  // Offers
  for (const [id, items] of Object.entries(offers)) {
    const name = items[0]?.offerTitle || "Unknown Offer";
    csv += `Offer: ${name}\n`;
    csv += buildLedgerCsvRows(items, "OFFER") + "\n\n";
  }
  // Users
  for (const [id, items] of Object.entries(users)) {
    const name = items[0]?.userName || "Unknown User";
    csv += `User: ${name}\n`;
    csv += buildLedgerCsvRows(items, "USER") + "\n\n";
  }

  return csv.trim();
}

/**
 * Download the entire ledger as CSV.
 */
function downloadFullLedgerCSV(allData) {
  const csvString = buildFullLedgerCSV(allData);
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "ledger-full.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// --------------------
// 4) MAIN COMPONENT
// --------------------
const SpecialOfferSalesLedger = () => {
  const location = useLocation();
  const [allData, setAllData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    fetchLedgerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchLedgerData() {
    const payload = {
      limit: 10000,
      page: 1,
      sortField: "createdAt",
      sortOrder: "asc",
    };

    if (location.state) {
      const { startDate, endDate, paymentStatus } = location.state;
      payload.filterArgs = [
        { paymentStatus, type: "eq" },
        { startDate, type: "date-range" },
        { endDate, type: "date-range" },
      ];
    }

    try {
      const response = await postRequest("subscription/order/list/admin", payload);
      const data = response.data || {};
      let list = data.list || [];
      // Sort ascending by date
      list.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      // Transform the API data
      list = transformApiData(list);
      setAllData(list);
    } catch (err) {
      console.error("Failed to fetch ledger data:", err);
    }
  }

  const toggleRow = (key) => {
    if (expandedRows.includes(key)) {
      setExpandedRows(expandedRows.filter((k) => k !== key));
    } else {
      setExpandedRows([...expandedRows, key]);
    }
  };

  // Helpers to form group arrays
  const venueGroups = Object.entries(groupBy(allData, "venueId")).map(([id, items]) => ({
    id,
    items,
    name: items[0]?.venueName || "Unknown Venue",
  }));
  const offerGroups = Object.entries(groupBy(allData, "specialOfferId")).map(([id, items]) => ({
    id,
    items,
    name: items[0]?.offerTitle || "Unknown Offer",
  }));
  const userGroups = Object.entries(groupBy(allData, "userId")).map(([id, items]) => ({
    id,
    items,
    name: items[0]?.userName || "Unknown User",
  }));

  // Renders the 6-column ledger inside a collapsible card
  const renderGroupCard = (cardId, cardTitle, items, perspective) => {
    const isOpen = expandedRows.includes(cardId);
    const { totalDr, totalCr } = getTotalsForGroup(items, perspective);

    // Single-line DR or CR total, depending on perspective
    const drDisplay = Math.abs(totalDr).toFixed(2);
    const crDisplay = Math.abs(totalCr).toFixed(2);

    return (
      <div className="statement-item" key={cardId}>
        <div className="top-row">
          <button className="expand-btn" onClick={() => toggleRow(cardId)}>
            {isOpen ? "–" : "+"}
          </button>
          <div className="left-content">
            <div className="title">{cardTitle}</div>
          </div>
          <div className="middle-content" />
          <div className="right-content">
            {perspective === "USER" ? (
              <span style={{ color: "red", fontSize: "1.1rem" }}>{crDisplay}</span>
            ) : (
              <span style={{ color: "green", fontSize: "1.1rem" }}>{drDisplay}</span>
            )}
          </div>
        </div>

        {/* Group-level (Print / CSV) actions */}
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", margin: "10px 0" }}>
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => printLedgerGroup(items, perspective)}
            title="Print Group"
          >
            <i className="fas fa-print" style={{ fontSize: "1rem", color: "#d30b7d" }}></i>
          </button>
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => downloadLedgerCSV(items, perspective)}
            title="Download CSV"
          >
            <i className="fas fa-file-csv" style={{ fontSize: "1rem", color: "#d30b7d" }}></i>
          </button>
        </div>

        {isOpen && (
          <div className="detail-section">
            <div className="detail-card">
              {items.length === 0 ? (
                <div style={{ padding: "1rem" }}>No transactions found.</div>
              ) : (
                renderLedgerTable(items, perspective)
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <ToastContainer />
      <Row>
        <Col className="col-12">
          <div className="page-title-box">
            <h4>Ledger Data</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Account Logs</li>
              <li className="breadcrumb-item">Ledger</li>
            </ol>
          </div>
        </Col>

        {/* Top-level toolbar for printing/downloading the entire ledger */}
        <Col className="col-12">
          <div
            className="card text-center"
            style={{ backgroundColor: "#d30b7d", border: "none", marginBottom: "20px" }}
          >
            <div
              className="card-body"
              style={{ display: "flex", justifyContent: "center", gap: "15px" }}
            >
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => printFullLedger(allData)}
                title="Print Full Ledger"
              >
                <i className="fas fa-print" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => downloadFullLedgerCSV(allData)}
                title="Download Full Ledger CSV"
              >
                <i className="fas fa-file-csv" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
            </div>
          </div>
        </Col>

        <Col className="col-12">
          <div className="account-statement">
            {/* WHOSIN => all data, perspective=WHOSIN => Dr side */}
            {allData.length > 0 &&
              renderGroupCard("WHOSIN", "WHOS'IN", allData, "WHOSIN")}

            {/* Venues => perspective=VENUE => Dr side */}
            {venueGroups.map((venue) =>
              renderGroupCard(`VENUE-${venue.id}`, `Venue: ${venue.name}`, venue.items, "VENUE")
            )}

            {/* Offers => perspective=OFFER => Dr side */}
            {offerGroups.map((offer) =>
              renderGroupCard(`OFFER-${offer.id}`, `Offer: ${offer.name}`, offer.items, "OFFER")
            )}

            {/* Users => perspective=USER => Cr side */}
            {userGroups.map((user) =>
              renderGroupCard(`USER-${user.id}`, `User: ${user.name}`, user.items, "USER")
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SpecialOfferSalesLedger;
