import React, { useState } from "react";
import {
    postRequest
   
  } from "../../components/Common/Utils.js";
import "./tab.css";
import { te } from "date-fns/locale";

// Your existing API function


const TabButtonComponent = ({businessManagment, fetchFilter, setactiveStatus}) => {
  const tabs = ["All", "Completed", "Initiated", "Failed"];
  const [activeTab, setActiveTab] = useState("All");

  const handleTabClick = async (tab) => {
    setActiveTab(tab);

    

    // Call the businessManagment function with appropriate filter arguments based on the selected tab
    switch (tab) {
      case "All":
        await businessManagment([...fetchFilter()]);
        setactiveStatus("");
        break;
      case "Completed":
        await businessManagment( [...fetchFilter(), { status: "succeeded", type: "eq"  }]);
        setactiveStatus("succeeded");
        break;
      case "Initiated":
        await businessManagment([...fetchFilter(), { status: "initiated", type: "eq"   }]);
        setactiveStatus("initiated");
        break;
      case "Failed":
        await businessManagment([...fetchFilter(), { status: "failed", type: "eq"   }]);
        setactiveStatus("failed");
        break;
      default:
        await businessManagment([...fetchFilter()]);
        setactiveStatus("");
    }
  };

  return (
    <div className="tab-button-container">
      {tabs.map((tab) => (
        <button
          key={tab}
          className={`tab-button ${activeTab === tab ? "active" : ""}`}
          onClick={() => handleTabClick(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default TabButtonComponent;
