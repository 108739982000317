import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import MultiSelect from "components/Multiselect/MultiselectCommon.js";

import {
  postRequest,
  putRequest,
  postRequestForm,
} from "../../components/Common/Utils.js";
import ActivityProviderModal from "components/Modal/ActivityProviderModal.js";
import ActivityTypeModal from "components/Modal/ActivityTypeModal.js";
import OrganizerlistModal from "components/Modal/OrganizerlistModal.js";
import IconPackModal from "components/Modal/IconPackModal.js";

import FileDrop from "components/FileDrop.js";
import { icon } from "leaflet";
// import { use } from "chai";

export default function UpdateActivity() {
  const location = useLocation();
  const state = location.state?.row;
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [listFetched, setListFetched] = useState(false);
  const [packages, setPackages] = useState([]);

  const [typeList, setTypeList] = useState([]);
  const [typeId, setTypeId] = useState("");
  const [typeText, setTypeText] = useState("");

  const [minDate, setMinDate] = useState();

  const [eventOrganizerId, setEventOrganizerId] = useState("");
  const [eventOrganizerText, setEventOrganizerText] = useState("");

  const [orgList, setOrgList] = useState([]);
  const [orgId, setOrgId] = useState("");
  const [orgText, setOrgText] = useState("");

  const [providerId, setProviderId] = useState("");
  const [providerText, setProviderText] = useState("");
  const [providerImage, setProviderImage] = useState("");
  const [offerList, setOfferList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState("");

  const [model, setModel] = useState(false);
  const [providerModel, setProviderModel] = useState(false);
  const [orgModal, setOrgModal] = useState(false);
  const [iconModal, setIconModal] = useState(false);

  const [disableType, setDisableType] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [daysSelected, setDaysSelected] = useState([]);
  const [dayList, setDayList] = useState([]);

  const [uploadedImages, setUploadedImages] = useState([]);

  const [availableTime, setAvailableTime] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);

  const [iconList, setIconList] = useState([]);

  const [regularTime, setRegularTime] = useState({
    start: state?.activityTime?.start,
    end: state?.activityTime?.end,
  });

  const [defaultDays, setDefaultDays] = useState([]);

  const [days, setDays] = useState([
    {
      name: "Mon",
      id: "Mon",
    },
    {
      name: "Tue",
      id: "Tue",
    },
    {
      name: "Wed",
      id: "Wed",
    },
    {
      name: "Thu",
      id: "Thu",
    },
    {
      name: "Fri",
      id: "Fri",
    },
    {
      name: "Sat",
      id: "Sat",
    },
    {
      name: "Sun",
      id: "Sun",
    },
  ]);

  const calculateAvilableDaysType = (days) => {
    const weekends = ["Sat", "Sun"];
    const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri"];
    const daily = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    if (days.every((day) => daily.includes(day))) {
      return "daily";
    } else if (days.every((day) => weekdays.includes(day))) {
      return "weekdays";
    } else if (days.every((day) => weekends.includes(day))) {
      return "weekends";
    } else {
      return "selectedDays";
    }
  };

  const selectedDays = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setDaysSelected(ids);
  };

  const handleFileUpload = (url) => {
    setUploadedImages((prevState) => [...prevState, url]);
  };

  const { id } = useParams();
  useEffect(() => {
    if (!state) return;

    // 2023-06-27T00:00:00.000+00:00 to 2023-06-27

    setMinDate(new Date().toISOString().split("T")[0]);

    if(!state.avilableDaysType){
      state.avilableDaysType = calculateAvilableDaysType(state.avilableDays);
    }



    //state.avilableDaysType = calculateAvilableDaysType(state.avilableDays);   


    if(state.avilableDaysType == 'selectedDays'){
      setDaysSelected(state.avilableDays);
    }

    state.startDate = state.startDate.split("T")[0];
    state.endDate = state.endDate.split("T")[0];

    if(state.reservationStart)  state.reservationStart = state.reservationStart?.split("T")[0];
    if(state.reservationEnd) state.reservationEnd = state.reservationEnd?.split("T")[0];


    
    

    if (state?.activityTime?.type == "regular") {
      state.start_time = state?.activityTime.start;
      state.end_time = state?.activityTime.end;      
      setAvailableTime("regular");
    } else {
      setAvailableTime("slot");

      const timeSlots = state?.activityTime?.slot?.map((item) => {
        const time = item?.time?.split("-");
        return {
          start: time[0],
          end: time[1],
          totalSeats: item?.totalSeats,
        };
      });

      setTimeSlots([...timeSlots]);
    }

    if (state?.avilableFeatures?.length) {
      const features = state?.avilableFeatures?.map((item) => {
        return {
          icon: item.icon,
          feature: item.feature,
          saved: true,
        };
      });
      setPackages([...features]);
    }

    if (state?.galleries?.length) {
      setUploadedImages([...state?.galleries]);
    }

    if (state?.avilableDays?.length) {
      const options = state?.avilableDays?.map((item) => {
        return { id: item, name: item };
      });

      setDefaultDays([...options]);
    }

    setEventData(state);
    setTypeId(state.type_id);
    setProviderId(state.provider_id);
    setEventOrganizerId(state.organizer_id);
    setProviderText(state.providerName);
    setProviderImage(state.provider?.logo);
    setProviderId(state.providerId);
    setTypeText(state.typeName);
    setTypeId(state.typeId);
  }, [state]);

  useEffect(() => {
    if (!iconList.length) return;
    const ePackages = packages.map((item) => {
      return {
        icon: item.icon,
        iconName: fetchIconName(item.icon),
        feature: item.feature,
        saved: item.saved,
      };
    });
    setPackages(ePackages);
  }, [iconList]);

  const fetchIconName = (id) => {
    const icon = iconList.find((item) => item.image == id);
    return icon?.name;
  };

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (eventData?.user_type == "user") {
      // set type into private & disable type dropdown
      const data = { ...eventData };
      data.type = "private";
      setEventData(data);
      setDisableType(true);
    } else {
      setDisableType(false);
    }
  }, [eventData?.user_type]);

  let history = useHistory();

  const updateActivityData = async (e) => {

    e.preventDefault();
    if (!eventData.name) {
      toast.error("Title is required");
      return;
    }

    if (!eventData.description) {
      toast.error("Please enter description");
      return;
    }

    if (!eventData.price) {
      toast.error("Please enter price");
      return;
    }

    // check price will be positive   

    if(eventData.increaseSize && eventData.increaseSize < 0){
      toast.error("Increase size should not be less than 0");
      return;
    }

    // if price < 0

    if (eventData.price < 1) {
      toast.error("Price should not be 0 & less than 0");
      return;
    }

    if (!eventData.discount) {
      toast.error("Please enter discount");
      return;
    }

    // discount 0 - 100

    if (eventData.discount < 5) {
      toast.error("Discount should not be less than 5");
      return;
    }

    if (eventData.discount > 99) {
      toast.error("Discount should not be greater than 100");
      return;
    }

    // minimum person 1

    if (eventData.minPerson < 1) {
      toast.error("Please enter minimum person not less than 1");
      return;
    }



   
    
    if (availableTime == "regular") {
      if (!eventData.totalSeats || eventData.totalSeats < 1) {
        toast.error("Please enter total seats");
        return;
      }
    }


    if (!eventData.startDate) {
      toast.error("Please enter start date");
      return;
    }

    if (!eventData.endDate) {
      toast.error("Please enter end date");
      return;
    }

    // check start date < end date

    if (eventData.startDate >= eventData.endDate) {
      toast.error("Start date should be less than end date");
      return;
    }

    // check reservation start date
    
    // if(eventData.reservationStart && eventData.reservationStart < eventData.startDate){
    //   toast.error("Reservation start date should be greater than start date");
    //   return;
    // }

    // // check reservation end date
    // if(eventData.reservationEnd && eventData.reservationEnd > eventData.endDate){
    //   toast.error("Reservation end date should be less than end date");
    //   return;
    // }

    



    // check reservation start date < reservation end date

    if (eventData.reservationStart >= eventData.reservationEnd) {
      toast.error("Reservation start date should be less than reservation end date");
      return;
    }

    // check atleast 2 features selected

    if (packages?.length < 2) {
      toast.error("Please select atleast 2 features");
      return;
    }

    // add atleas 1 gallery image

    if (!uploadedImages?.length) {
      toast.error("Please add atleast 1 gallery image");
      return;
    }

    // check provider selected

    if (!providerId) {
      toast.error("Please select provider");
      return;
    }

    // chech type selected

    if (!typeId) {
      toast.error("Please select type");
      return;
    }

    // check selected days type

    if (!eventData?.avilableDaysType) {
      toast.error("Please select available days type");
      return;
    }

    let finalDays = [];

    if (eventData?.avilableDaysType == "selectedDays") {
      if (!daysSelected?.length) {
        toast.error("Please select available days");
        return;
      }
      

      if(typeof daysSelected[0] == 'string'){
        finalDays = daysSelected;
      }else{
        finalDays = daysSelected.map((item) => item.id);
      }     


    } else if (eventData?.avilableDaysType == "daily") {
      finalDays = days.map((item) => item.id);
    } else if (eventData?.avilableDaysType == "weekdays") {
      finalDays = ["Mon", "Tue", "Wed", "Thu", "Fri"];
    } else if (eventData?.avilableDaysType == "weekends") {
      finalDays = ["Sat", "Sun"];
    }

    // check available time

    if (!availableTime) {
      toast.error("Please select available time");
      return;
    }





    const y = new FormData();
    y.append("image", uploadedImages);
    const selectedCoverRes = await postRequestForm("comman/img/upload", y);

    let activityTime = {
      type: availableTime,
      start: "",
      end: "",
      slot: [],
    };

    if (availableTime == "regular") {
      if (!regularTime?.start || !regularTime?.end) {
        toast.error("Please select regular time");
        return;
      }

      activityTime.start = regularTime?.start;
      activityTime.end = regularTime?.end;
    } else {
      if (!timeSlots?.length) {
        toast.error("Please select time slots");
        return;
      }

      // concate start & end to 16:00-17:00

      const slots = timeSlots.map((item) => {
        return {
          time: item.start + "-" + item.end,
          totalSeats: item.totalSeats,
        };
      });

      activityTime.slot = slots;
    }



    eventData.activityId = eventData._id;
    eventData.avilableDays = finalDays;
    eventData.providerId = providerId;
    eventData.typeId = typeId;
    eventData.galleries = selectedCoverRes?.data?.url || [];
    eventData.features = packages;
    eventData.activityTime = activityTime;
    eventData.status = true;
    eventData.avilableDaysType; 
    
    eventData.reservationStart = eventData.reservationStart ? eventData.reservationStart : eventData.startDate;
    eventData.reservationEnd = eventData.reservationEnd ? eventData.reservationEnd : eventData.endDate;

    setLoading(true);

    const response = await putRequest("activity/update", eventData);
    setLoading(false);
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/activityManagement");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...eventData };
    list[index] = value;
    setEventData(list);
  };

  const updateDiscount = (index, value) => {
    const list = [...packages];
    list[index].discount = value;
    setPackages(list);
  };

  const fetchProviders = async () => {
    const param = { page: 1, limit: 100000 };
    const user = await postRequest("activity/provider/list", param);
    if (user.status == 1) {
      const options = user.data.list.map((item) => {
        return {
          id: item._id,
          phone: item.phone,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
        };
      });
      setProviderList(options);
    }
  };

  const fetchTypeList = async () => {
    setLoading(true);
    const response = await postRequest("activity/type/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setTypeList(response.data.list);
    }
    setLoading(false);
  };

  const fetchOfferList = async () => {
    setLoading(true);
    const response = await postRequest("venue/offer/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setOfferList(response.data.list);
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setCategoryList(response.data.list);
    }
    setLoading(false);
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const toggleProviderModel = () => {
    setProviderModel(!providerModel);
  };

  const toggleOrgModel = () => {
    setOrgModal(!orgModal);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closeProviderModal = () => {
    setProviderModel(false);
  };

  const closeOrgModal = () => {
    setOrgModal(false);
  };

  const closeIconModal = () => {
    setIconModal(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };

  const selectIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const selectRow = (id, name) => {
    setTypeId(id);
    setTypeText(name);
    closeModal();
  };

  const selectProviderRow = (id, name, provider) => {
    setProviderId(id);
    setProviderText(name);
    setProviderImage(provider?.logo);
    closeProviderModal();
  };

  const selectOrgRow = (id, name) => {
    setEventOrganizerId(id);
    setEventOrganizerText(name);
    closeOrgModal();
  };

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const selectOfferRow = (id, name) => {
    setOfferId(id);
    setOfferText(name);
    closeOfferModal();
  };

  const toggleCategoryModel = () => {
    setCategoryModel(!categoryModel);
  };

  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };

  const addRow = () => {
    setPackages([...packages, { icon: "", feature: "", iconName: "" }]);
  };

  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };

  const addSlot = () => {
    setTimeSlots([...timeSlots, { start: "", end: "", totalSeats: 0 }]);
  };

  const updateSlot = (index, type, value) => {
    const updatedSlots = [...timeSlots];
    updatedSlots[index][type] = value;
    setTimeSlots(updatedSlots);
  };

  const deleteSlot = (index) => {
    const updatedSlots = [...timeSlots];
    updatedSlots.splice(index, 1);
    setTimeSlots(updatedSlots);
  };

  const updateRegulaTime = (type, value) => {
    const updatedSlots = { ...regularTime };
    updatedSlots[type] = value;
    setRegularTime(updatedSlots);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Activity Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Activity Management</li>:{" "}
              <li className="breadcrumb-item">
                <Link to="#">Update Activity</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}

      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Update Activity </h4>
                <Form onSubmit={updateActivityData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              value={eventData?.name}
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              defaultValue={eventData?.description}
                              name="description"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Disclaimer Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={eventData?.disclaimerTitle}
                              onChange={(e) =>
                                updateValue("disclaimerTitle", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Disclaimer Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("disclaimerDescription", e.target.value)
                              }
                              value={eventData?.disclaimerDescription}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Activity Provider
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleProviderModel()}
                          >
                            {providerText ? (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       {providerText}
                              //     </a>
                              //   </div>
                              //   <div>
                              //     <a
                              //       onClick={() => toggleProviderModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseActivityBtn"
                                  onclick={() => toggleProviderModel()}
                                >
                                  Choose Activity Provider
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={providerImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={providerText}
                                  id="selectedActivity"
                                  placeholder="No Activity Provider chosen"
                                />
                              </div>
                            ) : (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       Select Provider
                              //     </a>
                              //   </div>
                              //   <div>
                              //     <a
                              //       onClick={() => toggleProviderModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseActivityBtn"
                                  onclick={() => toggleProviderModel()}
                                >
                                  Choose Activity Provider
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedActivity"
                                  placeholder="No Activity Provider chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Activity Type
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {typeText ? (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       {typeText}
                              //     </a>
                              //   </div>
                              //   <div>
                              //     <a
                              //       onClick={() => toggleModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseActivityTypeBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Activity Type
                                </button>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={typeText}
                                  id="selectedActivityType"
                                  placeholder="No Activity Type chosen"
                                />
                              </div>
                            ) : (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       Select Activity Type
                              //     </a>
                              //   </div>
                              //   <div>
                              //     <a
                              //       onClick={() => toggleModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseActivityTypeBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Activity Type
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedActivityType"
                                  placeholder="No Activity Type chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("price", e.target.value)
                              }
                              defaultValue={eventData?.price}
                              name="price"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Discount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("discount", e.target.value)
                              }
                              defaultValue={eventData?.discount}
                              name="discount"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Minimum Person
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("minPerson", e.target.value)
                              }
                              defaultValue={eventData?.minPerson}
                              name="minPerson"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Increase Size
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("increaseSize", e.target.value)
                              }
                              defaultValue={eventData?.increaseSize}
                              name="increaseSize"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Start Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"                              
                              onChange={(e) => {
                                const selectedDate = e.target.value;
                                updateValue("startDate", selectedDate);                                 
                              }}
                              defaultValue={eventData?.startDate}
                              name="startDate"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            End Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"                             
                              onChange={(e) =>
                                updateValue("endDate", e.target.value)
                              }
                              defaultValue={eventData?.endDate}                             
                              name="endDate"
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Start Date (Reservation)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) => {
                                const selectedDate = e.target.value;
                                if (selectedDate >= today) {
                                  updateValue("reservationStart", selectedDate);
                          
                                  // Create a new Date object from the selected date
                                  const nextDay = new Date(selectedDate);
                                  nextDay.setDate(nextDay.getDate() + 1);
                          
                                  // Format the date back to the input field format
                                  const formattedNextDay = nextDay.toISOString().split("T")[0];
                          
                                  // Update the minimum date for the End Date input
                                  const endDateInput = document.querySelector('[name="reservationEnd"]');
                                  if (endDateInput) {
                                      endDateInput.min = formattedNextDay;
                                  }
                              }
                              }}                              
                              value={eventData?.reservationStart}
                              name="startDate"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                            
                          >
                            End Date (Reservation)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"                             
                              onChange={(e) => {
                                const selectedDate = e.target.value;
                                if (selectedDate >= tomorrow) {
                                  updateValue("reservationEnd", selectedDate);
                                }
                              }}
                              value={eventData?.reservationEnd}
                              name="endDate"
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Avilable Days
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-select"
                              onChange={(e) =>
                                updateValue("avilableDaysType", e.target.value)
                              }
                              value={eventData?.avilableDaysType}
                            >
                              <option value="">Select Day Type</option>
                              <option value="daily">Daily</option>
                              <option value="weekdays">Weekdays</option>
                              <option value="weekends">Weekends</option>
                              <option value="selectedDays">
                                Selected Days
                              </option>
                            </select>
                          </div>
                        </div>

                        {eventData?.avilableDaysType === "selectedDays" && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Select Days
                            </label>
                            <div className="col-sm-6">
                              <MultiSelect
                                options={days}
                                onSelect={selectedDays}
                                onRemove={selectedDays}
                                // selectedValues={daysSelected?.length > 0 ? daysSelected : []}
                                selectedValues={defaultDays}
                                placeholder={"Select Days"}
                              />
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Avilable Time
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-select"
                              onChange={(e) => setAvailableTime(e.target.value)}
                              value={availableTime}
                            >
                              <option value="">Select Time</option>
                              <option value="regular">Regular</option>
                              <option value="slot">Multiple Slot</option>
                            </select>
                          </div>
                        </div>

                        {availableTime == "regular" && (
                          <>
                            {" "}
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Quantity of Seats
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    updateValue("totalSeats", e.target.value)
                                  }
                                  defaultValue={eventData?.totalSeats}
                                  name="discount"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Start Time / End Time
                              </label>
                              <div className="col-sm-3">
                                <label>Start Time:</label>
                                <input
                                  type="time"
                                  value={state?.activityTime?.start}
                                  onChange={(e) =>
                                    updateRegulaTime("start", e.target.value)
                                  }
                                  className="form-control"
                                />
                              </div>
                              <div className="col-sm-3">
                                <label>End Time:</label>
                                <input
                                  type="time"
                                  value={state?.activityTime?.end}
                                  onChange={(e) =>
                                    updateRegulaTime("end", e.target.value)
                                  }
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {availableTime == "slot" && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Select Slots
                            </label>
                            <div className="col-sm-6">
                              {timeSlots.map((slot, index) => (
                                <div className="col-sm-12" key={index}>
                                  <div
                                    class="row"
                                    style={{ marginBottom: "16px" }}
                                  >
                                    <div className="col-sm-3">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={slot.start}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "start",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-3">
                                      <input
                                        type="time"
                                        className="form-control"
                                        value={slot.end}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "end",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={slot.totalSeats}
                                        onChange={(e) =>
                                          updateSlot(
                                            index,
                                            "totalSeats",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="col-sm-3">
                                      <button
                                        type="button"
                                        style={{
                                          color: "white",
                                          backgroundColor: "red",
                                          border: "none",
                                          padding: "10px 20px",
                                        }}
                                        onClick={() => deleteSlot(index)}
                                      >
                                        Delete Slot
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}

                              <button
                                type="button"
                                style={{
                                  color: "white",
                                  backgroundColor: "green",
                                  border: "none",
                                  padding: "10px 20px",
                                }}
                                onClick={addSlot}
                              >
                                Add Slot
                              </button>
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Feature List
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Select Feature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.icon && (
                                            <img
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              src={item.icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.icon && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.feature}`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "feature",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Feature
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Upload Gallery Image
                          </label>
                          <div className="col-sm-6">
                            <FileDrop
                              files={state?.galleries}
                              accept="image/*"
                              onFileUpload={handleFileUpload}
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <ActivityTypeModal
        show={model}
        closeModal={closeModal}
        selectRow={selectRow}
      />
      <ActivityProviderModal
        show={providerModel}
        closeModal={closeProviderModal}
        selectRow={selectProviderRow}
      />
      <OrganizerlistModal
        show={orgModal}
        closeModal={closeOrgModal}
        selectRow={selectOrgRow}
      />
      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
      />
    </React.Fragment>
  );
}
