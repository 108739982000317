import React, { useState, useEffect } from "react";
import { Row, Col, Button, Badge } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/src/sweetalert2.scss";
import "./SpecialOfferSalesManagementlist.scss";

import { postRequest } from "../../components/Common/Utils.js";
import { useLocation } from "react-router-dom";

// --- Placeholders (for UI only) ---
const USER_IMAGE_PLACEHOLDER = "https://via.placeholder.com/80x80?text=User+Image";
const VENUE_IMAGE_PLACEHOLDER = "https://via.placeholder.com/100x60?text=Venue+Image";
const OFFER_IMAGE_PLACEHOLDER = "https://via.placeholder.com/100x60?text=Offer+Image";

// Rayna (static) info for “ticket” type
const RAYNA_LOGO =
  "https://d1i3enf1i5tb1f.cloudfront.net/assets/Images/AGT-06437/raynatourslogo.png";
const RAYNA_NAME = "Rayna Tours";
const RAYNA_WEBSITE = "https://www.raynatours.com/";

// --------------------
// HELPER FUNCTIONS
// --------------------
function getTotalAmount(row) {
  return (row.price || 0) * (row.qty || 0);
}

const getVoucherCount = (row) => {
  if (row.totalPerson && row.totalPerson > 1) {
    return `X ${row.totalPerson}`;
  }
  return null;
};

const calculateTotals = (row) => {
  const subtotal = getTotalAmount(row);
  const vat = 0;
  const discount = 0;
  const finalPrice = subtotal;
  return { subtotal, vat, discount, finalPrice };
};

const getTopRowTitle = (row) => {
  if (row.type === "ticket") return "Ticket Purchase";
  if (row.type === "offer") return row.offer?.title || "N/A";
  if (row.type === "deal") return row.deal?.title || "N/A";
  return "N/A";
};

const getTopRowAmount = (row) => {
  const total = getTotalAmount(row);
  return total.toFixed(2);
};

/**
 * Enhanced "ticket" UI, showing additional details from row.ticket if available.
 */
const renderTicketUI = (row) => {
  if (row.type === "ticket") {
    const ticket = row.ticket || {};
    const TourDetails = ticket.TourDetails?.[0] || {};
    const Passenger = ticket.passengers?.[0] || {};

    return (
      <div>
        <h5 className="ticket-title">Ticket Purchase</h5>
        <p className="ticket-offer">Ticket ID: {row.ticketId || "N/A"}</p>

        <p className="ticket-desc">
          <strong>Tour Name:</strong>{" "}
          {TourDetails.tour?.tourName || "N/A"}
        </p>
        <p className="ticket-desc">
          <strong>Option Name:</strong>{" "}
          {TourDetails.tourOption?.optionName || "N/A"}
        </p>
        <p className="ticket-desc">
          <strong>Tour Date / Time:</strong>{" "}
          {TourDetails.tourDate || "N/A"}{" "}
          {TourDetails.startTime || ""}
        </p>
        <p className="ticket-desc">
          <strong>PAX:</strong>{" "}
          Adult({TourDetails.adult || 0}) / Child({TourDetails.child || 0}) / Infant({TourDetails.infant || 0})
        </p>
        <p className="ticket-desc">
          <strong>Passenger:</strong>{" "}
          {Passenger.prefix || ""} {Passenger.firstName || ""} {Passenger.lastName || ""}
        </p>
      </div>
    );
  }

  // If it's an offer or deal, keep the same logic
  if (row.type === "offer") {
    return (
      <>
        <h5 className="ticket-title">Offer</h5>
        <p className="ticket-offer">Offer: {row.offer?.title || "No Title"}</p>
        {row.offer?.packages && row.offer.packages.length > 0 && (
          <p className="ticket-desc">
            Package:{" "}
            {row.offer.packages.find((pkg) => pkg._id === row.packageId)
              ? row.offer.packages.find((pkg) => pkg._id === row.packageId).title
              : row.offer.packages[0].title}{" "}
            - AED{" "}
            {row.offer.packages.find((pkg) => pkg._id === row.packageId)
              ? row.offer.packages.find((pkg) => pkg._id === row.packageId).amount
              : row.offer.packages[0].amount}
          </p>
        )}
        <p className="ticket-desc">
          {row.offer?.description || "No description provided"}
        </p>
      </>
    );
  }
  if (row.type === "deal") {
    return (
      <>
        <h5 className="ticket-title">Deal</h5>
        <p className="ticket-offer">{row.deal?.title || "No Title"}</p>
        <p className="ticket-desc">
          {row.deal?.description || "No description provided"}
        </p>
      </>
    );
  }
  return <h5 className="ticket-title">Unknown Type</h5>;
};

/**
 * Invoice table under the user/venue info
 * We can keep the same basic structure; if it's ticket, we do a simple table with qty * price,
 * but the *detailed* "tour" info is shown above in renderTicketUI.
 */
const renderInvoiceBody = (row) => {
  if (row.type === "ticket") {
    return (
      <>
        <h6>Ticket Items (qty × price)</h6>
        <table className="invoice-table">
          <thead>
            <tr>
              <th>Ticket ID</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Calculated</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{row.ticketId || "N/A"}</td>
              <td>{row.qty}</td>
              <td>{row.price || 0}</td>
              <td>{(row.qty || 0) * (row.price || 0)}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  } else if (row.type === "offer") {
    return (
      <>
        <h6>Offer Items (qty × price)</h6>
        <table className="invoice-table">
          <thead>
            <tr>
              <th>Offer Title</th>
              <th>Package</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Calculated</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{row.offer?.title || "N/A"}</td>
              <td>
                {row.offer?.packages && row.offer.packages.length > 0
                  ? row.offer.packages.find((pkg) => pkg._id === row.packageId)
                    ? row.offer.packages.find((pkg) => pkg._id === row.packageId).title
                    : row.offer.packages[0].title
                  : "N/A"}
              </td>
              <td>{row.qty}</td>
              <td>{row.price || 0}</td>
              <td>{(row.qty || 0) * (row.price || 0)}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  } else if (row.type === "deal") {
    return (
      <>
        <h6>Deal Items (qty × price)</h6>
        <table className="invoice-table">
          <thead>
            <tr>
              <th>Deal Title</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Calculated</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{row.deal?.title || "N/A"}</td>
              <td>{row.qty}</td>
              <td>{row.price || 0}</td>
              <td>{(row.qty || 0) * (row.price || 0)}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  } else {
    return <p>No item details available for this type.</p>;
  }
};

//
// (A) PDF PRINTING LOGIC
// ---------------------------------------------------
function buildAllItemsHTML(offerList) {
  const inlineCSS = `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
    body {
      font-family: 'Inter', sans-serif;
      margin: 20px;
      background: #f2f2f2;
    }
    .account-statement {
      width: 80%;
      margin: 0 auto;
    }
    .statement-item {
      background-color: #fff;
      border-radius: 8px;
      padding: 15px;
      min-height: 980px;
      margin-bottom: 20px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    }
    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .left-section {
      display: flex;
      flex-direction: column;
    }
    .date-time {
      font-size: 0.875rem;
      color: #777;
      margin-bottom: 0.25rem;
    }
    .title {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0.25rem;
      color: #333;
    }
    .payment-status-badge {
      font-size: 0.8rem;
      text-transform: capitalize;
      padding: 0.4em 0.6em;
      border-radius: 0.25rem;
    }
    .paid {
      background-color: #28a745 !important;
      color: #fff !important;
    }
    .unpaid {
      background-color: #dc3545 !important;
      color: #fff !important;
    }
    .free {
      background-color: #6c757d !important;
      color: #fff !important;
    }
    .amount {
      font-size: 0.95rem;
    }
    .credit {
      color: green;
    }
    .debit {
      color: red;
    }
    .detail-section {
      margin-top: 15px;
      background-color: #F0F1F2;
      border-radius: 6px;
      padding: 15px;
    }
    .detail-card {
      background-color: #fff;
      border-radius: 8px;
      padding: 15px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
    }
    .ticket-ui {
      border: 2px dashed #ccc;
      border-radius: 8px;
      padding: 1rem;
      text-align: center;
      margin-bottom: 1rem;
      position: relative;
    }
    .ticket-count {
      font-size: 1.6rem;
      font-weight: 700;
      color: #d9534f;
      margin-bottom: 0.5rem;
    }
    .ticket-title {
      font-size: 1.1rem;
      font-weight: 600;
    }
    .ticket-offer {
      font-size: 0.9rem;
      color: #555;
      margin: 0.3rem 0;
    }
    .ticket-desc {
      font-size: 0.85rem;
      color: #777;
      margin: 0.3rem 0;
    }
    .invoice-container {
      margin-bottom: 10px;
    }
    .invoice-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    .user-info {
      display: flex;
      align-items: center;
    }
    .user-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
    .offer-venue-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .venue-offer-images {
      margin-bottom: 5px;
    }
    .venue-offer-images img {
      margin-left: 5px;
      width: 80px;
      height: 50px;
      object-fit: cover;
      border-radius: 4px;
    }
    .invoice-body, .invoice-summary {
      margin-bottom: 1rem;
    }
    table.invoice-table, table.summary-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 0.5rem;
    }
    table.invoice-table th, table.summary-table th {
      text-align: left;
      border-bottom: 1px solid #ccc;
      padding-bottom: 8px;
      font-weight: 600;
      font-size: 0.9rem;
    }
    table.invoice-table td, table.summary-table td {
      border-bottom: 1px solid #eee;
      padding: 6px 0;
      font-size: 0.9rem;
    }
    .total-row td {
      font-weight: 600;
      border-bottom: 2px solid #333;
    }
  `;

  // Build HTML for each order row
  const htmlRows = offerList
    .map((row) => {
      const dateTime = new Date(row.createdAt).toLocaleString();
      const topTitle = getTopRowTitle(row);
      const totalAmount = getTotalAmount(row);
      const isFree = totalAmount === 0;
      const paymentStatusText = isFree ? "FREE" : row.paymentStatus || "N/A";

      // Payment badge class
      let paymentBadgeClass = "";
      if (isFree) paymentBadgeClass = "free";
      else if (row.paymentStatus === "paid") paymentBadgeClass = "paid";
      else paymentBadgeClass = "unpaid";

      // Sign + color
      const sign = isFree ? "" : row.paymentStatus === "paid" ? "+" : "-";
      const creditDebitClass = isFree
        ? ""
        : row.paymentStatus === "paid"
        ? "credit"
        : "debit";

      // Possibly show voucher count
      const voucherCount = row.totalPerson && row.totalPerson > 1
        ? `<div class="ticket-count">X ${row.totalPerson}</div>`
        : "";

      // Build “ticket-ui” details, including Tour info if type === "ticket"
      let ticketBox = "";
      if (row.type === "ticket") {
        const ticket = row.ticket || {};
        const TourDetails = ticket.TourDetails?.[0] || {};
        const Passenger = ticket.passengers?.[0] || {};

        ticketBox = `
          <h5 class="ticket-title">Ticket Purchase</h5>
          <p class="ticket-offer">Ticket ID: ${row.ticketId || "N/A"}</p>
          <p class="ticket-desc"><strong>Tour Name:</strong> ${TourDetails.tour?.tourName || "N/A"}</p>
          <p class="ticket-desc"><strong>Option Name:</strong> ${TourDetails.tourOption?.optionName || "N/A"}</p>
          <p class="ticket-desc"><strong>Tour Date / Time:</strong> ${TourDetails.tourDate || "N/A"} ${TourDetails.startTime || ""}</p>
          <p class="ticket-desc"><strong>PAX:</strong> Adult(${TourDetails.adult || 0}) / Child(${TourDetails.child || 0}) / Infant(${TourDetails.infant || 0})</p>
          <p class="ticket-desc"><strong>Passenger:</strong> ${(Passenger.prefix || "")} ${(Passenger.firstName || "")} ${(Passenger.lastName || "")}</p>
        `;
      } else if (row.type === "offer") {
        let pkgTitle = "N/A";
        let pkgAmount = 0;
        if (row.offer?.packages?.length) {
          const foundPkg =
            row.offer.packages.find((p) => p._id === row.packageId) ||
            row.offer.packages[0];
          pkgTitle = foundPkg.title;
          pkgAmount = foundPkg.amount;
        }
        ticketBox = `
          <h5 class="ticket-title">Offer</h5>
          <p class="ticket-offer">Offer: ${row.offer?.title || "No Title"}</p>
          <p class="ticket-desc">
            Package: ${pkgTitle} - AED ${pkgAmount}
          </p>
          <p class="ticket-desc">
            ${row.offer?.description || "No description provided"}
          </p>
        `;
      } else if (row.type === "deal") {
        ticketBox = `
          <h5 class="ticket-title">Deal</h5>
          <p class="ticket-offer">${row.deal?.title || "No Title"}</p>
          <p class="ticket-desc">${row.deal?.description || "No description provided"}</p>
        `;
      }

      // Invoice table body
      // (We'll keep it simple: one row for each type with qty, price, etc.)
      let invoiceBody = "";
      if (row.type === "ticket") {
        invoiceBody = `
          <h6>Ticket Items (qty × price)</h6>
          <table class="invoice-table">
            <thead>
              <tr>
                <th>Ticket ID</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Calculated</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${row.ticketId || "N/A"}</td>
                <td>${row.qty || 0}</td>
                <td>${row.price || 0}</td>
                <td>${(row.qty || 0) * (row.price || 0)}</td>
              </tr>
            </tbody>
          </table>
        `;
      } else if (row.type === "offer") {
        let pkgTitle = "N/A";
        if (row.offer?.packages?.length) {
          const p =
            row.offer.packages.find((x) => x._id === row.packageId) ||
            row.offer.packages[0];
          pkgTitle = p.title;
        }
        invoiceBody = `
          <h6>Offer Items (qty × price)</h6>
          <table class="invoice-table">
            <thead>
              <tr>
                <th>Offer Title</th>
                <th>Package</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Calculated</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${row.offer?.title || "N/A"}</td>
                <td>${pkgTitle}</td>
                <td>${row.qty || 0}</td>
                <td>${row.price || 0}</td>
                <td>${(row.qty || 0) * (row.price || 0)}</td>
              </tr>
            </tbody>
          </table>
        `;
      } else if (row.type === "deal") {
        invoiceBody = `
          <h6>Deal Items (qty × price)</h6>
          <table class="invoice-table">
            <thead>
              <tr>
                <th>Deal Title</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Calculated</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>${row.deal?.title || "N/A"}</td>
                <td>${row.qty || 0}</td>
                <td>${row.price || 0}</td>
                <td>${(row.qty || 0) * (row.price || 0)}</td>
              </tr>
            </tbody>
          </table>
        `;
      }

      // Summaries
      const { subtotal, vat, discount, finalPrice } = calculateTotals(row);

      // User info
      const userImg = row.user?.image || USER_IMAGE_PLACEHOLDER;
      const userEmail = row.user?.email || "N/A";
      const userPhone = row.user?.phone || "N/A";

      // Venue or Rayna
      let rightHeaderHTML = "";
      if (row.type === "ticket") {
        // Rayna tours
        rightHeaderHTML = `
          <div class="offer-venue-info">
            <div class="venue-offer-images">
              <img src="${RAYNA_LOGO}" alt="Rayna Logo" style="margin-left:5px;width:80px;height:50px;object-fit:contain;border-radius:4px;" />
            </div>
            <div class="venue-offer-text">
              <h5>${RAYNA_NAME}</h5>
              <p>Website: <a href="${RAYNA_WEBSITE}" target="_blank">${RAYNA_WEBSITE}</a></p>
            </div>
          </div>
        `;
      } else {
        const venueImg = row.venue?.logo || VENUE_IMAGE_PLACEHOLDER;
        let typeImg = OFFER_IMAGE_PLACEHOLDER;
        if (row.type === "offer") {
          typeImg = row.offer?.image || OFFER_IMAGE_PLACEHOLDER;
        } else if (row.type === "deal") {
          typeImg = row.deal?.image || OFFER_IMAGE_PLACEHOLDER;
        }

        rightHeaderHTML = `
          <div class="offer-venue-info">
            <div class="venue-offer-images">
              <img src="${venueImg}" alt="Venue" />
              <img src="${typeImg}" alt="Type" />
            </div>
            <div class="venue-offer-text">
              <h5>${row.venue?.name || "Unknown Venue"}</h5>
              <p>Type: ${row.type}</p>
            </div>
          </div>
        `;
      }

      return `
        <div class="statement-item">
          <div class="top-row">
            <div class="left-section">
              <div class="date-time">${dateTime}</div>
              <div class="title">${topTitle}</div>
            </div>
            <div>
              <span class="payment-status-badge ${paymentBadgeClass}">
                ${paymentStatusText}
              </span>
            </div>
            <div class="amount">
              ${
                isFree
                  ? `<span style="color: #999;">AED 0.00</span>`
                  : row.paymentStatus === "paid"
                  ? `<span class="${creditDebitClass}">+ AED ${totalAmount}</span>`
                  : `<span class="${creditDebitClass}">- AED ${totalAmount}</span>`
              }
            </div>
          </div>

          <div class="detail-section">
            <div class="detail-card">
              <div class="ticket-ui">
                ${voucherCount || ""}
                ${ticketBox}
              </div>

              <div class="invoice-container">
                <div class="invoice-header">
                  <!-- USER INFO -->
                  <div class="user-info">
                    <img class="user-image" src="${userImg}" alt="User" />
                    <div>
                      <h5>${(row.user?.first_name || "")} ${(row.user?.last_name || "")}</h5>
                      <p>Email: ${userEmail}</p>
                      <p>Phone: ${userPhone}</p>
                      <p>Order ID: ${row.orderId || ""}</p>
                    </div>
                  </div>
                  ${rightHeaderHTML}
                </div>

                <div class="invoice-body">
                  ${invoiceBody}
                </div>
                <div class="invoice-summary">
                  <table class="summary-table">
                    <tbody>
                      <tr>
                        <td>Subtotal</td>
                        <td>AED ${subtotal.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>VAT</td>
                        <td>AED ${vat.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>${discount}</td>
                      </tr>
                      <tr class="total-row">
                        <td>Total</td>
                        <td>AED ${finalPrice.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div><!-- .invoice-container -->
            </div><!-- .detail-card -->
          </div><!-- .detail-section -->
        </div><!-- .statement-item -->
      `;
    })
    .join("");

  return `
    <html>
      <head>
        <meta charset="utf-8"/>
        <title>Printable Account Statement</title>
        <style>${inlineCSS}</style>
      </head>
      <body>
        <div class="account-statement">
          ${htmlRows}
        </div>
      </body>
    </html>
  `;
}

function printDocument(htmlString) {
  const printWindow = window.open("", "_blank");
  if (!printWindow) return;
  printWindow.document.open();
  printWindow.document.write(htmlString);
  printWindow.document.close();
  setTimeout(() => {
    printWindow.focus();
    printWindow.print();
  }, 500);
}

// Print all
function printAllItems(offerData) {
  const htmlString = buildAllItemsHTML(offerData);
  printDocument(htmlString);
}

// Print single
function printSingleItem(row) {
  const htmlString = buildAllItemsHTML([row]);
  printDocument(htmlString);
}

//
// (B) CSV EXPORT LOGIC
// (If you want to include the new “tour name”, “option name”, etc. in your CSV,
//  you'll need to add them to buildCsvRow. Below is an example.)
//
function buildCsvRow(row) {
  const userName = `${row.user?.first_name || ""} ${row.user?.last_name || ""}`.trim();
  const userEmail = row.user?.email || "";
  const userPhone = row.user?.phone || "";
  const orderId = row.orderId || "";
  const createdAt = row.createdAt || "";
  const totalAmount = getTotalAmount(row);
  const payStatus = totalAmount === 0 ? "FREE" : row.paymentStatus || "N/A";
  const rowType = row.type || "";

  // Prepare ticket details if type === "ticket"
  let tourName = "";
  let optionName = "";
  let paxInfo = "";
  if (rowType === "ticket") {
    const ticket = row.ticket || {};
    const td = ticket.TourDetails?.[0] || {};
    const pass = ticket.passengers?.[0] || {};
    tourName = td.tour?.tourName || "";
    optionName = td.tourOption?.optionName || "";
    paxInfo = `Adult(${td.adult || 0}),Child(${td.child || 0}),Infant(${td.infant || 0}) Passenger:${pass.prefix || ""} ${pass.firstName || ""} ${pass.lastName || ""}`;
  }

  // Title, etc.
  let title = "";
  let extraDescription = "";
  if (rowType === "ticket") {
    title = "Ticket Purchase";
    extraDescription = tourName;
  } else if (rowType === "offer") {
    title = row.offer?.title || "";
    extraDescription = row.offer?.description || "";
  } else if (rowType === "deal") {
    title = row.deal?.title || "";
    extraDescription = row.deal?.description || "";
  }

  // Price, quantity, etc.
  const price = row.price || 0;
  const quantity = row.qty || 0;
  const calculatedTotal = price * quantity;

  // Summaries
  const { subtotal, vat, discount, finalPrice } = calculateTotals(row);

  // Return CSV row
  return [
    orderId,
    createdAt,
    userName,
    userEmail,
    userPhone,
    payStatus,
    rowType,
    title,
    row.ticketId || "", // if it's an offer/deal, this may be blank
    totalAmount.toFixed(2),
    extraDescription.replace(/,/g, " "), // remove commas
    optionName.replace(/,/g, " "),
    paxInfo.replace(/,/g, " "),
    price,
    quantity,
    calculatedTotal.toFixed(2),
    subtotal.toFixed(2),
    vat.toFixed(2),
    discount,
    finalPrice.toFixed(2),
  ].join(",");
}

function downloadAllCSV(offerData) {
  const headers = [
    "Order ID",
    "Created At",
    "User Name",
    "User Email",
    "User Phone",
    "Payment Status",
    "Type",
    "Title",
    "Ticket ID",
    "Total Amount",
    "Description/Tour Name",
    "Option Name",
    "PAX Info",
    "Price",
    "Quantity",
    "Calculated Total",
    "Subtotal",
    "VAT",
    "Discount",
    "Final Price",
  ].join(",");

  const csvRows = [headers];
  offerData.forEach((row) => {
    csvRows.push(buildCsvRow(row));
  });
  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "account-statement-full.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function downloadSingleCSV(row) {
  const headers = [
    "Order ID",
    "Created At",
    "User Name",
    "User Email",
    "User Phone",
    "Payment Status",
    "Type",
    "Title",
    "Ticket ID",
    "Total Amount",
    "Description/Tour Name",
    "Option Name",
    "PAX Info",
    "Price",
    "Quantity",
    "Calculated Total",
    "Subtotal",
    "VAT",
    "Discount",
    "Final Price",
  ].join(",");

  const csvLine = buildCsvRow(row);
  const csvString = headers + "\n" + csvLine;

  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `order-${row.orderId}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

//
// (C) MAIN COMPONENT
// ---------------------------------------------------
const SpecialOfferClaimManagementlist = () => {
  const location = useLocation();
  const [offerData, setOfferData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);

  const sortField = "createdAt";
  const sortOrder = "desc";

  useEffect(() => {
    fetchSpecialOfferClaims(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  const fetchSpecialOfferClaims = async (page) => {
    const limit = page === 1 ? 50 : 25;
    const payload = { limit, page, sortField, sortOrder };

    if (location.state) {
      const { startDate, endDate, paymentStatus } = location.state;
      let filterArgs = [];
      if (paymentStatus) {
        filterArgs.push({ paymentStatus, type: "eq" });
      }
      if (startDate) {
        filterArgs.push({ startDate: startDate, type: "date-range" });
      }
      if (endDate) {
        filterArgs.push({ endDate: endDate, type: "date-range" });
      }
      if (filterArgs.length > 0) {
        payload.filterArgs = filterArgs;
      }
    }

    try {
      const response = await postRequest("subscription/order/list/admin", payload);
      const data = response.data;
      setTotalItems(data.count);

      if (page === 1) {
        setOfferData(data.list);
      } else {
        setOfferData((prev) => [...prev, ...data.list]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const toggleRow = (id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  // Print all
  const downloadAllPDF = () => {
    printAllItems(offerData);
  };

  // Print single
  const downloadSinglePDF = (row) => {
    printSingleItem(row);
  };

  return (
    <>
      <ToastContainer />
      <Row>
        <Col className="12">
          <div className="page-title-box">
            <h4>Account Statement</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Account Logs</li>
              <li className="breadcrumb-item">Account Statement</li>
            </ol>
          </div>
        </Col>
        <Col className="col-12">
          {/* “All” actions => Print (PDF) & CSV */}
          <div
            className="card text-center"
            style={{ backgroundColor: "#d30b7d", border: "none", marginBottom: "20px" }}
          >
            <div
              className="card-body"
              style={{ display: "flex", justifyContent: "center", gap: "15px" }}
            >
              <button
                style={{ border: "none", background: "none" }}
                onClick={downloadAllPDF}
                title="Print/Save All as PDF"
              >
                <i className="fas fa-print" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => downloadAllCSV(offerData)}
                title="Download All CSV"
              >
                <i className="fas fa-file-csv" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
            </div>
          </div>

          <div className="account-statement">
            {offerData.length === 0 && (
              <div className="text-center">No data available</div>
            )}
            {offerData.map((row) => {
              const isExpanded = expandedRows.includes(row._id);
              const { subtotal, vat, discount, finalPrice } = calculateTotals(row);
              const voucherCount = getVoucherCount(row);
              const totalAmount = getTotalAmount(row);
              const isFree = totalAmount === 0;
              const displayPaymentStatus = isFree ? "FREE" : row.paymentStatus || "N/A";

              return (
                <div className="statement-item" key={row._id}>
                  {/* Main summary row */}
                  <div className="top-row">
                    <button className="expand-btn" onClick={() => toggleRow(row._id)}>
                      {isExpanded ? "–" : "+"}
                    </button>

                    <div className="left-content">
                      <div className="date-time">
                        {new Date(row.createdAt).toLocaleString()}
                      </div>
                      <div className="title">{getTopRowTitle(row)}</div>
                    </div>

                    <div className="middle-content">
                      <Badge
                        className={`payment-status-badge ${
                          isFree
                            ? "free"
                            : row.paymentStatus === "paid"
                            ? "paid"
                            : "unpaid"
                        }`}
                      >
                        {displayPaymentStatus}
                      </Badge>
                    </div>

                    <div className="right-content">
                      <div className="amount">
                        {isFree ? (
                          <span style={{ color: "#999" }}>AED 0.00</span>
                        ) : row.paymentStatus === "paid" ? (
                          <span className="credit">+ AED {getTopRowAmount(row)}</span>
                        ) : (
                          <span className="debit">- AED {getTopRowAmount(row)}</span>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Expanded Detail */}
                  {isExpanded && (
                    <div className="detail-section">
                      <div className="detail-card">
                        <div className="ticket-ui">
                          {voucherCount && (
                            <div className="ticket-count">{voucherCount}</div>
                          )}
                          {renderTicketUI(row)}
                        </div>

                        <div className="invoice-container">
                          <div className="invoice-header">
                            {/* USER INFO */}
                            <div
                              className="user-info"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={row.user?.image || USER_IMAGE_PLACEHOLDER}
                                alt="User"
                                style={{
                                  width: 60,
                                  height: 60,
                                  borderRadius: "50%",
                                  marginRight: 10,
                                  objectFit: "cover",
                                }}
                              />
                              <div>
                                <h5>
                                  {row.user?.first_name} {row.user?.last_name}
                                </h5>
                                <p>Email: {row.user?.email || "N/A"}</p>
                                <p>Phone: {row.user?.phone || "N/A"}</p>
                                <p>Order ID: {row.orderId}</p>
                              </div>
                            </div>

                            {/* Venue or Rayna Info */}
                            {row.type === "ticket" ? (
                              <div
                                className="offer-venue-info"
                                style={{ textAlign: "right" }}
                              >
                                <div
                                  className="venue-offer-images"
                                  style={{ marginBottom: 5 }}
                                >
                                  <img
                                    src={RAYNA_LOGO}
                                    alt="Rayna Logo"
                                    style={{
                                      marginLeft: 5,
                                      width: 80,
                                      height: 50,
                                      objectFit: "contain",
                                      borderRadius: 4,
                                    }}
                                  />
                                </div>
                                <div className="venue-offer-text">
                                  <h5>{RAYNA_NAME}</h5>
                                  <p>
                                    Website:{" "}
                                    <a
                                      href={RAYNA_WEBSITE}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {RAYNA_WEBSITE}
                                    </a>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="offer-venue-info"
                                style={{ textAlign: "right" }}
                              >
                                <div
                                  className="venue-offer-images"
                                  style={{ marginBottom: 5 }}
                                >
                                  <img
                                    src={row.venue?.logo || VENUE_IMAGE_PLACEHOLDER}
                                    alt="Venue"
                                    style={{
                                      marginLeft: 5,
                                      width: 80,
                                      height: 50,
                                      objectFit: "cover",
                                      borderRadius: 4,
                                    }}
                                  />
                                  <img
                                    src={
                                      row.type === "offer"
                                        ? row.offer?.image || OFFER_IMAGE_PLACEHOLDER
                                        : row.type === "deal"
                                        ? row.deal?.image || OFFER_IMAGE_PLACEHOLDER
                                        : OFFER_IMAGE_PLACEHOLDER
                                    }
                                    alt="Type"
                                    style={{
                                      marginLeft: 5,
                                      width: 80,
                                      height: 50,
                                      objectFit: "cover",
                                      borderRadius: 4,
                                    }}
                                  />
                                </div>
                                <div className="venue-offer-text">
                                  <h5>{row.venue?.name || "Unknown Venue"}</h5>
                                  <p>Type: {row.type}</p>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="invoice-body">{renderInvoiceBody(row)}</div>

                          {/* Summaries */}
                          <div className="invoice-summary">
                            <table className="summary-table">
                              <tbody>
                                <tr>
                                  <td>Subtotal</td>
                                  <td>AED {subtotal.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td>VAT</td>
                                  <td>AED {vat.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td>Discount</td>
                                  <td>{discount}</td>
                                </tr>
                                <tr className="total-row">
                                  <td>Total</td>
                                  <td>AED {finalPrice.toFixed(2)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {/* Per-item print & CSV */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "15px",
                          marginTop: "10px",
                        }}
                      >
                        {/* “Print Single” => user can Save as PDF */}
                        <button
                          style={{ border: "none", background: "none" }}
                          onClick={() => downloadSinglePDF(row)}
                          title="Print Single (Save as PDF)"
                        >
                          <i
                            className="fas fa-print"
                            style={{ color: "#d30b7d", fontSize: "1.2rem" }}
                          ></i>
                        </button>
                        {/* Single CSV */}
                        <button
                          style={{ border: "none", background: "none" }}
                          onClick={() => downloadSingleCSV(row)}
                          title="Download CSV"
                        >
                          <i
                            className="fas fa-file-csv"
                            style={{ color: "#d30b7d", fontSize: "1.2rem" }}
                          ></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            {/* LOAD MORE if there are more items */}
            {offerData.length > 0 && offerData.length < totalItems && (
              <div className="text-center mt-3">
                <Button color="primary" onClick={handleLoadMore}>
                  Load More
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SpecialOfferClaimManagementlist;
