import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

import { postRequest } from "../../components/Common/Utils.js";
import "./SpecialOfferSalesManagementlist.scss"; 

/** ==========================
 *  1) Basic Utilities
 *  ========================== */

/** Group items by a specific key (e.g. "venueId", "userId"). */
function groupBy(array, key) {
  return array.reduce((result, item) => {
    const groupKey = item[key];
    if (!result[groupKey]) result[groupKey] = [];
    result[groupKey].push(item);
    return result;
  }, {});
}

/** If perspective = "USER", amounts go to Cr side; else Dr. */
function splitDrCrRows(items, perspective) {
  const drRows = [];
  const crRows = [];
  items.forEach((row) => {
    const dateStr = format(new Date(row.createdAt), "dd/MM/yyyy");
    const lines = [
      `Sale of ${row.offerName || row.offerTitle || "N/A"}`,
      `to ${row.userName || "Unknown User"}`,
      `at ${row.venueName || "Unknown Venue"}`,
    ];
    const multilineParticulars = lines.join("<br/>");

    const rowObj = {
      date: dateStr,
      particulars: multilineParticulars,
      lf: "",
      amount: Math.abs(row.billAmount).toFixed(2),
    };

    if (perspective === "USER") {
      crRows.push(rowObj);
    } else {
      drRows.push(rowObj);
    }
  });
  return { drRows, crRows };
}

/** Ensure Dr side and Cr side have the same length so we can pair them. */
function mergeLedgerRows(drRows, crRows) {
  const maxLen = Math.max(drRows.length, crRows.length);
  const paddedDr = [...drRows];
  const paddedCr = [...crRows];
  while (paddedDr.length < maxLen) {
    paddedDr.push({ date: "", particulars: "", lf: "", amount: "" });
  }
  while (paddedCr.length < maxLen) {
    paddedCr.push({ date: "", particulars: "", lf: "", amount: "" });
  }
  return { paddedDr, paddedCr };
}

/** Compute Dr/Cr totals. */
function computeTotals(items, perspective) {
  let totalDr = 0;
  let totalCr = 0;
  items.forEach((row) => {
    const amt = Math.abs(row.billAmount);
    if (perspective === "USER") totalCr += amt;
    else totalDr += amt;
  });
  return { totalDr, totalCr };
}

/** ==========================
 *  2) Print & CSV Helpers
 *  ========================== */

/** 
 * Build HTML for the two-column ledger table (Dr | Cr). 
 * Returns a string suitable for printing in a new window. 
 */
function buildLedgerPrintTableHTML(items, perspective) {
  const { drRows, crRows } = splitDrCrRows(items, perspective);
  const { paddedDr, paddedCr } = mergeLedgerRows(drRows, crRows);

  let tableHTML = `
    <div class="table-header">
      <div class="th dr-date">Date</div>
      <div class="th dr-particulars">Particulars</div>
      <div class="th dr-lf">JF</div>
      <div class="th dr-amount">Amount</div>

      <div class="th cr-date">Date</div>
      <div class="th cr-particulars">Particulars</div>
      <div class="th cr-lf">JF</div>
      <div class="th cr-amount">Amount</div>
    </div>
    <div class="table-body">
  `;

  paddedDr.forEach((dr, idx) => {
    const cr = paddedCr[idx];
    const drStyle = dr.amount ? 'style="color:green"' : "";
    const crStyle = cr.amount ? 'style="color:red"' : "";

    tableHTML += `
      <div class="table-row">
        <div class="td dr-date">${dr.date}</div>
        <div class="td dr-particulars">${dr.particulars}</div>
        <div class="td dr-lf">${dr.lf}</div>
        <div class="td dr-amount" ${drStyle}>${dr.amount}</div>

        <div class="td cr-date">${cr.date}</div>
        <div class="td cr-particulars">${cr.particulars}</div>
        <div class="td cr-lf">${cr.lf}</div>
        <div class="td cr-amount" ${crStyle}>${cr.amount}</div>
      </div>
    `;
  });

  tableHTML += `</div>`; // close .table-body
  return tableHTML;
}

/** Wraps the table HTML in a full HTML page for printing a single group. */
function buildSingleLedgerPrintHTML(items, perspective) {
  const inlineCSS = `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
    body { font-family: 'Inter', sans-serif; margin: 20px; background: #f2f2f2; }
    .account-statement { width: 90%; margin: 0 auto; }
    .table-header { display: flex; font-weight: 600; background: #f9f9f9; padding: 0.75rem; }
    .th { flex: 1; text-transform: uppercase; font-size: 0.85rem; }
    .table-body .table-row { display: flex; border-bottom: 1px solid #eee; padding: 0.75rem; }
    .td { flex: 1; font-size: 0.85rem; }
    .dr-date, .cr-date { width: 80px; }
    .dr-lf, .cr-lf { width: 45px; text-align: center; }
    .dr-amount, .cr-amount { width: 70px; text-align: right; }
  `;

  let html = `<html><head><meta charset="utf-8"/><title>Claim Ledger Print</title>`;
  html += `<style>${inlineCSS}</style></head><body><div class="account-statement">`;
  html += buildLedgerPrintTableHTML(items, perspective);
  html += `</div></body></html>`;
  return html;
}

/** Open a new window with the HTML and trigger print. */
function printDocument(htmlString) {
  const printWindow = window.open("", "_blank");
  if (!printWindow) return;
  printWindow.document.open();
  printWindow.document.write(htmlString);
  printWindow.document.close();
  setTimeout(() => {
    printWindow.focus();
    printWindow.print();
  }, 400);
}

/** Print a single group of ledger data. */
function printLedgerGroup(items, perspective) {
  const htmlString = buildSingleLedgerPrintHTML(items, perspective);
  printDocument(htmlString);
}

/** Build CSV lines for Dr/Cr columns: 8 columns total:
 * Dr Date, Dr Particulars, Dr LF, Dr Amount, Cr Date, Cr Particulars, Cr LF, Cr Amount 
 */
function buildLedgerCsvRows(items, perspective) {
  const stripHtml = (str) => str.replace(/<[^>]+>/g, " ").trim();

  const { drRows, crRows } = splitDrCrRows(items, perspective);
  const { paddedDr, paddedCr } = mergeLedgerRows(drRows, crRows);

  const header = [
    "Dr Date",
    "Dr Particulars",
    "Dr LF",
    "Dr Amount",
    "Cr Date",
    "Cr Particulars",
    "Cr LF",
    "Cr Amount",
  ].join(",");

  const lines = [header];
  for (let i = 0; i < paddedDr.length; i++) {
    const dr = paddedDr[i];
    const cr = paddedCr[i];
    lines.push([
      dr.date || "",
      stripHtml(dr.particulars || ""),
      dr.lf || "",
      dr.amount || "",
      cr.date || "",
      stripHtml(cr.particulars || ""),
      cr.lf || "",
      cr.amount || "",
    ].join(","));
  }
  return lines.join("\n");
}

/** Download CSV for a single group. */
function downloadLedgerCSV(items, perspective) {
  const csvString = buildLedgerCsvRows(items, perspective);
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "claim-ledger.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/** Build a single HTML document containing all ledger groups (WHOSIN, Venue, Offer, User). */
function buildFullLedgerPrintHTML(allData) {
  // We gather them here
  const groupedVenues = groupBy(allData, "venueId");
  const groupedOffers = groupBy(allData, "specialOfferId");
  const groupedUsers = groupBy(allData, "userId");

  const inlineCSS = `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
    body { font-family: 'Inter', sans-serif; margin: 20px; background: #f2f2f2; }
    .account-statement { width: 90%; margin: 0 auto; }
    h2 { font-size: 1rem; color: #333; margin-top: 20px; }
    .table-header { display: flex; font-weight: 600; background: #f9f9f9; padding: 0.75rem; }
    .th { flex: 1; text-transform: uppercase; font-size: 0.85rem; }
    .table-body .table-row { display: flex; border-bottom: 1px solid #eee; padding: 0.75rem; }
    .td { flex: 1; font-size: 0.85rem; }
    .dr-date, .cr-date { width: 80px; }
    .dr-lf, .cr-lf { width: 45px; text-align: center; }
    .dr-amount, .cr-amount { width: 70px; text-align: right; }
  `;
  let html = `<html><head><meta charset="utf-8"/><title>Claim Ledger (Full)</title><style>${inlineCSS}</style></head><body>`;
  html += `<div class="account-statement">`;

  // 1) WHOSIN => Dr
  if (allData.length > 0) {
    html += `<h2>WHOS'IN</h2>`;
    html += buildLedgerPrintTableHTML(allData, "WHOSIN");
  }

  // 2) Venues => Dr
  for (const [id, items] of Object.entries(groupedVenues)) {
    const name = items[0]?.venueName || "Unknown Venue";
    html += `<h2>Venue: ${name}</h2>`;
    html += buildLedgerPrintTableHTML(items, "VENUE");
  }

  // 3) Offers => Dr
  for (const [id, items] of Object.entries(groupedOffers)) {
    const name = items[0]?.offerName || items[0]?.offerTitle || "Unknown Offer";
    html += `<h2>Offer: ${name}</h2>`;
    html += buildLedgerPrintTableHTML(items, "OFFER");
  }

  // 4) Users => Cr
  for (const [id, items] of Object.entries(groupedUsers)) {
    const name = items[0]?.userName || "Unknown User";
    html += `<h2>User: ${name}</h2>`;
    html += buildLedgerPrintTableHTML(items, "USER");
  }

  html += `</div></body></html>`;
  return html;
}

/** Print the entire data set. */
function printFullLedger(allData) {
  const htmlString = buildFullLedgerPrintHTML(allData);
  printDocument(htmlString);
}

/** Build a CSV for the entire ledger. */
function buildFullLedgerCSV(allData) {
  let csv = "";
  const groupedVenues = groupBy(allData, "venueId");
  const groupedOffers = groupBy(allData, "specialOfferId");
  const groupedUsers = groupBy(allData, "userId");

  // WHOSIN
  if (allData.length > 0) {
    csv += "WHOS'IN\n";
    csv += buildLedgerCsvRows(allData, "WHOSIN") + "\n\n";
  }

  // Venues
  for (const [id, items] of Object.entries(groupedVenues)) {
    const name = items[0]?.venueName || "Unknown Venue";
    csv += `Venue: ${name}\n`;
    csv += buildLedgerCsvRows(items, "VENUE") + "\n\n";
  }

  // Offers
  for (const [id, items] of Object.entries(groupedOffers)) {
    const name = items[0]?.offerName || items[0]?.offerTitle || "Unknown Offer";
    csv += `Offer: ${name}\n`;
    csv += buildLedgerCsvRows(items, "OFFER") + "\n\n";
  }

  // Users
  for (const [id, items] of Object.entries(groupedUsers)) {
    const name = items[0]?.userName || "Unknown User";
    csv += `User: ${name}\n`;
    csv += buildLedgerCsvRows(items, "USER") + "\n\n";
  }

  return csv.trim();
}

/** Download entire ledger as CSV. */
function downloadFullLedgerCSV(allData) {
  const csvString = buildFullLedgerCSV(allData);
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "claim-ledger-full.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/** ==========================
 *  3) Main Component
 *  ========================== */
const SpecialOfferClaimLedger = () => {
  const location = useLocation();
  const [allData, setAllData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    fetchLedgerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchLedgerData() {
    const payload = {
      limit: 10000,
      page: 1,
      sortField: "createdAt",
      sortOrder: "asc",
    };

    if (location.state) {
      const { startDate, endDate, paymentStatus } = location.state;
      payload.filterArgs = [
        { paymentStatus, type: "eq" },
        { startDate, type: "date-range" },
        { endDate, type: "date-range" },
      ];
    }

    try {
      const response = await postRequest("venue/special-offer-claim/list", payload);
      const data = response.data || {};
      let list = data.list || [];
      // Sort ascending by date
      list.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setAllData(list);
    } catch (err) {
      console.error("Failed to fetch ledger data:", err);
    }
  }

  const toggleRow = (key) => {
    if (expandedRows.includes(key)) {
      setExpandedRows(expandedRows.filter((k) => k !== key));
    } else {
      setExpandedRows([...expandedRows, key]);
    }
  };

  // Groups
  const groupedVenues = groupBy(allData, "venueId");
  const groupedOffers = groupBy(allData, "specialOfferId");
  const groupedUsers = groupBy(allData, "userId");

  const venueGroups = Object.entries(groupedVenues).map(([id, items]) => ({
    id,
    items,
    name: items[0]?.venueName || "Unknown Venue",
  }));
  const offerGroups = Object.entries(groupedOffers).map(([id, items]) => ({
    id,
    items,
    name: items[0]?.offerName || items[0]?.offerTitle || "Unknown Offer",
  }));
  const userGroups = Object.entries(groupedUsers).map(([id, items]) => ({
    id,
    items,
    name: items[0]?.userName || "Unknown User",
  }));

  /** Renders the two-column ledger in React (Dr | Cr). */
  function renderLedgerTable(items, perspective) {
    const { drRows, crRows } = splitDrCrRows(items, perspective);
    const { paddedDr, paddedCr } = mergeLedgerRows(drRows, crRows);

    return (
      <div className="custom-table-container">
        <div className="table-header">
          <div className="th dr-date">Date</div>
          <div className="th dr-particulars">Particulars</div>
          <div className="th dr-lf">JF</div>
          <div className="th dr-amount">Amount</div>

          <div className="th cr-date">Date</div>
          <div className="th cr-particulars">Particulars</div>
          <div className="th cr-lf">JF</div>
          <div className="th cr-amount">Amount</div>
        </div>

        <div className="table-body">
          {paddedDr.map((dr, idx) => {
            const cr = paddedCr[idx];
            return (
              <div className="table-row" key={idx}>
                {/* Dr side */}
                <div className="td dr-date">{dr.date}</div>
                <div
                  className="td dr-particulars"
                  dangerouslySetInnerHTML={{ __html: dr.particulars }}
                />
                <div className="td dr-lf">{dr.lf}</div>
                <div
                  className="td dr-amount"
                  style={{ color: dr.amount ? "green" : undefined }}
                >
                  {dr.amount}
                </div>

                {/* Cr side */}
                <div className="td cr-date">{cr.date}</div>
                <div
                  className="td cr-particulars"
                  dangerouslySetInnerHTML={{ __html: cr.particulars }}
                />
                <div className="td cr-lf">{cr.lf}</div>
                <div
                  className="td cr-amount"
                  style={{ color: cr.amount ? "red" : undefined }}
                >
                  {cr.amount}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  /** Renders each group’s card with expand/collapse and Dr/Cr totals. */
  function renderLedgerCard(cardId, cardTitle, items, perspective) {
    const isOpen = expandedRows.includes(cardId);
    const { totalDr, totalCr } = computeTotals(items, perspective);

    return (
      <div className="statement-item" key={cardId}>
        <div className="top-row">
          <button className="expand-btn" onClick={() => toggleRow(cardId)}>
            {isOpen ? "–" : "+"}
          </button>
          <div className="left-content">
            <div className="title">{cardTitle}</div>
          </div>
          <div className="middle-content"></div>
          <div className="right-content">
            <span style={{ color: "green", fontSize: "1.1rem" }}>
              {Math.abs(totalDr).toFixed(2)}
            </span>
            <span style={{ color: "red", fontSize: "1.1rem", marginLeft: "1rem" }}>
              {Math.abs(totalCr).toFixed(2)}
            </span>
          </div>
        </div>

        {/* Group-level: Print & CSV */}
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px", margin: "10px 0" }}>
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => printLedgerGroup(items, perspective)}
            title="Print Group"
          >
            <i className="fas fa-print" style={{ fontSize: "1rem", color: "#d30b7d" }}></i>
          </button>
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => downloadLedgerCSV(items, perspective)}
            title="Download CSV"
          >
            <i className="fas fa-file-csv" style={{ fontSize: "1rem", color: "#d30b7d" }}></i>
          </button>
        </div>

        {isOpen && (
          <div className="detail-section">
            <div className="detail-card">
              {items.length === 0 ? (
                <div style={{ padding: "1rem" }}>No transactions found.</div>
              ) : (
                renderLedgerTable(items, perspective)
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <Row>
        <Col className="col-12">
          <div className="page-title-box">
            <h4>Claim Ledger</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Account Logs</li>
              <li className="breadcrumb-item">Ledger</li>
            </ol>
          </div>
        </Col>

        {/* Top-level (full ledger) print/CSV */}
        <Col className="col-12">
          <div
            className="card text-center"
            style={{ backgroundColor: "#d30b7d", border: "none", marginBottom: "20px" }}
          >
            <div className="card-body" style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => printFullLedger(allData)}
                title="Print Full Ledger"
              >
                <i className="fas fa-print" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => downloadFullLedgerCSV(allData)}
                title="Download Full Ledger CSV"
              >
                <i className="fas fa-file-csv" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
            </div>
          </div>
        </Col>

        <Col className="col-12">
          <div className="account-statement">
            {/* 1) WHOS’IN => Dr perspective */}
            {allData.length > 0 && renderLedgerCard("WHOSIN", "WHOS'IN", allData, "WHOSIN")}

            {/* 2) Venues => Dr perspective */}
            {venueGroups.map((venue) =>
              renderLedgerCard(`VENUE-${venue.id}`, `Venue: ${venue.name}`, venue.items, "VENUE")
            )}

            {/* 3) Offers => Dr perspective */}
            {offerGroups.map((offer) =>
              renderLedgerCard(`OFFER-${offer.id}`, `Offer: ${offer.name}`, offer.items, "OFFER")
            )}

            {/* 4) Users => Cr perspective */}
            {userGroups.map((user) =>
              renderLedgerCard(`USER-${user.id}`, `User: ${user.name}`, user.items, "USER")
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SpecialOfferClaimLedger;
