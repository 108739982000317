import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Modal } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest 
} from "../Common/Utils.js";

import FileUpload from "../Common/FileUpload"; 
import VenueModal from "./VenueModal.js";

// import HeliDubaiTicketModal from "components/Modal/HeliDubaiTicketModal.js";
// import RaynaTicketModal from "components/Modal/RaynaTicketModal.js";
import CustomTicketModal from "components/Modal/CustomTicketModal.js"

 function CreateVideo({AddUploadedVideo, closeModal2,isHome}) {
  const [videoData, setVideoData] = useState({
    title: "",
    description: "",
    buttonText: "",
    contentType: "video",
  });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [venueList, setVenueList] = useState([]);

  const [heliDubaiModel, setHeliDubaiModel] = useState(false);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [model, setModel] = useState(false);
  const [startLoader, setStartLoader] = useState(false);

    const [heliDubaiText, setHeliDubaiText] = useState("");
    const [heliDubaiImage, setHeliDubaiImage] = useState("");
    const [heliDubaiId, setHeliDubaiId] = useState("");
    const [heliDubaiCode, setHeliDubaiCode] = useState("");
    const [selectTicket, setSelectTicket] = useState("");


  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const closeHeliModel = () => {
    setHeliDubaiModel(false);
  };

  const selectHeliDubaiRow = (id) => {
    setHeliDubaiText(id?.title);
    setHeliDubaiImage(id?.images[0]);
    setHeliDubaiId(id?._id);  
    setHeliDubaiCode(id?.tourProductId);   
    closeHeliModel();
    setSelectTicket(id);

  };

  const closeModal = () => {
    setHeliDubaiModel(false)
  } 

  const toggleModel = () => {
    setHeliDubaiModel(!model);
  };  
 
 

  const Createvideo = async (e) => {
    e.preventDefault();
    if (!heliDubaiId) {
      toast.error("Please select Rayna Ticket");
      return;
    }
    // if (!videoData?.title) {
    //   toast.error("Title is required");
    //   return;
    // }
    // if (!videoData?.description) {
    //   toast.error("Please enter description");
    //   return;
    // }

    // if (!videoData?.buttonText) {
    //     toast.error("Please enter button text");
    //     return;
    // }
    
    // if (!selectedImage) {
    //   toast.error("Please select video / image");
    //   return;
    // }
    //setStartLoader(true);
    //setLoading(true);

   // const formData = new FormData();   
    // let ep = "comman/doc/upload";
    // formData.append("file", selectedImage);

    // if(videoData?.contentType == "video") {
    //     ep = "comman/doc/upload"
    // }    

    //const response = await postRequestForm( formData);
   // setStartLoader(false);
    //setLoading(false);     


      console.log("infodata",selectTicket);
      AddUploadedVideo(selectTicket);

      closeModal2();     
   
  };

  const updateValue = (index, value) => {
    const list = { ...videoData };
    list[index] = value;
    setVideoData(list);
  };
 
  return (
    <React.Fragment>
      <ToastContainer />      

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                {/* <h4 className="card-title"> Create Video </h4> */}
                <Form onSubmit={Createvideo}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Rayna Ticket 
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {heliDubaiText ? (                              
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Rayna Ticket
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={heliDubaiImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={heliDubaiText}
                                  id="selectedVenue"
                                  placeholder="No Ticket chosen"
                                />
                              </div>
                            ) : (
                              
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose Rayna Ticket

                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No ticket chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Button Text
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("buttonText", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Type
                          </label>
                          <div className="col-sm-6">

                            <select
                                className="form-control"
                                onChange={(e) =>
                                    updateValue("contentType", e.target.value)
                                }
                                name="type"
                            >
                                <option value="video">Video</option>
                                <option value="image">Image</option>
                            </select>
                            
                            <br />
                          </div>
                        </div>



                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                          <input
                            type="file"
                            accept="image/*, video/*"
                            onChange={(e) =>
                              setSelectedImage(e.target.files[0])
                            }
                            name="media"
                            className="form-control"
                            id="horizontal-password-input"
                          />
                          <br />
                        </div>
                        </div> */}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      {/* modal */}

      <CustomTicketModal isHome={isHome} show={heliDubaiModel} closeModal={closeHeliModel} selectRow={selectHeliDubaiRow} />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}

const RaynaModal = ({ show, closeModal, venueId, reloadImage, AddUploadedVideo,isHome  }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Create Rayna Ticket 
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <CreateVideo isHome={isHome} venueId={venueId} reloadImage={reloadImage} closeModal2={closeModal} AddUploadedVideo={AddUploadedVideo} />
      </div>
    </Modal>
  );
};

export default RaynaModal;
