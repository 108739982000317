import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import { useHistory } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import Select from "react-select";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { canvasPreview } from "pages/VenueManagement/canvasPreview";
import { useDebounceEffect } from "pages/VenueManagement/useDebounceEffect";

import MultiselectCommon from "../../components/Multiselect/MultiselectCommon.js";
import DayTimePicker from "pages/VenueManagement/DayTimePicker";

import { ToastContainer, toast } from "react-toastify";
import { postRequest, postRequestForm } from "../../components/Common/Utils.js";
import LocationPicker from "react-location-picker";
import Lightbox from "react-image-lightbox";
import FileUpload from "../../components/Common/FileUpload";

const defaultPosition = {
  lat: 25.2048,
  lng: 55.2708,
};
export default function CreateYachtClub() {
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [yachtData, setYachtData] = useState({
    name: "",
    about: "",
    address: "",
    phone: "",
    email: "",
    website: "",
    booking_url: "",
    isAllowReview: "",
    isAllowRating: "",
    latitude: 25.2048,
    longitude: 55.2708,
  });
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });
  const [selectedCover, setSelectedCover] = useState("");

  const blobUrlRef = useRef("");

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [logoSelected, setLogoSelected] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);

  const [selectedFeature, setSelectedFeature] = useState([]);
  const [featureList, setFeatureList] = useState([]);

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  let history = useHistory();

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedFeature(ids);
  };

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };

  const showLightBox = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox(true);
  };

  const showLightBox2 = (previewCover) => {
    setPreviewCover(previewCover);
    setOpenLightBox2(true);
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      // convert blob to file

      const file = new File([blob], yachtData.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  const handleLocationChange = ({ position, address, places }) => {
    const lng = position.lng;
    const lat = position.lat;
    const yachtDt = { ...yachtData, latitude: lat, longitude: lng };
    setYachtData(yachtDt);
  };

  useEffect(() => {
    fetchFeature();
  }, []);

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("venue/feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setFeatureList(options);
    }
  };

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const Createyachtclub = async (e) => {
    e.preventDefault();

    if (!yachtData?.name) {
      toast.error("Please enter yacht club name");
      return;
    }
    if (!yachtData?.about) {
      toast.error("Please enter yacht club about");
      return;
    }
    if (!yachtData?.address) {
      toast.error("Please enter yacht club address");
      return;
    }
    if (!yachtData?.phone) {
      toast.error("Please enter yacht club phone");
      return;
    }

    // check phone number is valid and not contact - and 8-15 digits

    const phone = yachtData?.phone;

    if (phone.length < 8 || phone.length > 13) {
      toast.error("Please enter valid phone number");
      return;
    }

    if (phone.includes("-")) {
      toast.error("Please enter valid phone number");
      return;
    }

    // check valid phone pattern

    const phonePattern = /^\+?[0-9\b\s-]+$/;

    if (!phonePattern.test(phone)) {
      toast.error("Please enter valid phone number");
      return;
    }

    if (!yachtData?.email) {
      toast.error("Please enter yacht club email");
      return;
    }

    let emailPattern = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

    if (!emailPattern.test(yachtData.email)) {
      toast.error("Please enter valid email");
      return;
    }    

    if (!remoteLogo) {
      toast.error("Please select yacht club logo");
      return;
    }
    if (!selectedCover) {
      toast.error("Please select yacht club cover");
      return;
    }

    if (!selectedFeature?.length) {
      toast.error("Please select yacht club feature");
      return;
    }

    if (!yachtData?.latitude) {
      toast.error("Please select latitude & longitude through Map Picker");
      return;
    }

    const formData = new FormData();

    let isAtLeastOneDaySelected = false;
    let errorTime = false;

    timeOptions.forEach((item, index) => {
      if (item.checked) {
        // If we find a checked item, mark that we have at least one day selected.
        isAtLeastOneDaySelected = true;

        // Check that open and close times are not empty and not the same.
        if (item.open !== "" && item.close !== "" && item.open !== item.close) {
          formData.append(`days[]`, days[index].toString().toLowerCase());
          formData.append(`open_time[]`, item.open);
          formData.append(`close_time[]`, item.close);
        } else {
          // Handle the case where times are empty or the same.

          errorTime = true;
        }
      }
    });

    // After the loop, check if at least one day was selected.
    if (!isAtLeastOneDaySelected) {
      toast.error("Please select at least one day");
      return;
    }

    if (errorTime) {
      toast.error(
        "Please select valid time and End time should be greater than start time"
      );
      return;
    }

    const selectedImageForm = new FormData();
    setStartLoader(true);
    selectedImageForm.append("logo", remoteLogo);
    const selectedImageRes = await postRequestForm(
      "comman/logo/upload",
      selectedImageForm
    );
    const selectedImageForm2 = new FormData();
    selectedImageForm2.append("cover", selectedCover);
    const selectedImageRes2 = await postRequestForm(
      "comman/img/upload",
      selectedImageForm2
    );
    setStartLoader(false);
    formData.append("name", yachtData.name);
    formData.append("about", yachtData.about);
    formData.append("address", yachtData.address);
    formData.append("phone", yachtData.phone);
    formData.append("email", yachtData.email);
    formData.append("website", yachtData.website ? yachtData.website : "");
    formData.append("latitude", yachtData.latitude);
    formData.append("longitude", yachtData.longitude);
    formData.append(
      "booking_url",
      yachtData.booking_url ? yachtData.booking_url : ""
    );
    
    if (selectedImageRes?.data?.url) {
      formData.append("logo", selectedImageRes.data.url);
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    }
    if (selectedImageRes2?.data?.url) {
      formData.append("cover", selectedImageRes2.data.url);
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    }

    // add selected feature
    selectedFeature.forEach((item) => {
      formData.append("features[]", item);
    });

    formData.append("isAllowReview", yachtData.isAllowReview ? true : false);
    formData.append("isAllowRating", yachtData.isAllowRating ? true : false);

    setLoading(true);
    const response = await postRequestForm("yacht/club/create", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/yachtclub");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...yachtData };
    list[index] = value;
    setYachtData(list);
  };

  const updateLocation = (value, type = "lat") => {
    const location = currentLocation;

    let newLocation = {
      lat: location.lat,
      lng: location.lng,
    };

    if (type === "lat") {
      newLocation.lat = parseFloat(value);
    } else {
      newLocation.lng = parseFloat(value);
    }
    
    setCurrentLocation(newLocation);
    setYachtData({
      ...yachtData,
      latitude: newLocation.lat,
      longitude: newLocation.lng,
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Yacht Club Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Yacht Club Management</li>:{" "}
              <li className="breadcrumb-item">Create Yacht Club</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Yacht Club </h4>
                <Form onSubmit={Createyachtclub}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("name", e.target.value)
                              }
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            About
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("about", e.target.value)
                              }
                              rows="4"
                              cols="50"
                              name="about"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Address
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("address", e.target.value)
                              }
                              rows="4"
                              cols="50"
                              name="address"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("phone", e.target.value)
                              }
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Email
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("email", e.target.value)
                              }
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Website
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("website", e.target.value)
                              }
                              name="website"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Booking Url
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("booking_url", e.target.value)
                              }
                              name="booking_url"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Reviews
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("isAllowReview", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Ratings
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("isAllowRating", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Logo
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"                              
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewLogo && (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) => setCrop(newCrop)}
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "720px",
                                  maxHeight: "600px",
                                }}
                              >
                                <img
                                  ref={imgRef}
                                  src={previewLogo}
                                  onClick={() => showLightBox(previewLogo)}
                                />
                              </ReactCrop>
                            )}

                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Cover
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedCover(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewCover && (
                              <img
                                src={previewCover}
                                onClick={() => showLightBox2(previewCover)}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Feature
                          </label>
                          <div className="col-sm-6">
                            <MultiselectCommon
                              options={featureList}
                              onSelect={msUpdateFeature}
                              selectedValues={[]}
                              placeholder="Select Feature"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Time
                          </label>
                          <div className="col-sm-6">
                            {days.map((day, index) => (
                              <DayTimePicker
                                updateTiming={updateTiming}
                                currentValue={timeOptions[index]}
                                index={index}
                                key={index}
                                day={day}
                              />
                            ))}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Select Location
                          </label>
                          <div className="col-sm-6">
                            <LocationPicker
                              containerElement={
                                <div style={{ height: "100%" }} />
                              }
                              mapElement={<div style={{ height: "600px" }} />}
                              defaultPosition={currentLocation}
                              onChange={handleLocationChange}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Latitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="latitude"
                              min={0}
                              value={yachtData?.latitude}
                              onChange={(e) =>
                                updateLocation(e.target.value, "lat")
                              }
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-email-input"
                            className="col-sm-3 col-form-label"
                          >
                            Longitude
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number" // Change the type to "number"
                              step="any"
                              name="longitude"
                              min={0}
                              value={yachtData?.longitude}
                              onChange={(e) =>
                                updateLocation(e.target.value, "lng")
                              }
                              className="form-control"
                              id="horizontal-email-input"
                            />
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewCover}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
