import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { parseISO, format } from "date-fns";
import OfferPackageModal from "../../../components/Modal/OfferPackageModal.js";

import {
  Row,
  Col,
  Card,
  CardBody, 
  Form, 
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  putRequestForm,
  postRequestForm,
  deleteRequest,
  pushUpdates,
  putRequest,
} from "../../../components/Common/Utils.js";
//import DateTimePicker from 'react-datetime-picker';

import PackageModal from "components/Modal/PackageModal.js";

import MultiSelect from "../../../components/Multiselect/MultiselectCommon.js";

import VenueModal from "../../../components/Modal/VenueModalOrg.js";
import EventOrgModal from "../../../components/Modal/EventOrgModal.js";
import ActivityProviderModal from "../../../components/Modal/ActivityProviderModal.js";
import Lightbox from "react-image-lightbox";
import IconPackModal from "components/Modal/IconPackModal.js";
import FileUpload from "components/Common/FileUpload.js";
export default function UpdateVoucher() {
  const location = useLocation();
  const state = location.state?.row;

  const [voucherData, setVoucherData] = useState({ type: "video" });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [iconModal, setIconModal] = useState(false);

  const [selectedIcon, setSelectedIcon] = useState("");
  const [venueList, setVenueList] = useState([]);

  const [openPackage, setOpenPackage] = useState(false);

  const [dayList, setDayList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);

  const [defaultDays, setDefaultDays] = useState([]);

  const [listFetched, setListFetched] = useState(false);

  const [open, setOpen] = useState(false);
  const [packages, setPackages] = useState([]);

  const [model, setModel] = useState(false);
  const [modelOrg, setModelOrg] = useState(false);
  const [modelActivity, setModelActivity] = useState(false);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const [eventOrgId, setEventOrgId] = useState("");
  const [eventOrgText, setEventOrgText] = useState("");
  const [eventOrgImage, setEventOrgImage] = useState("");

  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");


  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [discountList, setDiscountList] = useState([]);
  const [startLoader, setStartLoader] = useState(false);

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModalVenue();
  };


  const selectRowEventOrg = (id) => {  
    setEventOrgId(id?._id);
    setEventOrgText(id?.name);
    setEventOrgImage(id?.logo);
    closeModalEventOrg();
  };

  const selectRowActivity = (id, name, venue) => {
    setActivityId(venue?._id);
    setActivityText(venue?.name);
    setActivityImage(venue?.logo);    
    closeModalActivity();
  };

  const closeIconModal = () => {
    setIconModal(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };
  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };

  const selectIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const addRow = () => {
    setPackages([...packages, { icon: "", feature: "", iconName: "" }]);
  };

  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);
    setPackages(newPackages);
  };

  const closeModalVenue = () => {
    setModel(false);
  };

  const closeModalEventOrg = () => {
    setModelOrg(false);
  };

  const closeModalActivity = () => {
    setModelActivity(false);
  };

  const toggleModelEventOrg = () => {
    setModelOrg(!modelOrg);
  };

  const toggleModelActivity = () => {
    setModelActivity(!modelActivity);
  };

  const toggleModel = () => {
    setModel(!model);
  };  

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const msUpdateVenue = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedVenue(ids);
  };

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDay(ids);
  };

  useEffect(() => {
    if (!state) return;

    state.allowWhosin = state.allowWhosIn ? 1 : 0;    

    let days = state.days?.split(",") || [];

    if (state?.features?.length) {
      const newPackages = state.features.map((item) => {
        return {
          icon: item.icon,
          feature: item.title,
          saved: true,
        };
      });
      setPackages(newPackages);
    }

    state.type = state.providerType;

    if(state.type == "venue"){      
      setVenueId(state?.venueId);
      setVenueText(state?.venueName);
      setVenueImage(state?.venueLogo);
    }

    if(state.type == "event-org"){
      setEventOrgId(state?.eventOrgId);
      setEventOrgText(state?.eventOrgName);
      setEventOrgImage(state?.eventOrgLogo);
    }

    if(state.type == "activity-provider"){
      setActivityId(state?.activityProviderId);
      setActivityText(state?.activityProviderName);
      setActivityImage(state?.activityProviderLogo);
    }


    let daysFull = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];

    // Filter days based on `days` array from state
    days = daysFull.filter((dayFull) => days.includes(dayFull.id));

    setDefaultDays(days);
    setPreviewImage(state.image);
    setVoucherData(state);

    // If you need to fetch data, call those functions here
    // fetchList();
    // fetchPackages();
  }, [state]);

  let history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!selectedVenue.length) return;
    if (listFetched) return;

    fetchList();
  }, [selectedVenue]);

  useEffect(() => {
    fetchCategoryList();
    const days = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, []);

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);

      const defaultItems = list.filter((item) => {
        return selectedVenue.includes(item.id);
      });
      setListFetched(true);
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("venue/category/list/admin", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list?.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setCategoryList(list);
    }
    setLoading(false);
  };

  const updateVoucher = async (e) => {
    e.preventDefault();
    //return;

    if (
      voucherData.discountedPrice > voucherData.actualPrice ||
      !voucherData.discountedPrice
    ) {
      if (voucherData.discountedPrice > voucherData.actualPrice) {
        toast.error("Discounted price should be less than actual price");
      } else {
        toast.error("Please enter discounted price");
      }
      return;
    }







    // check discounted value 

    if (!voucherData.discountValue) {
      toast.error("Please enter discount value");
      return;
    }

    // check discount value is less than 100

    if (voucherData.discountValue > 100) {
      toast.error("Discount value should be less than 100");
      return;
    }

    if(!voucherData.type){  
      toast.error("Please select type");
      return;
    }

    // check type venue && venueId is selected

    if(voucherData.type == "venue"){
      if(!venueId){
        toast.error("Please select venue");
        return;
      }
    }

    if(voucherData.type == "event-org"){
      if(!eventOrgId){
        toast.error("Please select event org");
        return;
      }
    }

    if(voucherData.type == "activity-provider"){
      if(!activityId){
        toast.error("Please select activity provider");
        return;
      }
    }   


    // check discount value is less than 0

    if (voucherData.discountValue < 0) {
      toast.error("Discount value should be greater than 0");
      return;
    }

    // check paxPerVoucher value must be 1 

    if (voucherData.paxPerVoucher < 1) {
      toast.error("Pax per voucher must be greater than 0");
      return;
    }

    if (packages?.length < 2) {
      toast.error("Please select atleast 2 features");
      return;
    }



    for (let i = 0; i < packages.length; i++) {
      if (!packages[i].icon) {
        toast.error("Please select icon for feature " + (i + 1));
        return;
      }

      if (!packages[i].feature) {
        toast.error("Please enter title for feature " + (i + 1));
        return;
      }
    }




    const newPackages = packages.map((item) => {
      return {
        title: item.feature,
        icon: item.icon,
      };
    });

    // combine date and time
    let startDate = null;
    let endDate = null;

    if (voucherData.startDate) {
      if (voucherData.startTime) {
        startDate = new Date(
          voucherData.startDate + " " + voucherData.startTime
        );
      } else {
        startDate = new Date(voucherData.startDate + " " + "00:00");
      }
    }

    if (voucherData.endDate) {
      if (voucherData.endTime) {
        endDate = new Date(voucherData.endDate + " " + voucherData.endTime);
      } else {
        endDate = new Date(voucherData.endDate + " " + "00:00");
      }
    }
    setStartLoader(true);
    const x = new FormData();
    var imgres = null;

    if (selectedImage) {
      x.append("image", selectedImage);
      imgres = await postRequestForm("comman/img/upload", x);
    }
    setStartLoader(false);
    setLoading(true);

    const dataObj = {
      dealPackageId: id,
      title: voucherData.title,
      description: voucherData.description,      
      providerType: voucherData.type,
      categoryId: voucherData.categoryId,
      days: selectedDay.join(","),
      startDate: voucherData.startDate,
      startTime: voucherData.startTime,
      endDate: voucherData.endDate,
      endTime: voucherData.endTime,
      actualPrice: voucherData.actualPrice,
      discountedPrice: voucherData.discountedPrice,
      paxPerVoucher: voucherData.paxPerVoucher,
      discountValue: voucherData.discountValue,
      features: newPackages,
    };


    if(voucherData.type == "venue"){
      dataObj.venueId = venueId;
    }

    if(voucherData.type == "event-org"){
      dataObj.eventOrgId = eventOrgId;
    }

    if(voucherData.type == "activity-provider"){
      dataObj.activityProviderId = activityId;
    }

    if (imgres?.data?.url) {
      dataObj.image = imgres?.data?.url;
    }

    const response = await postRequest(
      "homeblock/deal-package/update",
      dataObj
    );
    setLoading(false);

    if (response.status == 1) {
      // If the update is successful, call the pushUpdates function
      toast.success("Voucher updated successfully");

      setTimeout(() => {
        // history.push("/offer");
        history.goBack();
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...voucherData };
    if (index == 'discountValue') {
      if (!list.actualPrice) {
        toast.error("Please Enter Actual Price First");
        return false
      }
      list['discountedPrice'] = list?.actualPrice - (value * list?.actualPrice) / 100;
    }
    if (index == 'actualPrice') {
      if (list.discountValue) {
        list['discountedPrice'] = value - (list.discountValue * value) / 100;
      }else{
        list['discountedPrice'] = value 
     }
    }
    
    list[index] = value;
    setVoucherData(list);
  };

  const addPackage = () => {
    setOpenPackage(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "",description:"", isFeatured: false },
    // ]);
  };

  const addPackageData = (data) => {
    setPackages([...packages, data]);
    setOpenPackage(false);
  };

  const updatePackageData = (index, field, value) => {
    const newPackages = [...packages];
    newPackages[index][field] = value;
    setPackages(newPackages);
  };

  const closePackageModal = () => {
    setOpenPackage(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Deal Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Deal Management</li>:{" "}
              <li className="breadcrumb-item">Update Deal</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Deal </h4>
                <Form onSubmit={updateVoucher}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              value={voucherData.title}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={voucherData.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Category
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("categoryId", e.target.value)
                              }
                              value={voucherData.categoryId}
                              name="categoryId"
                              class="form-select"
                            >
                              <option value="">Select Category</option>
                              {categoryList?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              // defaultValue={"venue"}
                              value={voucherData.type}
                              name="categoryId"
                              class="form-select"
                            >
                              <option value="">Select Type</option>
                              <option value="event-org">Event Org</option>
                              <option value="activity-provider">Activity Provider</option>
                              <option value="venue">Venue</option>
                            </select>
                          </div>
                        </div>


                        {
                          voucherData.type == "venue" && (
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Venue
                            </label>
  
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readOnly
                                    value={venueText}
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              ) : (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Venue
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No venue chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          )
                        }


                        {
                          voucherData.type == "event-org" && (
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Event Org
                            </label>
  
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModelEventOrg()}
                            >
                              {eventOrgText ? (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelEventOrg()}
                                  >
                                    Choose Event Org
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={eventOrgImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readOnly
                                    value={eventOrgText}
                                    id="selectedVenue"
                                    placeholder="No Event org chosen"
                                  />
                                </div>
                              ) : (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelEventOrg()}
                                  >
                                    Choose Event Org
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No Event org chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          )
                        } 

{
                          voucherData.type == "activity-provider" && (
                            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Activty Provider
                            </label>
  
                            <div
                              className="col-sm-6"
                              onClick={() => toggleModelActivity() }
                            >
                              {activityText ? (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelActivity()}
                                  >
                                    Choose Activty Provider
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={activityImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readOnly
                                    value={activityText}
                                    id="selectedVenue"
                                    placeholder="No Activity Provider chosen"
                                  />
                                </div>
                              ) : (
  
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#e60489",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleModelActivity()}
                                  >
                                    Choose Activty Provider
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No Activity Provider chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          )
                        }  

                       


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Days
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={dayList}
                              onSelect={msUpdateDay}
                              onRemove={msUpdateDay}
                              selectedValues={defaultDays}
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Actual Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("actualPrice", e.target.value)
                              }
                              value={voucherData.actualPrice}
                              name="actualPrice"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>{" "}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Value (In Percent)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("discountValue", e.target.value)
                              }
                              value={voucherData.discountValue}
                              name="discountValue"
                              min={5}
                              max={100}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discounted Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("discountedPrice", e.target.value)
                              }
                              value={voucherData.discountedPrice}
                              name="discountedPrice"
                              min={0}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>{" "}
                      
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Per Person Voucher
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("paxPerVoucher", e.target.value)
                              }
                              value={voucherData.paxPerVoucher}
                              name="paxPerVoucher"                              
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("startDate", e.target.value)
                              }
                              name="startDate"
                              value={voucherData.startDate}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("startTime", e.target.value)
                              }
                              name="startTime"
                              value={voucherData.startTime}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("endDate", e.target.value)
                              }
                              name="endDate"
                              value={voucherData.endDate}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("endTime", e.target.value)
                              }
                              name="endTime"
                              value={voucherData.endTime}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                onClick={() => showLightBox(previewImage)}
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        </div>

                        

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Feature List
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Select Feature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.icon && (
                                            <img
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              src={item.icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.icon && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${
                                          item?.feature || item?.title || ""
                                        }`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "feature",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Feature
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      <VenueModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />

      <EventOrgModal
        show={modelOrg}
        closeEventOrgModal={closeModalEventOrg}
        selectEventOrgRow={selectRowEventOrg}
      />

      <ActivityProviderModal
        show={modelActivity}
        closeModal={closeModalActivity}
        selectRow={selectRowActivity}
      />


      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
      />

      <OfferPackageModal
        show={openPackage}
        closeModal={closePackageModal}
        addData={addPackageData}
      />

      {/* modal */}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
