import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CreatePromo.css";

import { postRequest } from "../../components/Common/Utils.js";
import MultipleSelectUserList from "components/Modal/MultipleUserSelect.js";
import MultipleSelectDealList from "components/Modal/MultipleDealSelect.js";
import MultipleSelectOfferList from "components/Modal/MultipleOfferSelect.js";
import MultipleSelectPackageList from "components/Modal/MultipleSelectPackageList.js";

import SelectedUsers from "./SelectedUsers.js";
import SelectedDeals from "./SelectedDeals.js";
import SelectedOffers from "./SelectedOffers.js";
import SelectedPackages from "./SelectedPackages.js";

export default function CreatePackage() {
  const history = useHistory();

  // -------------------- STATE --------------------
  const [loading, setLoading] = useState(false);

  // Main form data
  const [promoData, setPromoData] = useState({
    // Basic details
    title: "",
    description: "",
    applyOn: "cumulative", // default 
    claimCode: "",
    quantity: "",

    // applyOn => "totalPrice" or "discountedPrice"
    applyOn: "cumulative",

    // User scope
    userScope: "all", // "all" | "specific"

    // If userScope=all => single discount fields
    discountType: "flat",
    discount: "",
    maxDiscount: "",
    minPurchase: "",
    maxApplyPerUser: "",

    // Items scope
    applyToAllItems: true, // true = apply to all, false = select specific
    
    startDate: null,
    endDate: null,
  });

  // Real-time Claim Code validation
  const [claimCodeStatus, setClaimCodeStatus] = useState({ status: "", message: "" });

  // If userScope=specific => store selected users w/ discount fields
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  // Items selection
  const [selectDeals, setSelectDeals] = useState(false);
  const [selectOffers, setSelectOffers] = useState(false);

  const [selectedDealIds, setSelectedDealIds] = useState([]);
  const [selectedOfferIds, setSelectedOfferIds] = useState([]);
  const [selectedPackageIds, setSelectedPackageIds] = useState([]);

  // Modals
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [dealModalOpen, setDealModalOpen] = useState(false);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [packageModalOpen, setPackageModalOpen] = useState(false);

  // -------------------- HANDLERS --------------------
  const updateField = (field, value) => {
    setPromoData((prev) => ({ ...prev, [field]: value }));
  };

  // Claim Code Check
  const handleClaimCodeChange = async (e) => {
    const code = e.target.value;
    updateField("claimCode", code);

    // Clear if empty
    if (!code) {
      setClaimCodeStatus({ status: "", message: "" });
      return;
    }

    // Basic length check
    if (code.length < 4 || code.length > 8) {
      setClaimCodeStatus({
        status: "error",
        message: "Claim code must be 4-8 characters",
      });
      return;
    }

    try {
      const response = await postRequest("venue/promo-code/check", {
        claimCode: code,
      });
      if (response.status === 1) {
        // Code available
        setClaimCodeStatus({ status: "success", message: response.message });
      } else {
        // Code not available
        setClaimCodeStatus({ status: "error", message: response.message });
      }
    } catch (err) {
      console.error(err);
      setClaimCodeStatus({
        status: "error",
        message: "Error checking promo code",
      });
    }
  };

  // Modal toggles
  const toggleUserModal = () => setUserModalOpen(!userModalOpen);
  const toggleDealModal = () => setDealModalOpen(!dealModalOpen);
  const toggleOfferModal = () => setOfferModalOpen(!offerModalOpen);
  const togglePackageModal = () => setPackageModalOpen(!packageModalOpen);

  // User selection
  const onSelectedUser = (list) => {
    // Add default discount fields if needed:
    const enrichedList = list.map((u) => ({
      ...u,
      discountType: "flat",
      discount: "",
      maxDiscount: "",
      minPurchase: "",
      maxApplyPerUser: "",
    }));
    setSelectedUserIds(enrichedList);
  };
  const popSelectedUser = (id) => {
    setSelectedUserIds((prev) => prev.filter((item) => item._id !== id));
  };

  // Deals
  const onSelectedDeal = (list) => setSelectedDealIds(list);
  const popSelectedDeal = (id) => {
    setSelectedDealIds((prev) => prev.filter((item) => item._id !== id));
  };

  // Offers
  const onSelectedOffer = (list) => setSelectedOfferIds(list);
  const popSelectedOffer = (id) => {
    setSelectedOfferIds((prev) => prev.filter((item) => item._id !== id));
  };

  // Packages
  const onSelectedPackage = (list) => setSelectedPackageIds(list);
  const popSelectedPackage = (id) => {
    setSelectedPackageIds((prev) => prev.filter((item) => item._id !== id));
  };

  // Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!promoData.title) {
      toast.error("Title is required");
      return;
    }
    if (!promoData.description) {
      toast.error("Description is required");
      return;
    }
    if (!promoData.claimCode) {
      toast.error("Claim code is required");
      return;
    }
    if (claimCodeStatus.status === "error") {
      toast.error("Claim code is not available");
      return;
    }
    if (!promoData.quantity || promoData.quantity < 1) {
      toast.error("Quantity must be at least 1");
      return;
    }
    if (!promoData.startDate || !promoData.endDate) {
      toast.error("Both start and end dates are required");
      return;
    }
    if (promoData.startDate >= promoData.endDate) {
      toast.error("Start date cannot be after end date");
      return;
    }

    // If "all" users => check discount fields
    let users = [];
    if (promoData.userScope === "all") {
      const { discount, maxDiscount, minPurchase, maxApplyPerUser } = promoData;
      if (!discount || +discount <= 0) {
        toast.error("Discount must be greater than 0");
        return;
      }
      if (!maxDiscount || +maxDiscount <= 0) {
        toast.error("Max discount must be greater than 0");
        return;
      }
      if (!minPurchase || +minPurchase <= 0) {
        toast.error("Minimum purchase must be greater than 0");
        return;
      }
      if (!maxApplyPerUser || +maxApplyPerUser < 1) {
        toast.error("Max usage per user must be at least 1");
        return;
      }
    }

    // If "specific" users => must have at least 1 user with valid discount fields
    if (promoData.userScope === "specific") {
      if (!selectedUserIds.length) {
        toast.error("Please select at least one user");
        return;
      }
      // Validate each
      for (let u of selectedUserIds) {
        if (!u.discount || +u.discount <= 0) {
          toast.error(`User [${u.name}] discount invalid`);
          return;
        }
        if (!u.maxDiscount || +u.maxDiscount <= 0) {
          toast.error(`User [${u.name}] max discount invalid`);
          return;
        }
        if (!u.minPurchase || +u.minPurchase <= 0) {
          toast.error(`User [${u.name}] min purchase invalid`);
          return;
        }
        if (!u.maxApplyPerUser || +u.maxApplyPerUser < 1) {
          toast.error(
            `User [${u.name}] max usage per user must be at least 1`
          );
          return;
        }
      }
      // Build user data
      users = selectedUserIds.map((u) => ({
        userId: u._id,
        discountType: u.discountType,
        discount: +u.discount,
        maxDiscount: +u.maxDiscount,
        minPurchase: +u.minPurchase,
        maxApplyPerUser: +u.maxApplyPerUser,
      }));
    }

    // If applyToAllItems is false => gather IDs if checkboxes selected
    let dealIds = [];
    let packageIds = [];
    if (!promoData.applyToAllItems) {
      // If user checked "Deals"
      if (selectDeals) {
        dealIds = selectedDealIds.map((d) => d._id);
      }
      // If user checked "Offers & Packages"
      if (selectOffers) {
        // Offers + Packages
        packageIds = [
          ...selectedOfferIds.map((o) => o._id),
          ...selectedPackageIds.map((p) => p._id),
        ];
      }
    }

    // Build final payload
    const finalPayload = {
      title: promoData.title,
      description: promoData.description,
      claimCode: promoData.claimCode,
      qty: +promoData.quantity,

      // New field: applyOn => "totalPrice" or "discountedPrice"
      applyType: promoData.applyOn,

      // userScope => "public" or "private" in your original system
      type: promoData.userScope === "all" ? "public" : "private",

      // discount fields (public only)
      discountType: promoData.userScope === "all" ? promoData.discountType : undefined,
      discount: promoData.userScope === "all" ? +promoData.discount : undefined,
      maxDiscount: promoData.userScope === "all" ? +promoData.maxDiscount : undefined,
      minPurchase: promoData.userScope === "all" ? +promoData.minPurchase : undefined,
      maxApplyPerUser:
        promoData.userScope === "all" ? +promoData.maxApplyPerUser : undefined,

      // if private => user array
      users,

      // Target scope
      target: promoData.applyToAllItems ? "all" : "specific",
      dealIds,
      packageIds,

      startDate: promoData.startDate,
      endDate: promoData.endDate,
    };

    // Submit
    setLoading(true);
    try {
      const response = await postRequest("venue/promo-code/add", finalPayload);
      setLoading(false);

      if (response.status === 1) {
        toast.success(response.message);
        setTimeout(() => {
          history.push("/promo-code");
        }, 2000);
      } else {
        toast.error(response.message || "Failed to create promo code");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("An error occurred while creating the promo code");
    }
  };

  // -------------------- RENDER --------------------
  return (
    <React.Fragment>
      <ToastContainer />

      <Row className="mb-3 mt-3">
        <Col md={{ size: 8, offset: 2 }}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2>Create Promo Code</h2>
            <Button color="primary" onClick={() => history.goBack()}>
              <i className="fas fa-chevron-left me-1" />
              Back
            </Button>
          </div>

          {loading && (
            <div className="text-center my-4">
              <Spinner color="info" style={{ width: "4rem", height: "4rem" }} />
            </div>
          )}

          {!loading && (
            <Form onSubmit={handleSubmit}>

              {/* Card: "Apply On" Radios */}
              <Card className="shopify-card">
                <CardBody>
                  <CardTitle tag="h5" className="mb-3">
                    Promo Code Apply On
                  </CardTitle>

                  <div className="mb-2"> 
                    <Label check>
                      <Input
                        type="radio"
                        name="applyOn"
                        value="cumulative"
                        checked={promoData.applyOn === "cumulative"}
                        onChange={(e) => updateField("applyOn", e.target.value)}
                      />
                      <span className="ms-2">Cumulative</span>
                    </Label>
                  </div>
                  <div className="mb-2">
                    <Label check>
                      <Input
                        type="radio"
                        name="applyOn"
                        value="on-discounted-price"
                        checked={promoData.applyOn === "on-discounted-price"}
                        onChange={(e) => updateField("applyOn", e.target.value)}
                      />
                      <span className="ms-2">On Discounted Price</span>
                    </Label>
                  </div>
                  <div >
                    <Label check>
                      <Input
                        type="radio"
                        name="applyOn"
                        value="override"
                        checked={promoData.applyOn === "override"}
                        onChange={(e) => updateField("applyOn", e.target.value)}
                      />
                      <span className="ms-2">Override</span>
                    </Label>
                  </div>
                
                </CardBody>
              </Card>

              {/* Card 1: Basic Details */}
              <Card className="shopify-card">
                <CardBody>
                  <CardTitle tag="h5" className="mb-3">
                    Basic Details
                  </CardTitle>

                  <div className="mb-3">
                    <Label for="title">Title</Label>
                    <Input
                      id="title"
                      value={promoData.title}
                      onChange={(e) => updateField("title", e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Label for="description">Description</Label>
                    <Input
                      id="description"
                      type="textarea"
                      value={promoData.description}
                      onChange={(e) => updateField("description", e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <Label for="claimCode">Claim Code</Label>
                    <Input
                      id="claimCode"
                      value={promoData.claimCode}
                      maxLength={8}
                      onChange={handleClaimCodeChange}
                    />
                    {claimCodeStatus.message && (
                      <small
                        style={{
                          color:
                            claimCodeStatus.status === "success"
                              ? "green"
                              : "red",
                        }}
                      >
                        {claimCodeStatus.message}
                      </small>
                    )}
                  </div>

                  <div className="mb-3">
                    <Label for="quantity">Quantity</Label>
                    <Input
                      id="quantity"
                      type="number"
                      min={1}
                      value={promoData.quantity}
                      onChange={(e) => updateField("quantity", e.target.value)}
                    />
                  </div>
                </CardBody>
              </Card>

              {/* Card 2: Select Users */}
              <Card className="shopify-card">
                <CardBody>
                  <CardTitle tag="h5" className="mb-3">
                    Select Users
                  </CardTitle>

                  <div className="mb-3">
                    <Label check>
                      <Input
                        type="radio"
                        name="userScope"
                        value="all"
                        checked={promoData.userScope === "all"}
                        onChange={() => updateField("userScope", "all")}
                      />
                      <span className="ms-2">All Users</span>
                    </Label>
                  </div>

                  <div className="mb-3">
                    <Label check>
                      <Input
                        type="radio"
                        name="userScope"
                        value="specific"
                        checked={promoData.userScope === "specific"}
                        onChange={() => updateField("userScope", "specific")}
                      />
                      <span className="ms-2">Specific Users</span>
                    </Label>
                  </div><br />

                  {/* If All Users => discount fields */}
                  {promoData.userScope === "all" && (
                    <>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Discount Type</Label>
                            <Input
                              type="select"
                              value={promoData.discountType}
                              onChange={(e) => updateField("discountType", e.target.value)}
                            >
                              <option value="flat">Flat</option>
                              <option value="percentage">Percentage</option>
                            </Input>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Discount</Label>
                            <Input
                              type="number"
                              min={1}
                              value={promoData.discount}
                              onChange={(e) => updateField("discount", e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Max Discount</Label>
                            <Input
                              type="number"
                              min={1}
                              value={promoData.maxDiscount}
                              onChange={(e) =>
                                updateField("maxDiscount", e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Min Purchase</Label>
                            <Input
                              type="number"
                              min={1}
                              value={promoData.minPurchase}
                              onChange={(e) =>
                                updateField("minPurchase", e.target.value)
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-3">
                        <Label>Max Apply Per User</Label>
                        <Input
                          type="number"
                          min={1}
                          value={promoData.maxApplyPerUser}
                          onChange={(e) =>
                            updateField("maxApplyPerUser", e.target.value)
                          }
                        />
                      </div>
                    </>
                  )}

                  {/* If Specific Users => show modal selection + user-based discount */}
                  {promoData.userScope === "specific" && (
                    <>
                      <Button color="info" onClick={toggleUserModal} className="mb-3">
                        <i className="fa fa-plus me-1" />
                        Select Users
                      </Button>

                      {selectedUserIds.length > 0 && (
                        <SelectedUsers
                          selectedUser={selectedUserIds}
                          deleteHandler={popSelectedUser}
                          onUpdate={(updatedData) => setSelectedUserIds(updatedData)}
                        />
                      )}
                    </>
                  )}
                </CardBody>
              </Card>

              {/* Card 3: Select Offer Packages & Deals */}
              <Card className="shopify-card">
                <CardBody>
                  <CardTitle tag="h5" className="mb-3">
                    Select Offer Packages &amp; Deals
                  </CardTitle>

                  {/* Radio Buttons for "All" or "Select" */}
                  <div className="mb-2">
                    <Label check>
                      <Input
                        type="radio"
                        name="itemsScope"
                        checked={promoData.applyToAllItems === true}
                        onChange={() => updateField("applyToAllItems", true)}
                      />
                      <span className="ms-2">Apply On All Packages &amp; Deals</span>
                    </Label>
                  </div>
                  <div>
                    <Label check>
                      <Input
                        type="radio"
                        name="itemsScope"
                        checked={promoData.applyToAllItems === false}
                        onChange={() => updateField("applyToAllItems", false)}
                      />
                      <span className="ms-2">Select Packages &amp; Deals</span>
                    </Label>
                  </div>

                  {/* If user chooses to "Select Packages & Deals," show checkboxes */}
                  {!promoData.applyToAllItems && (
                    <div className="mt-3">
                      <div className="d-flex gap-4 mb-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={selectDeals}
                            onChange={(e) => setSelectDeals(e.target.checked)}
                          />
                          <span className="ms-2">Deals</span>
                        </Label>

                        <Label check>
                          <Input
                            type="checkbox"
                            checked={selectOffers}
                            onChange={(e) => setSelectOffers(e.target.checked)}
                          />
                          <span className="ms-2">Offers &amp; Packages</span>
                        </Label>
                      </div> <br />

                      {/* If "Deals" is checked => show button & selected deals */}
                      {selectDeals && (
                        <div className="mb-3">
                          <Button color="info" onClick={toggleDealModal} className="me-2">
                            <i className="fa fa-plus me-1" />
                            Select Deals
                          </Button> <br /><br />
                          {selectedDealIds.length > 0 && (
                            <SelectedDeals
                              selectedUser={selectedDealIds}
                              deleteHandler={popSelectedDeal}
                            />
                          )}
                        </div>
                      )}

                      {/* If "Offers & Packages" is checked => show buttons & selected items */}
                      {selectOffers && (
                        <>
                          <div className="mb-2">
                            <Button color="info" onClick={togglePackageModal}>
                              <i className="fa fa-plus me-1" />
                              Select Offer Packages
                            </Button><br /><br />
                          </div>
                          {selectedPackageIds.length > 0 && (
                            <SelectedPackages
                              selectedUser={selectedPackageIds}
                              deleteHandler={popSelectedPackage}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>

              {/* Card 4: Active Dates */}
              <Card className="shopify-card">
                <CardBody>
                  <CardTitle tag="h5" className="mb-3">
                    Active Dates
                  </CardTitle>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>Start Date</Label>
                        <DatePicker
                          selected={promoData.startDate}
                          onChange={(date) => updateField("startDate", date)}
                          className="form-control"
                          minDate={new Date()}
                          placeholderText="Select start date"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label>End Date</Label>
                        <DatePicker
                          selected={promoData.endDate}
                          onChange={(date) => updateField("endDate", date)}
                          className="form-control"
                          minDate={new Date()}
                          placeholderText="Select end date"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {/* Submit */}
              <div className="text-end mb-5">
                <Button color="primary" type="submit">
                  Create Promo Code
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>

      {/* Modals */}
      <MultipleSelectUserList
        showUser={userModalOpen}
        onSelectUser={onSelectedUser}
        closeUserModal={() => setUserModalOpen(false)}
      />
      <MultipleSelectDealList
        showUser={dealModalOpen}
        onSelectUser={onSelectedDeal}
        closeUserModal={() => setDealModalOpen(false)}
      />
      <MultipleSelectOfferList
        showUser={offerModalOpen}
        onSelectUser={onSelectedOffer}
        closeUserModal={() => setOfferModalOpen(false)}
      />
      <MultipleSelectPackageList
        showUser={packageModalOpen}
        withPackages={true}
        offerIds={selectedOfferIds.map((item) => item._id)}
        onSelectUser={onSelectedPackage}
        closeUserModal={() => setPackageModalOpen(false)}
      />
    </React.Fragment>
  );
}
