import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  postRequestForm,
  pushUpdates,
} from "../../components/Common/Utils.js";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// import './venueTime.css';

import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import Lightbox from "react-image-lightbox";
import SubAdminModal from "../../components/Modal/SubAdminModal.js";
import PromoterModal from "components/Modal/PromoterModal.js";
import FileUpload from "../../components/Common/FileUpload";

import OfferPackageModal from "../../components/Modal/OfferPackageModal.js";
import { set } from "lodash";

export default function CreateSubAdmin() {
  const location = useLocation();
  const state = location.state?.row;

  const [validTimeRange, setValidTimeRange] = useState({ startTime: '', endTime: '', range: '' });

  const [offerData, setHomeBlockData] = useState({ type: "video" });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [venueList, setVenueList] = useState([]);

  const [venueTimings, setVenueTimings] = useState([]);
  const [venueDays, setVenueDays] = useState([]);

  const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];





  const [openPackage, setOpenPackage] = useState(false);

  const [dayList, setDayList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState("");

  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);
  const [dateInputValue, setDateInputValue] = useState('');

  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(false);
  const [modelPromoter, setModelPromoter] = useState(false);
  const [packages, setPackages] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [promoterId, setPromoterId] = useState("");
  const [promoterText, setPromoterText] = useState("");
  const [promoterImage, setPromoterImage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [discountList, setDiscountList] = useState([]);

  const [defaultCategory, setDefaultCategory] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingIndex1, setEditingIndex1] = useState(null);
  const [currentPackageData, setCurrentPackageData] = useState({});

  const [modelHoliday, setModelHoliday] = useState(false);

  const [selectedHoliday, setSelectedHoliday] = useState([]);



  const today = new Date().toISOString().split("T")[0];

  // apply useeffect for venueDays

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    return `${((+hours % 12) || 12)}:${minutes} ${+hours < 12 ? 'AM' : 'PM'}`;
  };

  const isClosed = (timing) => !timing;

  // Function to render the status badge
  const renderStatusBadge = (isOpen) => (
    <span style={{
      display: 'inline-block',
      padding: '0.25em 0.6em',
      fontSize: '75%',
      fontWeight: '700',
      lineHeight: '1',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'baseline',
      borderRadius: '0.25rem',
      color: '#fff',
      backgroundColor: isOpen ? '#28a745' : '#dc3545',
    }}>
      {isOpen ? 'OPENING' : 'CLOSING'}
    </span>
  );

  useEffect(() => {
    console.log("venueTimings", venueTimings);
  }, [venueTimings]);




  useEffect(() => {
    const calculateValidTimeRange = (selected) => {
      const filteredTimings = venueTimings.filter(timing => selected.includes(timing.day));

      const maxStartTime = Math.max(...filteredTimings.map(timing => new Date(`1970/01/01 ${timing.openingTime}`).getTime()));
      const minEndTime = Math.min(...filteredTimings.map(timing => new Date(`1970/01/01 ${timing.closingTime}`).getTime()));

      const validStartTime = new Date(maxStartTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
      const validEndTime = new Date(minEndTime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
      const validRange = `${validStartTime} - ${validEndTime}`;

      setValidTimeRange({ startTime: validStartTime, endTime: validEndTime, range: validRange });
    };

    if (!selectedDay.length) return;
    calculateValidTimeRange(selectedDay);
  }, [selectedDay, venueTimings]);


  useEffect(() => {
    console.log("ValidTimeRange", validTimeRange);
  }, [validTimeRange]);



  useEffect(() => {
    if (!selectedDay.length) return;

    console.log("selectedDay", selectedDay);

    // Function to find the next date for any of the selected days
    const getNextDate = (days) => {
      const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      let today = new Date();
      let nextDate = new Date();

      // Find the next date that matches one of the selected days
      for (let i = 0; i < 7; i++) {
        const dayIndex = (today.getDay() + i) % 7;
        const dayName = daysOfWeek[dayIndex];

        if (days.includes(dayName)) {
          nextDate.setDate(today.getDate() + i);
          return nextDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
        }
      }

      return today.toISOString().split('T')[0];
    };

    // Update date input based on selected days
    const nextDate = getNextDate(selectedDay);
    setDateInputValue(nextDate);

  }, [selectedDay]);

  const isDayEnabled = (date) => {
    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const dayName = daysOfWeek[date.getDay()];
    return selectedDay.includes(dayName);
  };

  useEffect(() => {
    if (!venueDays) return;

    const fullDayNames = {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday'
    };

    const days = venueDays.map((abbr) => {
      const day = fullDayNames[abbr.toLowerCase()];
      return { id: abbr, name: day || abbr };
    });
    setDayList(days);
    setSelectedDay([]);
  }, [venueDays]);

  const fetchDiscount = async () => {
    setLoading(true);
    const response = await postRequest("discount/list", { limit: 10000000 });
    if (response.status == 1) {
      setDiscountList(response.data.list);
    }
    setLoading(false);
  };

  const checkOfferTimes = (offerData, validTimeRange) => {
    // Helper function to convert time string to date object
    const timeStringToDate = (timeString) => {
      const [hours, minutes] = timeString?.split(':');
      return new Date(0, 0, 0, parseInt(hours, 10), parseInt(minutes, 10));
    };

    // Function to check if a given time is within the range
    const isTimeInRange = (time, startTime, endTime) => {
      return time >= startTime && time <= endTime;
    };

    // Convert the times to Date objects
    const offerStartTime = timeStringToDate(offerData.startTime);
    const offerEndTime = timeStringToDate(offerData.endTime);
    const validStartTime = timeStringToDate(validTimeRange.startTime);
    const validEndTime = timeStringToDate(validTimeRange.endTime);

    // Check if the offer start and end times are within the valid time range
    const isStartTimeValid = isTimeInRange(offerStartTime, validStartTime, validEndTime);
    const isEndTimeValid = isTimeInRange(offerEndTime, validStartTime, validEndTime);

    return { isStartTimeValid, isEndTimeValid };
  };


  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const selectRow = (id, name, venue) => {
   
   const userName = `${venue?.first_name} ${venue?.last_name}`;
    setSelectedDay([]);
    setVenueId(id);
    setVenueText(userName);    
    closeModalVenue();
    console.log("venue", venue);

  };

  const toggleModel = () => {
    setModel(!model);
  };

  const closeModalVenue = () => {
    setModel(false);
  };

  const selectRow2 = (id, name, promoter,data) => {
   
    const userName = `${id?.first_name}`;
    
     setPromoterId(id?._id);
     setPromoterText(userName);  
     setPromoterImage(id?.image);  
     closeModalPromoter();
     console.log("promoter", id);
 
   };

  const toggleModel2 = () => {
    setModelPromoter(!modelPromoter);
  };

  const closeModalPromoter = () => {
    setModelPromoter(false);
  };

  const toggleModelHoliday = () => {
    setModelHoliday(!modelHoliday);
  };

  const closeModalHoliday = () => {
    setModelHoliday(false);
  };

  const selectHoliday = (items) => {

    setSelectedHoliday(items);
    closeModalHoliday();
    console.log("items", items);

  };

  const deleteHoliday = (index) => {
    const newHoliday = selectedHoliday.filter((_, idx) => idx !== index);
    setSelectedHoliday(newHoliday);
  };

  const closePackageModal = () => {
    setOpenPackage(false);
  };

  //const [day]

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDay(ids);
  };

  const msUpdateCategory = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);
  };

  const updateDiscount = (index, value) => {
    const list = [...packages];
    list[index].discount = value;
    setPackages(list);
  };

  let history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!state) return;

    if (state?.venueId) {
      setVenueId(state?.venueId);
      setVenueText(state?.venueName);
      setVenueImage(state?.venueLogo)
    }

    if (state?.promoterId) {
      setPromoterId(state?.promoterId);
      setPromoterText(state?.promoterName);
      setPromoterImage(state?.promoterImage)
    }

    if (state?.categoryId) {
      setCategoryId(state?.categoryId);

      const defaulit = [];
      defaulit.push(state?.categoryId);

      setSelectedCategory([...defaulit]);

      const tempData = { ...offerData };
      tempData.categoryId = state?.categoryId;
      setHomeBlockData(tempData);
    }

  }, [state]);

  useEffect(() => {
    fetchList();
    fetchCategoryList();
    //fetchPackages();
    //fetchDiscount();
    // fetch days

    const days = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, []);

  const fetchPackages = async () => {
    setLoading(true);
    const response = await postRequest("subscription/membership/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setPackages(response.data.list);
    }
    setLoading(false);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);
    }
    setLoading(false);
  };

  const renderDay = (day) => {
    const dayLowerCase = day.toLowerCase();
    const timing = venueTimings.find(t => t.day === dayLowerCase);
    if (timing) {
      return (
        <td style={{ color: 'green' }}>
          {formatTime(timing.openingTime)} to {formatTime(timing.closingTime)}
        </td>
      );
    }
    return <td style={{ color: 'red' }}>Closed</td>;
  };

  function isTimeWithinTimings(offerDate, timings) {
    return true;
    const offerTime = moment(offerDate);
    const openingTime = moment(`${offerDate.split('T')[0]}T${timings.openingTime}`);
    const closingTime = moment(`${offerDate.split('T')[0]}T${timings.closingTime}`);

    console.log("DYA", offerTime, openingTime, closingTime)

    // Adjust for next day if closing time is earlier than opening time
    if (closingTime.isBefore(openingTime)) {
      closingTime.add(1, 'day');
    }

    console.log("DYA", offerTime, openingTime, closingTime)

    return offerTime.isBetween(openingTime, closingTime, null, '[]');
  }

  const fetchCategoryList = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("venue/category/list/admin", param);
    if (category.status == 1) {
      const options = category.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCategoryList(options);
      if (selectedCategory.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCategory.includes(item.id);
        });
        setDefaultCategory(defaultItems);
      }
    }
  };
  const search = location.search;
  const from = new URLSearchParams(search).get("from");

  const AddcreateOfferf = async (e) => {
    e.preventDefault();

   const payload = {
    promoterId: promoterId,
    userId: venueId,
   };
    
    setStartLoader(true);
    const response = await postRequest("promoter/create/subadmin", payload);
    setStartLoader(false);
    setLoading(true);
    setLoading(false);

    if (response.status === 1) {    

      toast.success(response.message);
      setTimeout(() => {
        history.push("/promoter-sub-admin");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...offerData };
    list[index] = value;
    setHomeBlockData(list);
  };

  const addPackage = () => {

    // reset currentPackageData

    setCurrentPackageData({});


    setOpenPackage(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "", isFeatured: false },
    // ]);
  };

  // const addPackageData = (data) => {

  //   // check editingIndex is not -1

  //   if (editingIndex !== -1) {
  //     const newPackages = [...packages];
  //     newPackages[editingIndex] = data;
  //     setPackages(newPackages);
  //     setCurrentPackageData({});
  //     setEditingIndex(-1);
  //     setOpenPackage(false);
  //     return;
  //   }

  //   setPackages([...packages, data]);
  //   setOpenPackage(false);
  // };



  const addPackageData = (data) => {
    let newData = { ...data }; // Make a copy of the data to avoid mutating the original object
    let newPackages = [...packages];
    // If the new package is marked as featured, unselect any previously selected featured packages
    if (newData.packages_isFeatured) {
      newPackages = newPackages.map(pkg => {
        pkg.packages_isFeatured=false
        return pkg;
      });
      // setPackages(newPackages);
    }
  
    // Check if editingIndex is not -1 (i.e., if we're editing an existing package)
    if (editingIndex !== -1) {
      newPackages[editingIndex] = newData;
      setPackages(newPackages);
      setCurrentPackageData({});
      setEditingIndex(-1);
      setOpenPackage(false);
    } else {
      setPackages([...newPackages, newData]);
      setOpenPackage(false);
    }
  };
  
  









  // const updatePackageData = (index, field, value) => {
  //   const newPackages = [...packages];
  //   newPackages[index][field] = value;
  //   setPackages(newPackages);
  // };


  const updatePackageData = (index, field, value) => {
    const newPackages = packages.map((pkg, i) => {
      if (i === index) {
        return { ...pkg, [field]: value };
      } else if (field === "packages_isFeatured" && pkg.packages_isFeatured) {
        // Uncheck any other checkbox if it was checked
        return { ...pkg, packages_isFeatured: false };
      }
      return pkg;
    });
    
    const selectedCount = newPackages.filter(pkg => pkg.packages_isFeatured).length;
  
    if (selectedCount > 1) {
      alert("Please select only one isFeatured checkbox.");
      return;
    }
  
    setPackages(newPackages);
  };
  


  const saveEditedPackage = (index) => {

    // check title and amount is not empty

    if (!packages[index].title) {
      toast.error("Please enter title");
      return;
    }

    if (!packages[index].amount) {
      toast.error("Please enter amount");
      return;
    }

    // check amount is number

    if (isNaN(packages[index].amount)) {
      toast.error("Amount must be number");
      return;
    }

    // check amount is not negative

    if (packages[index].amount < 1) {
      toast.error("Amount must be greater than 0");
      return;
    }

    // check discount 0 - 100

    if (packages[index].discount) {
      if (isNaN(packages[index].discount)) {
        toast.error("Discount must be number");
        return;
      }

      if (packages[index].discount < 5) {
        toast.error("Discount must be 5 or greater than 5 ");
        return;
      }

      if (packages[index].discount > 99) {
        toast.error("Discount must be less than 100");
        return;
      }

    }

    // check qty is number

    if (packages[index].qty) {
      if (isNaN(packages[index].qty)) {
        toast.error("Qty must be number");
        return;
      }

      if (packages[index].qty < 0) {
        toast.error("Qty must be greater than 0");
        return;
      }
    }

    // check cliam amount is <= amount

    if (packages[index].pricePerBrunch) {
      if (isNaN(packages[index].pricePerBrunch)) {
        toast.error("Claim amount must be number");
        return;
      }

      console.log("packages[index].pricePerBrunch", packages[index].pricePerBrunch, packages[index].amount)

      if (packages[index].pricePerBrunch < 0) {
        toast.error("Claim amount must be greater than 0");
        return;
      }

      if (parseInt(packages[index].pricePerBrunch) > parseInt(packages[index].amount)) {
        toast.error("Claim amount must be less than amount");
        return;
      }
    }




    // Add any validation logic here before saving

    // cliam code must be 8 digit
    //   if (packages[index].claimCode){
    //   if(packages[index].claimCode.length !== 8) {
    //     toast.error("Claim code must be  8 digit");
    //     return;

    //   }
    //   if (/^[A-Za-z]{2}\d{6}$/.test(packages[index].claimCode) === false) {
    //     toast.error("Claim code must be 2 letter and 6 digit");
    //     return;
    //   }
    // }

    setEditingIndex(null); // Reset editing index
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((_, idx) => idx !== index);
    setPackages(newPackages);
  };


  const editPackage = (index) => {
    setEditingIndex(index);
    let currentPackageDt = packages[index];
    setCurrentPackageData({ ...currentPackageDt });
    setOpenPackage(true);
  };




  const [allAllowSale, setAllAllowSale] = React.useState(false);
  const allAllowSaleSelected = (e) => {
    let value = e.target.value;
    setAllAllowSale(!allAllowSale)
    if (!allAllowSale) {

      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowSale: true }
      });
      setPackages(updatedPackages);
    } else {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowSale: false }
      });
      setPackages(updatedPackages);
    }
  };
  const [allAllowClaim, setAllAllowClaim] = React.useState(false);
  const allAllowClaimSelected = (e) => {
    let value = e.target.value;
    setAllAllowClaim(!allAllowClaim)
    if (!allAllowClaim) {

      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowClaim: true }
      });
      setPackages(updatedPackages);
    } else {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowClaim: false }
      });
      setPackages(updatedPackages);
    }
  };


  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Promoter SubAdmin Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Promoter Sub Admin Management</li>:{" "}
              <li className="breadcrumb-item">Promoter Create Sub Admin</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
        {state?.type == "category" && (
          <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() =>
                history.push(`categorydetails/${state?.categoryId}`)
              }
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
        )}
      </Row>

      {/* header */}

      {/* form */}
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Promoter SubAdmin </h4>
                <Form onSubmit={AddcreateOfferf}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                       

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select User
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => {
                              if (from) {
                                // Do nothing or handle the case where 'from' is present
                              } else {
                                toggleModel(); // Open the model when 'from' is not present
                              }
                            }}
                          >
                            {venueText ? (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              // <div style={{ minWidth: "100%" }}>
                              //   <a
                              //     href="javascript:void(0)"
                              //     style={{
                              //       textDecoration: "none",
                              //       color: "#5b626b",
                              //       fontSize: "0.8125rem",
                              //       fontWeight: 400,
                              //     }}
                              //   >
                              //     {venueText}
                              //   </a>
                              // </div>
                              // <div>
                              // <a
                              //     onClick={() => toggleModel()}
                              //     href="javascript:void(0)"
                              //   >
                              //     <i
                              //       style={{ color: "#5b626b" }}
                              //       class="fa fa-plus"
                              //     ></i>
                              //   </a>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose User
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                {/* <img
                                  src={venueImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img> */}
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={venueText}
                                  id="selectedVenue"
                                  placeholder="No venue chosen"
                                />
                              </div>
                            ) : (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       Select Venue
                              //     </a>
                              //   </div>
                              //   <div>
                              //     <a
                              //       onClick={() => toggleModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel()}
                                >
                                  Choose User
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No user chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Promoter
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => {
                              if (from) {
                                // Do nothing or handle the case where 'from' is present
                              } else {
                                toggleModel2(); // Open the model when 'from' is not present
                              }
                            }}
                          >
                            {promoterText ? (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              // <div style={{ minWidth: "100%" }}>
                              //   <a
                              //     href="javascript:void(0)"
                              //     style={{
                              //       textDecoration: "none",
                              //       color: "#5b626b",
                              //       fontSize: "0.8125rem",
                              //       fontWeight: 400,
                              //     }}
                              //   >
                              //     {venueText}
                              //   </a>
                              // </div>
                              // <div>
                              // <a
                              //     onClick={() => toggleModel()}
                              //     href="javascript:void(0)"
                              //   >
                              //     <i
                              //       style={{ color: "#5b626b" }}
                              //       class="fa fa-plus"
                              //     ></i>
                              //   </a>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel2()}
                                >
                                  Choose Promoter
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={promoterImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={promoterText}
                                  id="selectedVenue"
                                  placeholder="No promoter chosen"
                                />
                              </div>
                            ) : (
                              // <div
                              //   style={{
                              //     display: "flex",
                              //     border: "1px solid #ced4da",
                              //     padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                              //     borderRadius: "0.25rem",
                              //     lineHeight: 1.5,
                              //   }}
                              // >
                              //   <div style={{ minWidth: "100%" }}>
                              //     <a
                              //       href="javascript:void(0)"
                              //       style={{
                              //         textDecoration: "none",
                              //         color: "#5b626b",
                              //         fontSize: "0.8125rem",
                              //         fontWeight: 400,
                              //       }}
                              //     >
                              //       Select Venue
                              //     </a>
                              //   </div>
                              //   <div>
                              //     <a
                              //       onClick={() => toggleModel()}
                              //       href="javascript:void(0)"
                              //     >
                              //       <i
                              //         style={{ color: "#5b626b" }}
                              //         class="fa fa-plus"
                              //       ></i>
                              //     </a>
                              //   </div>
                              // </div>
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseVenueBtn"
                                  onclick={() => toggleModel2()}
                                >
                                  Choose Promoter
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedVenue"
                                  placeholder="No promoter chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>       

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

   

      <SubAdminModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />

     <PromoterModal
        show={modelPromoter}
        closeModal={closeModalPromoter}
        selectRow={selectRow2}
      />


    

      <FileUpload message="Logo & Cover Uploading" status={startLoader} />

      {/* modal */}
    </React.Fragment>
  );
}
