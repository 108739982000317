import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequest,
  postRequestForm,
  pushUpdates,
} from "../../components/Common/Utils.js";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import './venueTime.css';

import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import Lightbox from "react-image-lightbox";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import HolidayModal from "../../components/Modal/HolidayModal.js";
import FileUpload from "../../components/Common/FileUpload";

import OfferPackageModal from "../../components/Modal/OfferPackageModal.js";
import { set } from "lodash";
import YachtClubModal from "components/Modal/YachtClubModal.js";
import YachtOfferAddModal from "components/Modal/YachtOfferAddModal.js";
import YachtModal from "components/Modal/YachtModal.js";
import YachtPackageAddHourly from "components/Modal/YachtPackageAddHourly.js";

export default function CreateYachtOffer() {
  const location = useLocation();
  const state = location.state?.row;

  const [validTimeRange, setValidTimeRange] = useState({
    startTime: "",
    endTime: "",
    range: "",
  });

  const [offerData, setHomeBlockData] = useState({ type: "video" });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [venueList, setVenueList] = useState([]);
  const [yachtClubId, setYachtClubId] = useState("");
  const [yachtClubText, setYachtClubText] = useState("");
  const [yachtClubImage, setYachtClubImage] = useState("");
  const [venueTimings, setVenueTimings] = useState([]);
  const [venueDays, setVenueDays] = useState([]);
  const [previewCover, setPreviewCover] = useState([]);
  const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  const [openPackage, setOpenPackage] = useState(false);
  const [openPackageHourly, setOpenPackageHourly] = useState(false);

  const [dayList, setDayList] = useState([]);
  const [AddOnsList, setAddOnsList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryText, setCategoryText] = useState("");

  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);
  const [dateInputValue, setDateInputValue] = useState("");

  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(false);
  const [YachtModelShow, setYachtModelShow] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packagesHourly, setPackagesHourly] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [selectedAddonId, setselectedAddonId] = useState([]);
  const [discountList, setDiscountList] = useState([]);

  const [defaultAddOns, setdefaultAddOns] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editingHourlyIndex, setEditingHourlyIndex] = useState(-1);
  const [editingIndex1, setEditingIndex1] = useState(null);
  const [currentPackageData, setCurrentPackageData] = useState({});
  const [currentPackageHourlyData, setCurrentPackageHourlyData] = useState({});

  const [modelHoliday, setModelHoliday] = useState(false);
  const [needToKnow, setNeedToKnow] = useState(['']);

  const [selectedHoliday, setSelectedHoliday] = useState([]);

  const today = new Date().toISOString().split("T")[0];

  // apply useeffect for venueDays

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    return `${+hours % 12 || 12}:${minutes} ${+hours < 12 ? "AM" : "PM"}`;
  };

  const isClosed = (timing) => !timing;

  // Function to render the status badge
  const renderStatusBadge = (isOpen) => (
    <span
      style={{
        display: "inline-block",
        padding: "0.25em 0.6em",
        fontSize: "75%",
        fontWeight: "700",
        lineHeight: "1",
        textAlign: "center",
        whiteSpace: "nowrap",
        verticalAlign: "baseline",
        borderRadius: "0.25rem",
        color: "#fff",
        backgroundColor: isOpen ? "#28a745" : "#dc3545",
      }}
    >
      {isOpen ? "OPENING" : "CLOSING"}
    </span>
  );

  useEffect(() => {
    console.log("venueTimings", venueTimings);
  }, [venueTimings]);

  useEffect(() => {
    const calculateValidTimeRange = (selected) => {
      const filteredTimings = venueTimings.filter((timing) =>
        selected.includes(timing.day)
      );

      const maxStartTime = Math.max(
        ...filteredTimings.map((timing) =>
          new Date(`1970/01/01 ${timing.openingTime}`).getTime()
        )
      );
      const minEndTime = Math.min(
        ...filteredTimings.map((timing) =>
          new Date(`1970/01/01 ${timing.closingTime}`).getTime()
        )
      );

      const validStartTime = new Date(maxStartTime).toLocaleTimeString(
        "en-US",
        { hour: "2-digit", minute: "2-digit", hour12: false }
      );
      const validEndTime = new Date(minEndTime).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const validRange = `${validStartTime} - ${validEndTime}`;

      setValidTimeRange({
        startTime: validStartTime,
        endTime: validEndTime,
        range: validRange,
      });
    };

    if (!selectedDay.length) return;
    calculateValidTimeRange(selectedDay);
  }, [selectedDay, venueTimings]);

  useEffect(() => {
    console.log("ValidTimeRange", validTimeRange);
  }, [validTimeRange]);

  useEffect(() => {
    if (!selectedDay.length) return;

    console.log("selectedDay", selectedDay);

    // Function to find the next date for any of the selected days
    const getNextDate = (days) => {
      const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
      let today = new Date();
      let nextDate = new Date();

      // Find the next date that matches one of the selected days
      for (let i = 0; i < 7; i++) {
        const dayIndex = (today.getDay() + i) % 7;
        const dayName = daysOfWeek[dayIndex];

        if (days.includes(dayName)) {
          nextDate.setDate(today.getDate() + i);
          return nextDate.toISOString().split("T")[0]; // Format to YYYY-MM-DD
        }
      }

      return today.toISOString().split("T")[0];
    };

    // Update date input based on selected days
    const nextDate = getNextDate(selectedDay);
    setDateInputValue(nextDate);
  }, [selectedDay]);

  const isDayEnabled = (date) => {
    const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
    const dayName = daysOfWeek[date.getDay()];
    return selectedDay.includes(dayName);
  };

  useEffect(() => {
    if (!venueDays) return;

    const fullDayNames = {
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
      sat: "Saturday",
      sun: "Sunday",
    };

    const days = venueDays.map((abbr) => {
      const day = fullDayNames[abbr.toLowerCase()];
      return { id: abbr, name: day || abbr };
    });
    setDayList(days);
    setSelectedDay([]);
  }, [venueDays]);

  const fetchDiscount = async () => {
    setLoading(true);
    const response = await postRequest("discount/list", { limit: 10000000 });
    if (response.status == 1) {
      setDiscountList(response.data.list);
    }
    setLoading(false);
  };

  const checkOfferTimes = (offerData, validTimeRange) => {
    // Helper function to convert time string to date object
    const timeStringToDate = (timeString) => {
      const [hours, minutes] = timeString?.split(":");
      return new Date(0, 0, 0, parseInt(hours, 10), parseInt(minutes, 10));
    };

    // Function to check if a given time is within the range
    const isTimeInRange = (time, startTime, endTime) => {
      return time >= startTime && time <= endTime;
    };

    // Convert the times to Date objects
    const offerStartTime = timeStringToDate(offerData.startTime);
    const offerEndTime = timeStringToDate(offerData.endTime);
    const validStartTime = timeStringToDate(validTimeRange.startTime);
    const validEndTime = timeStringToDate(validTimeRange.endTime);

    // Check if the offer start and end times are within the valid time range
    const isStartTimeValid = isTimeInRange(
      offerStartTime,
      validStartTime,
      validEndTime
    );
    const isEndTimeValid = isTimeInRange(
      offerEndTime,
      validStartTime,
      validEndTime
    );

    return { isStartTimeValid, isEndTimeValid };
  };

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const selectRow = (id, name, venue) => {
    setSelectedDay([]);
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    setVenueTimings(venue?.timings);

    const days = venue?.timings?.map((item) => item?.day);
    setVenueDays(days);
    closeModalVenue();
  };

  const toggleModel = () => {
    setModel(!model);
  };

  const closeModalVenue = () => {
    setModel(false);
  };

  const closePackageModal = () => {
    setOpenPackage(false);
  };
  const closeHourlyPackageModal = () => {
    setOpenPackageHourly(false);
  };

  //const [day]

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item?.id);
    setSelectedDay(ids);
  };

  const msUpdateAddOns = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item?.id);
    setselectedAddonId(ids);
  };

  const updateDiscount = (index, value) => {
    const list = [...packages];
    list[index].discount = value;
    setPackages(list);
  };

  let history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (!state) return;

    if (state?.venueId) {
      setVenueId(state?.venueId);
      setVenueText(state?.venueName);
      setVenueImage(state?.venueLogo);
    }

    if (state?.categoryId) {
      setCategoryId(state?.categoryId);

      const defaulit = [];
      defaulit.push(state?.categoryId);

      setselectedAddonId([...defaulit]);

      const tempData = { ...offerData };
      tempData.categoryId = state?.categoryId;
      setHomeBlockData(tempData);
    }
  }, [state]);

  useEffect(() => {
    fetchList();
    fetchAddOnsList();
    //fetchPackages();
    //fetchDiscount();
    // fetch days

    const days = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, []);

  const fetchPackages = async () => {
    setLoading(true);
    const response = await postRequest("subscription/membership/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setPackages(response.data.list);
    }
    setLoading(false);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item?._id,
          name: item?.name,
        };
      });
      setVenueList(list);
    }
    setLoading(false);
  };

  const renderDay = (day) => {
    const dayLowerCase = day.toLowerCase();
    const timing = venueTimings.find((t) => t.day === dayLowerCase);
    if (timing) {
      return (
        <td style={{ color: "green" }}>
          {formatTime(timing.openingTime)} to {formatTime(timing.closingTime)}
        </td>
      );
    }
    return <td style={{ color: "red" }}>Closed</td>;
  };

  function isTimeWithinTimings(offerDate, timings) {
    return true;
    const offerTime = moment(offerDate);
    const openingTime = moment(
      `${offerDate.split("T")[0]}T${timings.openingTime}`
    );
    const closingTime = moment(
      `${offerDate.split("T")[0]}T${timings.closingTime}`
    );

    console.log("DYA", offerTime, openingTime, closingTime);

    // Adjust for next day if closing time is earlier than opening time
    if (closingTime.isBefore(openingTime)) {
      closingTime.add(1, "day");
    }

    console.log("DYA", offerTime, openingTime, closingTime);

    return offerTime.isBetween(openingTime, closingTime, null, "[]");
  }

  const fetchAddOnsList = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("yacht/get/addons/list", param);
    if (category.status == 1) {
      const options = category.data.list.map((item) => {
        return { id: item?._id, name: item?.title };
      });

      setAddOnsList(options);
      if (selectedAddonId.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedAddonId.includes(item?.id);
        });
        setdefaultAddOns(defaultItems);
      }
    }
  };
  const search = location.search;
  const from = new URLSearchParams(search).get("from");

  const AddcreateOfferf = async (e) => {
    e.preventDefault();
    if (!yachtClubId?._id) {
      toast.error("Please Select Yacht");
      return;
    }
    if (!offerData.title) {
      toast.error("Please enter title");
      return;
    }
    if (!offerData.description) {
      toast.error("Please enter Description");
      return;
    }
    if (!offerData.startingAmount) {
      toast.error("Please enter Starting Amount");
      return;
    }

    if (!offerData.startDate) {
      toast.error("Please select start date");
      return;
    }

    if (!offerData.endDate) {
      toast.error("Please select end date");
      return;
    }

    if (!offerData.importantNotice) {
      toast.error("Please enter importantNotice");
      return;
    }
    if (!offerData.disclaimer) {
      toast.error("Please enter disclaimer");
      return;
    }
    if (!selectedAddonId.length) {
      toast.error("Please select AddOns");
      return;
    }
    if (!previewCover.length) {
      toast.error("Please upload Image");
      return;
    }



    const isEmpty = needToKnow.some((slot) => slot.trim() == '');
    if (isEmpty) {
      toast.error("Please  enter needToKnow");
      return;
    }

    if (!offerData.packageType) {
      toast.error("Please select packageType");
      return;
    }

    let updatedPackages = packages?.map((item) => {
      return {
        ...item,
        slots: item?.slots.map((slot) => slot?.availabilityTime),
      };
    });

    if (offerData?.packageType == "fixed") {
      if (!updatedPackages.length) {
        toast.error("Please add atleast one packages");
        return;
      }
    }
    if (offerData?.packageType == "hourly") {
      if (!packagesHourly.length) {
        toast.error("Please add atleast one packages");
        return;
      }
    }
    console.log({ updatedPackages });
    let payLoad = {
      yachtId: yachtClubId?._id,
      title: offerData?.title,
      description: offerData?.description,
      startingAmount: offerData?.startingAmount,
      startDate: offerData?.startDate,
      endDate: offerData?.endDate,
      addOns: selectedAddonId,
      importantNotice: offerData?.importantNotice,
      disclaimer: offerData?.disclaimer,
      images: previewCover,
      packageType: offerData?.packageType,
      packages:
        offerData?.packageType == "fixed" ? updatedPackages : packagesHourly,
      needToKnow: needToKnow,
    };

    setLoading(true);
    const response = await postRequest("yacht/add/offer", payLoad);
    setLoading(false);

    if (response.status === 1) {
      try {
        const pushUpdatesResponse = await pushUpdates({
          type: "offer",
          id: response.data._id,
          receivers: [],
        });
      } catch (error) {
        console.log("pushUpdates error:");
      }

      toast.success(response.message);
      setTimeout(() => {
        history.push("/yacht-offer-list");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...offerData };
    list[index] = value;
    setHomeBlockData(list);
  };

  const updateNeedtoKnowValue = (index, value) => {
    setNeedToKnow((prevState) => {
      const updatedNeedToKnow = [...prevState];
      updatedNeedToKnow[index] = value;
      return updatedNeedToKnow;
    });
  };

  const deleteNeed = (index) => {
    setNeedToKnow((prevState) => {
      const updatedNeedToKnow = [
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1),
      ];
      return updatedNeedToKnow;
    });
  };

  const addNeedSlot = () => {
    setNeedToKnow((prevState) => [...prevState, ""]);
  };

  const addPackage = () => {
    // reset currentPackageData

    setCurrentPackageData({});

    setOpenPackage(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "", isFeatured: false },
    // ]);
  };
  const addPackageHourly = () => {
    // reset currentPackageData

    setCurrentPackageHourlyData({});

    setOpenPackageHourly(true);
    // setPackages([
    //   ...packages,
    //   { title: "", amount: "", discount: "", qty: "", isFeatured: false },
    // ]);
  };

  // const addPackageData = (data) => {

  //   // check editingIndex is not -1

  //   if (editingIndex !== -1) {
  //     const newPackages = [...packages];
  //     newPackages[editingIndex] = data;
  //     setPackages(newPackages);
  //     setCurrentPackageData({});
  //     setEditingIndex(-1);
  //     setOpenPackage(false);
  //     return;
  //   }

  //   setPackages([...packages, data]);
  //   setOpenPackage(false);
  // };

  const addPackageData = (data) => {
    console.log({ data });
    let newData = { ...data }; // Make a copy of the data to avoid mutating the original object
    let newPackages = [...packages];

    // Check if editingIndex is not -1 (i.e., if we're editing an existing package)
    if (editingIndex !== -1) {
      newPackages[editingIndex] = newData;
      setPackages(newPackages);
      setCurrentPackageData({});
      setEditingIndex(-1);
      setOpenPackage(false);
    } else {
      setPackages([...newPackages, newData]);
      setOpenPackage(false);
    }
  };

  const addPackageHourlyData = (data) => {
    console.log({ data });
    let newData = { ...data }; // Make a copy of the data to avoid mutating the original object
    let newPackages = [...packagesHourly];

    // Check if editingIndex is not -1 (i.e., if we're editing an existing package)
    if (editingHourlyIndex !== -1) {
      newPackages[editingHourlyIndex] = newData;
      setPackagesHourly(newPackages);
      setCurrentPackageHourlyData({});
      setEditingHourlyIndex(-1);
      setOpenPackageHourly(false);
    } else {
      setPackagesHourly([...newPackages, newData]);
      setOpenPackageHourly(false);
    }
  };

  // const updatePackageData = (index, field, value) => {
  //   const newPackages = [...packages];
  //   newPackages[index][field] = value;
  //   setPackages(newPackages);
  // };

  const updatePackageData = (index, field, value) => {
    const newPackages = packages.map((pkg, i) => {
      if (i === index) {
        return { ...pkg, [field]: value };
      } else if (field === "packages_isFeatured" && pkg.packages_isFeatured) {
        // Uncheck any other checkbox if it was checked
        return { ...pkg, packages_isFeatured: false };
      }
      return pkg;
    });

    const selectedCount = newPackages.filter(
      (pkg) => pkg.packages_isFeatured
    ).length;

    if (selectedCount > 1) {
      alert("Please select only one isFeatured checkbox.");
      return;
    }

    setPackages(newPackages);
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((_, idx) => idx !== index);
    setPackages(newPackages);
  };

  const deleteHourlyPackage = (index) => {
    const newPackages = packagesHourly.filter((_, idx) => idx !== index);
    setPackagesHourly(newPackages);
  };

  const editPackage = (index) => {
    setEditingIndex(index);
    let currentPackageDt = packages[index];
    setCurrentPackageData({ ...currentPackageDt });
    setOpenPackage(true);
  };

  const editHourlyPackage = (index) => {
    setEditingHourlyIndex(index);
    let currentPackageDt = packagesHourly[index];
    setCurrentPackageHourlyData({ ...currentPackageDt });
    setOpenPackageHourly(true);
  };

  const [allAllowSale, setAllAllowSale] = React.useState(false);
  const allAllowSaleSelected = (e) => {
    let value = e.target.value;
    setAllAllowSale(!allAllowSale);
    if (!allAllowSale) {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowSale: true };
      });
      setPackages(updatedPackages);
    } else {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowSale: false };
      });
      setPackages(updatedPackages);
    }
  };
  const [allAllowClaim, setAllAllowClaim] = React.useState(false);
  const allAllowClaimSelected = (e) => {
    let value = e.target.value;
    setAllAllowClaim(!allAllowClaim);
    if (!allAllowClaim) {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowClaim: true };
      });
      setPackages(updatedPackages);
    } else {
      let updatedPackages = packages.map((item) => {
        return { ...item, isAllowClaim: false };
      });
      setPackages(updatedPackages);
    }
  };
  const toggleYachtModel = () => {
    setYachtModelShow(!model);
  };
  const closeYachtClubModal = () => {
    setYachtModelShow(false);
  };

  const selectYachtClubRow = (id, name, club) => {
    setYachtClubId(id);
    setYachtClubText(id.name);
    setYachtClubImage(id?.images[0]);
    closeYachtClubModal();
  };

  const handlerUploadImage = async (e) => {
    const formData = new FormData();
    const selectedImageForm = new FormData();
    setStartLoader(true);
    selectedImageForm.append("image", e.target.files[0]);
    const selectedImageRes = await postRequestForm(
      "comman/img/upload",
      selectedImageForm
    ).then((res) => {
      let data = res.data;
      console.log({ data });
      if (data?.url) {
        let url = data?.url;
        console.log({ url });
        setPreviewCover((preview) => [...preview, url]);
        setStartLoader(false);
      }
    });
  };

  const removeUploadImage = async (index) => {
    // Filter out the image at the specified index
    const filteredImages = previewCover.filter((item, i) => i !== index);
    // Update the state with the filtered images
    setPreviewCover(filteredImages);
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4> Yacht Offer Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                {" "}
                Yacht Offer Management
              </li>
              : <li className="breadcrumb-item">Create Yacht Offer</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>
        {state?.type == "category" && (
          <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() =>
                history.push(`categorydetails/${state?.categoryId}`)
              }
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
        )}
      </Row>

      {/* header */}

      {/* form */}
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create offer </h4>
                <Form onSubmit={AddcreateOfferf}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Yacht
                          </label>

                          <div
                            className="col-sm-6"
                            onClick={() => toggleYachtModel()}
                          >
                            {yachtClubText ? (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseYachtBtn"
                                  onclick={() => toggleYachtModel()}
                                >
                                  Choose Yacht
                                </button>
                                <div
                                  style={{
                                    border: "1px solid #ced4da",
                                    width: "16px",
                                  }}
                                ></div>
                                <img
                                  src={yachtClubImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    border: "1px solid #ced4da",
                                    borderRight: "none",
                                    borderLeft: "none",
                                  }}
                                ></img>
                                <input
                                  type="text"
                                  style={{ borderLeft: "none" }}
                                  class="form-control"
                                  readonly
                                  value={yachtClubText}
                                  id="selectedYachtClub"
                                  placeholder="No Yacht  chosen"
                                />
                              </div>
                            ) : (
                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#e60489",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseYachtBtn"
                                  onclick={() => toggleYachtModel()}
                                >
                                  Choose Club
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  id="selectedYachtClub"
                                  placeholder="No Yacht Club chosen"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={offerData?.title}
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={offerData?.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Starting Amount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("startingAmount", e.target.value)
                              }
                              value={offerData?.startingAmount}
                              name="startingAmount"
                              className="form-control"
                              min={0}
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Date
                          </label>
                          <div className="col-sm-6">
                            {/* <input
                              type="date"
                              onChange={(e) => {
                                const selectedDate = e.target.value;
                                if (selectedDate >= today) {
                                  updateValue("startDate", selectedDate);
                                  filterDate={isDayEnabled}
                                  // Update the minimum date for the End Date input
                                  const endDateInput =
                                    document.querySelector('[name="endDate"]');
                                  if (endDateInput) {
                                    endDateInput.min = selectedDate; // instead of this we need to set selectedDate + 1 day
                                  }
                                }
                              }}
                              name="startDate"
                              min={today}
                              className="form-control"
                            /> */}

                            <DatePicker
                              selected={offerData?.startDate}
                              onChange={(date) =>
                                updateValue("startDate", date)
                              }
                              //   filterDate={isDayEnabled}
                              className="form-control"
                              minDate={new Date()}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Date
                          </label>
                          <div className="col-sm-6">
                            <DatePicker
                              selected={offerData?.endDate}
                              onChange={(date) => updateValue("endDate", date)}
                              //   filterDate={isDayEnabled}
                              className="form-control"
                              minDate={offerData?.startDate || new Date()}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Important Notice
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("importantNotice", e.target.value)
                              }
                              value={offerData?.importantNotice}
                              name="importantNotice"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Disclaimer
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("disclaimer", e.target.value)
                              }
                              value={offerData?.disclaimer}
                              name="disclaimer"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Need To Know
                          </label>
                          <div className="col-sm-6">
                            {needToKnow?.map((slot, index) => (
                              <div className="col-sm-12" key={index}>
                                <div className="row ">
                                  <div className="col-sm-9 my-2">
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        updateNeedtoKnowValue(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      value={slot}
                                      placeholder="Enter Need To Know"
                                      className="form-control"
                                      id={`horizontal-password-input-${index}`}
                                    />
                                  </div>
                                  <div className="col-sm-3 my-2">
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "red",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={() => deleteNeed(index)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <button
                              type="button"
                              style={{
                                color: "white",
                                backgroundColor: "green",
                                border: "none",
                                padding: "10px 20px",
                              }}
                              onClick={addNeedSlot}
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select AddOns
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={AddOnsList}
                              onSelect={msUpdateAddOns}
                              selectedValues={defaultAddOns}
                              placeholder="Select AddOns"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) => handlerUploadImage(e)}
                              // onChange={(e) =>
                              //   setSelectedCover(e.target.files[0])
                              // }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />

                            <br />
                            {previewCover && (
                              <div className="d-flex">
                                {previewCover.map((item, index) => (
                                  <div className=" me-2" key={index}>
                                    <img
                                      src={item}
                                      onClick={() => showLightBox(item)}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      alt={`Preview ${index}`} // It's a good practice to add an alt attribute to images
                                    />
                                    <button
                                      onClick={() => removeUploadImage(index)}
                                      type="button"
                                      className="btn btn-danger mt-1 d-block"
                                    >
                                      <i className="fal fa-trash fs-5"></i>
                                    </button>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Package Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("packageType", e.target.value)
                              }
                              name="packageType"
                              className="form-select"
                            >
                              <option selected>select Package Type</option>
                              <option value="fixed">fixed</option>
                              <option value="hourly">hourly</option>
                            </select>
                          </div>
                        </div>

                        {offerData?.packageType == "fixed" && (
                          <>
                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Add Package
                              </label>
                              <div className="col-sm-8">
                                <button
                                  type="button"
                                  onClick={addPackage}
                                  className="btn btn-primary mb-3"
                                >
                                  Add Package
                                </button>
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th className="col-2">Title</th>
                                        <th className="col-2">Description</th>
                                        <th className="col-1">Amount</th>
                                        <th className="col-1">Discount</th>

                                        <th className="col-1">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {packages.map((item, index) => (
                                        <tr key={index}>
                                          <td className="col-2">
                                            {editingIndex1 === index ? (
                                              <input
                                                type="text"
                                                value={item?.title}
                                                onChange={(e) =>
                                                  updatePackageData(
                                                    index,
                                                    "title",
                                                    e.target.value
                                                  )
                                                }
                                                className="form-control"
                                              />
                                            ) : (
                                              item?.title
                                            )}
                                          </td>

                                          <td className="col-2">
                                            {editingIndex1 === index ? (
                                              <input
                                                type="text"
                                                defaultValue={
                                                  item?.packages_description
                                                    ? item?.packages_description
                                                    : item?.description
                                                }
                                                onChange={(e) =>
                                                  updatePackageData(
                                                    index,
                                                    "packages_description",
                                                    e.target.value
                                                  )
                                                }
                                                className="form-control"
                                              />
                                            ) : item?.packages_description ? (
                                              item?.packages_description
                                            ) : (
                                              item?.description
                                            )}
                                          </td>
                                          <td className="col-1">
                                            {editingIndex1 === index ? (
                                              <input
                                                type="number"
                                                defaultValue={item?.amount}
                                                onChange={(e) =>
                                                  updatePackageData(
                                                    index,
                                                    "amount",
                                                    e.target.value
                                                  )
                                                }
                                                className="form-control"
                                              />
                                            ) : (
                                              item?.amount
                                            )}
                                          </td>
                                          <td className="col-1">
                                            {editingIndex1 === index ? (
                                              <input
                                                type="number"
                                                defaultValue={item?.discount}
                                                onChange={(e) =>
                                                  updatePackageData(
                                                    index,
                                                    "discount",
                                                    e.target.value
                                                  )
                                                }
                                                className="form-control"
                                              />
                                            ) : (
                                              item?.discount
                                            )}
                                          </td>

                                          <td className="col-1">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              {/* {editingIndex === index ? (
                                            <button
                                              type="button"
                                              className="btn btn-success btn-sm me-1"
                                              onClick={() => {
                                                saveEditedPackage(index);
                                              }}
                                            >
                                              Save
                                            </button>
                                          ) : (
                                            <span
                                              role="button"
                                              className="btn btn-primary btn-sm me-1"
                                              onClick={() => {
                                                setEditingIndex(index);
                                              }}
                                            >
                                              Edit
                                            </span>
                                          )} */}

                                              <i
                                                className="fas fa-pencil-alt"
                                                onClick={() =>
                                                  editPackage(index)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#e60489",
                                                }}
                                              ></i>

                                              <i
                                                className="fas fa-trash-alt mx-3"
                                                onClick={() =>
                                                  deletePackage(index)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  color: "red",
                                                }}
                                              ></i>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {offerData?.packageType == "hourly" && (
                          <>
                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Add Package
                              </label>
                              <div className="col-sm-8">
                                <button
                                  type="button"
                                  onClick={addPackageHourly}
                                  className="btn btn-primary mb-3"
                                >
                                  Add Package
                                </button>
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th className="col-2">Title</th>
                                        <th className="col-2">Description</th>
                                        <th className="col-2">Amount</th>
                                        <th className="col-2">Discount</th>
                                        <th className="col-1">Unit </th>
                                        <th className="col-1">Min Unit </th>
                                        <th className="col-1">Max Unit</th>

                                        <th className="col-1">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {packagesHourly?.map((item, index) => (
                                        <tr key={index}>
                                          <td className="col-2">
                                            {item?.title}
                                          </td>

                                          <td className="col-2">
                                            {item?.description}
                                          </td>
                                          <td className="col-2">
                                            {item?.amount}
                                          </td>
                                          <td className="col-2">
                                            {item?.discount}
                                          </td>
                                          <td className="col-1">
                                            {item?.unit}
                                          </td>
                                          <td className="col-1">
                                            {item?.minUnit}
                                          </td>
                                          <td className="col-1">
                                            {item?.maxUnit}
                                          </td>

                                          <td className="col-1">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <i
                                                className="fas fa-pencil-alt"
                                                onClick={() =>
                                                  editHourlyPackage(index)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  color: "#e60489",
                                                }}
                                              ></i>

                                              <i
                                                className="fas fa-trash-alt mx-3"
                                                onClick={() =>
                                                  deleteHourlyPackage(index)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  color: "red",
                                                }}
                                              ></i>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}

      <YachtOfferAddModal
        show={openPackage}
        closeModal={closePackageModal}
        currentPackageData={currentPackageData}
        addData={addPackageData}
      />

      <YachtPackageAddHourly
        show={openPackageHourly}
        closeModal={closeHourlyPackageModal}
        currentPackageData={currentPackageHourlyData}
        addData={addPackageHourlyData}
      />

      <VenueModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />

      <YachtModal
        show={YachtModelShow}
        closeYachtClubModal={closeYachtClubModal}
        selectRow={selectYachtClubRow}
        closeModal={() => setYachtModelShow(false)}
      />

      <FileUpload message="Logo & Cover Uploading" status={startLoader} />

      {/* modal */}
    </React.Fragment>
  );
}
