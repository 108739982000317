// src/components/PaidPass/CreatePaidPass.jsx

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Input, Button, Label, FormGroup } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequest } from "../../components/Common/Utils.js"; // Ensure this utility is set up for JSON requests
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function CreatePaidPass() {  

  const [paidPassData, setPaidPassData] = useState({
    title: "",
    description: "",    
    type: "venue",  
    amount: "",
    validityInDays: "",
    status: false,
  });

  const [loading, setLoading] = useState(false);  

  const history = useHistory();
  
  const handleInputChange = (field, value) => {
    setPaidPassData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPaidPassData(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPaidPassData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form Validation
    if (!paidPassData.title.trim()) {
      toast.error("Title is required");
      return;
    }
    if (!paidPassData.description.trim()) {
      toast.error("Description is required");
      return;
    }
    if (!paidPassData.type) {
      toast.error("Type is required");
      return;
    }
    if (paidPassData.amount === "" || isNaN(paidPassData.amount)) {
      toast.error("Valid amount is required");
      return;
    }
    if (paidPassData.validityInDays === "" || isNaN(paidPassData.validityInDays)) {
      toast.error("Valid validity in days is required");
      return;
    }    

    setLoading(true);

    // Prepare data to send
    const payload = {
      title: paidPassData.title,
      description: paidPassData.description,
      type: paidPassData.type,   
      amount: Number(paidPassData.amount),
      validityInDays: Number(paidPassData.validityInDays),
      status: paidPassData.status,
    };

    try {
      const response = await postRequest("promoter/paid-pass/create", payload);

      if (response.status === 1) {
        toast.success(response.message);
        // Redirect after a short delay to allow the user to see the toast
        setTimeout(() => {
          history.push("/paid-pass"); // Adjust the path as per your routing
        }, 3000);
      } else {
        toast.error(response.message || "Failed to create Paid Pass");
      }
    } catch (error) {
      console.error("Error creating Paid Pass:", error);
      toast.error("An error occurred while creating the Paid Pass");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Paid Pass Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Paid Pass Management</li>:{" "}
              <li className="breadcrumb-item">Create Paid Pass</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <Button color="primary" size="lg" onClick={() => history.goBack()}>
            <i className="fas fa-chevron-left"></i> Back
          </Button>
        </div>
      </div>

      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px", position: "relative" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Create Paid Pass</h4>
                <Form onSubmit={handleSubmit}>
                  {/* Title */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="title">
                      Title <span style={{ color: "red" }}>*</span>
                    </Label>
                    <div className="col-sm-6">
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Enter title"
                        value={paidPassData.title}
                        onChange={handleChange}
                        required
                        className="form-control"
                      />
                    </div>
                  </div>

                  {/* Description */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="description">
                      Description <span style={{ color: "red" }}>*</span>
                    </Label>
                    <div className="col-sm-6">
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        placeholder="Enter description"
                        value={paidPassData.description}
                        onChange={handleChange}
                        required
                        className="form-control"
                        // style={{ height: "150px" }}
                      />
                    </div>
                  </div>

                  {/* Type */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="type">
                      Type
                    </Label>
                    <div className="col-sm-6">
                      <Input
                        type="select"
                        name="type"
                        id="type"
                        value={paidPassData.type}
                        onChange={handleChange}
                        className="form-control"
                      >                        
                        <option value="venue">Venue</option>
                        <option value="event">Event</option>
                      </Input>
                    </div>
                  </div>

                 

                  {/* Amount */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="amount">
                      Amount
                    </Label>
                    <div className="col-sm-6">
                      <Input
                        type="number"
                        name="amount"
                        id="amount"
                        placeholder="Enter amount"
                        value={paidPassData.amount}
                        onChange={handleChange}
                        min="0"
                        step="0.01"
                        className="form-control"
                      />
                    </div>
                  </div>

                  {/* Validity in Days */}
                  <div className="row mb-4">
                    <Label className="col-sm-3 col-form-label" for="validityInDays">
                      Validity (Days)
                    </Label>
                    <div className="col-sm-6">
                      <Input
                        type="number"
                        name="validityInDays"
                        id="validityInDays"
                        placeholder="Enter validity in days"
                        value={paidPassData.validityInDays}
                        onChange={handleChange}
                        min="0"
                        className="form-control"
                      />
                    </div>
                  </div>

                  {/* Status */}

                    <div className="row mb-4">
                        <Label className="col-sm-3 col-form-label" for="status">
                            Status
                        </Label>
                        <div className="col-sm-6">
                            <FormGroup className="form-check">
                                <Input
                                    type="checkbox"
                                    name="status"
                                    id="status"
                                    checked={paidPassData.status}
                                    onChange={handleCheckboxChange}
                                    className="form-check-input"
                                />
                                <Label className="form-check-label" for="status">
                                    Active
                                </Label>
                            </FormGroup>
                        </div>
                    </div>

                    
                  

                  {/* Submit Button */}
                  <div className="mt-5 mt-lg-4">
                    <div className="row justify-content-end">
                      <div className="col-sm-6">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn btn-primary w-md"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <Spinner size="sm" /> Submitting...
                            </>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );

}
