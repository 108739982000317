import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/user-3.jpg";
import avatar4 from "../../../assets/images/users/user-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

import { postRequest } from "../../Common/Utils";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notifications, setNotificatios] = useState([]);

  const history = useHistory();

  useEffect(() => {
    unreadNotification();
  }, []);

  const unreadNotification = async (filterArgs = []) => {
    const payload = {};

    try {
      const response = await postRequest(
        `venue/offer/notification/list`,
        payload
      );
      const data = response.data;

      setNotificatios(data || []);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
    }
  };

  const readNotification = async () => {
    const ids = notifications?.map((notification) => notification._id);

    const payload = {
      ids,
    };

    try {
      const response = await postRequest(
        `venue/offer/notification/mark-as-read`,
        payload
      );
      const data = response.data;
      setNotificatios(data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      unreadNotification();
    }
  };

  const HandleNavigation = (notification) => {
    const pathname = notification?.ticketId
      ? `/rayna-expire-ticket/${notification?.ticketId}`
      : `/updateOffer/${notification?.offer?._id}`;

    history.push(pathname, {
      state: { row: notification },
    });
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ti-bell"></i>
          {notifications?.length ? (
            <span className="badge bg-danger rounded-pill">
              {notifications?.length}
            </span>
          ) : null}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                {notifications?.length ? (
                  <h5 className="m-0">
                    {" "}
                    {`${props.t("New Notifications")} (${
                      notifications?.length
                    })`}{" "}
                  </h5>
                ) : null}
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications.length > 0 ? (
              notifications?.map((notification, index) => (
                <div
                  onClick={() => HandleNavigation(notification)}
                  className="text-reset notification-item cursor-pointer"
                >
                  <div className="media">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title border-warning rounded-circle ">
                        <i className="mdi mdi-message"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h6 className="mt-0 mb-1">{notification.title}</h6>
                      <div className="text-muted">
                        <p className="mb-1">{notification.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">
                <h5 className="mt-6">{props.t("No New Notifications")}</h5>
              </div>
            )}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/offerNotification"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>

            {notifications.length > 0 && (
              <a
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
                onClick={() => {
                  readNotification();
                }}
                href="javascript:void(0);"
              >
                <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                {props.t("Mark As Read")}{" "}
              </a>
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
