import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
  Button
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
  getRequest,
  getRequestNew,
} from "../../components/Common/Utils.js";
// import CustomLoder from "pages/TicketCategory/CustomLoder.js";
// import MultiselectCommon from "./MultiselectCommon.js";

import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";

import RaynaTicketModal from "components/Modal/RaynaTicketModal.js";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LocationPicker from "react-location-picker";
import IconPackModal from "components/Modal/IconPackModal.js";
import IconPackModalupdated from "components/Modal/IconPackModalupdated.js";
import FileUpload from "../../components/Common/FileUpload.js";
import axios from "axios";
import { use } from "react";
import PricingPurchaseUnit from "components/PricingPurchaseUnit/PricingPurchaseUnit.jsx";

import ImagesReorderModal from "./ImagesReorderModal"; // <-- We'll create this below

const defaultPosition = {
  lat: 25.2048345,
  lng: 55.2708345,
};

export default function CreateCustomTicket() {

  const locationHook = useLocation();

  const { state } = locationHook;




  const [reorderModal, setReorderModal] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [bannerData, setBannerData] = useState({
    basePrice: 0,
    startingAmount: 0,
    startingBaseAmount: 0,
    discount: 0,
    markup: 0,
    vat: false,
    vatPercentage: 0,
    title: "",
    description: "",
    city: "",
    images: [],
    // etc. – basically enough defaults so you never get a `null` error
  });
  const [previewImage, setPreviewImage] = useState([]);
  const [ShowLoder, setShowLoder] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedCaterogy, setselectedCaterogy] = useState([]);
  const [selectedTicketoption, setselectedTicketoption] = useState([]);
  const [categoryOptions, setCategoryData] = useState([]);
  const [subCategoryOptions, setSubCategoryData] = useState([]);
  const [categoryDataIds, setSelectedCaterogyIds] = useState([]);
  const [subCategoryDataIds, setSelectedSubCaterogyIds] = useState([]);
  const [packages, setPackages] = useState([]);
  const [WhatsInclude, setIncludePackages] = useState([]);
  const [iconModal, setIconModal] = useState(false);
  const [iconModalInclude, seticonModalInclude] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [selectedIconInclude, setselectedIconInclude] = useState("");
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [optionimageUrls, setoptionimageUrls] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [loader, setLoader] = useState(false);

  const [cityOptions, setCityOptions] = useState([
    "Abu Dhabi",
    "Ajman",
    "Dubai",
    "Fujairah",
    "Sharjah",
    "Ras Al Khaimah",
    "Umm Al Quwain",
  ]);

  const [url, seturl] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      // setPreviewImage(reader.result);
      setPreviewImage((oldImage) => [...oldImage, reader.result]);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);


  const fetchCategoryList = async () => {
    const param = { page: 1, limit: 100000 };
    const category = await postRequest("rayna/category/list", param);
    if (category.status == 1) {
      const options = category.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });

      setCategoryList(options);
      if (selectedCategory.length > 0) {
        const defaultItems = options.filter((item) => {
          return selectedCategory.includes(item.id);
        });
        setDefaultCategory(defaultItems);
      }
    }
  };


  const msUpdateCategory = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);


    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      delete newErrors["selectedCategory"]
      return newErrors
    })
  };
  
  const fetchCityList = async () => {    
    const response = await postRequest("rayna/ticket/city/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          _id: item._id,
          name: item.name,
        };
      });
      setCityList([...list]);
    }
    // setLoading(false);
  };




  useEffect(() => {
    fetchCategoryList();
    fetchCityList();

  }, [])



  const selectIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const selectIncludeIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const selectIconRowIWhatsnclude = (icon) => {
    const packagesList = [...WhatsInclude];
    packagesList[selectedIconInclude].icon = icon.image;
    packagesList[selectedIconInclude].iconName = icon.title;
    setIncludePackages(packagesList);
    closeIconModalInclude();
  };

  const selectIncludeIconRowWhats = (icon) => {
    const packagesList = [...WhatsInclude];
    packagesList[selectedIconInclude].icon = icon.image;
    packagesList[selectedIconInclude].iconName = icon.title;
    setIncludePackages(packagesList);
    closeIconModalInclude();
  };

  const closeIconModal = () => {
    setIconModal(false);
  };
  const closeIconModalInclude = () => {
    seticonModalInclude(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };

  const openIconModalInclude = (index) => {
    setselectedIconInclude(index);
    seticonModalInclude(true);
  };

  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };
  const updatePackageFieldInclude = (index, fieldName, value) => {
    const newPackages = [...WhatsInclude];
    newPackages[index][fieldName] = value;
    setIncludePackages(newPackages);
  };

  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);

    setPackages(newPackages);
  };

  const deleteIncludePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);

    setIncludePackages(newPackages);
  };

  const addRow = () => {
    setPackages([...packages, { icon: "", feature: "", iconName: "" }]);
  };
  const addIncludeRow = () => {
    setIncludePackages([
      ...WhatsInclude,
      { icon: "", feature: "", iconName: "" },
    ]);
  };

  const saveIncludePackage = (index) => {
    const newPackages = [...WhatsInclude];
    newPackages[index] = { ...newPackages[index], saved: true };
    setIncludePackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const msUpdateDay = (selectedList, selectedItem) => {
    console.log("test click", selectedList, selectedItem);
    const ids = selectedList.map((item) => item.id);
    console.log("msUpdateDay", ids);
    setSelectedCaterogyIds(ids);
  };

  const msUpdateSubCat = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedSubCaterogyIds(ids);
  };



  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);

    // Validate the field immediately
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      delete newErrors[index]
      return newErrors
    })
    console.log({ index })
    if (index === 'Infantminage' || index === 'Infantmaxage') {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors }
        delete newErrors['infanterror']
        return newErrors
      })
    } else if (index === 'Childminage' || index === 'Childmaxage') {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors }
        delete newErrors['childerror']
        return newErrors
      })
    } else if (index === 'adultAge') {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors }
        delete newErrors['adulterror']
        return newErrors
      })
    }




  };

  const Imageupload = async (e) => {

    // if file not selected throw error

    if (!e.target.files[0]) return;

    const formData = new FormData();
    setFileUploadLoader(true);
    formData.append("file", e.target.files[0]);
    // formData.append("file", selectedImage);
    const response = await postRequestForm("comman/doc/upload", formData);
    let image = response.data.url;
    setFileUploadLoader(false);
    console.log({ image });
    setImageUrls([...imageUrls, image]);
    if (image) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors }
        delete newErrors['original']
        return newErrors
      })
    }


  };

  const RemoveImage = (index) => {
    // console.log({ url });
    // let image = previewImage.filter((i, j) => j !== index);
    let UrlImage = imageUrls.filter((i, j) => j !== index);
    // setPreviewImage(image);
    setImageUrls(UrlImage);
  };


  const OptionImageupload = async (e, tourOptionId) => {
    const formData = new FormData();
    setFileUploadLoader(true);
    formData.append("file", e.target.files[0]);
    const response = await postRequestForm("comman/doc/upload", formData);
    let image = response?.data?.url;
    setFileUploadLoader(false);
    setoptionimageUrls([...optionimageUrls, image])

    const PricingPurchase = [
      ...new Map(
        response?.data?.map((item) => [
          `${item.tourId}_${item.tourOptionId}_${item.transferId}`, // composite key
          {
            tourId: item.tourId,
            tourOptionId: item.tourOptionId,
            transferId: item.transferId,
            transferName: item.transferName,  // Added transferName
            startTime: item.startTime,        // Added starting time
            adultPrice: item.adultPrice,
            childPrice: item.childPrice,
            infantPrice: item.infantPrice,
            optionName: item?.optionDetail?.optionName,
            imageUrls: item?.optionDetail?.images || [],
          },
        ])
      ).values()
    ] || [];

    console.log({ PricingPurchase })


    setBannerData((prev) => ({
      ...prev,
      PricingPurchase

    }));
    setFileUploadLoader(false);

  };

  const OptionRemoveImage = (tourOptionId, indexToRemove) => {
    setBannerData((prev) => ({
        ...prev,
        PricingPurchase: prev.PricingPurchase.map((item) =>
            item.tourOptionId === tourOptionId
                ? { 
                    ...item, 
                    imageUrls: item.imageUrls?.filter((_, index) => index !== indexToRemove) 
                  }
                : item
        )
    }));
};


  // useEffect(() => {
  //   Imageupload();
  // }, [selectedImage]);

  // moda code ticket
  const [activityModel, setActivityModel] = useState(false);

  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [updateTicketpData, setUpdateTicketpData] = useState(null);

  const toggleActivityModel = () => {
    console.log("toggleActivityModel", activityModel);
    setActivityModel(!activityModel);
  };

  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const selectActivityRow = (id, name, activity) => {
    setActivityText(id?.name);
    setActivityImage(activity?.image);
    console.log("selected data", id);
    const formInput = { ...bannerData, code: id?.code };

    console.log({ formInput });
    setBannerData(formInput);
    closeActivityModal();
  };

  // Heli Dubai Ticket Modal code

  const [heliDubaiModel, setHeliDubaiModel] = useState(false);

  const [heliDubaiId, setHeliDubaiId] = useState("");
  const [heliDubaiText, setHeliDubaiText] = useState("");
  const [heliDubaiImage, setHeliDubaiImage] = useState("");
  const [selectedHeliIndex, setSelectedHeliIndex] = useState(-1);
  const [updateHeliTicketpData, setUpdateHeliTicketpData] = useState(null);

  const toggleHeliModel = () => {
    console.log("toggleHeliModel", heliDubaiModel);
    setHeliDubaiModel(!heliDubaiModel);
  };

  const closeHeliModel = () => {
    setHeliDubaiModel(false);
  };

  const selectHeliDubaiRow = (id, title, activity) => {
    setHeliDubaiText(id?.title);
    setHeliDubaiImage(activity?.image);
    console.log("selected data", id);
    const formInput = { ...bannerData, code: id?.tourProductId };

    console.log({ formInput });
    setBannerData(formInput);
    closeHeliModel();
  };

  // Dubai Park Ticket Modal code

  const [dubaiParkModel, setDubaiParkModel] = useState(false);

  const [dubaiParkId, setDubaiParkId] = useState("");
  const [dubaiParkText, setDubaiParkText] = useState("");
  const [dubaiParkImage, setDubaiParkImage] = useState("");
  const [selectedDubaiParkIndex, setSelectedDubaiParkIndex] = useState(-1);
  const [updateDubaiParkTicketpData, setUpdateHDubaiParkTicketpData] =
    useState(null);

  const toggleDubaiParkModel = () => {
    console.log("toggleDubaiParkModel", dubaiParkModel);
    setDubaiParkModel(!dubaiParkModel);
  };

  const closeDubaiParkModel = () => {
    setDubaiParkModel(false);
  };

  const selectDubaiParkRow = (id, title, activity) => {
    console.log("selected data", id);
    setDubaiParkText(id?.name);
    setDubaiParkImage(id?.imageUrl);
    console.log("selected data", id);
    const formInput = { ...bannerData, code: id?.productId };

    console.log({ formInput });
    setBannerData(formInput);
    closeDubaiParkModel();
  };

  // Emaar Ticket Modal code

  const [emaarModel, setEmaarModel] = useState(false);

  const [emaarId, setEmaarId] = useState("");
  const [emaarText, setEmaarText] = useState("");
  const [emaarImage, setEmaarImage] = useState("");
  const [selectedEmaarIndex, setSelectedEmaarIndex] = useState(-1);
  const [updateEmaarTicketpData, setUpdateEmaarTicketpData] = useState(null);

  const toggleEmaarModel = () => {
    console.log("toggleEmaarModel", emaarModel);
    setEmaarModel(!emaarModel);
  };

  const closeEmaarModel = () => {
    setEmaarModel(false);
  };

  const selectEmaarRow = (id, title, activity, ProductName, ProductBanner) => {
    console.log("selected data", id);
    setEmaarText(id?.ProductName);
    setEmaarImage(id?.ProductBanner);
    console.log("selected data", id);
    const formInput = { ...bannerData, code: id?.ProductId };

    console.log({ formInput });
    setBannerData(formInput);
    closeEmaarModel();
  };
  // Rayna Ticket Modal code

  const [raynaModel, setRaynaModel] = useState(false);
  const [raynaId, setRaynaId] = useState("");
  const [raynaText, setRaynaText] = useState("");
  const [raynaImage, setRaynaImage] = useState("");
  const [selectedRaynaIndex, setSelectedRaynaIndex] = useState(-1);
  const [updateRaynaTicketpData, setUpdateRaynaTicketpData] = useState(null);

  const toggleRaynaModel = () => {
    console.log("toggleRaynaModel", raynaModel);
    setRaynaModel(!raynaModel);
  };

  const closeRaynaModel = () => {
    setRaynaModel(false);
  };

  const selectRaynaRow = async (raynaData, title, activity) => {
    console.log("selected data", raynaData);

    const raynaCityName = raynaData?.cityName?.trim() || "";

    // Construct the image URL
    const imagePath = encodeURIComponent(raynaData?.imagePath || ""); // Encode the path
    const imageUrl = `https://d1i3enf1i5tb1f.cloudfront.net/${imagePath}`;
    let validImage = false;
    try {
      const response = await getRequestNew(imageUrl);
      if (response.status === 200) {
        validImage = true;
      }
    } catch (error) {
      console.log("Image not found or not accessible", error);
    }

    // If the Rayna city is not in our city options, add it
    if (
      raynaCityName &&
      !cityOptions
        .map((c) => c.toLowerCase())
        .includes(raynaCityName.toLowerCase())
    ) {
      setCityOptions((prevOptions) => [...prevOptions, raynaCityName]);
    }

    console.log("raynaCityName", `"${raynaCityName}"`);

    const formInput = {
      ...bannerData,
      code: raynaData?.tourId,
      title: raynaData?.name || "",
      city: raynaCityName || "",    
    };
    

    setDescription(raynaData?.tourShortDescription);

    setRaynaText(raynaData?.name);
    setRaynaImage(imageUrl);
    setselectedTicketoption(raynaData?._id || []);

    if (validImage) {
      setImageUrls([imageUrl]);
    } else {
      setImageUrls([]);
      // Optionally show a message to prompt user to upload their own image.
    }

    setBannerData(formInput);
    closeRaynaModel();

    // await for 500 ms before calling RayanDetails

   

    await RaynaDetails(raynaData)    
  };

  const [activeKey, setActiveKey] = useState(null)

  // const RayanDetails = async (row) => {
  //   try {
  //     const currentDate = new Date()
  //     const futureDate = new Date()
  //     futureDate.setDate(currentDate.getDate() + 5) // Add 5 days

  //     const response = await postRequest('rayna/tour-options', {
  //       tourId: row?.tourId,
  //       contractId: row?.contractId,
  //       date: futureDate.toISOString().split('T')[0],
  //       noOfAdult: 1,
  //       noOfChild: 0,
  //       noOfInfant: 0,
  //     })

  //     if (response?.status === 1) {
  //       const Data = response?.data[0]?.optionDetail
  //       const DataInfo = response?.data[0]
  //       console.log(DataInfo)

  //       const [Infantminage, Infantmaxage] = Data?.infantAge.split(' ')[0].split('-').map(Number)
  //       const [Childminage, Childmaxage] = Data?.childAge.split(' ')[0].split('-').map(Number)
  //       setActiveKey(Data?.tourOptionId)

  //       // Create PricingPurchase array using map
  //       const PricingPurchase = [
  //         ...new Map(
  //           response?.data?.map((item) => [
  //             item?.tourOptionId,
  //             {
  //               tourOptionId: item?.tourOptionId,
  //               adultPrice: item?.adultPrice,
  //               childPrice: item?.childPrice,
  //               infantPrice: item?.infantPrice,
  //               optionName: item?.optionDetail?.optionName,
  //               imageUrls: item?.optionDetail?.images||[],
  //             },
  //           ])
  //         ).values()
  //       ] || [];


  //       const getSmallestFinalAmount = (data) => {
  //         if (!data || data.length === 0) return null;

  //         return data.reduce((min, item) =>
  //           item.finalAmount < min.finalAmount ? item : min
  //           , data[0]?.finalAmount);
  //       };



  //       console.log({ PricingPurchase }, 'final amont', getSmallestFinalAmount(response?.data))
  //       // Update bannerData state
  //       setBannerData((prevData) => ({
  //         ...prevData,
  //         cancellationPolicy: Data?.cancellationPolicy,
  //         cancellationPolicyDescription: Data?.cancellationPolicyDescription,
  //         childPolicy: Data?.childPolicyDescription,
  //         Childminage,
  //         Childmaxage,
  //         Infantminage,
  //         Infantmaxage,
  //         tourOptionData: response?.data || [],
  //         allowChild: !DataInfo?.disableChild ? 'true' : 'false',
  //         allowInfant: !DataInfo?.disableInfant ? 'true' : 'false',
  //         allowAdult: 'true',
  //         adultAge: Childmaxage,
  //         maximumPax: DataInfo?.minimumPax,
  //         PricingPurchase,
  //         startingAmount: getSmallestFinalAmount(response?.data),
  //       }))




  //     }
  //   } catch (error) {
  //     console.error('Error fetching Rayan details:', error)
  //   }
  // }



  // useEffect(()=>{

  //   setBannerData

  // },[])


  // const RaynaDetails = async (row) => {

  //   console.log("row", row)

  //   const currentDate = new Date()
  //   const futureDate = new Date()
  //   futureDate.setDate(currentDate.getDate() + 5) // Add 5 days

  //   const payload ={
  //     tourId: row?.tourId,
  //     contractId: row?.contractId,
  //     date: futureDate.toISOString().split('T')[0],
  //     noOfAdult: 1,
  //     noOfChild: 0,
  //     noOfInfant: 0,
  //   }

  //   const response = await postRequest('rayna/tour-options/admin', payload)
  
  //   if (response?.status === 1) {
  //     const data = response?.data.options // This has 3 entries

  //     const detail = response?.data.tourDetailsData      
  
  //     // Build PricingPurchase using a composite key
  //     const PricingPurchase = [
  //       ...new Map(
  //         data.map((item) => [
  //           // Composite key using tourId, tourOptionId, and transferId
  //           `${item.tourId}_${item.tourOptionId}_${item.transferId}`,
  //           {
  //             tourId: item.tourId,
  //             tourOptionId: item.tourOptionId,
  //             transferId: item.transferId,
  //             transferName: item.transferName,  // Added transferName
  //             startTime: item.startTime,        // Added starting time
  //             adultPrice: item.adultPrice,
  //             childPrice: item.childPrice,
  //             infantPrice: item.infantPrice,
  //             optionDetail: item?.optionDetail,
  //             optionName: item?.optionDetail?.optionName,
  //             imageUrls: item?.optionDetail?.images || [],
  //           }
  //         ])
  //       ).values()
  //     ]

  //     let lowAdultPrice = PricingPurchase.reduce((min, item) => item.adultPrice < min.adultPrice ? item : min, PricingPurchase[0]);


  //     console.log("lowAdultPrice", lowAdultPrice.adultPrice) 
  //     console.log("main data", data)
  //     console.log("detail", detail)

  //     const infantAgeText = detail?.infantAge?.split(' ')[0] || ''; // gets "3-10"
  //     const childAgeText = detail?.childAge?.split(' ')[0] || '';   // gets "3-10"

  //     const infantAgeRange = infantAgeText.split('-') || [];
  //     const childAgeRange = childAgeText.split('-') || [];

  //     console.log("age ", infantAgeText, childAgeText)
  
  //     setBannerData((prev) => ({
  //       ...prev,
  //       startingAmount: lowAdultPrice.adultPrice,
  //       // cancellationPolicy: data[0]?.cancellationPolicy,
  //       // cancellationPolicyDescription: data[0]?.cancellationPolicyDescription,
  //       // childPolicy: data[0]?.childPolicyDescription,       
  //       overview: detail?.itenararyDescription,
  //       inclusion: detail?.tourInclusion,
  //       cancellationPolicy: detail?.cancellationPolicyName,
  //       childPolicy: detail?.childCancellationPolicyDescription,
  //       latitude: detail?.latitude,
  //       longitude: detail?.longitude,
  //       howToRedeem: detail?.howToRedeem,
  //       tourExclusion: detail?.tourExclusion,
  //       videoUrl: detail?.videoUrl,
  //       faqDetails: detail?.faqDetails,
  //       googleMapUrl: detail?.googleMapUrl,
  //       raynaToursAdvantage: detail?.raynaToursAdvantage,
  //       usefulInformation: detail?.usefulInformation,
  //       importantInformation: detail?.importantInformation,
  //       departurePoint: detail?.departurePoint,
  //       tourLanguage: detail?.tourLanguage,
  //       allowAdult: detail?.onlyChild ? false : true,
  //       allowChild: detail?.childAge ? true : false,
  //       allowInfant: detail?.infantAge ? true : false,
  //       Infantminage: infantAgeRange[0] || '',
  //       Infantmaxage: infantAgeRange[1] || '',
  //       Childminage: childAgeRange[0] || '',
  //       Childmaxage: childAgeRange[1] || '',
  //       adultAge: childAgeRange[1] || '',



  //       // Now you have all 3 unique items in PricingPurchase
  //       PricingPurchase
  //     }))



  //     console.log("detail?.tourShortDescription", detail?.infantAge.split('-')[0])
  //     setDescription(detail?.tourDescription)
  //     const location = {
  //       lat: parseFloat(detail?.latitude || defaultPosition.lat),
  //       lng: parseFloat(detail?.longitude ||  defaultPosition.lng)
  //     }
     
  //     setCurrentLocation(location);

  //   }
  // } 

  function computeFinalPrice(baseAmount, markup, discount, vat, vatPercentage) {

    console.log("baseAmount", baseAmount, markup, discount, vat, vatPercentage)

    let price = parseFloat(baseAmount) || 0;
  
    // 1) Apply Markup
    if (markup > 0) {
      price += price * (markup / 100);
    }
  
    // 2) Apply Discount
    if (discount > 0) {
      price -= price * (discount / 100);
    }
  
    // 3) Apply VAT
    if (vat && vatPercentage > 0) {
      price += price * (vatPercentage / 100);
    }
  
    return parseFloat(price.toFixed(2));
  }

  useEffect(() => {   

    setBannerData((prev) => ({
      ...prev,
      startingAmount: computeFinalPrice(
        prev.startingBaseAmount,
        prev.markup,
        prev.discount,
        prev.vat,
        prev.vatPercentage
      ),
    }));
  }, [bannerData?.startingBaseAmount, bannerData?.markup, bannerData?.discount, bannerData?.vat, bannerData?.vatPercentage]);












  console.log("state default data", state?.row)


useEffect(()=>{

  if(!cityList.length) return

  RaynaDetails(state?.row)
  selectRaynaRow(state?.row)
  
  
},[state, cityList])


  const RaynaDetails = async (row) => {
    console.log("row", row);
    setLoader(true)
    const currentDate = new Date();
    const futureDate = new Date();
    futureDate.setDate(currentDate.getDate() + 5); // Add 5 days
  
    const payload = {
      tourId: row?.tourId,
      contractId: row?.contractId,
      date: futureDate.toISOString().split('T')[0],
      noOfAdult: 1,
      noOfChild: 0,
      noOfInfant: 0,
    };
  
    const response = await postRequest('rayna/tour-options/admin', payload);
  
    if (response?.status === 1) {
      const data = response?.data.options; // 3 entries expected
      const detail = response?.data.tourDetailsData;
  
      // Build unique PricingPurchase array
      const PricingPurchase = [
        ...new Map(
          data.map((item) => [
            `${item.tourId}_${item.tourOptionId}_${item.transferId}`,
            {
              tourId: item.tourId,
              tourOptionId: item.tourOptionId,
              transferId: item.transferId,
              transferName: item.transferName,
              startTime: item.startTime,
              adultPrice: item.adultPrice,
              childPrice: item.childPrice,
              infantPrice: item.infantPrice,
              optionDetail: item?.optionDetail,
              optionName: item?.optionDetail?.optionName,
              imageUrls: item?.optionDetail?.images || [],
            }
          ])
        ).values()
      ];
  
      // Get lowest adult price from all options
      const lowAdultPrice = PricingPurchase.reduce(
        (min, item) => item.adultPrice < min.adultPrice ? item : min,
        PricingPurchase[0]
      );
  
      // Age range extraction logic
      const infantAgeText = detail?.infantAge?.split(' ')[0] || '';
      const childAgeText = detail?.childAge?.split(' ')[0] || '';
  
      const infantAgeRange = infantAgeText.split('-') || [];
      const childAgeRange = childAgeText.split('-') || [];
  
      const infantMin = parseInt(infantAgeRange[0] || '0', 10);
      const infantMax = parseInt(infantAgeRange[1] || '0', 10);
  
      const childMin = parseInt(childAgeRange[0] || '0', 10);
      const childMax = parseInt(childAgeRange[1] || '0', 10);
  
      const isInfantAllowed = !(infantMin === 0 && infantMax === 0);
      const isChildAllowed = !(childMin === 0 && childMax === 0);
  



      let cityName = row.cityName;
      let selectedCityId = cityList.find((city) => city.name === cityName)?._id || '';

      console.log("selectedCityId", selectedCityId)
      console.log("cityName", cityName)
      console.log("cityList", cityList)
  
      setBannerData((prev) => ({
        ...prev,
        startingAmount: lowAdultPrice.adultPrice,
        startingBaseAmount: lowAdultPrice.adultPrice,
        overview: detail?.itenararyDescription,
        inclusion: detail?.tourInclusion,
        cancellationPolicy: detail?.cancellationPolicyName,
        childPolicy: detail?.childCancellationPolicyDescription,
        latitude: detail?.latitude,
        longitude: detail?.longitude,
        howToRedeem: detail?.howToRedeem,
        tourExclusion: detail?.tourExclusion,
        videoUrl: detail?.videoUrl,
        faqDetails: detail?.faqDetails,
        googleMapUrl: detail?.googleMapUrl,
        raynaToursAdvantage: detail?.raynaToursAdvantage,
        usefulInformation: detail?.usefulInformation,
        importantInformation: detail?.importantInformation,
        departurePoint: detail?.departurePoint,
        tourLanguage: detail?.tourLanguage,
        allowAdult: true,
        cityId: selectedCityId,
        allowChild: isChildAllowed,
        allowInfant: isInfantAllowed,
        Infantminage: infantAgeRange[0] || '',
        Infantmaxage: infantAgeRange[1] || '',
        Childminage: childAgeRange[0] || '',
        Childmaxage: childAgeRange[1] || '',
        adultAge: childAgeRange[1] || '',
        PricingPurchase
      }));
  
      setDescription(detail?.tourDescription);
  
      const location = {
        lat: parseFloat(detail?.latitude || defaultPosition.lat),
        lng: parseFloat(detail?.longitude || defaultPosition.lng)
      };
      setCurrentLocation(location);
      setLoader(false)

    }else{
      setLoader(false)

    }
  };
  

 






  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setBannerData((prevData) => ({
      ...prevData,
      city: selectedCity,
    }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      delete newErrors["city"]
      return newErrors
    })


  };

  //
  // Bigbus Ticket Modal code

  const [bigbusModel, setBigbusModel] = useState(false);
  const [bigbusId, setBigbusId] = useState("");
  const [bigbusText, setBigbusText] = useState("");
  const [bigbusImage, setBigbusImage] = useState("");
  const [selectedBigbusIndex, setSelectedBigbusIndex] = useState(-1);
  const [updateBigbusTicketpData, setUpdateBigbusTicketpData] = useState(null);

  const toggleBigbusModel = () => {
    console.log("toggleBigbusModel", bigbusModel);
    setBigbusModel(!bigbusModel);
  };

  const closeBigbusModel = () => {
    setBigbusModel(false);
  };

  const selectBigbusRow = (id, title, activity) => {
    setBigbusText(id?.title);
    setBigbusImage(activity?.image);
    console.log("selected data", id);
    const formInput = { ...bannerData, code: id?.id };

    console.log({ formInput });
    setBannerData(formInput);
    closeBigbusModel();
  };

  // ticket option modal code

  const [TicketModalshow, setTicketModalshow] = useState(false);

  const showTicketModel = () => {
    setUpdateTicketpData({});
    setTicketModalshow(true);
  };

  const closeTicketModal = () => {
    setTicketModalshow(false);
  };

  // useEffect(() => {
  //   SelectformData();
  // }, [selectedTicketoption]);

  const SelectformData = (data) => {
    let customtickets = [...selectedTicketoption];
    if (selectedIndex !== -1) {
      customtickets[selectedIndex] = data;
      setselectedTicketoption(customtickets);
      setUpdateTicketpData({});
      setSelectedIndex(-1);
    } else {
      setselectedTicketoption([...selectedTicketoption, data]);
    }
  };

  const handleRemoveTicket = (index) => {
    const newArray = [...selectedTicketoption];
    newArray.splice(index);
    setselectedTicketoption(newArray);
    console.log(selectedTicketoption);
  };

  const handleUpdateTicket = (data, index) => {
    setTicketModalshow(true);
    setSelectedIndex(index);
    setUpdateTicketpData(data);
  };



  const handleBack = () => {
    setActiveStep((prev) => Math.max(0, prev - 1))
  }


  const handleNext = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }) // Scrolls to top smoothly
    if (validateForm()) {
      setActiveStep((prev) => prev + 1)
    }
  }

  const handleNext2 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }) // Scrolls to top smoothly
    if (validateForm2()) {
      setActiveStep((prev) => prev + 1)
    }
  }

  const [errors, setErrors] = useState({})


  const validateForm = () => {
    const newErrors = {}
    if (!bannerData?.title) newErrors.title = 'Title (English) is required and should be at least 3 characters.'
    // if (!description) newErrors.description = 'Description (English) is required.'
    if (!bannerData?.cityId) newErrors.cityId = 'City id is required.'
    if (selectedCategory?.length <= 0) newErrors.selectedCategory = 'Category is required.'
    if (!bannerData?.startingAmount) newErrors.startingAmount = 'Starting Amount must be greater than 0.'
    if (bannerData?.startingAmount <= 0) newErrors.startingAmount = 'Starting Amount must be greater than 0.'
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }


  const validateForm2 = () => {
    const newErrors = {}
    if (imageUrls.length === 0) newErrors.original = 'At least one gallery image is required'
    console.log({ newErrors, bannerData })
    if (!bannerData?.isFreeCancellation) newErrors.isFreeCancellation = 'Free Cancellation is required'
    if (!bannerData?.overview) newErrors.overview = 'Overview (English) is required'
    if (!bannerData?.inclusion) newErrors.inclusion = 'Inclusion (English) is required'

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }







  const validateForm3 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }) // Scrolls to top smoothly

    const newErrors = {}
    if (bannerData?.vat && (!bannerData?.vatPercentage || isNaN(bannerData?.vatPercentage))) {
      newErrors.vatPercentage = 'VAT Percentage is required'
    }

    if (bannerData?.vat && parseFloat(bannerData?.vatPercentage) < 0) {
      newErrors.vatPercentage = 'VAT Percentage must be greater than 0'
    }
    if (parseFloat(bannerData?.markup) < 0) {
      newErrors.markup = 'Markup must be greater than or equal to 0'
    }
    const Infantminage = Number(bannerData?.Infantminage)
    const Infantmaxage = Number(bannerData?.Infantmaxage)
    const Childminage = Number(bannerData?.Childminage)
    const Childmaxage = Number(bannerData?.Childmaxage)
    const adultAge = Number(bannerData?.adultAge)
    console.log('data baba', bannerData, { Infantminage, Infantmaxage })
    // Infant Age Validation
    if (bannerData?.allowInfant) {
      if ((!Infantminage && Infantminage < 0) || !Infantmaxage) {
        newErrors.infanterror = 'Infant age range is required'
      } else if (Infantminage >= Infantmaxage) {
        newErrors.infanterror = 'Infant min age must be less than max age'
      } else if (Infantmaxage > 18) {
        newErrors.infanterror = 'Infant age must not be greater than 18'
      }
    }

    // Child Age Validation
    if (bannerData?.allowChild) {
      if (!Childminage || !Childmaxage) {
        newErrors.childerror = 'Child age range is required'
      } else if (bannerData?.allowInfant) {
        if (Infantmaxage && Infantmaxage > Childminage) {
          newErrors.childerror = 'Child min age must be greater than Infant max age'
        }
      } else if (Childminage >= Childmaxage) {
        newErrors.childerror = 'Child min age must be less than max age'
      } else if (Childmaxage > 18) {
        newErrors.childerror = 'Child age must not be greater than 18'
      }
    }

    // Ensure consistency when both Infant and Child are allowed
    if (bannerData?.allowAdult) {
      if (!adultAge) {
        newErrors.adulterror = 'Adult age is required'
      } else if (bannerData?.allowChild) {
        // If Child is allowed, Adult must be greater than Childmaxage
        if (Childmaxage && adultAge <= Childmaxage) {
          newErrors.adulterror = `Adult age must be greater than ${Childmaxage}`
        }
      } else if (adultAge > 100) {
        newErrors.adulterror = 'Adult age must not be greater than 100'
      }
    }

    const minPax = Number(bannerData?.minimumPax)
    const maxPax = Number(bannerData?.maximumPax)

    if (minPax < 0) {
      newErrors.minimumPax = 'Minimum Pax must be greater than 0'
    }

    if (maxPax < 0) {
      newErrors.maximumPax = 'Maximum Pax must be greater than 0'
    }

    if (minPax > maxPax) {
      newErrors.minimumPax = 'Minimum Pax cannot be greater than Maximum Pax'
    }

    // Update errors state
    setErrors(newErrors)

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }


  const CreateCustomTicketform = async (e) => {
    e.preventDefault();
    if (validateForm3()) {
      setLoader(true)
      if (imageUrls.length === 0) {
        toast.error("Please select at least one Image");
        return;
      }
      if (bannerData?.discount) {
        if (bannerData?.discount < 1 || bannerData?.discount > 99) {
          toast.error("Discount must be between 1 and 99");
          return;
        }
      }

      let imageData = imageUrls.filter((item) => item != null);

      const finalStartingAmount = computeStartingPrice(
        bannerData?.startingAmount,      // base
        parseFloat(bannerData?.markup) || 0,
        parseFloat(bannerData?.discount) || 0,
        bannerData?.vat,
        parseFloat(bannerData?.vatPercentage) || 0
      );


      console.log({ bannerData })


      let payload = {
        title: bannerData.title,
        description: description,
        badge: bannerData.badge,
        city: bannerData.city,
        images: imageData,
        categoryIds: selectedCategory,
        subCategoryIds: subCategoryDataIds,
        code: bannerData?.code,
        discount: bannerData?.discount,
        bookingType: bannerData?.bookingType,
        startingAmount: bannerData?.startingAmount,
        vat: bannerData?.vat,
        vatPercentage: parseInt(bannerData?.vatPercentage) || 0,
        markup: parseInt(bannerData?.markup) || 0,
        infantAge: `${bannerData?.Infantminage}-${bannerData?.Infantmaxage} Yrs`,
        childAge: `${bannerData?.Childminage}-${bannerData?.Childmaxage} Yrs`,
        adultAge: bannerData?.adultAge,
        allowChild: bannerData?.allowChild,
        allowInfant: bannerData?.allowInfant,
        allowAdult: bannerData?.allowAdult,
        overview: bannerData.overview,
        inclusion: bannerData.inclusion,
        isFreeCancellation: bannerData?.isFreeCancellation,
        cancellationPolicy: bannerData?.cancellationPolicy,
        childPolicy: bannerData?.childPolicy,
        ticketOptions: selectedTicketoption,
        features: packages,
        whatsInclude: WhatsInclude,
        isEnableRating: bannerData.isEnableRating,
        cityId: bannerData.cityId,        
        videoUrl: bannerData.videoUrl,
        howToRedeem: bannerData.howToRedeem,
        googleMapUrl: bannerData.googleMapUrl,
        faqDetails: bannerData.faqDetails,
        raynaToursAdvantage: bannerData.raynaToursAdvantage,
        departurePoint: bannerData.departurePoint,
        tourLanguage: bannerData.tourLanguage,
        importantInformation: bannerData.importantInformation,
        usefulInformation: bannerData.usefulInformation,
        tourExclusion: bannerData.tourExclusion,
        // For location: the backend may use these to build a GeoJSON object
        latitude: bannerData.latitude,
        longitude: bannerData.longitude,
      };
      try {
        const response = await postRequest("rayna/custom/create", payload);
        console.log({ response });
        if (response.status === 1) {
          toast.success(response.message);
          setTimeout(() => {
            history.push("/custom/tickets");
          }, 1000);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("API Error:", error);
        toast.error("Something went wrong. Please try again.");
      } finally {
        setLoader(false)
      }
    }
  };

  function computeStartingPrice(baseAmount, markup, discount, vat, vatPercentage) {
    // Convert inputs to valid numbers (or 0)
    let price = parseFloat(baseAmount) || 0;
  
    // 1) Apply Markup
    //    e.g., if price=100 and markup=10 => price=100 + (100*0.10)=110
    if (markup > 0) {
      price += price * (markup / 100);
    }
  
    // 2) Apply Discount
    //    e.g., if price=110 and discount=15 => price=110 - (110*0.15)=93.5
    if (discount > 0) {
      price -= price * (discount / 100);
    }
  
    // 3) Apply VAT if enabled
    //    e.g., if price=93.5 and vatPercentage=5 => price=93.5 + (93.5*0.05)=98.175
    if (vat && vatPercentage > 0) {
      price += price * (vatPercentage / 100);
    }
  
    // Optional: round to 2 decimals
    return parseFloat(price.toFixed(2));
  }



  const optionAddImages = async (id, images) => {

    console.log("images add ", id)


    setLoader(true)
    let payload = {
      id,
      images: images
    };
    try {
      const response = await postRequest("rayna/update-tour-options/admin", payload);
      if (response.status == 1) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoader(false)
    }

  };

  const handleLocationChange = ({ position, address, places }) => {
    const lng = position.lng;
    const lat = position.lat;

    const location = {...currentLocation};

    location.lat = lat;
    location.lng = lng;



    setCurrentLocation(location);

    const venueDt = { ...bannerData, latitude: lat, longitude: lng };
    setBannerData(venueDt);
  };
  
  const updateLocation = (value, type = "lat") => {
    const location = currentLocation;

    let newLocation = {
      lat: location.lat,
      lng: location.lng,
    };

    if (type === "lat") {
      newLocation.lat = parseFloat(value);
    } else {
      newLocation.lng = parseFloat(value);
    }

    let bannerDataTemp = {...bannerData}
    if(type == "lat"){
      bannerDataTemp.latitude = value;
    }else{
      bannerDataTemp.longitude = value;
    }

    setBannerData(bannerDataTemp)

    setCurrentLocation(newLocation);
    // setVenueData({
    //   ...venueData,
    //   latitude: newLocation.lat,
    //   longitude: newLocation.lng,
    // });
  };
  const renderStepOne = () => {
    return (
      <Row>
        <Col lg={12} className="ms-lg-auto">
          <div className="mt-5 mt-lg-4">
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">
                Select Rayna Ticket
              </label>
              <div className="col-sm-6" onClick={() => toggleRaynaModel()}>
                {raynaText ? (
                  <div class="input-group">
                    <button
                      class="btn"
                      style={{
                        border: "1px solid #ced4da",
                        background: "#e60489",
                        color: "#ffff",
                      }}
                      type="button"
                      id="chooseVenueBtn"
                      onclick={() => toggleRaynaModel()}
                    >
                      Choose Ticket
                    </button>
                    {/* <div
                                                style={{
                                                  border: "1px solid #ced4da",
                                                  width: "16px",
                                                }}
                                              ></div> */}
                    {/* <img
                                                src={activityImage}
                                                style={{
                                                  width: "40px",
                                                  height: "40px",
                                                  border: "1px solid #ced4da",
                                                  borderRight: "none",
                                                  borderLeft: "none",
                                                }}
                                              ></img> */}
                    <input
                      type="text"
                      style={{ borderLeft: "none" }}
                      class="form-control"
                      readonly
                      value={raynaText}
                      id="selectedVenue"
                      placeholder="No Ticket chosen"
                    />
                  </div>
                ) : (
                  <div class="input-group">
                    <button
                      class="btn"
                      style={{
                        border: "1px solid #ced4da",
                        background: "#e60489",
                        color: "#ffff",
                      }}
                      type="button"
                      id="chooseVenueBtn"
                      onclick={() => toggleRaynaModel()}
                    >
                      Choose Ticket
                    </button>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      id="selectedVenue"
                      placeholder="No Ticket chosen"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Title</label>
              <div className="col-sm-6">
                <input
                  type="text"
                  onChange={(e) => updateValue("title", e.target.value)}
                  value={bannerData?.title}
                  name="title"
                  className="form-control"
                />

                <div>{errors.title && <div className="text-danger">{errors.title}</div>}</div>

              </div>
            </div>

            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Description</label>
              {/* <div className="col-sm-6">
              <input
                type="text"
                onChange={(e) =>
                  updateValue("description", e.target.value)
                }
                value={bannerData?.description}
                name="description"
                className="form-control"
              />
            </div> */}

              <div className="col-sm-6">
                <ReactQuill
                  style={{
                    height: "100px",
                    width: "100%",
                    marginBottom: "60px",
                  }}
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      [{ align: [] }],
                      ["link"],
                      [{ color: [] }, { background: [] }],
                      ["image"],
                    ],
                  }}
                  value={description || ""}
                  onChange={(value) => setDescription(value)}
                />

                <div>{errors.description && <div className="text-danger">{errors.description}</div>}</div>



              </div>
            </div>

            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Badge</label>
              <div className="col-sm-6">
                <input
                  type="text"
                  onChange={(e) => updateValue("badge", e.target.value)}
                  value={bannerData?.badge}
                  name="badge"
                  className="form-control"
                />
              </div>
            </div>

            {/* <div className="row mb-4">
              <label className="col-sm-3 col-form-label">City</label>
              <div className="col-sm-6">
                <input
                  type="text"
                  onChange={(e) => updateValue("city", e.target.value)}
                  value={bannerData?.city}
                  name="city"
                  className="form-control"
                />
              </div>
            </div> */}



            <div className="row mb-4" style={{ visibility: "hidden" }}>
              <label className="col-sm-3 col-form-label">City</label>
              <div className="col-sm-6">
                <select
                  className="form-select w-100"
                  onChange={handleCityChange}
                  value={bannerData?.city || ""}
                >
                  <option value="">Select</option>
                  {cityOptions.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>

                {errors.city && <div className="text-danger">{errors.city}</div>}

              </div>
            </div>

                         <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select City Id
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue("cityId", e.target.value)
                                }
                                value={bannerData?.cityId}
                                name="cityId"
                                className="form-select"
                              >
                                <option value="">Select City Id</option>
                                {cityList.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>

                              {errors.cityId && <div className="text-danger">{errors.cityId}</div>}


                            </div>
                            </div>



            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">
                Select Category
              </label>
              <div className="col-sm-6">
                <MultiSelect
                  options={categoryList}
                  onSelect={msUpdateCategory}
                  selectedValues={defaultCategory}
                  placeholder="Select Category"
                />

                {errors.selectedCategory && <div className="text-danger">{errors.selectedCategory}</div>}

              </div>
            </div>







            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Starting Amount</label>
              <div className="col-sm-6">
                <input
                  type="number"
                  onChange={(e) =>
                    updateValue("startingAmount", e.target.value)
                  }
                  step="0.01"
                  value={bannerData?.startingAmount}
                  name="startingAmount"
                  min={0}
                  className="form-control"
                />
                {errors.startingAmount && <div className="text-danger">{errors.startingAmount}</div>}

              </div>
            </div>

            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Allow Ratings</label>
              <div className="col-sm-6">
              <input
                  type="checkbox"
                  className="mt-2"
                  onChange={(e) => updateValue('isEnableRating', !bannerData?.isEnableRating)}
                  checked={bannerData?.isEnableRating || ''}
                  name="isEnableRating"
                />
              </div>
            </div>
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Allow Reviews</label>
              <div className="col-sm-6">
                <input
                 className="mt-2"
                  type="checkbox"
                  onChange={(e) => updateValue('isEnableReview', !bannerData?.isEnableReview)}
                  checked={bannerData?.isEnableReview || ''}
                  name="isEnableReview"
                />
              </div>
            </div>



            <div className="mt-5 mt-lg-4">
              <div className="d-flex justify-content-end">
                {activeStep > 0 && (
                  <button type="button" className="btn btn-secondary btn-lg me-2" onClick={handleBack}>
                    Back
                  </button>
                )}
                <button type="button" className="btn btn-primary btn-lg" onClick={handleNext}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderStepTwo = () => {
    return (
      <Row>
        <Col lg={12} className="ms-lg-auto">
          <div className="row mb-4">
            <label
              htmlFor="horizontal-password-input"
              className="col-sm-3 col-form-label"
            >
              Images
            </label>



            <div className="col-sm-6">
              <input
                type="file"
                onChange={(e) => Imageupload(e)}
                name="mediaUrl"
                className="form-control"
                id="horizontal-password-input"
              />
              {errors.original && <div className="text-danger">{errors.original}</div>}

              <br />

                  {imageUrls.length > 1 && (
                <button
                  type="button"
                  className="btn btn-sm btn-info mt-2"
                  onClick={() => setReorderModal(true)}
                >
                  Reorder Images
                </button>
              )}





              <div className="row">
                {imageUrls.map((image, index) => (
                  <div className="d-flex align-items-center flex-column col-md-3">
                    <img
                      key={index} // Add a unique key for each image in the array
                      src={image}
                      style={{
                        width: "100px",
                        height: "100px",
                        padding: "5px",
                      }}
                      alt={`Preview ${index + 1}`} // Provide alt text for accessibility
                    />

                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => RemoveImage(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Col>


        {/* Overview */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Overview </label>
          <div className="col-sm-6">
            <div className="d-flex align-items-center">
              <ReactQuill
                style={{
                  height: '100px',
                  width: '100%',
                  marginBottom: '60px',
                }}
                modules={{
                  toolbar: [
                    [{ header: '1' }, { header: '2' }, { font: [] }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['bold', 'italic', 'underline'],
                    [{ align: [] }],
                    ['link'],
                    [{ color: [] }, { background: [] }],
                    ['image'],
                  ],
                }}
                defaultValue={bannerData?.overview}
                onChange={(value) => updateValue('overview', value)}
              />

            </div>
            <div className="mt-2">{errors.overview && <p className="text-danger">{errors.overview}</p>}</div>
          </div>
        </div>






        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Inclusion</label>
          <div className="col-sm-6">
            <div className="d-flex align-items-center">
              <ReactQuill
                style={{
                  height: '100px',
                  width: '100%',
                  marginBottom: '60px',
                }}
                modules={{
                  toolbar: [
                    [{ header: '1' }, { header: '2' }, { font: [] }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['bold', 'italic', 'underline'],
                    [{ align: [] }],
                    ['link'],
                    [{ color: [] }, { background: [] }],
                    ['image'],
                  ],
                }}
                defaultValue={bannerData?.inclusion}
                onChange={(value) => updateValue('inclusion', value)}
              />


            </div>

            <div className="mt-2">{errors.inclusion && <p className="text-danger">{errors.inclusion}</p>}</div>
          </div>
        </div>




        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">
            Is Free Cancellation Available
          </label>

          <div className="col-sm-6">
            <select
              className="form-control"
              onChange={(e) =>
                updateValue("isFreeCancellation", e.target.value)
              }
              name="isFreeCancellation"
              class="form-select"
            >
              <option value="">Select</option>

              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            <div className="mt-2">{errors.isFreeCancellation && <p className="text-danger">{errors.isFreeCancellation}</p>}</div>

          </div>
        </div>



        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">
            Cancellation Policy
          </label>
          <div className="col-sm-6"> 

            <textarea
              rows="4"
              className="form-control"
              onChange={(e) => updateValue('cancellationPolicy', e.target.value)}
              value={bannerData?.cancellationPolicy}
              placeholder="Enter Cancellation Policy"
            ></textarea>      

            

          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Child Policy </label>
          <div className="col-sm-6">
            <ReactQuill
              style={{
                height: '100px',
                width: '100%',
                marginBottom: '60px',
              }}
              modules={{
                toolbar: [
                  [{ header: '1' }, { header: '2' }, { font: [] }],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['bold', 'italic', 'underline'],
                  [{ align: [] }],
                  ['link'],
                  [{ color: [] }, { background: [] }],
                  ['image'],
                ],
              }}
              defaultValue={bannerData?.childPolicy}
              onChange={(value) => updateValue('childPolicy', value)}
            />

            {/* <div className="mt-2">{errors.childPolicy && <p className="text-danger">{errors.childPolicy}</p>}</div> */}
          </div>
        </div>
        <div className="mt-5 mt-lg-4">
          <div className="d-flex justify-content-end">
            {activeStep > 0 && (
              <button type="button" className="btn btn-secondary btn-lg me-2" onClick={handleBack}>
                Back
              </button>
            )}
            <button className="btn btn-primary btn-lg" onClick={handleNext2}>
              Next
            </button>
          </div>
        </div>

      </Row>
    );
  };


  const renderStepThree = () => {
    return (
      <Row>
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Discount </label>

          <div className="col-sm-6">
            <input
              type="number"
              onChange={(e) => updateValue('discount', e.target.value)}
              value={parseFloat(bannerData?.discount) || ''}
              name="discount"
              className="form-control"
              placeholder="Enter Discount"
              min={0}
            />

            {/* <div className="mt-2">{errors.markup && <p className="text-danger">{errors.markup}</p>}</div> */}
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Markup </label>

          <div className="col-sm-6">
            <input
              type="number"
              onChange={(e) => updateValue('markup', e.target.value)}
              value={parseFloat(bannerData?.markup) || ''}
              name="markup"
              className="form-control"
              placeholder="Enter Markup"
              min={0}
            />

            {/* <div className="mt-2">{errors.markup && <p className="text-danger">{errors.markup}</p>}</div> */}
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">VAT </label>

          <div className="col-sm-6 d-flex ">
            <input
              className="me-2"
              type="checkbox"
              name="vat"
              onChange={(e) => updateValue('vat', !bannerData?.vat)}
              checked={bannerData?.vat || ''}
            />
            {bannerData?.vat && (
              <div className="d-flex flex-column w-100">
                <input
                  type="number"
                  onChange={(e) => updateValue('vatPercentage', e.target.value)}
                  value={parseFloat(bannerData?.vatPercentage) || ''}
                  name="vatPercentage"
                  className="form-control"
                  placeholder="Enter VAT Percentage"
                  min={0}
                  max={100}
                />

                <div className="mt-2">{errors.vatPercentage && <p className="text-danger">{errors.vatPercentage}</p>}</div>
              </div>
            )}
          </div>
        </div>




        <div className="row">
          <label className="col-sm-3 col-form-label">Allow Infant</label>
          <div className="col-sm-6 d-flex ">
            <input
              className="me-2"
              type="checkbox"
              name="allowInfant"
              // onChange={(e) => updateValue('allowInfant', !bannerData?.allowInfant)}
              readOnly              
              checked={bannerData?.allowInfant || ''}
            />
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Infant Age</label>
          {bannerData?.allowInfant == false ? (
            <div className="col-sm-6">Infants Not Allowed</div>
          ) : (
            <>
              <div className="col-sm-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Min Age"
                  onChange={(e) => updateValue('Infantminage', e.target.value)}
                  min={0}
                  max={18}
                  value={bannerData?.Infantminage}
                />
                <div className="mt-2">{errors.infanterror && <p className="text-danger">{errors.infanterror}</p>}</div>
              </div>

              <div className="col-sm-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Max Age"
                  onChange={(e) => updateValue('Infantmaxage', e.target.value)}
                  min={0}
                  max={18}
                  value={bannerData?.Infantmaxage}
                />
                <div className="mt-2">{errors.infanterror && <p className="text-danger">{errors.infanterror}</p>}</div>
              </div>
            </>
          )}
        </div>

        <div className="row">
          <label className="col-sm-3 col-form-label">Allow Child </label>

          <div className="col-sm-6 d-flex ">
            <input
              className="me-2"
              type="checkbox"
              name="allowChild"
              // onChange={(e) => updateValue('allowChild', !bannerData?.allowChild)}
              readOnly
              checked={bannerData?.allowChild || ''}
            />
          </div>
        </div>

        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Child Age</label>
          {bannerData?.allowChild == false ? (
            <div className="col-sm-6">Children Not Allowed</div>
          ) : (
            <>
              <div className="col-sm-3">
                <input
                  type="number"
                  className="form-control"
                  name="Childminage"
                  placeholder="Min Age"
                  onChange={(e) => updateValue('Childminage', e.target.value)}
                  min={bannerData?.Infantmaxage}
                  max={18}
                  value={bannerData?.Childminage}
                />
                <div className="mt-2">{errors.childerror && <p className="text-danger">{errors.childerror}</p>}</div>
              </div>
              <div className="col-sm-3">
                <input
                  type="number"
                  name="Childmaxage"
                  className="form-control"
                  placeholder="Max Age"
                  onChange={(e) => updateValue('Childmaxage', e.target.value)}
                  min={bannerData?.Childminage}
                  max={18}
                  value={bannerData?.Childmaxage}
                />
                <div className="mt-2">{errors.childerror && <p className="text-danger">{errors.childerror}</p>}</div>
              </div>
            </>
          )}
        </div>

        <div className="row">
          <label className="col-sm-3 col-form-label">Allow Adult </label>

          <div className="col-sm-6 d-flex ">
            <input
              className="me-2"
              type="checkbox"
              name="allowAdult"
              // onChange={(e) => updateValue('allowAdult', !bannerData?.allowAdult)}
              readOnly
              checked={bannerData?.allowAdult || ''}
            />
          </div>
        </div>

        {bannerData?.allowAdult === false ? (
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Adult Age </label>
            <div className="col-sm-6">{`${bannerData?.adultAge || 'N/A'} +`}</div>
          </div>
        ) : (
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Adult Age </label>
            <div className="col-sm-6">
              <input
                type="number"
                className="form-control"
                placeholder="Min Age"
                onChange={(e) => updateValue('adultAge', e.target.value)}
                value={bannerData?.adultAge}
                min={bannerData?.Childmaxage || bannerData?.Childminage || 0}
              />
              <div className="mt-2">{errors.adulterror && <p className="text-danger">{errors.adulterror}</p>}</div>
            </div>
          </div>
        )}


        {/* ---------- New Additional Fields Section ---------- */}
        <h5 className="mt-4">Additional Details</h5>
          {/* Latitude & Longitude */}

          <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Location</label>
          <div className="col-sm-6">
            <LocationPicker
              containerElement={<div style={{ height: "100%" }} />}
              mapElement={<div style={{ height: "600px" }} />}
              zoom={14}
              defaultPosition={currentLocation}
              onChange={handleLocationChange}
            />
          </div>
        </div>


          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Latitude</label>
            <div className="col-sm-6">
              <input
                type="number"

                onChange={(e) =>
                  updateLocation(e.target.value, "lat")
                }
                
                value={bannerData?.latitude || ""}
                name="latitude"
                className="form-control"
                placeholder="Select via Google Map Picker"
              />
            </div>
          </div>
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Longitude</label>
            <div className="col-sm-6">
              <input
                type="number"
                onChange={(e) =>
                  updateLocation(e.target.value, "lng")
                }
                value={bannerData?.longitude || ""}
                name="longitude"
                className="form-control"
                placeholder="Select via Google Map Picker"
              />
            </div>
          </div>
          {/* Video URL */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Video URL</label>
            <div className="col-sm-6">
              <input
                type="text"
                onChange={(e) => updateValue("videoUrl", e.target.value)}
                value={bannerData?.videoUrl || ""}
                name="videoUrl"
                className="form-control"
                placeholder="Enter video URL"
              />
            </div>
          </div>
          {/* How To Redeem */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">How To Redeem</label>
            <div className="col-sm-6">
              <ReactQuill
                style={{ height: "100px", width: "100%", marginBottom: "60px" }}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["bold", "italic", "underline"],
                    [{ align: [] }],
                    ["link"],
                    [{ color: [] }, { background: [] }],
                    ["image"],
                  ],
                }}
                defaultValue={bannerData?.howToRedeem || ""}
                onChange={(value) => updateValue("howToRedeem", value)}
              />
            </div>
          </div>
          {/* Google Map URL */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Google Map URL</label>
            <div className="col-sm-6">
              <input
                type="text"
                onChange={(e) => updateValue("googleMapUrl", e.target.value)}
                value={bannerData?.googleMapUrl || ""}
                name="googleMapUrl"
                className="form-control"
                placeholder="Enter Google Map URL"
              />
            </div>
          </div>

                

          {/* FAQ Details */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">FAQ Details</label>
            <div className="col-sm-6">
              <ReactQuill
                style={{ height: "100px", width: "100%", marginBottom: "60px" }}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["bold", "italic", "underline"],
                    [{ align: [] }],
                    ["link"],
                    [{ color: [] }, { background: [] }],
                    ["image"],
                  ],
                }}
                defaultValue={bannerData?.faqDetails || ""}
                onChange={(value) => updateValue("faqDetails", value)}
              />
            </div>
          </div>
          {/* Rayna Tours Advantage */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Rayna Tours Advantage</label>
            <div className="col-sm-6">
              <input
                type="text"
                onChange={(e) => updateValue("raynaToursAdvantage", e.target.value)}
                value={bannerData?.raynaToursAdvantage || ""}
                name="raynaToursAdvantage"
                className="form-control"
                placeholder="Enter advantage details"
              />
            </div>
          </div>
          {/* Departure Point */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Departure Point</label>
            <div className="col-sm-6">
              <input
                type="text"
                onChange={(e) => updateValue("departurePoint", e.target.value)}
                value={bannerData?.departurePoint || ""}
                name="departurePoint"
                className="form-control"
                placeholder="Enter departure point"
              />
            </div>
          </div>
          {/* Tour Language */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Tour Language</label>
            <div className="col-sm-6">
              <input
                type="text"
                onChange={(e) => updateValue("tourLanguage", e.target.value)}
                value={bannerData?.tourLanguage || ""}
                name="tourLanguage"
                className="form-control"
                placeholder="Enter tour language"
              />
            </div>
          </div>
          {/* Important Information */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Important Information</label>
            <div className="col-sm-6">
              <ReactQuill
                style={{ height: "100px", width: "100%", marginBottom: "60px" }}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["bold", "italic", "underline"],
                    [{ align: [] }],
                    ["link"],
                    [{ color: [] }, { background: [] }],
                    ["image"],
                  ],
                }}
                defaultValue={bannerData?.importantInformation || ""}
                onChange={(value) => updateValue("importantInformation", value)}
              />
            </div>
          </div>
          {/* Useful Information */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Useful Information</label>
            <div className="col-sm-6">
              <ReactQuill
                style={{ height: "100px", width: "100%", marginBottom: "60px" }}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["bold", "italic", "underline"],
                    [{ align: [] }],
                    ["link"],
                    [{ color: [] }, { background: [] }],
                    ["image"],
                  ],
                }}
                defaultValue={bannerData?.usefulInformation || ""}
                onChange={(value) => updateValue("usefulInformation", value)}
              />
            </div>
          </div>
          {/* Tour Exclusion */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Tour Exclusion</label>
            <div className="col-sm-6">
              <ReactQuill
                style={{ height: "100px", width: "100%", marginBottom: "60px" }}
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["bold", "italic", "underline"],
                    [{ align: [] }],
                    ["link"],
                    [{ color: [] }, { background: [] }],
                    ["image"],
                  ],
                }}
                defaultValue={bannerData?.tourExclusion || ""}
                onChange={(value) => updateValue("tourExclusion", value)}
              />
            </div>
          </div>



        <PricingPurchaseUnit bannerData={bannerData} setActiveKey={setActiveKey} activeKey={activeKey} OptionImageupload={OptionImageupload} optionimageUrls={optionimageUrls} OptionRemoveImage={OptionRemoveImage} optionAddImages={optionAddImages} />





        <div className="d-flex justify-content-end">
          <button type="button" className="btn btn-secondary btn-lg me-2" onClick={handleBack}>
            Back
          </button>
          {/* On the last step, you can do a "Submit" instead of next */}
          <button type="button" className=" btn btn-primary btn-lg" onClick={CreateCustomTicketform}>
            Submit
          </button>
        </div>
      </Row>
    );
  };



  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Ticket Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Ticket Management</li>
              <li className="breadcrumb-item">Create Custom Ticket </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loader ? (

              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>

            ) : (


              <CardBody>
                <h4 className="card-title"> Create Custom Ticket</h4>

                <div>
                  {activeStep === 0 && renderStepOne()}
                  {activeStep === 1 && renderStepTwo()}
                  {activeStep === 2 && renderStepThree()}

                </div>
              </CardBody>)}
          </Card>
        </Col>

        <IconPackModal
          show={iconModal}
          closeModal={closeIconModal}
          selectRow={selectIconRow}
          selectIncludeRow={selectIncludeIconRow}
        />

        <IconPackModalupdated
          show={iconModalInclude}
          closeModal={closeIconModalInclude}
          selectRow={selectIconRowIWhatsnclude}
          selectIncludeRow={selectIncludeIconRowWhats}
        />
      </Row>

      <ImagesReorderModal
        show={reorderModal}
        closeModal={() => setReorderModal(false)}
        imageUrls={imageUrls}
        onReorderImages={(newOrder) => setImageUrls(newOrder)}
      />

      <RaynaTicketModal
        show={raynaModel}
        closeModal={closeRaynaModel}
        selectRow={selectRaynaRow}
        selectIncludeRow={selectRaynaRow}
        isCustomTicket={true}
      />

      <FileUpload message="File Uploading" status={fileUploadLoader} />
    </React.Fragment>
  );
}
