import React, { useState, useEffect } from "react";
import { Row, Col, Button, Badge } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/src/sweetalert2.scss";
import "./SpecialOfferClaimManagementlist.scss";

import { postRequest } from "../../components/Common/Utils.js";
import { useLocation } from "react-router-dom";

// Placeholder images (replace with actual URLs if available)
const USER_IMAGE_PLACEHOLDER =
  "https://via.placeholder.com/80x80?text=User+Image";
const VENUE_IMAGE_PLACEHOLDER =
  "https://via.placeholder.com/100x60?text=Venue+Image";
const OFFER_IMAGE_PLACEHOLDER =
  "https://via.placeholder.com/100x60?text=Offer+Image";

/**
 * Helper: Check for voucher purchase and return "X <num>"
 */
const getVoucherCount = (row) => {
  if (row.totalPerson && row.totalPerson > 1) {
    return `X ${row.totalPerson}`;
  }
  return null;
};

/**
 * Compute summary: either from brunch items or from billAmount
 */
const calculateSummary = (row) => {
  let subtotal = 0;
  if (row.brunch?.length) {
    // Sum up each brunch item
    row.brunch.forEach((item) => {
      const itemTotal = (item.qty || 0) * (item.pricePerBrunch || 0);
      subtotal += itemTotal;
    });
  } else {
    // Fallback to billAmount
    subtotal = Math.abs(row.billAmount) || 0;
  }
  const vat = 0;
  const discount = 0;
  const finalPrice = subtotal;
  return { subtotal, vat, discount, finalPrice };
};

// ------------------ PRINT & PDF ------------------

/**
 * buildAllItemsHTML: Builds an HTML string for the entire dataset (or single item).
 * This is used for the "Print to PDF" approach.
 */
function buildAllItemsHTML(dataList) {
  // Minimal inline CSS for the printed doc
  const inlineCSS = `
    body {
      font-family: Arial, sans-serif;
      margin: 20px;
      background: #f2f2f2;
    }
    .account-statement {
      width: 80%;
      margin: 0 auto;
    }
    .statement-item {
      background-color: #fff;
      border-radius: 8px;
      padding: 15px;
      min-height: 980px;   
      margin-bottom: 20px;
      box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    }
    .top-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .left-content {
      display: flex;
      flex-direction: column;
    }
    .date-time {
      font-size: 0.875rem;
      color: #777;
      margin-bottom: 0.25rem;
    }
    .title {
      font-weight: 600;
      font-size: 1rem;
      margin-bottom: 0.25rem;
      color: #333;
    }
    .payment-status-badge {
      font-size: 0.8rem;
      text-transform: capitalize;
      padding: 0.4em 0.6em;
      border-radius: 0.25rem;
    }
    .paid {
      background-color: #28a745 !important;
      color: #fff !important;
    }
    .unpaid {
      background-color: #dc3545 !important;
      color: #fff !important;
    }
    .amount {
      font-size: 0.95rem;
    }
    .credit {
      color: green;
    }
    .debit {
      color: red;
    }
    .detail-section {
      margin-top: 15px;
      background-color: #F0F1F2;
      border-radius: 6px;
      padding: 15px;
    }
    .detail-card {
      background-color: #fff;
      border-radius: 8px;
      padding: 15px;
      box-shadow: 0 2px 8px rgba(0,0,0,0.1);
      margin-bottom: 15px;
    }
    .ticket-ui {
      border: 2px dashed #ccc;
      border-radius: 8px;
      padding: 1rem;
      text-align: center;
      margin-bottom: 1rem;
      position: relative;
    }
    .ticket-count {
      font-size: 1.6rem;
      font-weight: 700;
      color: #d9534f;
      margin-bottom: 0.5rem;
    }
    .ticket-title {
      font-size: 1.1rem;
      font-weight: 600;
    }
    .ticket-offer {
      font-size: 0.9rem;
      color: #555;
      margin: 0.3rem 0;
    }
    .ticket-desc {
      font-size: 0.85rem;
      color: #777;
    }
    .invoice-container {
      margin-bottom: 10px;
    }
    .invoice-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    .user-info {
      display: flex;
      align-items: center;
    }
    .user-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
    .offer-venue-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .venue-offer-images {
      margin-bottom: 5px;
    }
    .venue-image,
    .offer-image {
      width: 80px;
      height: 50px;
      object-fit: cover;
      border-radius: 4px;
      margin-left: 5px;
    }
    .invoice-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 0.5rem;
    }
    .invoice-table th {
      text-align: left;
      border-bottom: 1px solid #ccc;
      padding-bottom: 8px;
      font-weight: 600;
      font-size: 0.9rem;
    }
    .invoice-table td {
      border-bottom: 1px solid #eee;
      padding: 6px 0;
      font-size: 0.9rem;
    }
    .summary-table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 0.5rem;
    }
    .summary-table td {
      border-bottom: 1px solid #eee;
      padding: 6px 0;
      font-size: 0.9rem;
    }
    .total-row td {
      font-weight: 600;
      border-bottom: 2px solid #333;
    }
  `;

  // Build the HTML for each row
  const htmlRows = dataList
    .map((row) => {
      const dateTime = new Date(row.createdAt).toLocaleString();
      const voucherCount = getVoucherCount(row);

      // Payment logic
      const isPaid = row.paymentStatus === "paid";
      const sign = isPaid && row.billAmount > 0 ? "+" : "-";
      const payBadgeClass = isPaid ? "paid" : "unpaid";
      const creditDebitClass = isPaid && row.billAmount > 0 ? "credit" : "debit";
      const absAmount = Math.abs(row.billAmount).toFixed(2);

      // Summaries
      let subtotal = 0;
      if (row.brunch?.length) {
        row.brunch.forEach((item) => {
          const itemTotal = (item.qty || 0) * (item.pricePerBrunch || 0);
          subtotal += itemTotal;
        });
      } else {
        subtotal = Math.abs(row.billAmount) || 0;
      }
      const vat = 0;
      const discount = 0;
      const finalPrice = subtotal;

      return `
        <div class="statement-item">
          <div class="top-row">
            <div class="left-content">
              <div class="date-time">${dateTime}</div>
              <div class="title">${row.specialOffer?.title || "N/A"}</div>
            </div>
            <div>
              <span class="payment-status-badge ${payBadgeClass}">
                ${row.paymentStatus || "N/A"}
              </span>
            </div>
            <div class="amount">
              <span class="${creditDebitClass}">
                ${sign} AED ${absAmount}
              </span>
            </div>
          </div>

          <div class="detail-section">
            <div class="detail-card">
              <div class="ticket-ui">
                ${
                  voucherCount
                    ? `<div class="ticket-count">${voucherCount}</div>`
                    : ""
                }
                <h5 class="ticket-title">Special Offer</h5>
                <p class="ticket-offer">
                  Offer: ${row.specialOffer?.title || "No Title"}
                </p>
                <p class="ticket-desc">
                  ${row.specialOffer?.description || "No description provided"}
                </p>
              </div>
              <div class="invoice-container">
                <div class="invoice-header">
                  <div class="user-info">
                    <img
                      class="user-image"
                      src="${row.userImage || USER_IMAGE_PLACEHOLDER}"
                      alt="User"
                    />
                    <div>
                      <h5>${row.userName || "Unknown User"}</h5>
                      <p>ID: ${row.userId || ""}</p>
                      ${
                        row.vipAccess
                          ? `<span style="color:#d9534f; font-weight:bold;">VIP</span>`
                          : ""
                      }
                    </div>
                  </div>
                  <div class="offer-venue-info">
                    <div class="venue-offer-images">
                      <img
                        class="venue-image"
                        src="${row.venueImage || VENUE_IMAGE_PLACEHOLDER}"
                        alt="Venue"
                      />
                      <img
                        class="offer-image"
                        src="${row.offerImage || OFFER_IMAGE_PLACEHOLDER}"
                        alt="Offer"
                      />
                    </div>
                    <div class="venue-offer-text">
                      <h5>${row.venueName || "Unknown Venue"}</h5>
                      <p>Offer: ${row.offerName || "Unknown Offer"}</p>
                    </div>
                  </div>
                </div>
                <div class="invoice-body">
                  ${
                    row.type === "brunch" && row.brunch?.length
                      ? `
                        <h6>Brunch Items (qty × price)</h6>
                        <table class="invoice-table">
                          <thead>
                            <tr>
                              <th>Item</th>
                              <th>Qty</th>
                              <th>Price</th>
                              <th>Calculated</th>
                            </tr>
                          </thead>
                          <tbody>
                            ${row.brunch
                              .map((item) => {
                                const calc =
                                  (item.qty || 0) * (item.pricePerBrunch || 0);
                                return `
                                  <tr>
                                    <td>${item.item}</td>
                                    <td>${item.qty || 0}</td>
                                    <td>${item.pricePerBrunch || 0}</td>
                                    <td>${calc}</td>
                                  </tr>
                                `;
                              })
                              .join("")}
                          </tbody>
                        </table>
                      `
                      : row.type === "total"
                      ? `
                        <div>
                          <p><strong>Total Person:</strong> ${row.totalPerson || 0}</p>
                          <p><strong>Price per Person:</strong> ${
                            row.specialOffer?.pricePerPerson || 0
                          }</p>
                        </div>
                      `
                      : `<p>No brunch/total items available.</p>`
                  }
                </div>
                <div class="invoice-summary">
                  <table class="summary-table">
                    <tbody>
                      <tr>
                        <td>Subtotal</td>
                        <td>AED ${subtotal.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>VAT</td>
                        <td>AED ${vat.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td>${discount}</td>
                      </tr>
                      <tr class="total-row">
                        <td>Total</td>
                        <td>AED ${finalPrice.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div><!-- .invoice-container -->
            </div><!-- .detail-card -->
          </div><!-- .detail-section -->
        </div><!-- .statement-item -->
      `;
    })
    .join("");

  return `
    <html>
      <head>
        <meta charset="utf-8"/>
        <title>Printable Special Offers</title>
        <style>${inlineCSS}</style>
      </head>
      <body>
        <div class="account-statement">
          ${htmlRows}
        </div>
      </body>
    </html>
  `;
}

/**
 * printDocument: Opens a new window, writes the HTML, calls print()
 */
function printDocument(htmlString) {
  const printWindow = window.open("", "_blank");
  if (!printWindow) return;
  printWindow.document.open();
  printWindow.document.write(htmlString);
  printWindow.document.close();
  setTimeout(() => {
    printWindow.focus();
    printWindow.print();
  }, 500);
}

/** Print all items */
function printAllItems(offerData) {
  const htmlString = buildAllItemsHTML(offerData);
  printDocument(htmlString);
}

/** Print single item */
function printSingleItem(row) {
  const htmlString = buildAllItemsHTML([row]);
  printDocument(htmlString);
}

// ------------------ CSV EXPORT ------------------

/** Build a single CSV row string for a row object */
function buildCsvRow(row) {
  // For demonstration, we'll just pick a few fields.
  // Adjust as needed to match your data structure.
  const userName = row.userName || "Unknown User";
  const venueName = row.venueName || "Unknown Venue";
  const offerTitle = row.specialOffer?.title || "No Title";
  const payStatus = row.paymentStatus || "N/A";
  const billAmount = row.billAmount || 0;
  const type = row.type || "N/A";
  const dateStr = new Date(row.createdAt).toLocaleString();

  // Could also incorporate brunch details, etc., but that can be more complex
  return [
    row._id || "",
    dateStr,
    userName,
    venueName,
    offerTitle,
    payStatus,
    type,
    billAmount,
  ].join(",");
}

/** Download CSV for all items */
function downloadAllCSV(offerData) {
  const headers = [
    "ID",
    "Created At",
    "User Name",
    "Venue Name",
    "Offer Title",
    "Payment Status",
    "Type",
    "Bill Amount",
  ].join(",");

  const csvRows = [headers];
  offerData.forEach((row) => {
    csvRows.push(buildCsvRow(row));
  });
  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "special-offers.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/** Download CSV for single item */
function downloadSingleCSV(row) {
  const headers = [
    "ID",
    "Created At",
    "User Name",
    "Venue Name",
    "Offer Title",
    "Payment Status",
    "Type",
    "Bill Amount",
  ].join(",");

  const dataRow = buildCsvRow(row);
  const csvString = headers + "\n" + dataRow;
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", `special-offer-${row._id}.csv`);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// ------------------ MAIN COMPONENT ------------------

const SpecialOfferClaimManagementlist = () => {
  const location = useLocation();
  const [offerData, setOfferData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);

  // Sorting defaults
  const sortField = "createdAt";
  const sortOrder = "desc";

  // ---------------------------- Data Fetching ----------------------------
  const fetchSpecialOfferClaims = async (page) => {
    // 50 items on first page, 25 on subsequent pages
    const limit = page === 1 ? 50 : 25;
    const payload = { limit, page, sortField, sortOrder };

    if (location.state) {
      const { startDate, endDate, paymentStatus } = location.state;
      let filterArgs = [
        { paymentStatus: paymentStatus, type: "eq" },
        { startDate: startDate, type: "date-range" },
        { endDate: endDate, type: "date-range" },
      ];
      payload.filterArgs = filterArgs;
    }

    try {
      // NOTE: Adjust your endpoint if needed
      const response = await postRequest("venue/special-offer-claim/list", payload);
      const data = response.data;
      setTotalItems(data.count);
      if (page === 1) {
        setOfferData(data.list);
      } else {
        setOfferData((prev) => [...prev, ...data.list]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    fetchSpecialOfferClaims(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const toggleRow = (id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
    }
  };

  /**
   * For the UI summary, compute (row.brunch items or billAmount).
   * Discount = 0, VAT = 0 for simplicity.
   */
  const calculateSummary = (row) => {
    let subtotal = 0;
    if (row.brunch?.length) {
      row.brunch.forEach((item) => {
        const itemTotal = (item.qty || 0) * (item.pricePerBrunch || 0);
        subtotal += itemTotal;
      });
    } else {
      subtotal = Math.abs(row.billAmount) || 0;
    }
    const vat = 0;
    const discount = 0;
    const finalPrice = subtotal;
    return { subtotal, vat, discount, finalPrice };
  };

  // ------------------ RENDER ------------------
  return (
    <>
      <ToastContainer />
      <Row>
        <Col className="12">
          <div className="page-title-box">
            <h4>Account Statement</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Account Logs</li>
              <li className="breadcrumb-item">Account Statement</li>
            </ol>
          </div>
        </Col>

        <Col className="col-12">
          {/* "All" Print & CSV Buttons */}
          <div
            className="card text-center"
            style={{ backgroundColor: "#d30b7d", border: "none", marginBottom: "20px" }}
          >
            <div className="card-body" style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
              {/* Print All => buildAllItemsHTML(offerData), open new window, user can save as PDF */}
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => printAllItems(offerData)}
                title="Print All (Save as PDF)"
              >
                <i className="fas fa-print" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
              {/* CSV All */}
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => downloadAllCSV(offerData)}
                title="Download All CSV"
              >
                <i className="fas fa-file-csv" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
            </div>
          </div>

          <div className="account-statement">
            {offerData.length === 0 && (
              <div className="text-center">No data available</div>
            )}

            {offerData.map((row) => {
              const isExpanded = expandedRows.includes(row._id);
              const { subtotal, vat, discount, finalPrice } = calculateSummary(row);
              const voucherCount = getVoucherCount(row);

              return (
                <div className="statement-item" key={row._id}>
                  {/* Main summary row */}
                  <div className="top-row">
                    <button
                      className="expand-btn"
                      onClick={() => toggleRow(row._id)}
                    >
                      {isExpanded ? "–" : "+"}
                    </button>
                    <div className="left-content">
                      <div className="date-time">
                        {new Date(row.createdAt).toLocaleString()}
                      </div>
                      <div className="title">
                        {row.specialOffer?.title || "N/A"}
                      </div>
                    </div>
                    <div className="middle-content">
                      <Badge
                        className={`payment-status-badge ${
                          row.paymentStatus === "paid" ? "paid" : "unpaid"
                        }`}
                      >
                        {row.paymentStatus || "N/A"}
                      </Badge>
                    </div>
                    <div className="right-content">
                      <div className="amount">
                        {row.billAmount > 0 && row.paymentStatus === "paid" ? (
                          <span className="credit">
                            + AED {row.billAmount.toFixed(2)}
                          </span>
                        ) : (
                          <span className="debit">
                            - AED {Math.abs(row.billAmount).toFixed(2)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Expanded detail */}
                  {isExpanded && (
                    <div className="detail-section">
                      <div className="detail-card">
                        {/* Ticket/Voucher UI */}
                        <div className="ticket-ui">
                          {voucherCount && (
                            <div className="ticket-count">{voucherCount}</div>
                          )}
                          <h5 className="ticket-title">Special Offer</h5>
                          <p className="ticket-offer">
                            Offer: {row.specialOffer?.title || "No Title"}
                          </p>
                          <p className="ticket-desc">
                            {row.specialOffer?.description || "No description provided"}
                          </p>
                        </div>

                        {/* Invoice-like details */}
                        <div className="invoice-container">
                          <div className="invoice-header">
                            <div className="user-info">
                              <img
                                src={row.userImage || USER_IMAGE_PLACEHOLDER}
                                alt="User"
                                className="user-image"
                              />
                              <div>
                                <h5>{row.userName || "Unknown User"}</h5>
                                <p>ID: {row.userId}</p>
                                {row.vipAccess && (
                                  <Badge color="warning" className="vip-badge">
                                    VIP
                                  </Badge>
                                )}
                              </div>
                            </div>
                            <div className="offer-venue-info">
                              <div className="venue-offer-images">
                                <img
                                  src={row.venueImage || VENUE_IMAGE_PLACEHOLDER}
                                  alt="Venue"
                                  className="venue-image"
                                />
                                <img
                                  src={row.offerImage || OFFER_IMAGE_PLACEHOLDER}
                                  alt="Offer"
                                  className="offer-image"
                                />
                              </div>
                              <div className="venue-offer-text">
                                <h5>{row.venueName || "Unknown Venue"}</h5>
                                <p>Offer: {row.offerName || "Unknown Offer"}</p>
                              </div>
                            </div>
                          </div>

                          {/* Brunch or total items */}
                          <div className="invoice-body">
                            {row.type === "brunch" && row.brunch?.length ? (
                              <>
                                <h6>Brunch Items (qty × price)</h6>
                                <table className="invoice-table">
                                  <thead>
                                    <tr>
                                      <th>Item</th>
                                      <th>Qty</th>
                                      <th>Price</th>
                                      <th>Calculated</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {row.brunch.map((item) => {
                                      const calc =
                                        (item.qty || 0) * (item.pricePerBrunch || 0);
                                      return (
                                        <tr key={item._id}>
                                          <td>{item.item}</td>
                                          <td>{item.qty}</td>
                                          <td>{item.pricePerBrunch || 0}</td>
                                          <td>{calc}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </>
                            ) : row.type === "total" ? (
                              <div className="total-info">
                                <p>
                                  <strong>Total Person:</strong>{" "}
                                  {row.totalPerson || 0}
                                </p>
                                <p>
                                  <strong>Price per Person:</strong>{" "}
                                  {row.specialOffer?.pricePerPerson || 0}
                                </p>
                              </div>
                            ) : (
                              <p>No brunch/total items available.</p>
                            )}
                          </div>

                          {/* Summaries */}
                          <div className="invoice-summary">
                            <table className="summary-table">
                              <tbody>
                                <tr>
                                  <td>Subtotal</td>
                                  <td>AED {subtotal.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td>VAT</td>
                                  <td>AED {vat.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td>Discount</td>
                                  <td>{discount}</td>
                                </tr>
                                <tr className="total-row">
                                  <td>Total</td>
                                  <td>AED {finalPrice.toFixed(2)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      {/* Per-item print & CSV */}
                      <div style={{ display: "flex", justifyContent: "center", gap: "15px", marginTop: "10px" }}>
                        {/* Print single => user can choose Save as PDF */}
                        <button
                          style={{ border: "none", background: "none" }}
                          onClick={() => printSingleItem(row)}
                          title="Print Single (Save as PDF)"
                        >
                          <i className="fas fa-print" style={{ color: "#d30b7d", fontSize: "1.2rem" }}></i>
                        </button>
                        {/* Single CSV */}
                        <button
                          style={{ border: "none", background: "none" }}
                          onClick={() => downloadSingleCSV(row)}
                          title="Download Single CSV"
                        >
                          <i className="fas fa-file-csv" style={{ color: "#d30b7d", fontSize: "1.2rem" }}></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            {offerData.length > 0 && offerData.length < totalItems && (
              <div className="text-center mt-3">
                <Button color="primary" onClick={handleLoadMore}>
                  Load More
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SpecialOfferClaimManagementlist;
