import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  postRequestForm,
  postRequest,
  getRequestNew,
} from "../../components/Common/Utils.js";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import PricingPurchaseUnit from "components/PricingPurchaseUnit/PricingPurchaseUnit.jsx";
import RaynaTicketModal from "components/Modal/RaynaTicketModal.js";
import IconPackModal from "components/Modal/IconPackModal.js";
import IconPackModalupdated from "components/Modal/IconPackModalupdated.js";
import LocationPicker from "react-location-picker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import ImagesReorderModal from "./ImagesReorderModal";

export default function UpdatedCustomTicket() {
  const { id } = useParams();
  const history = useHistory();
  const locationHook = useLocation();
  const state = locationHook.state?.row; // Data passed from previous page

  // Step flow
  const [activeStep, setActiveStep] = useState(0);

  // Loader & errors
  const [loader, setLoader] = useState(false);
  const [ShowLoder, setShowLoder] = useState(true);
  const [errors, setErrors] = useState({});

  // Basic form data
   const [bannerData, setBannerData] = useState(state);


  const [description, setDescription] = useState("");

  // Category & city
  const [categoryList, setCategoryList] = useState([]);
  const [defaultCategory, setdefaultCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [cityOptions, setCityOptions] = useState([
    "abu dhabi",
    "ajman",
    "dubai",
    "fujairah",
    "sharjah",
    "sas al khaimah",
    "umm al quwain",
  ]);

  // Images
  const [imageUrls, setImageUrls] = useState([]);
  const [reorderModal, setReorderModal] = useState(false); // For reordering images
  const [fileUploadLoader, setFileUploadLoader] = useState(false);

  // Icon pack modals
  const [iconModal, setIconModal] = useState(false);
  const [iconModalInclude, seticonModalInclude] = useState(false);

  // “features” & “whatsInclude”
  const [packages, setPackages] = useState([]);
  const [WhatsInclude, setIncludePackages] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [selectedIconInclude, setselectedIconInclude] = useState("");

  // Rayna ticket modal
  const [raynaModel, setRaynaModel] = useState(false);
  const [cityList, setCityList] = useState([]);

  // For PricingPurchaseUnit (Rayna Tour Options)
  const [optionimageUrls, setoptionimageUrls] = useState([]);
  const [activeKey, setActiveKey] = useState(null);

  // Location
  const defaultPosition = { lat: 25.2048345, lng: 55.2708345 };
  const [currentLocation, setCurrentLocation] = useState(fetchDeafultLocation(state.location));

  // Quill modules for text editors
  const quillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      ["link"],
      [{ color: [] }, { background: [] }],
      ["image"],
    ],
  };

  function fetchDeafultLocation(location){
    try {

      

      if(location && location?.coordinates?.length){

        return{
          lat: location?.coordinates[1],
          lng: location?.coordinates[0]
        }
      }


      
    } catch (error) {
      console.log("issue");
        return defaultPosition
    }
  }




  /* 
   |--------------------------------------------------------------------------
   | COMPONENT DID MOUNT / INITIALIZATION
   |--------------------------------------------------------------------------
  */
  useEffect(() => {
    // If we already have data in `state`, populate the form
    if (state) {

      let localtionTemp = fetchDeafultLocation(state.location);

      if(localtionTemp?.lat){
        state.latitude = localtionTemp?.lat;
        state.longitude = localtionTemp?.lng;
      }



      setBannerData(state);
      setDescription(state?.description || "");
      setLoader(false);
      setShowLoder(false);



      // If we have categories from state, set them as defaults
      if (Array.isArray(state?.categoryIds)) {
        setSelectedCategory(state.categoryIds);
      }

      if (Array.isArray(state?.images)) {
        setImageUrls(state.images);
      }

      if (Array.isArray(state?.features)) {
        setPackages(state.features);
      }

      if (Array.isArray(state?.whatsInclude)) {
        setIncludePackages(state.whatsInclude);
      }
    }

    // Fetch category list for MultiSelect
    fetchCategoryList();
    fetchCityList();
  }, [state]);

  const fetchCategoryList = async () => {
    try {
      const param = { page: 1, limit: 100000 };
      const category = await postRequest("rayna/category/list", param);
      if (category.status === 1) {
        const options = category.data.list.map((item) => ({
          id: item._id,
          name: item.title,
        }));

        setCategoryList(options);

        // If we have existing categoryIds from state, filter them
        if (Array.isArray(state?.categoryIds)) {
          const filtered = options.filter((opt) =>
            state.categoryIds.includes(opt.id)
          );
          setdefaultCategory(filtered);
        }
      }
    } catch (err) {
      console.error("Failed to fetch categories:", err);
      toast.error("Something went wrong while fetching categories.");
    }
  };
  const fetchCityList = async () => {    
    const response = await postRequest("rayna/ticket/city/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          _id: item._id,
          name: item.name,
        };
      });
      setCityList(list);
    }
    // setLoading(false);
  };

  /*
   |--------------------------------------------------------------------------
   | IMAGE HANDLERS (Gallery + Reordering)
   |--------------------------------------------------------------------------
  */
  const Imageupload = async (e) => {
    if (!e.target.files[0]) return;
    setFileUploadLoader(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const response = await postRequestForm("comman/doc/upload", formData);
      const uploadedImage = response.data.url;
      setImageUrls((prev) => [...prev, uploadedImage]);
      // Clear the original error about images if any
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors["original"];
        return newErrors;
      });
    } catch (error) {
      console.error("Image upload error:", error);
      toast.error("Failed to upload image. Please try again.");
    } finally {
      setFileUploadLoader(false);
    }
  };

  const RemoveImage = (index) => {
    const newImages = imageUrls.filter((_, i) => i !== index);
    setImageUrls(newImages);
  };

  /*
   |--------------------------------------------------------------------------
   | LOCATION HANDLERS
   |--------------------------------------------------------------------------
  */
  const handleLocationChange = ({ position }) => {
    const { lat, lng } = position;
    setCurrentLocation({ lat, lng });
    setBannerData((prev) => ({ ...prev, latitude: lat, longitude: lng }));
  };

  const updateLocation = (value, type = "lat") => {
    const newLocation = { ...currentLocation };
    if (type === "lat") newLocation.lat = parseFloat(value);
    else newLocation.lng = parseFloat(value);

    setCurrentLocation(newLocation);
    setBannerData((prev) => ({
      ...prev,
      latitude: newLocation.lat,
      longitude: newLocation.lng,
    }));
  };

  /*
   |--------------------------------------------------------------------------
   | ICON PACK MODALS
   |--------------------------------------------------------------------------
  */
  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };
  const closeIconModal = () => {
    setIconModal(false);
  };
  const selectIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };
  const selectIncludeIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  // For "whatsInclude" array
  const openIconModalInclude = (index) => {
    setselectedIconInclude(index);
    seticonModalInclude(true);
  };
  const closeIconModalInclude = () => {
    seticonModalInclude(false);
  };
  const selectIconRowIWhatsnclude = (icon) => {
    const packagesList = [...WhatsInclude];
    packagesList[selectedIconInclude].icon = icon.image;
    packagesList[selectedIconInclude].iconName = icon.title;
    setIncludePackages(packagesList);
    closeIconModalInclude();
  };
  const selectIncludeIconRowWhats = (icon) => {
    const packagesList = [...WhatsInclude];
    packagesList[selectedIconInclude].icon = icon.image;
    packagesList[selectedIconInclude].iconName = icon.title;
    setIncludePackages(packagesList);
    closeIconModalInclude();
  };

  /*
   |--------------------------------------------------------------------------
   | FEATURES & WHAT’S INCLUDED HANDLERS
   |--------------------------------------------------------------------------
  */
  const addRow = () => {
    setPackages([...packages, { icon: "", feature: "", iconName: "" }]);
  };

  const addIncludeRow = () => {
    setIncludePackages([
      ...WhatsInclude,
      { icon: "", feature: "", iconName: "" },
    ]);
  };

  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };

  const updatePackageFieldInclude = (index, fieldName, value) => {
    const newPackages = [...WhatsInclude];
    newPackages[index][fieldName] = value;
    setIncludePackages(newPackages);
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((_, i) => i !== index);
    setPackages(newPackages);
  };

  const deleteIncludePackage = (index) => {
    const newPackages = WhatsInclude.filter((_, i) => i !== index);
    setIncludePackages(newPackages);
  };

  /*
   |--------------------------------------------------------------------------
   | RAYNA TICKET & RAYNA DETAILS
   |--------------------------------------------------------------------------
  */
  const toggleRaynaModel = () => {
    setRaynaModel(!raynaModel);
  };

  // 1) Called after user selects a ticket in the RaynaTicketModal
  const selectRaynaRow = async (raynaData) => {
    try {
      // Update city if cityName is present
      const raynaCityName = raynaData?.cityName?.trim() || "";
      if (
        raynaCityName &&
        !cityOptions.map((c) => c.toLowerCase()).includes(raynaCityName.toLowerCase())
      ) {
        setCityOptions((prev) => [...prev, raynaCityName]);
      }

      // Attempt to form an image URL
      const imagePath = encodeURIComponent(raynaData?.imagePath || "");
      const imageUrl = `https://d1i3enf1i5tb1f.cloudfront.net/${imagePath}`;
      let validImage = false;
      try {
        const response = await getRequestNew(imageUrl);
        if (response.status === 200) {
          validImage = true;
        }
      } catch (error) {
        console.log("Image not found or not accessible", error);
      }

      // Update local state
      const updated = {
        ...bannerData,
        code: raynaData?.tourId,
        title: raynaData?.name || "",
        city: raynaCityName,
      };

      setDescription(raynaData?.tourShortDescription || "");

      // If the remote image is valid, show it in the gallery
      const newImages = validImage ? [imageUrl] : [];
      setImageUrls(newImages);

      // Put data in bannerData
      setBannerData(updated);
      setRaynaModel(false);

      // 2) Also call RayanDetails to fetch PricingPurchase
      await RayanDetails(raynaData);
    } catch (err) {
      console.error("Error selecting Rayna row:", err);
      toast.error("Failed to select Rayna ticket.");
    }
  };

  // 2) This function fetches the Rayna "tour-options" and updates bannerData.PricingPurchase
  // const RayanDetails = async (rowData) => {
  //   try {
  //     /*
  //       Example payload. Adjust contractId if needed. 
  //       In older code, you had something like:
  //         const futureDate = new Date();
  //         futureDate.setDate(futureDate.getDate() + 5);
  //     */
  //     const futureDate = new Date();
  //     futureDate.setDate(futureDate.getDate() + 5);

  //     const payload = {
  //       tourId: rowData?.tourId,
  //       contractId: rowData?.contractId || 300,
  //       date: futureDate.toISOString().split("T")[0],
  //       noOfAdult: 1,
  //       noOfChild: 0,
  //       noOfInfant: 0,
  //     };

  //     const response = await postRequest("rayna/tour-options/admin", payload);
  //     if (response.status === 1) {
  //       // The main data array
  //       const dataArr = response?.data?.options || [];
  //       const detailData = response?.data?.tourDetailsData;

  //       // Build PricingPurchase array with composite key
  //       const PricingPurchase = [
  //         ...new Map(
  //           dataArr.map((item) => [
  //             `${item.tourId}_${item.tourOptionId}_${item.transferId}`,
  //             {
  //               tourId: item.tourId,
  //               tourOptionId: item.tourOptionId,
  //               transferId: item.transferId,
  //               transferName: item.transferName,
  //               startTime: item.startTime,
  //               adultPrice: item.adultPrice,
  //               childPrice: item.childPrice,
  //               infantPrice: item.infantPrice,
  //               optionName: item?.optionDetail?.optionName,
  //               imageUrls: item?.optionDetail?.images || [],
  //             },
  //           ])
  //         ).values(),
  //       ];

  //       // Optional: find the lowest adultPrice
  //       let lowestAdultPrice = null;
  //       if (PricingPurchase.length > 0) {
  //         lowestAdultPrice = PricingPurchase.reduce((min, item) => {
  //           return item.adultPrice < min.adultPrice ? item : min;
  //         }, PricingPurchase[0]).adultPrice;
  //       }

  //       // Additional detail fields from "tourDetailsData"
  //       // e.g., detailData?.faqDetails, etc.
  //       setBannerData((prev) => ({
  //         ...prev,
  //         // Basic policy info from older code or from detailData
  //         cancellationPolicy: detailData?.cancellationPolicyName || "",
  //         childPolicy: detailData?.childCancellationPolicyDescription || "",
  //         overview: detailData?.itenararyDescription || "",
  //         inclusion: detailData?.tourInclusion || "",
  //         tourExclusion: detailData?.tourExclusion || "",
  //         videoUrl: detailData?.videoUrl || "",
  //         howToRedeem: detailData?.howToRedeem || "",
  //         faqDetails: detailData?.faqDetails || "",
  //         googleMapUrl: detailData?.googleMapUrl || "",
  //         raynaToursAdvantage: detailData?.raynaToursAdvantage || "",
  //         usefulInformation: detailData?.usefulInformation || "",
  //         importantInformation: detailData?.importantInformation || "",
  //         departurePoint: detailData?.departurePoint || "",
  //         tourLanguage: detailData?.tourLanguage || "",
  //         latitude: detailData?.latitude || "",
  //         longitude: detailData?.longitude || "",

  //         // Price data
  //         PricingPurchase,
  //         startingAmount: lowestAdultPrice || 0,
  //       }));

  //       // Optionally also set location states
  //       if (detailData?.latitude && detailData?.longitude) {
  //         const latNum = parseFloat(detailData.latitude);
  //         const lngNum = parseFloat(detailData.longitude);
  //         setCurrentLocation({ lat: latNum, lng: lngNum });
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching Rayan details:", error);
  //     toast.error("Failed to fetch Rayna tour options.");
  //   }
  // };
   

  const RayanDetails = async (rowData) => {
    try {
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() + 5);
  
      const payload = {
        tourId: rowData?.tourId,
        contractId: rowData?.contractId || 300,
        date: futureDate.toISOString().split("T")[0],
        noOfAdult: 1,
        noOfChild: 0,
        noOfInfant: 0,
      };
  
      const response = await postRequest("rayna/tour-options/admin", payload);
      if (response.status === 1) {
        const dataArr = response?.data?.options || [];
        const detailData = response?.data?.tourDetailsData;
  
        // Composite key-based unique list
        const PricingPurchase = [
          ...new Map(
            dataArr.map((item) => [
              `${item.tourId}_${item.tourOptionId}_${item.transferId}`,
              {
                tourId: item.tourId,
                tourOptionId: item.tourOptionId,
                transferId: item.transferId,
                transferName: item.transferName,
                startTime: item.startTime,
                adultPrice: item.adultPrice,
                childPrice: item.childPrice,
                infantPrice: item.infantPrice,
                optionName: item?.optionDetail?.optionName,
                imageUrls: item?.optionDetail?.images || [],
              },
            ])
          ).values(),
        ];
  
        // Lowest adult price
        let lowestAdultPrice = null;
        if (PricingPurchase.length > 0) {
          lowestAdultPrice = PricingPurchase.reduce((min, item) =>
            item.adultPrice < min.adultPrice ? item : min,
          PricingPurchase[0]).adultPrice;
        }
  
        // Age range handling (same as before)
        const infantAgeText = detailData?.infantAge?.split(' ')[0] || '';
        const childAgeText = detailData?.childAge?.split(' ')[0] || '';
  
        const infantAgeRange = infantAgeText.split('-') || [];
        const childAgeRange = childAgeText.split('-') || [];
  
        const infantMin = parseInt(infantAgeRange[0] || '0', 10);
        const infantMax = parseInt(infantAgeRange[1] || '0', 10);
  
        const childMin = parseInt(childAgeRange[0] || '0', 10);
        const childMax = parseInt(childAgeRange[1] || '0', 10);
  
        const isInfantAllowed = !(infantMin === 0 && infantMax === 0);
        const isChildAllowed = !(childMin === 0 && childMax === 0);

        console.log("detailData",detailData);

        // console.log ages and allow flags

        console.log("isInfantAllowed",isInfantAllowed);
        console.log("isChildAllowed",isChildAllowed);
        console.log("infantAgeRange",infantAgeRange);
        console.log("childAgeRange",childAgeRange);
        console.log("infantAgeText",infantAgeText);
        console.log("childAgeText",childAgeText);
        console.log("infantMin",infantMin);
        console.log("infantMax",infantMax);
        console.log("childMin",childMin);
        console.log("childMax",childMax);

        let cityName = rowData.cityName;
        let selectedCityId = cityList.find((city) => city.name === cityName)?._id || '';

  
        setBannerData((prev) => ({
          ...prev,
          // Details
          cancellationPolicy: detailData?.cancellationPolicyName || "",
          childPolicy: detailData?.childCancellationPolicyDescription || "",
          overview: detailData?.itenararyDescription || "",
          inclusion: detailData?.tourInclusion || "",
          tourExclusion: detailData?.tourExclusion || "",
          videoUrl: detailData?.videoUrl || "",
          howToRedeem: detailData?.howToRedeem || "",
          faqDetails: detailData?.faqDetails || "",
          googleMapUrl: detailData?.googleMapUrl || "",
          raynaToursAdvantage: detailData?.raynaToursAdvantage || "",
          usefulInformation: detailData?.usefulInformation || "",
          importantInformation: detailData?.importantInformation || "",
          departurePoint: detailData?.departurePoint || "",
          tourLanguage: detailData?.tourLanguage || "",
          latitude: detailData?.latitude || "",
          longitude: detailData?.longitude || "",
          cityId: selectedCityId,
          // Age-based rules
          allowAdult:  true,
          allowChild: isChildAllowed,
          allowInfant: isInfantAllowed,
          Infantminage: infantAgeRange[0] || '',
          Infantmaxage: infantAgeRange[1] || '',
          Childminage: childAgeRange[0] || '',
          Childmaxage: childAgeRange[1] || '',
          adultAge: childAgeRange[1] || '',
  
          // Pricing
          PricingPurchase,
          startingAmount: lowestAdultPrice || bannerData?.startingAmount || 0,
          startingBaseAmount: lowestAdultPrice || bannerData?.startingAmount || 0,
        }));
  
        if (detailData?.latitude && detailData?.longitude) {
          setCurrentLocation({
            lat: parseFloat(detailData.latitude),
            lng: parseFloat(detailData.longitude)
          });
        }
      }
    } catch (error) {
      console.error("Error fetching Rayan details:", error);
      toast.error("Failed to fetch Rayna tour options.");
    }
  };
  


  /*
   |--------------------------------------------------------------------------
   | PRICING PURCHASE UNIT HANDLERS
   |--------------------------------------------------------------------------
  */
  const OptionImageupload = async (e, tourOptionId) => {
    if (!e.target.files[0]) return;
    setFileUploadLoader(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    try {
      const response = await postRequestForm("comman/doc/upload", formData);
      let uploadedUrl = response?.data?.url;
      setBannerData((prev) => ({
        ...prev,
        PricingPurchase: prev.PricingPurchase?.map((item) =>
          item.tourOptionId === tourOptionId
            ? { ...item, imageUrls: [...(item.imageUrls || []), uploadedUrl] }
            : item
        ),
      }));
    } catch (error) {
      console.error("Option image upload error:", error);
      toast.error("Failed to upload image for option.");
    } finally {
      setFileUploadLoader(false);
    }
  };

  const OptionRemoveImage = (tourOptionId, indexToRemove) => {
    setBannerData((prev) => ({
      ...prev,
      PricingPurchase: prev.PricingPurchase?.map((item) =>
        item.tourOptionId === tourOptionId
          ? {
              ...item,
              imageUrls: item.imageUrls?.filter(
                (_, idx) => idx !== indexToRemove
              ),
            }
          : item
      ),
    }));
  };

  const optionAddImages = async (id, images) => {
    try {
      setLoader(true);
      const payload = { id, images };
      const response = await postRequest("rayna/update-tour-options/admin", payload);
      if (response.status === 1) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("optionAddImages error:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  /*
   |--------------------------------------------------------------------------
   | MULTISELECT & FIELD UPDATES
   |--------------------------------------------------------------------------
  */
  const msUpdateCategory = (selectedList) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedCategory(ids);
    setErrors((prev) => {
      const newErr = { ...prev };
      delete newErr.selectedCategory;
      return newErr;
    });
  };

  const updateValue = (key, value) => {
    setBannerData((prev) => ({
      ...prev,
      [key]: value,
    }));

    // Some immediate field-level error clearing
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[key];
      if (["Infantminage", "Infantmaxage"].includes(key)) {
        delete newErrors.infanterror;
      }
      if (["Childminage", "Childmaxage"].includes(key)) {
        delete newErrors.childerror;
      }
      if (key === "adultAge") {
        delete newErrors.adulterror;
      }
      return newErrors;
    });
  };

  /*
   |--------------------------------------------------------------------------
   | FORM STEPS & VALIDATION
   |--------------------------------------------------------------------------
  */
  const handleNext = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (validateFormStep1()) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleNext2 = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (validateFormStep2()) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => Math.max(0, prev - 1));
  };

  // Step 1 validation
  const validateFormStep1 = () => {
    const newErrors = {};
    if (!bannerData?.title || bannerData.title.trim().length < 3) {
      newErrors.title =
        "Title (English) is required and should be at least 3 characters.";
    }
    if (!bannerData?.cityId) newErrors.cityId = 'City id is required.'
    if (!selectedCategory || selectedCategory.length === 0) {
      newErrors.selectedCategory = "Category is required.";
    }
    if (!bannerData?.startingAmount || bannerData?.startingAmount <= 0) {
      newErrors.startingAmount = "Starting Amount must be greater than 0.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Step 2 validation
  const validateFormStep2 = () => {
    const newErrors = {};
    if (imageUrls.length === 0) {
      newErrors.original = "At least one gallery image is required";
    }
    if (!bannerData?.overview) {
      newErrors.overview = "Overview (English) is required.";
    }
    if (!bannerData?.inclusion) {
      newErrors.inclusion = "Inclusion (English) is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Step 3 validation
  const validateFormStep3 = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const newErrors = {};

    // Example checks
    if (bannerData?.vat && (!bannerData?.vatPercentage || isNaN(bannerData?.vatPercentage))) {
      newErrors.vatPercentage = "VAT Percentage is required.";
    }
    if (bannerData?.vat && parseFloat(bannerData?.vatPercentage) < 0) {
      newErrors.vatPercentage = "VAT Percentage must be greater than 0.";
    }
    if (parseFloat(bannerData?.markup) < 0) {
      newErrors.markup = "Markup must be greater than or equal to 0.";
    }

    // Age validations
    const Infantminage = Number(bannerData?.Infantminage);
    const Infantmaxage = Number(bannerData?.Infantmaxage);
    const Childminage = Number(bannerData?.Childminage);
    const Childmaxage = Number(bannerData?.Childmaxage);
    const adultAge = Number(bannerData?.adultAge);

    if (bannerData?.allowInfant) {
      if (!Infantminage || !Infantmaxage) {
        newErrors.infanterror = "Infant age range is required.";
      } else if (Infantminage >= Infantmaxage) {
        newErrors.infanterror = "Infant min age must be less than max age.";
      } else if (Infantmaxage > 18) {
        newErrors.infanterror = "Infant max age must not be greater than 18.";
      }
    }
    if (bannerData?.allowChild) {
      if (!Childminage || !Childmaxage) {
        newErrors.childerror = "Child age range is required.";
      } else if (
        bannerData?.allowInfant &&
        Infantmaxage &&
        Infantmaxage > Childminage
      ) {
        newErrors.childerror =
          "Child min age must be greater than Infant max age.";
      } else if (Childminage >= Childmaxage) {
        newErrors.childerror = "Child min age must be less than max age.";
      } else if (Childmaxage > 18) {
        newErrors.childerror = "Child max age must not be greater than 18.";
      }
    }
    if (bannerData?.allowAdult) {
      if (!adultAge) {
        newErrors.adulterror = "Adult age is required.";
      } else if (bannerData?.allowChild && Childmaxage && adultAge <= Childmaxage) {
        newErrors.adulterror = `Adult age must be greater than ${Childmaxage}.`;
      } else if (adultAge > 100) {
        newErrors.adulterror = "Adult age must not be greater than 100.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /*
   |--------------------------------------------------------------------------
   | SUBMIT HANDLER
   |--------------------------------------------------------------------------
  */
  const CreateCustomTicketform = async (e) => {
    e.preventDefault();
    if (!validateFormStep3()) return;

    setLoader(true);

    const finalStartingAmount = computeStartingPrice(
      bannerData?.startingAmount,
      parseFloat(bannerData?.markup) || 0,
      parseFloat(bannerData?.discount) || 0,
      bannerData?.vat,
      parseFloat(bannerData?.vatPercentage) || 0
    );

    let payload = {
      customTicketId: id,
      title: bannerData?.title,
      description,
      badge: bannerData?.badge,
      city: bannerData?.city,
      images: imageUrls,
      categoryIds: selectedCategory,
      code: bannerData?.code, // from the Rayna selection
      bookingType: bannerData?.bookingType,
      startingAmount: bannerData?.startingAmount,
      discount: bannerData?.discount,
      isFreeCancellation: bannerData?.isFreeCancellation,
      cancellationPolicy: bannerData?.cancellationPolicy,
      childPolicy: bannerData?.childPolicy,
      features: packages,
      whatsInclude: WhatsInclude,
      overview: bannerData?.overview,
      inclusion: bannerData?.inclusion,
      vat: bannerData?.vat,
      vatPercentage: parseInt(bannerData?.vatPercentage) || 0,
      markup: parseInt(bannerData?.markup) || 0,
      infantAge: `${bannerData?.Infantminage || 0}-${bannerData?.Infantmaxage || 0} Yrs`,
      childAge: `${bannerData?.Childminage || 0}-${bannerData?.Childmaxage || 0} Yrs`,
      adultAge: bannerData?.adultAge,
      allowChild: bannerData?.allowChild,
      allowInfant: bannerData?.allowInfant,
      allowAdult: bannerData?.allowAdult,
      isEnableRating: bannerData?.isEnableRating,
      isEnableReview: bannerData?.isEnableReview,
      cityId: bannerData.cityId, 

      // Additional fields
      latitude: bannerData?.latitude,
      longitude: bannerData?.longitude,
      videoUrl: bannerData?.videoUrl,
      howToRedeem: bannerData?.howToRedeem,
      googleMapUrl: bannerData?.googleMapUrl,
      faqDetails: bannerData?.faqDetails,
      raynaToursAdvantage: bannerData?.raynaToursAdvantage,
      departurePoint: bannerData?.departurePoint,
      tourLanguage: bannerData?.tourLanguage,
      importantInformation: bannerData?.importantInformation,
      usefulInformation: bannerData?.usefulInformation,
      tourExclusion: bannerData?.tourExclusion,
    };

    try {
      const response = await postRequest("rayna/custom/update", payload);
      if (response.status === 1) {
        toast.success(response.message);
        setTimeout(() => {
          history.push("/custom/tickets");
        }, 800);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Update custom ticket error:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoader(false);
    }
  };

  function computeStartingPrice(baseAmount, markup, discount, vat, vatPercentage) {
    let price = parseFloat(baseAmount) || 0;
    // 1) Markup
    if (markup > 0) {
      price += price * (markup / 100);
    }
    // 2) Discount
    if (discount > 0) {
      price -= price * (discount / 100);
    }
    // 3) VAT
    if (vat && vatPercentage > 0) {
      price += price * (vatPercentage / 100);
    }
    return parseFloat(price.toFixed(2));
  }

    function computeFinalPrice(baseAmount, markup, discount, vat, vatPercentage) {
  
      console.log("baseAmount", baseAmount, markup, discount, vat, vatPercentage)
  
      let price = parseFloat(baseAmount) || 0;
    
      // 1) Apply Markup
      if (markup > 0) {
        price += price * (markup / 100);
      }
    
      // 2) Apply Discount
      if (discount > 0) {
        price -= price * (discount / 100);
      }
    
      // 3) Apply VAT
      if (vat && vatPercentage > 0) {
        price += price * (vatPercentage / 100);
      }
    
      return parseFloat(price.toFixed(2));
    }
  
    useEffect(() => {  
      
      if(!bannerData?.startingBaseAmount) return;
  
      setBannerData((prev) => ({
        ...prev,
        startingAmount: computeFinalPrice(
          prev.startingBaseAmount,
          prev.markup,
          prev.discount,
          prev.vat,
          prev.vatPercentage
        ),
      }));
    }, [bannerData?.startingBaseAmount, bannerData?.markup, bannerData?.discount, bannerData?.vat, bannerData?.vatPercentage]);

  /*
   |--------------------------------------------------------------------------
   | RENDER STEPS
   |--------------------------------------------------------------------------
  */
  const renderStepOne = () => {
    return (
      <Row>
        <Col lg={12}>
          <div className="mt-5 mt-lg-4">
            {/* Rayna Ticket Selection */}
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">
                Select Rayna Ticket
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <button
                    className="btn"
                    style={{
                      border: "1px solid #ced4da",
                      background: "#e60489",
                      color: "#fff",
                    }}
                    type="button"
                    onClick={toggleRaynaModel}
                  >
                    Choose Ticket
                  </button>
                  <input
                    type="text"
                    style={{ borderLeft: "none" }}
                    className="form-control"
                    readOnly
                    value={bannerData?.title || ""}
                    placeholder="No Ticket chosen"
                  />
                </div>
              </div>
            </div>

            {/* Title */}
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Title</label>
              <div className="col-sm-6">
                <input
                  type="text"
                  onChange={(e) => updateValue("title", e.target.value)}
                  value={bannerData?.title || ""}
                  className="form-control"
                />
                {errors.title && <div className="text-danger">{errors.title}</div>}
              </div>
            </div>

            {/* Description */}
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Description</label>
              <div className="col-sm-6">
                <ReactQuill
                  style={{ height: "100px", width: "100%", marginBottom: "60px" }}
                  modules={quillModules}
                  value={description || ""}
                  onChange={(value) => setDescription(value)}
                />
                {errors.description && (
                  <div className="text-danger">{errors.description}</div>
                )}
              </div>
            </div>

            {/* Badge */}
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Badge</label>
              <div className="col-sm-6">
                <input
                  type="text"
                  onChange={(e) => updateValue("badge", e.target.value)}
                  value={bannerData?.badge || ""}
                  className="form-control"
                />
              </div>
            </div>

            {/* City */}
            <div className="row mb-4"  style={{ visibility: "hidden" }}>
              <label className="col-sm-3 col-form-label">City</label>
              <div className="col-sm-6">
                <select
                  className="form-select"
                  onChange={(e) => updateValue("city", e.target.value)}
                  value={bannerData?.city || ""}
                >
                  <option value="">Select</option>
                  {cityOptions.map((city, idx) => (
                    <option key={idx} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
                {errors.city && <div className="text-danger">{errors.city}</div>}
              </div>
            </div>
           
            <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select City Id
                            </label>
                            <div className="col-sm-6">
                              <select
                                onChange={(e) =>
                                  updateValue("cityId", e.target.value)
                                } 
                                value={bannerData?.cityId}
                                name="cityId"
                                className="form-select"
                              >
                                <option value="">Select City Id</option>
                                {cityList.map((item, index) => (
                                  <option key={index} value={item._id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>

                              {errors.cityId && <div className="text-danger">{errors.cityId}</div>}


                            </div>
                            </div>


            {/* Category */}
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Select Category</label>
              <div className="col-sm-6">
                <MultiSelect
                  options={categoryList}
                  onSelect={msUpdateCategory}
                  selectedValues={defaultCategory}
                  placeholder="Select Category"
                />
                {errors.selectedCategory && (
                  <div className="text-danger">{errors.selectedCategory}</div>
                )}
              </div>
            </div>

            {/* Starting Amount */}
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Starting Amount</label>
              <div className="col-sm-6">
                <input
                  type="number"
                  onChange={(e) => updateValue("startingAmount", e.target.value)}
                  value={bannerData?.startingAmount || ""}
                  step="0.01"
                  min={0}
                  className="form-control"
                />
                {errors.startingAmount && (
                  <div className="text-danger">{errors.startingAmount}</div>
                )}
              </div>
            </div>

            {/* Ratings & Reviews */}
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Allow Ratings</label>
              <div className="col-sm-6">
                <input
                  type="checkbox"
                  className="mt-2"
                  onChange={() => updateValue("isEnableRating", !bannerData?.isEnableRating)}
                  checked={bannerData?.isEnableRating || false}
                />
              </div>
            </div>
            <div className="row mb-4">
              <label className="col-sm-3 col-form-label">Allow Reviews</label>
              <div className="col-sm-6">
                <input
                  type="checkbox"
                  className="mt-2"
                  onChange={() => updateValue("isEnableReview", !bannerData?.isEnableReview)}
                  checked={bannerData?.isEnableReview || false}
                />
              </div>
            </div>

            <div className="mt-5 mt-lg-4">
              <div className="d-flex justify-content-end">
                {activeStep > 0 && (
                  <button
                    type="button"
                    className="btn btn-secondary btn-lg me-2"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderStepTwo = () => {
    return (
      <Row>
        <Col lg={12}>
          {/* Gallery Images */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Images</label>
            <div className="col-sm-6">
              <input
                type="file"
                onChange={Imageupload}
                className="form-control"
              />
              {errors.original && (
                <div className="text-danger">{errors.original}</div>
              )}
              <br />
              {imageUrls.length > 1 && (
                <button
                  type="button"
                  className="btn btn-sm btn-info mt-2"
                  onClick={() => setReorderModal(true)}
                >
                  Reorder Images
                </button>
              )}
              <div className="row">
                {imageUrls.map((image, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center flex-column col-md-3"
                  >
                    <img
                      src={image}
                      alt={`Preview ${index + 1}`}
                      style={{ width: "100px", height: "100px", padding: "5px" }}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => RemoveImage(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Overview */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Overview</label>
            <div className="col-sm-6">
              <ReactQuill
                style={{ height: "100px", width: "100%", marginBottom: "60px" }}
                modules={quillModules}
                value={bannerData?.overview || ""}
                onChange={(value) => updateValue("overview", value)}
              />
              {errors.overview && (
                <p className="text-danger mt-2">{errors.overview}</p>
              )}
            </div>
          </div>

          {/* Inclusion */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Inclusion</label>
            <div className="col-sm-6">
              <ReactQuill
                style={{ height: "100px", width: "100%", marginBottom: "60px" }}
                modules={quillModules}
                value={bannerData?.inclusion || ""}
                onChange={(value) => updateValue("inclusion", value)}
              />
              {errors.inclusion && (
                <p className="text-danger mt-2">{errors.inclusion}</p>
              )}
            </div>
          </div>

          {/* Free Cancellation */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Is Free Cancellation Available
            </label>
            <div className="col-sm-6">
              <select
                className="form-select"
                value={bannerData?.isFreeCancellation || "false"}
                onChange={(e) => updateValue("isFreeCancellation", e.target.value)}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              {errors.isFreeCancellation && (
                <div className="text-danger">{errors.isFreeCancellation}</div>
              )}
            </div>
          </div>

          {/* Cancellation Policy */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">
              Cancellation Policy
            </label>
            <div className="col-sm-6">
              <textarea
                rows="4"
                onChange={(e) => updateValue("cancellationPolicy", e.target.value)}
                value={bannerData?.cancellationPolicy || ""}
                className="form-control"
              />
            </div>
          </div>

          {/* Child Policy */}
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Child Policy</label>
            <div className="col-sm-6">
              <ReactQuill
                style={{ height: "100px", width: "100%", marginBottom: "60px" }}
                modules={quillModules}
                value={bannerData?.childPolicy || ""}
                onChange={(value) => updateValue("childPolicy", value)}
              />
            </div>
          </div>

          <div className="mt-5 mt-lg-4">
            <div className="d-flex justify-content-end">
              {activeStep > 0 && (
                <button
                  type="button"
                  className="btn btn-secondary btn-lg me-2"
                  onClick={handleBack}
                >
                  Back
                </button>
              )}
              <button className="btn btn-primary btn-lg" onClick={handleNext2}>
                Next
              </button>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderStepThree = () => {
    return (
      <Row>
        {/* Discount */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Discount</label>
          <div className="col-sm-6">
            <input
              type="number"
              onChange={(e) => updateValue("discount", e.target.value)}
              value={bannerData?.discount || ""}
              className="form-control"
              placeholder="Enter Discount"
              min={0}
            />
          </div>
        </div>

        {/* Markup */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Markup</label>
          <div className="col-sm-6">
            <input
              type="number"
              onChange={(e) => updateValue("markup", e.target.value)}
              value={bannerData?.markup || ""}
              className="form-control"
              placeholder="Enter Markup"
              min={0}
            />
            {errors.markup && <p className="text-danger mt-2">{errors.markup}</p>}
          </div>
        </div>

        {/* VAT */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">VAT</label>
          <div className="col-sm-6 d-flex">
            <input
              className="me-2"
              type="checkbox"
              onChange={() => updateValue("vat", !bannerData?.vat)}
              checked={bannerData?.vat || false}
            />
            {bannerData?.vat && (
              <div className="d-flex flex-column w-100">
                <input
                  type="number"
                  onChange={(e) => updateValue("vatPercentage", e.target.value)}
                  value={bannerData?.vatPercentage || ""}
                  className="form-control"
                  placeholder="Enter VAT Percentage"
                  min={0}
                  max={100}
                />
                {errors.vatPercentage && (
                  <p className="text-danger mt-2">{errors.vatPercentage}</p>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Allow Infant */}
        <div className="row">
          <label className="col-sm-3 col-form-label">Allow Infant</label>
          <div className="col-sm-6 d-flex">
            <input
              className="me-2"
              type="checkbox"
              // onChange={() => updateValue("allowInfant", !bannerData?.allowInfant)}
              readOnly
              checked={bannerData?.allowInfant || false}
            />
          </div>
        </div>

        {/* Infant Age */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Infant Age</label>
          {bannerData?.allowInfant === false ? (
            <div className="col-sm-6">Infants Not Allowed</div>
          ) : (
            <>
              <div className="col-sm-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Min Age"
                  onChange={(e) => updateValue("Infantminage", e.target.value)}
                  min={0}
                  max={18}
                  value={bannerData?.Infantminage || ""}
                />
                {errors.infanterror && (
                  <p className="text-danger mt-2">{errors.infanterror}</p>
                )}
              </div>
              <div className="col-sm-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Max Age"
                  onChange={(e) => updateValue("Infantmaxage", e.target.value)}
                  min={0}
                  max={18}
                  value={bannerData?.Infantmaxage || ""}
                />
                {errors.infanterror && (
                  <p className="text-danger mt-2">{errors.infanterror}</p>
                )}
              </div>
            </>
          )}
        </div>

        {/* Allow Child */}
        <div className="row">
          <label className="col-sm-3 col-form-label">Allow Child</label>
          <div className="col-sm-6 d-flex">
            <input
              className="me-2"
              type="checkbox"
              // onChange={() => updateValue("allowChild", !bannerData?.allowChild)}
              readOnly
              checked={bannerData?.allowChild || false}
            />
          </div>
        </div>

        {/* Child Age */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Child Age</label>
          {bannerData?.allowChild === false ? (
            <div className="col-sm-6">Children Not Allowed</div>
          ) : (
            <>
              <div className="col-sm-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Min Age"
                  onChange={(e) => updateValue("Childminage", e.target.value)}
                  min={bannerData?.Infantmaxage}
                  max={18}
                  value={bannerData?.Childminage || ""}
                />
                {errors.childerror && (
                  <p className="text-danger mt-2">{errors.childerror}</p>
                )}
              </div>
              <div className="col-sm-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Max Age"
                  onChange={(e) => updateValue("Childmaxage", e.target.value)}
                  min={bannerData?.Childminage}
                  max={18}
                  value={bannerData?.Childmaxage || ""}
                />
                {errors.childerror && (
                  <p className="text-danger mt-2">{errors.childerror}</p>
                )}
              </div>
            </>
          )}
        </div>

        {/* Allow Adult */}
        <div className="row">
          <label className="col-sm-3 col-form-label">Allow Adult</label>
          <div className="col-sm-6 d-flex">
            <input
              className="me-2"
              type="checkbox"
              // onChange={() => updateValue("allowAdult", !bannerData?.allowAdult)}
              readOnly
              checked={bannerData?.allowAdult || false}
            />
          </div>
        </div>

        {/* Adult Age */}
        {bannerData?.allowAdult === false ? (
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Adult Age</label>
            <div className="col-sm-6">
              {bannerData?.adultAge ? `${bannerData?.adultAge}+` : "N/A"}
            </div>
          </div>
        ) : (
          <div className="row mb-4">
            <label className="col-sm-3 col-form-label">Adult Age</label>
            <div className="col-sm-6">
              <input
                type="number"
                className="form-control"
                placeholder="Min Age"
                onChange={(e) => updateValue("adultAge", e.target.value)}
                value={bannerData?.adultAge || ""}
                min={bannerData?.Childmaxage || 0}
              />
              {errors.adulterror && (
                <p className="text-danger mt-2">{errors.adulterror}</p>
              )}
            </div>
          </div>
        )}

        {/* Additional Fields Section */}
        <h5 className="mt-4">Additional Details</h5>
        {/* Location */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Location</label>
          <div className="col-sm-6">
            <LocationPicker
              containerElement={<div style={{ height: "100%" }} />}
              mapElement={<div style={{ height: "600px" }} />}
              zoom={14}
              defaultPosition={currentLocation}
              onChange={handleLocationChange}
            />
          </div>
        </div>
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Latitude</label>
          <div className="col-sm-6">
            <input
              type="number"
              onChange={(e) => updateLocation(e.target.value, "lat")}
              value={bannerData?.latitude || ""}
              className="form-control"
              placeholder="Select via Google Map Picker"
            />
          </div>
        </div>
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Longitude</label>
          <div className="col-sm-6">
            <input
              type="number"
              onChange={(e) => updateLocation(e.target.value, "lng")}
              value={bannerData?.longitude || ""}
              className="form-control"
              placeholder="Select via Google Map Picker"
            />
          </div>
        </div>

        {/* Video URL */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Video URL</label>
          <div className="col-sm-6">
            <input
              type="text"
              onChange={(e) => updateValue("videoUrl", e.target.value)}
              value={bannerData?.videoUrl || ""}
              className="form-control"
              placeholder="Enter video URL"
            />
          </div>
        </div>

        {/* How to Redeem */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">How To Redeem</label>
          <div className="col-sm-6">
            <ReactQuill
              style={{ height: "100px", width: "100%", marginBottom: "60px" }}
              modules={quillModules}
              value={bannerData?.howToRedeem || ""}
              onChange={(value) => updateValue("howToRedeem", value)}
            />
          </div>
        </div>

        {/* Google Map URL */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Google Map URL</label>
          <div className="col-sm-6">
            <input
              type="text"
              onChange={(e) => updateValue("googleMapUrl", e.target.value)}
              value={bannerData?.googleMapUrl || ""}
              className="form-control"
              placeholder="Enter Google Map URL"
            />
          </div>
        </div>

        {/* FAQ Details */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">FAQ Details</label>
          <div className="col-sm-6">
            <ReactQuill
              style={{ height: "100px", width: "100%", marginBottom: "60px" }}
              modules={quillModules}
              value={bannerData?.faqDetails || ""}
              onChange={(value) => updateValue("faqDetails", value)}
            />
          </div>
        </div>

        {/* Rayna Tours Advantage */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Rayna Tours Advantage</label>
          <div className="col-sm-6">
            <input
              type="text"
              onChange={(e) => updateValue("raynaToursAdvantage", e.target.value)}
              value={bannerData?.raynaToursAdvantage || ""}
              className="form-control"
              placeholder="Enter advantage details"
            />
          </div>
        </div>

        {/* Departure Point */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Departure Point</label>
          <div className="col-sm-6">
            <input
              type="text"
              onChange={(e) => updateValue("departurePoint", e.target.value)}
              value={bannerData?.departurePoint || ""}
              className="form-control"
              placeholder="Enter departure point"
            />
          </div>
        </div>

        {/* Tour Language */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Tour Language</label>
          <div className="col-sm-6">
            <input
              type="text"
              onChange={(e) => updateValue("tourLanguage", e.target.value)}
              value={bannerData?.tourLanguage || ""}
              className="form-control"
              placeholder="Enter tour language"
            />
          </div>
        </div>

        {/* Important Information */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Important Information</label>
          <div className="col-sm-6">
            <ReactQuill
              style={{ height: "100px", width: "100%", marginBottom: "60px" }}
              modules={quillModules}
              value={bannerData?.importantInformation || ""}
              onChange={(value) => updateValue("importantInformation", value)}
            />
          </div>
        </div>

        {/* Useful Information */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Useful Information</label>
          <div className="col-sm-6">
            <ReactQuill
              style={{ height: "100px", width: "100%", marginBottom: "60px" }}
              modules={quillModules}
              value={bannerData?.usefulInformation || ""}
              onChange={(value) => updateValue("usefulInformation", value)}
            />
          </div>
        </div>

        {/* Tour Exclusion */}
        <div className="row mb-4">
          <label className="col-sm-3 col-form-label">Tour Exclusion</label>
          <div className="col-sm-6">
            <ReactQuill
              style={{ height: "100px", width: "100%", marginBottom: "60px" }}
              modules={quillModules}
              value={bannerData?.tourExclusion || ""}
              onChange={(value) => updateValue("tourExclusion", value)}
            />
          </div>
        </div>

        {/* PricingPurchaseUnit for Rayna Tour Options */}
        <PricingPurchaseUnit
          bannerData={bannerData}
          setActiveKey={setActiveKey}
          activeKey={activeKey}
          OptionImageupload={OptionImageupload}
          optionimageUrls={optionimageUrls}
          OptionRemoveImage={OptionRemoveImage}
          optionAddImages={optionAddImages}
        />

        {/* Final Submit */}
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary btn-lg me-2"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={CreateCustomTicketform}
          >
            Submit
          </button>
        </div>
      </Row>
    );
  };

  /*
   |--------------------------------------------------------------------------
   | RENDER
   |--------------------------------------------------------------------------
  */
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Ticket Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Ticket Management</li>
              <li className="breadcrumb-item">Update Custom Ticket</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      <Row>
        <Col className="col-12 user-management-container">
          <Card className="custom-shadow-card">
            {loader ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title">Update Custom Ticket</h4>
                <div>
                  {activeStep === 0 && renderStepOne()}
                  {activeStep === 1 && renderStepTwo()}
                  {activeStep === 2 && renderStepThree()}
                </div>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      {/* Rayna Ticket Modal */}
      <RaynaTicketModal
        show={raynaModel}
        closeModal={() => setRaynaModel(false)}
        selectRow={selectRaynaRow}
        isCustomTicket={true}
      />

      {/* Icon Pack Modals */}
      <IconPackModal
        show={iconModal}
        closeModal={closeIconModal}
        selectRow={selectIconRow}
        selectIncludeRow={selectIncludeIconRow}
      />
      <IconPackModalupdated
        show={iconModalInclude}
        closeModal={closeIconModalInclude}
        selectRow={selectIconRowIWhatsnclude}
        selectIncludeRow={selectIncludeIconRowWhats}
      />

      {/* Images Reorder Modal */}
      <ImagesReorderModal
        show={reorderModal}
        closeModal={() => setReorderModal(false)}
        imageUrls={imageUrls}
        onReorderImages={(newOrder) => setImageUrls(newOrder)}
      />
    </React.Fragment>
  );
}
