import React, { useState, useEffect } from "react";
import { Row, Col, Button, Badge } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { format } from "date-fns";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/src/sweetalert2.scss";
import "./SpecialOfferSalesManagementlist.scss";

import { postRequest } from "../../components/Common/Utils.js";
import { useLocation } from "react-router-dom";

// --------------------
// Utility Helpers
// --------------------
function groupBy(array, key) {
  return array.reduce((result, item) => {
    const groupKey = item[key];
    if (!result[groupKey]) result[groupKey] = [];
    result[groupKey].push(item);
    return result;
  }, {});
}

function getTotalsForGroup(items, perspective) {
  let totalDr = 0;
  let totalCr = 0;
  items.forEach((row) => {
    if (perspective === "USER") {
      totalCr += row.billAmount;
    } else {
      totalDr += row.billAmount;
    }
  });
  return { totalDr, totalCr };
}

// --------------------
// PRINT & CSV Helpers
// --------------------

// Build HTML for the journal table (used for both full & single print)
function buildJournalPrintTableHTML(data, perspective) {
  let tableHTML = `
    <div class="table-header">
      <div class="th date-column">Date</div>
      <div class="th particulars-column">Particulars</div>
      <div class="th lf-column">LF</div>
      <div class="th dr-column">Dr (AED)</div>
      <div class="th cr-column">Cr (AED)</div>
    </div>`;
  data.forEach((row) => {
    const dateStr = format(new Date(row.createdAt), "dd/MM/yyyy");
    const particulars = `Sale of ${row.offerTitle || "N/A"}<br/>to ${row.userName || "Unknown User"}<br/>at ${row.venueName || "Unknown Venue"}`;
    const absAmount = Math.abs(row.billAmount).toFixed(2);
    let dr = "";
    let cr = "";
    if (perspective === "USER") {
      cr = `<span style="color:red">${absAmount}</span>`;
    } else {
      dr = `<span style="color:green">${absAmount}</span>`;
    }
    tableHTML += `
      <div class="table-row">
        <div class="td date-column">${dateStr}</div>
        <div class="td particulars-column">${particulars}</div>
        <div class="td lf-column"></div>
        <div class="td dr-column">${dr}</div>
        <div class="td cr-column">${cr}</div>
      </div>`;
  });
  return tableHTML;
}

// For printing a single transaction (or group of one), wrap table HTML with inline CSS.
function buildSingleJournalPrintHTML(data, perspective) {
  const inlineCSS = `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
    body { font-family: 'Inter', sans-serif; margin: 20px; background: #f2f2f2; }
    .account-statement { width: 80%; margin: 0 auto; }
    .table-header { display: flex; font-weight: 600; font-size: 0.875rem; text-transform: uppercase; background: #f9f9f9; padding: 0.75rem; }
    .th { flex: 1; color: #555; }
    .th.date-column { flex: 1.2; }
    .th.particulars-column { flex: 2.5; }
    .th.lf-column { flex: 0.6; text-align: center; }
    .th.dr-column, .th.cr-column { width: 80px; text-align: right; }
    .table-row { display: flex; align-items: center; border-bottom: 1px solid #eee; padding: 0.75rem; }
    .table-row:last-child { border-bottom: none; }
    .td { flex: 1; font-size: 0.875rem; color: #333; }
    .td.date-column { flex: 1.2; }
    .td.particulars-column { flex: 2.5; white-space: pre-line; }
    .td.lf-column { flex: 0.6; text-align: center; }
    .td.dr-column, .td.cr-column { width: 80px; text-align: right; }
  `;
  let html = `<html><head><meta charset="utf-8"/><title>Printable Journal Entry</title><style>${inlineCSS}</style></head><body>`;
  html += `<div class="account-statement">`;
  html += buildJournalPrintTableHTML(data, perspective);
  html += `</div></body></html>`;
  return html;
}

function printDocument(htmlString) {
  const printWindow = window.open("", "_blank");
  if (!printWindow) return;
  printWindow.document.open();
  printWindow.document.write(htmlString);
  printWindow.document.close();
  setTimeout(() => {
    printWindow.focus();
    printWindow.print();
  }, 500);
}

// Print full journal (grouped by entities)
function buildFullJournalPrintHTML(allData) {
  const inlineCSS = `
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
    body { font-family: 'Inter', sans-serif; margin: 20px; background: #f2f2f2; }
    .account-statement { width: 80%; margin: 0 auto; }
    h2 { font-size: 1.2rem; color: #333; margin-top: 20px; }
    .table-header { display: flex; font-weight: 600; font-size: 0.875rem; text-transform: uppercase; background: #f9f9f9; padding: 0.75rem; }
    .th { flex: 1; color: #555; }
    .th.date-column { flex: 1.2; }
    .th.particulars-column { flex: 2.5; }
    .th.lf-column { flex: 0.6; text-align: center; }
    .th.dr-column, .th.cr-column { width: 80px; text-align: right; }
    .table-row { display: flex; align-items: center; border-bottom: 1px solid #eee; padding: 0.75rem; }
    .table-row:last-child { border-bottom: none; }
    .td { flex: 1; font-size: 0.875rem; color: #333; }
    .td.date-column { flex: 1.2; }
    .td.particulars-column { flex: 2.5; white-space: pre-line; }
    .td.lf-column { flex: 0.6; text-align: center; }
    .td.dr-column, .td.cr-column { width: 80px; text-align: right; } 
  `;
  let html = `<html><head><meta charset="utf-8"/><title>Printable Journal</title><style>${inlineCSS}</style></head><body>`;
  html += `<div class="account-statement">`;

  // WHOS'IN Section (DR perspective)
  if (allData.length > 0) {
    html += `<h2>WHOS'IN</h2>`;
    html += buildJournalPrintTableHTML(allData, "DR");
  }
  // Group by Venue (DR perspective)
  const venues = groupBy(allData, "venueId");
  for (const [id, items] of Object.entries(venues)) {
    const name = items[0]?.venueName || "Unknown Venue";
    html += `<h2>Venue: ${name}</h2>`;
    html += buildJournalPrintTableHTML(items, "DR");
  }
  // Group by Offer (DR perspective)
  const offers = groupBy(allData, "specialOfferId");
  for (const [id, items] of Object.entries(offers)) {
    const name = items[0]?.offerName || "Unknown Offer";
    html += `<h2>Offer: ${name}</h2>`;
    html += buildJournalPrintTableHTML(items, "DR");
  }
  // Group by User (USER perspective)
  const users = groupBy(allData, "userId");
  for (const [id, items] of Object.entries(users)) {
    const name = items[0]?.userName || "Unknown User";
    html += `<h2>User: ${name}</h2>`;
    html += buildJournalPrintTableHTML(items, "USER");
  }
  html += `</div></body></html>`;
  return html;
}

function printFullJournal(allData) {
  const htmlString = buildFullJournalPrintHTML(allData);
  printDocument(htmlString);
}

// Print single (or a group of one) with consistent styling
function printGroupJournal(data, perspective) {
  const htmlString = buildSingleJournalPrintHTML(data, perspective);
  printDocument(htmlString);
}

// CSV Helpers – Now accepting a perspective parameter
function buildJournalCsvRow(row, perspective) {
  const dateStr = format(new Date(row.createdAt), "dd/MM/yyyy");
  const particulars = `Sale of ${row.offerTitle || "N/A"} to ${row.userName || "Unknown User"} at ${row.venueName || "Unknown Venue"}`;
  const absAmount = Math.abs(row.billAmount).toFixed(2);
  let dr = "";
  let cr = "";
  if (perspective === "USER") {
    cr = absAmount;
  } else {
    dr = absAmount;
  }
  return [dateStr, particulars, "", dr, cr].join(",");
}

function downloadJournalCSV(data, perspective) {
  const headers = ["Date", "Particulars", "LF", "Dr (AED)", "Cr (AED)"].join(",");
  const csvRows = [headers];
  data.forEach((row) => {
    csvRows.push(buildJournalCsvRow(row, perspective));
  });
  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "journal-entries.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function buildFullJournalCSV(allData) {
  let csv = "";
  // WHOS'IN section (DR perspective)
  if (allData.length > 0) {
    csv += "WHOS'IN\n";
    csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
    allData.forEach((row) => {
      csv += buildJournalCsvRow(row, "DR") + "\n";
    });
    csv += "\n";
  }
  // Venue groups (DR perspective)
  const venues = groupBy(allData, "venueId");
  for (const [id, items] of Object.entries(venues)) {
    const name = items[0]?.venueName || "Unknown Venue";
    csv += `Venue: ${name}\n`;
    csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
    items.forEach((row) => {
      csv += buildJournalCsvRow(row, "DR") + "\n";
    });
    csv += "\n";
  }
  // Offer groups (DR perspective)
  const offers = groupBy(allData, "specialOfferId");
  for (const [id, items] of Object.entries(offers)) {
    const name = items[0]?.offerName || "Unknown Offer";
    csv += `Offer: ${name}\n`;
    csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
    items.forEach((row) => {
      csv += buildJournalCsvRow(row, "DR") + "\n";
    });
    csv += "\n";
  }
  // User groups (USER perspective)
  const users = groupBy(allData, "userId");
  for (const [id, items] of Object.entries(users)) {
    const name = items[0]?.userName || "Unknown User";
    csv += `User: ${name}\n`;
    csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
    items.forEach((row) => {
      csv += buildJournalCsvRow(row, "USER") + "\n";
    });
    csv += "\n";
  }
  return csv;
}

function downloadFullJournalCSV(allData) {
  const csvString = buildFullJournalCSV(allData);
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", "journal-full.csv");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// --------------------
// MAIN COMPONENT
// --------------------
const SpecialOfferClaimJournallist = () => {
  const location = useLocation();
  const [allData, setAllData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const sortField = "createdAt";
  const sortOrder = "desc";

  useEffect(() => {
    fetchJournalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchJournalData() {
    const payload = {
      limit: 10000,
      page: 1,
      sortField,
      sortOrder,
    };

    if (location.state) {
      const { startDate, endDate, paymentStatus } = location.state;
      const filterArgs = [
        { paymentStatus, type: "eq" },
        { startDate, type: "date-range" },
        { endDate, type: "date-range" },
      ];
      payload.filterArgs = filterArgs;
    }

    try {
      const response = await postRequest("venue/special-offer-claim/list", payload);
      const data = response.data || {};
      const list = data.list || [];
      list.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setAllData(list);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  }

  const toggleRow = (key) => {
    if (expandedRows.includes(key)) {
      setExpandedRows(expandedRows.filter((k) => k !== key));
    } else {
      setExpandedRows([...expandedRows, key]);
    }
  };

  // Render journal table for individual transactions (with Actions column)
  const renderJournalTable = (transactions, perspective) => {
    return (
      <div className="custom-table-container">
        <div className="table-header">
          <div className="th date-column">Date</div>
          <div className="th particulars-column">Particulars</div>
          <div className="th lf-column">LF</div>
          <div className="th dr-column">Dr (AED)</div>
          <div className="th cr-column">Cr (AED)</div>
          {/* <div className="th actions-column" style={{ width: "80px", textAlign: "center" }}>
            Actions
          </div> */}
        </div>
        <div className="table-body">
          {transactions.map((row) => {
            const dateStr = format(new Date(row.createdAt), "dd/MM/yyyy");
            const particulars = [
              `Sale of ${row.offerTitle || "N/A"}`,
              `to ${row.userName || "Unknown User"}`,
              `at ${row.venueName || "Unknown Venue"}`,
            ].join("<br/>");
            const absAmount = Math.abs(row.billAmount).toFixed(2);
            let drAmount = "";
            let crAmount = "";
            if (perspective === "USER") {
              crAmount = `<span style="color:red">${absAmount}</span>`;
            } else {
              drAmount = `<span style="color:green">${absAmount}</span>`;
            }
            return (
              <div className="table-row" key={row._id}>
                <div className="td date-column">{dateStr}</div>
                <div className="td particulars-column" dangerouslySetInnerHTML={{ __html: particulars }} />
                <div className="td lf-column"></div>
                <div className="td dr-column" dangerouslySetInnerHTML={{ __html: drAmount }} />
                <div className="td cr-column" dangerouslySetInnerHTML={{ __html: crAmount }} />
                {/* <div className="td actions-column" style={{ width: "80px", textAlign: "center" }}>
                  <button
                    style={{ border: "none", background: "none" }}
                    onClick={() => printGroupJournal([row], perspective)}
                    title="Print Transaction"
                  >
                    <i className="fas fa-print" style={{ fontSize: "1rem", color: "#007bff" }}></i>
                  </button>
                  <button
                    style={{ border: "none", background: "none", marginLeft: "5px" }}
                    onClick={() => downloadJournalCSV([row], perspective)}
                    title="Download Transaction CSV"
                  >
                    <i className="fas fa-file-csv" style={{ fontSize: "1rem", color: "#007bff" }}></i>
                  </button>
                </div> */}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // Render group card (collapsible) with group-level actions
  const renderGroupCard = (groupId, groupName, items, perspective) => {
    const isOpen = expandedRows.includes(groupId);
    const { totalDr, totalCr } = getTotalsForGroup(items, perspective);
    const drDisplay = Math.abs(totalDr).toFixed(2);
    const crDisplay = Math.abs(totalCr).toFixed(2);
    return (
      <div className="statement-item" key={groupId}>
        <div className="top-row">
          <button className="expand-btn" onClick={() => toggleRow(groupId)}>
            {isOpen ? "–" : "+"}
          </button>
          <div className="left-content">
            <div className="title">{groupName}</div>
          </div>
          <div className="middle-content" />
          <div className="right-content">
            <span style={{ color: "green", fontSize: "1.1rem", marginRight: "1rem" }}>
              {drDisplay}
            </span>
            <span style={{ color: "red", fontSize: "1.1rem" }}>{crDisplay}</span>
          </div>
        </div>
        {/* Group-level actions */}
        <div
          className="group-actions"
          style={{ display: "flex", justifyContent: "flex-end", gap: "10px", margin: "10px 0" }}
        >
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => printGroupJournal(items, perspective)}
            title="Print Group"
          >
            <i className="fas fa-print" style={{ fontSize: "1rem", color: "#d30b7d" }}></i>
          </button>
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => downloadJournalCSV(items, perspective)}
            title="Download Group CSV"
          >
            <i className="fas fa-file-csv" style={{ fontSize: "1rem", color: "#d30b7d" }}></i>
          </button>
        </div>
        {isOpen && (
          <div className="detail-section">
            <div className="detail-card">
              {items.length === 0 ? (
                <div style={{ padding: "1rem" }}>No transactions found.</div>
              ) : (
                renderJournalTable(items, perspective)
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  // ------------------
  // Top-level full journal actions
  // ------------------
  function buildFullJournalPrintHTML(allData) {
    const inlineCSS = `
      @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
      body { font-family: 'Inter', sans-serif; margin: 20px; background: #f2f2f2; }
      .account-statement { width: 80%; margin: 0 auto; }
      h2 { font-size: 1.2rem; color: #333; margin-top: 20px; }
      .table-header { display: flex; font-weight: 600; font-size: 0.875rem; text-transform: uppercase; background: #f9f9f9; padding: 0.75rem; }
      .th { flex: 1; color: #555; }
      .th.date-column { flex: 1.2; }
      .th.particulars-column { flex: 2.5; }
      .th.lf-column { flex: 0.6; text-align: center; }
      .th.dr-column, .th.cr-column { width: 80px; text-align: right; }
      .table-row { display: flex; align-items: center; border-bottom: 1px solid #eee; padding: 0.75rem; }
      .table-row:last-child { border-bottom: none; }
      .td { flex: 1; font-size: 0.875rem; color: #333; }
      .td.date-column { flex: 1.2; }
      .td.particulars-column { flex: 2.5; white-space: pre-line; }
      .td.lf-column { flex: 0.6; text-align: center; }
      .td.dr-column, .td.cr-column { width: 80px; text-align: right; }
    `;
    let html = `<html><head><meta charset="utf-8"/><title>Printable Journal</title><style>${inlineCSS}</style></head><body>`;
    html += `<div class="account-statement">`;

    // WHOS'IN Section (DR perspective)
    if (allData.length > 0) {
      html += `<h2>WHOS'IN</h2>`;
      html += buildJournalPrintTableHTML(allData, "DR");
    }
    // Group by Venue (DR perspective)
    const venues = groupBy(allData, "venueId");
    for (const [id, items] of Object.entries(venues)) {
      const name = items[0]?.venueName || "Unknown Venue";
      html += `<h2>Venue: ${name}</h2>`;
      html += buildJournalPrintTableHTML(items, "DR");
    }
    // Group by Offer (DR perspective)
    const offers = groupBy(allData, "specialOfferId");
    for (const [id, items] of Object.entries(offers)) {
      const name = items[0]?.offerName || "Unknown Offer";
      html += `<h2>Offer: ${name}</h2>`;
      html += buildJournalPrintTableHTML(items, "DR");
    }
    // Group by User (USER perspective)
    const users = groupBy(allData, "userId");
    for (const [id, items] of Object.entries(users)) {
      const name = items[0]?.userName || "Unknown User";
      html += `<h2>User: ${name}</h2>`;
      html += buildJournalPrintTableHTML(items, "USER");
    }
    html += `</div></body></html>`;
    return html;
  }

  function printFullJournal() {
    const htmlString = buildFullJournalPrintHTML(allData);
    printDocument(htmlString);
  }

  function downloadFullJournalCSV() {
    const csvString = buildFullJournalCSV(allData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "journal-full.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function buildFullJournalCSV(allData) {
    let csv = "";
    // WHOS'IN section (DR perspective)
    if (allData.length > 0) {
      csv += "WHOS'IN\n";
      csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
      allData.forEach((row) => {
        csv += buildJournalCsvRow(row, "DR") + "\n";
      });
      csv += "\n";
    }
    // Venue groups (DR perspective)
    const venues = groupBy(allData, "venueId");
    for (const [id, items] of Object.entries(venues)) {
      const name = items[0]?.venueName || "Unknown Venue";
      csv += `Venue: ${name}\n`;
      csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
      items.forEach((row) => {
        csv += buildJournalCsvRow(row, "DR") + "\n";
      });
      csv += "\n";
    }
    // Offer groups (DR perspective)
    const offers = groupBy(allData, "specialOfferId");
    for (const [id, items] of Object.entries(offers)) {
      const name = items[0]?.offerName || "Unknown Offer";
      csv += `Offer: ${name}\n`;
      csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
      items.forEach((row) => {
        csv += buildJournalCsvRow(row, "DR") + "\n";
      });
      csv += "\n";
    }
    // User groups (USER perspective)
    const users = groupBy(allData, "userId");
    for (const [id, items] of Object.entries(users)) {
      const name = items[0]?.userName || "Unknown User";
      csv += `User: ${name}\n`;
      csv += "Date,Particulars,LF,Dr (AED),Cr (AED)\n";
      items.forEach((row) => {
        csv += buildJournalCsvRow(row, "USER") + "\n";
      });
      csv += "\n";
    }
    return csv;
  }

  function buildJournalCsvRow(row, perspective) {
    const dateStr = format(new Date(row.createdAt), "dd/MM/yyyy");
    const particulars = `Sale of ${row.offerTitle || "N/A"} to ${row.userName || "Unknown User"} at ${row.venueName || "Unknown Venue"}`;
    const absAmount = Math.abs(row.billAmount).toFixed(2);
    let dr = "";
    let cr = "";
    if (perspective === "USER") {
      cr = absAmount;
    } else {
      dr = absAmount;
    }
    return [dateStr, particulars, "", dr, cr].join(",");
  }

  // --------------------
  // MAIN RENDER
  // --------------------
  return (
    <>
      <ToastContainer />
      <Row>
        <Col className="col-12">
          <div className="page-title-box">
            <h4>Journal Entries</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Account Logs</li>
              <li className="breadcrumb-item">Journal Entries</li>
            </ol>
          </div>
        </Col>
        <Col className="col-12">
          {/* Top-level full journal actions */}
          <div
            className="card text-center"
            style={{ backgroundColor: "#d30b7d", border: "none", marginBottom: "20px" }}
          >
            <div className="card-body" style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => printFullJournal(allData)}
                title="Print Full Journal"
              >
                <i className="fas fa-print" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
              <button
                style={{ border: "none", background: "none" }}
                onClick={downloadFullJournalCSV}
                title="Download Full Journal CSV"
              >
                <i className="fas fa-file-csv" style={{ color: "#fff", fontSize: "1.2rem" }}></i>
              </button>
            </div>
          </div>

          <div className="account-statement">
            {/* Group for WHOS'IN (all data) */}
            {allData.length > 0 && renderGroupCard("WHOSIN", "WHOS'IN", allData, "DR")}

            {/* Groups by Venue (DR perspective) */}
            {Object.entries(groupBy(allData, "venueId")).map(([id, items]) => {
              const name = items[0]?.venueName || "Unknown Venue";
              return renderGroupCard(`VENUE-${id}`, `Venue: ${name}`, items, "DR");
            })}

            {/* Groups by Offer (DR perspective) */}
            {Object.entries(groupBy(allData, "specialOfferId")).map(([id, items]) => {
              const name = items[0]?.offerName || "Unknown Offer";
              return renderGroupCard(`OFFER-${id}`, `Offer: ${name}`, items, "DR");
            })}

            {/* Groups by User (USER perspective) */}
            {Object.entries(groupBy(allData, "userId")).map(([id, items]) => {
              const name = items[0]?.userName || "Unknown User";
              return renderGroupCard(`USER-${id}`, `User: ${name}`, items, "USER");
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SpecialOfferClaimJournallist;
