import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import Lightbox from "react-image-lightbox";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
  putRequestForm,
} from "components/Common/Utils";

import countries from './countries.json';

import FileUpload from "../../components/Common/FileUpload";

export default function Updateuserdata() {
  const [selectedFile, setSelectedFile] = useState("");
  const [image, setImage] = useState("");
  const [userdata, setData] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [startLoader, setStartLoader] = useState(false);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const location = useLocation();

  const { id } = useParams();
  let history = useHistory();

  useEffect(() => {
    setPreviewImage(location.state?.row.image ? location.state?.row.image : "");
  }, [location.state]);

  useEffect(() => {
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  }, [selectedFile]);

  const showLightBox = (previewImage) => {
    setPreviewImage(previewImage);
    setOpenLightBox(true);
  };

  const updateuserdata = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    let Formvlaues = Object.fromEntries(data.entries());
    let formData = Formvlaues;
    let userData = formData;

    if (!userData?.first_name) {
      toast.error("Please enter First Name");
      return;
    }

    // if (!userData?.email) {
    //   toast.error("Please enter Email");
    //   return;
    // }

    if (!userData?.phone) {
      toast.error("Please enter Phone");
      return;
    }
    if (userData?.phone.length < 7 || userData?.phone.length > 14) {
      toast.error("Please enter valid Phone");
      return;
    }


    let dataToSend = {}
    const selectedImageForm = new FormData();
    let selectedImageRes = null;
    if (selectedFile) {
      selectedImageForm.append("image", selectedFile);
      setStartLoader(true);
      selectedImageRes = await postRequestForm("comman/img/upload", selectedImageForm);
      setStartLoader(false);
    }


    if (selectedImageRes?.data?.url) {
      dataToSend.image = selectedImageRes.data.url;
    }

    dataToSend.userId = formData.userId;
    dataToSend.first_name = formData.first_name;
    dataToSend.last_name = formData.last_name;
    dataToSend.email = formData.email;
    dataToSend.phone = formData.phone;
    dataToSend.platform = formData.platform;
    dataToSend.isVip = formData.isVip;
    dataToSend.bio = formData.bio;
    dataToSend.gender = formData.gender;
    dataToSend.dateOfBirth  = formData.dob;

    postRequest("user/update/admin", dataToSend).then((data) => {
      let status = data;
      if (status.status == 1) {
        toast.success(status.message);
        setTimeout(() => {
          history.push("/users");
        }, 3000);
      } else {
        toast.error(status.message);
      }
    });
    //  }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>User Management</h4>
            <div></div>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">User Management</li>:{" "}
              <li className="breadcrumb-item">
                <Link to="#">User update</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>


      </div>
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">

            <CardBody>
              <h4 className="card-title"> Update User </h4>
              <form onSubmit={updateuserdata}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          First name
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            name="first_name"
                            defaultValue={location.state?.row.first_name}
                            className="form-control"
                            id="horizontal-firstname-input"
                          />
                          <input
                            type="hidden"
                            name="userId"
                            defaultValue={location.state?.row._id}
                            className="form-control"
                            id="horizontal-firstname-input"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Last Name
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            name="last_name"
                            defaultValue={location.state?.row.last_name}
                            className="form-control"
                            id="horizontal-email-input"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Bio
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            name="bio"
                            defaultValue={location.state?.row.bio}
                            className="form-control"
                            id="horizontal-email-input"
                          />                          
                        </div>
                      </div>


                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Email
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="email"
                            name="email"
                            defaultValue={location.state?.row.email}
                            className="form-control"
                            id="horizontal-password-input"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Phone
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="tel"
                            name="phone"
                            defaultValue={location.state?.row.phone}
                            className="form-control"
                            id="horizontal-password-input"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Platform
                        </label>

                        <div className="col-sm-6">
                          <select
                            defaultValue={location.state?.row.platform}
                            name="platform"
                            className="form-select"
                          >
                            <option value="select">Select an option</option>
                            <option value="android">Android</option>
                            <option value="ios">iOS</option>
                            <option value="web">Web</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          VIP Access
                        </label>

                        <div className="col-sm-6">
                          <select
                            defaultValue={location.state?.row.isVip || false}
                            name="isVip"
                            className="form-select"
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Gender
                          </label>
                          <div className="col-sm-6">
                          <select                              
                              defaultValue={location.state?.row.gender || false}
                              name="gender"
                              className="form-select"
                            >
                              <option value="">Select an option</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Prefer not to say">Prefer not to say</option>
                            </select>
                          </div>
                        </div>                       

                         <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            DOB
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"                             
                              defaultValue={location.state?.row.dateOfBirth || false}
                              name="dob"
                              className="form-control"
                            />
                          </div>
                        </div> 


                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          image
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            className="form-control"
                            id="horizontal-password-input"
                          />
                          <input type="hidden" name="image" value={image} />
                          <br />
                          {previewImage && (
                            <img
                              src={previewImage}
                              onClick={() => showLightBox(previewImage)}
                              style={{ width: "100px", height: "100px" }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="row justify-content-end">
                        <div className="col-sm-9">
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
