import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { putRequest, customValidator } from "../../components/Common/Utils.js";

import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

export default function UpdateActivityType() {
  const [typeData, setTypeData] = useState({ title: "" });
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  useEffect(() => {
    fetchState();
  }, []);

  const fetchState = async () => {
    const data = { ...state };
    setTypeData(data);
    setLoading(false);
  };

  let search = state._id;
  let finalsearch = search.toString();

  const updateActivityTypeData = async (e) => {
    e.preventDefault();
    const validator = customValidator(
      [
        {
          field: "title",
          errorMsg: "Title is required",
        },
      ],
      typeData
    );

    if (!validator.success) {
      toast.error(validator.message);
      return;
    }

    setLoading(true);

    const dataObj = {
      activityTypeId: typeData._id,
      title: typeData.title,
    };
    const response = await putRequest("activity/admin/type/update", dataObj);

    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/activityType");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...typeData };
    list[index] = value;
    setTypeData(list);
  };

  const fetchIndex = (items, search) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].value === search) {
        return i;
      }
    }
    return -1;
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <Row>
        <Col className="12">
          <div className="page-title-box">
            <h4>Activity Type Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Activity Type Management
              </li>
              : <li className="breadcrumb-item">Activity Type update</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </Row>

      {/* header */}

      {/* form */}

      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Activity Type Update </h4>
                <Form onSubmit={updateActivityTypeData}>
                  <Row>
                    <Col lg={5} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              defaultValue={typeData.title}
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
