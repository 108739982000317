import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";



import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import CustomAvatar from "../Common/Avatar.js";
const TableComponent = ({ selectUserRow, onSelectUser, closeUserModal }) => {
  const [userdata, setuserdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = React.useState(10);
  const [selectedItems, setselectedItems] = useState([]);
  const [sort, setsort] = React.useState("name");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const history = useHistory();

  useEffect(() => {
    userManagment();
  }, [currentPage]);

  useEffect(() => {
    userManagment();
  }, [sizePerPageOrg, sort]);

  const handlerSelectMultiple = () => {
    closeUserModal();
    //history.push("/Createnotification", { selectedUserIds: selectedItems });
    onSelectUser(selectedItems);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];
  const initials =
    userdata?.first_name?.charAt(0).toUpperCase() +
    userdata?.last_name?.charAt(0).toUpperCase();

 

  function userFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p style={{ textAlign: "left", marginBottom: 0 }}>
          <>
              {row.image ? (
                <img
                  src={row.image}
                  onClick={() => showLightBox(row.image)}
                  alt="img"
                  width={"30px"}
                />
              ) : (
                <CustomAvatar
                  iconSize={30}
                  name={
                    row?.first_name + row?.last_name == ""
                      ? "*"
                      : `${row?.first_name} ${row?.last_name} `
                  }
                />
              )}
            </>
            &nbsp;&nbsp;
            {row.first_name} {row.last_name}{" "}
          </p>
        </div>
      </React.Fragment>
    );
  }

  function ImgFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.image}
          onClick={() => showLightBox(row.image)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }

  const columns = [
    {
      dataField: "title",      
      text: "Title",
      sort: true,
    },   

    {
      dataField: "venueName",
      text: "Venue",
      sort: true,
    },
    {
      dataField: "actualPrice",
      text: "Original Price",
      sort: true,
    },
    {
      dataField: "discountedPrice",
      text: "Discounted Price",
      sort: true,
    },

  ];

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  // function iconFormatter(cellContent, row) {
  //   return (
  //     <React.Fragment>
  //       <div className="d-flex">
  //         <button
  //           type="button"
  //           className="btn btn-danger"
  //           onClick={() =>
  //             selectUserRow(row._id, row.first_name + " " + row.last_name)
  //           }
  //         >
  //           <i class="fal fa-plus  fs-5"></i>
  //         </button>
  //       </div>
  //     </React.Fragment>
  //   );
  // }

  const limit = 10;

  const userManagment = async (page = 0) => {
    setLoader(true);
    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: "asc",
    };

    if (searchText) {
      payload.search = searchText;
    }

    const response = await postRequest(`homeblock/deal-package/list`, payload);
    const data = response.data;
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setuserdata(data.list);
    setLoader(false);
  };

  const selectRow = {
    mode: "checkbox", // or 'radio' for single selection
    clickToSelect: true, // enable click to select
    style: { background: "#c8e6c9" }, // background color for selected rows
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setselectedItems([...selectedItems, row]); // Push the whole user object
      } else {
        setselectedItems(selectedItems.filter((user) => user._id !== row._id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setselectedItems([]);
        return;
      }

      setselectedItems(rows); // Set selectedItems to all rows (whole user objects)
    },
  };

  const rowClasses = (row, rowIndex) => {
    return selectedItems.includes(row._id) ? "selected-row-class" : "";
  };

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={userdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <div className="w-50 d-flex">
                                    <label className="mb-2 mt-2 text-label">
                                      Page Size
                                    </label>
                                    <select
                                      value={sizePerPageOrg}
                                      onChange={handleChange}
                                      className="form-select"
                                      style={{ width: "20%" }}
                                    >
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                    </select>
                                  </div>
                                  
                                    <div className="d-flex g-3 justify-content-end">
                                      
                                      <input
                                        type="search"
                                        placeholder="Search"
                                        value={searchText}
                                        class="form-control"
                                        onChange={(e) =>
                                          setSearchText(e.target.value)
                                        }
                                        style={{ width: "250px" }}
                                      />

                                      <button
                                        onClick={() => {
                                          userManagment();
                                        }}
                                        type="button"
                                        className="btn btn-primary mx-2"
                                      >
                                        Search
                                      </button>
                                      {selectedItems.length > 0 && (
                                        <button
                                          className="btn btn-danger mx-2"
                                          onClick={() => {
                                            handlerSelectMultiple(
                                              selectedItems
                                            );
                                          }}
                                        >
                                          Add
                                        </button>
                                      )}
                                    </div>
                                 
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    remote={true}
                                    selectRow={selectRow}
                                    rowClasses={rowClasses}
                                    classes="table-custom-border"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const MultipleSelectDealList = ({
  showUser,
  closeUserModal,
  onSelectUser,
  selectUserRow,
}) => {
  return (
    <Modal
      isOpen={showUser}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Deal List
        </h5>

        <button
          onClick={() => {
            closeUserModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <TableComponent
          closeUserModal={closeUserModal}
          selectUserRow={selectUserRow}
          onSelectUser={onSelectUser}
        />
      </div>
    </Modal>
  );
};

export default MultipleSelectDealList;
