import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import { ToastContainer, toast } from "react-toastify";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { useLocation, useParams } from "react-router-dom";
import { postRequest } from "components/Common/Utils";

const RayanexpireTicket = () => {
  const [currentFPage, setCurrentFPage] = useState(1);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = useState("");
  const [openLightbox, setOpenLightBox] = useState(false);
  const [ticketInfo, setTicketInfo] = useState({ list: [], count: 0 });
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { id } = useParams();
  const offer = location.state?.state?.row;

  const fetchTicketInfo = async (
    page = currentFPage,
    sizePerPage = sizePerPageOrg
  ) => {
    setLoading(true);
    try {
      const response = await postRequest(`rayna/expired-tour/booking/list`, {
        ticketId: offer?.ticketId || id,
        page,
        sizePerPage,
      });
      if (response.data) {
        setTicketInfo(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch ticket information:", error);
      toast.error("Failed to fetch ticket information");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (offer?.ticketId) {
      fetchTicketInfo();
    }
  }, [offer, currentFPage, sizePerPageOrg, id]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setCurrentFPage(page);
    setSizePerPage(sizePerPage);
    fetchTicketInfo(page, sizePerPage);
  };

  const columns = [
    {
      dataField: "firstName",
      text: "Full Name",
      formatter: (cellContent, row) => (
        <p>
          {row?.passengers?.[0]?.firstName} {row?.passengers?.[0]?.lastName}
        </p>
      ),
      sort: false,
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cellContent, row) => <p>{row?.passengers?.[0]?.email}</p>,
      sort: false,
    },
    {
      dataField: "mobile",
      text: "Mobile",
      formatter: (cellContent, row) => <p>{row?.passengers?.[0]?.mobile}</p>,
      sort: false,
    },
    {
      dataField: "nationality",
      text: "Nationality",
      formatter: (cellContent, row) => (
        <p>{row?.passengers?.[0]?.nationality}</p>
      ),
      sort: false,
    },
    {
      dataField: "bookingType",
      text: "Booking Type",
      formatter: (cellContent, row) => <p>{row.bookingType}</p>,
      sort: false,
    },
    {
      dataField: "bookingStatus",
      text: "Booking Status",
      formatter: (cellContent, row) => <p>{row.bookingStatus}</p>,
      sort: false,
    },
    {
      dataField: "Tour Start Time",
      text: "Tour Start Time",
      formatter: (cellContent, row) => <p>{row?.TourDetails?.startTime}</p>,
      sort: false,
    },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
      formatter: (cellContent, row) => <p>{row?.paymentStatus}</p>,
      sort: false,
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cellContent, row) => <p>{row?.amount}</p>,
      sort: false,
    },
    {
      dataField: "cancelledTime",
      text: "Cancelled Time",
      formatter: (cellContent, row) => <p>{row.cancelledTime}</p>,
      sort: false,
    },
  ];

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Ticket Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Ticket Management</li>
              <li className="breadcrumb-item">Rayna Ticket Details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => window.history.back()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>

        <Card className="custom-card">
          <div
            className="cover-image"
            style={{ backgroundColor: "rgb(29, 155, 240)" }}
          ></div>
          <div className="profile-image" style={{ top: "150px" }}>
            <img
              src={`https://ui-avatars.com/api/?background=random&name=${ticketInfo?.tour?.name}`}
              onClick={() =>
                setOpenLightBox(
                  `https://ui-avatars.com/api/?background=random&name=${ticketInfo?.tour?.name}`
                )
              }
              alt="Image Not Available"
            />
          </div>
          <br />

          <div className="row my-2 py-2">
            <div className="userinfo col-md-12" style={{ paddingLeft: "20px" }}>
              <div className="fst">
                <h3>{ticketInfo?.tour?.email}</h3>
              </div>
              <div className="row">
                <div className="col-md-4 fst py-1">
                  <p>
                    Tour Name: <span>{ticketInfo?.tour?.tourName}</span>
                  </p>
                </div>
                <div className="col-md-4 fst py-1">
                  <p>
                    Tour Id: <span>{ticketInfo?.tour?.tourId}</span>
                  </p>
                </div>
                <div className="col-md-4 fst py-1">
                  <p>
                    City Name: <span>{ticketInfo?.tour?.cityName}</span>
                  </p>
                </div>
                <div className="col-md-4 fst py-1">
                  <p>
                    City Tour Type:{" "}
                    <span>{ticketInfo?.tour?.cityTourType}</span>
                  </p>
                </div>
                <div className="col-md-4 fst py-1">
                  <p>
                    Country Name: <span>{ticketInfo?.tour?.countryName}</span>
                  </p>
                </div>
                <div className="col-md-4 fst py-1">
                  <p>
                    Duration: <span>{ticketInfo?.tour?.duration}</span>
                  </p>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </Card>

        <Col className="col-12">
          <Card>
            <CardBody className="table-shorting">
              {ticketInfo.list.length === 0 ? (
                <div className="text-center py-5">
                  <h4>Ticket is no longer available</h4>
                </div>
              ) : (
                <PaginationProvider
                  pagination={paginationFactory({
                    page: currentFPage,
                    sizePerPage: sizePerPageOrg,
                    totalSize: ticketInfo?.count,
                    custom: true,
                  })}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <>
                      <ToolkitProvider
                        keyField="_id"
                        data={ticketInfo?.list}
                        columns={columns}
                      >
                        {(props) => (
                          <Row>
                            <Col className="col-lg-12 text-end">
                              <BootstrapTable
                                classes="table-striped"
                                {...props.baseProps}
                                {...paginationTableProps}
                                onTableChange={handleTableChange}
                              />
                            </Col>
                          </Row>
                        )}
                      </ToolkitProvider>
                      <Row>
                        <Col className="col-lg-12 text-end">
                          <div className="text-md-right">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </PaginationProvider>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {openLightbox && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
    </>
  );
};

export default RayanexpireTicket;
