import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  putRequestForm,
  customValidator,
  putRequest,
  postRequestForm,
  postRequest,
} from "../../components/Common/Utils.js";
import Lightbox from "react-image-lightbox";
import CustomCard from "pages/CustomSubscriptionManagement/CustomCard.js";
import NotificationPreview from "./notificationPreview.js";
import Accordion from "react-bootstrap/Accordion";
import OfferModal from "components/Modal/OfferModal.js";
import YachtModal from "components/Modal/YachtModal.js";
import EventModal from "components/Modal/EventModal.js";
import ActivityModal from "components/Modal/ActivityModal.js";
import VenueModal from "../../components/Modal/VenueModalOrg.js";
import { useDebounceEffect } from "pages/VenueManagement/useDebounceEffect.js";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "pages/VenueManagement/canvasPreview.js";
import FileUpload from "components/Common/FileUpload.js";
export default function NotificationForm() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  const [activeTab, setActiveTab] = useState("classic");

  const handleTabChange = (newTabValue) => {
    setActiveTab(newTabValue);
  };

  const [categoryData, setCategoryData] = useState({
    title: "",
    subTitle: "",
    frequency: "",
    bgColor: "",
    titleColor: "",
    subTitleColor: "",
    endColor: "",
    image: "",
    buttonText1: "",
    buttonText2: "",
    btn1Color: "",
    btn2Color: "",
    packageId: "",
    backgroundImage: "",
    description: "",
    backgroundType: "color",
    packageName: "",
    packagePrice: "",
    packageDuration: "",
    layout: "",
  });

  const [previewImage, setPreviewImage] = useState("");
  const [previewBackgroundImage, setPreviewBackgroundImage] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [packagedata, setpackagedata] = useState([]);
  const [previewLogo, setPreviewLogo] = useState(false);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [model, setModel] = useState(false);
  const [offerModel, setOfferModel] = useState(false);
  const [yachtId, setYachtId] = useState("");
  const [yachtText, setYachtText] = useState("");
  const [yachtImage, setYachtImage] = useState("");
  const [yachtModel, setYachtModel] = useState(false);
  const [eventId, setEventId] = useState("");
  const [eventText, setEventText] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventModel, setEventModel] = useState(false);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightbox2, setOpenLightBox2] = React.useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [activityModel, setActivityModel] = useState(false);
  const [logoSelected, setLogoSelected] = useState(0);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [startLoader, setStartLoader] = useState(false);
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });
  const [selectedCover, setSelectedCover] = useState("");
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (state) {
      delete state.__v;
      delete state._id;
      delete state.createdAt;
      delete state.updatedAt;

      delete state.status;

      setCategoryData(state);
      // setPreviewImage(state.image);
      // setPreviewBackgroundImage(state.backgroundImage);
      setImageIcon(state.image);
    }
  }, [state]);

  useEffect(() => {
    if (!remoteLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setLogoSelected(reader.result);
      const categoryTemp = { ...categoryData };
      categoryTemp.image = reader.result;
      setCategoryData(categoryTemp);
    };

    reader.readAsDataURL(remoteLogo);
  }, [remoteLogo]);

  // useEffect on packageId update

  useEffect(() => {
    const packageData = packagedata.find(
      (item) => item._id == categoryData.packageId
    );
    if (packageData) {
      const categoryTemp = { ...categoryData };
      categoryTemp.packageName = packageData.title;
      categoryTemp.packagePrice = packageData.discountedPrice;
      categoryTemp.packageDuration = packageData.time;
      setCategoryData(categoryTemp);
    }
  }, [categoryData.packageId]);

  useEffect(() => {
    if (!selectedBackgroundImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewBackgroundImage(reader.result);
      const categoryTemp = { ...categoryData };
      categoryTemp.backgroundImage = reader.result;
      setCategoryData(categoryTemp);
    };

    reader.readAsDataURL(selectedBackgroundImage);
  }, [selectedBackgroundImage]);

  const toggleModel = () => {
    setModel(!model);
    console.log("toggleModel", model);
  };
  // const showLightBox = (previewImage) => {
  //   setPreviewImage(previewImage);
  //   setOpenLightBox(true);
  // };
  // const showLightBox2 = (previewLogo) => {
  //   setPreviewLogo(previewLogo);
  //   setOpenLightBox2(true);
  // };

const blobUrlRef = useRef("");
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };
  const showLightBox = (previewLogo) => {
    setPreviewLogo(previewLogo);
    setOpenLightBox(true);
  };

  const showLightBox2 = (previewCover) => {
    setPreviewCover(previewCover);
    setOpenLightBox2(true);
  };
  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      // convert blob to file

      const file = new File([blob], categoryData?.name + "-logo.jpg", {
        type: "image/jpeg",
      });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const toggleOfferModel = () => {
    setOfferModel(!offerModel);
  };
  const toggleYachtModel = () => {
    setYachtModel(!yachtModel);
  };
  const toggleEventModel = () => {
    setEventModel(!eventModel);
  };
  const toggleActivityModel = () => {
    setActivityModel(!activityModel);
  };

  const closeModal = () => {
    setModel(false);
  };

  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const closeYachtModal = () => {
    setYachtModel(false);
  };
  const closeEventModal = () => {
    setEventModel(false);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };
  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(name);
    setVenueImage(venue?.logo);
    closeModal();
  };

  const selectOfferRow = (offer) => {
    setOfferId(offer?._id);
    setOfferText(offer?.title);
    setOfferImage(offer?.image);
    closeOfferModal();
  };

  const selectYachtRow = (yacht) => {
    setYachtId(yacht?._id);
    setYachtText(yacht?.name);
    setYachtImage(yacht?.image);
    closeYachtModal();
  };
  const selectEventRow = (id, name, event) => {
    setEventId(id);
    setEventText(name);
    setEventImage(event?.image);
    closeEventModal();
  };
  const selectActivityRow = (id, name, activity) => {
    setActivityId(id);
    setActivityText(name);
    setActivityImage(activity?.image);
    closeActivityModal();
  };

  const createNotificationData = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (!categoryData.title) {
      toast.error("Title is required");
      isValid = false;
    }
    if (!categoryData.subTitle) {
      toast.error("Please enter Subtitle");
      isValid = false;
    }

    if (!categoryData.type1) {
      toast.error("Please enter Button 1 Type");
      isValid = false;
    }
    if (
      !categoryData.data1 &&
      !venueId &&
      !offerId &&
      !eventId &&
      !activityId
    ) {
      toast.error("Please select at least one option");
      isValid = false;
    }

    // check image

    // if (!categoryData.image) {
    //   toast.error("Please select Image");
    //   isValid = false;
    // }

    if (!isValid) {
      return; // If any required field is missing, don't proceed
    }
    setStartLoader(true);
    setLoading(true);

    const titleObject = {
      text: categoryData.title,
      color: categoryData.titleColor || "#ffffff",
      alignment: categoryData.titleAlignment || "center",
    };

    const subTitleObject = {
      text: categoryData.subTitle,
      color: categoryData.subTitleColor || "#ffffff",
      alignment: categoryData.subTitleAlignment || "center",
    };

    const descObject = {
      text: categoryData.description,
      color: categoryData.descriptionColor || "#808080",
      alignment: categoryData.descAlignment || "center",
    };

    const btn1Object = {
      text: categoryData.buttonText1 || "Click Me",
      color: categoryData.btn1TextColor || "#000000",
      bgColor: categoryData.btn1Color || "#ffffff",
      action: categoryData.type1,
    };

    if (categoryData.type1 === "venue") {
      btn1Object.data = venueId;
    } else if (categoryData.type1 === "offer") {
      btn1Object.data = offerId;
    } else if (categoryData.type1 === "event") {
      btn1Object.data = eventId;
    } else if (categoryData.type1 === "activity") {
      btn1Object.data = activityId;
    } else if (categoryData.type1 === "link") {
      btn1Object.data = categoryData.data1;
    } else {
      btn1Object.data = null;
    }

    const btn2Object = {
      text: categoryData.buttonText2,
      color: categoryData.btn2TextColor || "#000000",
      bgColor: categoryData.btn2Color || "#ffffff",
      action: categoryData.type,
      data: categoryData.data,
    };
    if (categoryData.type === "venue") {
      btn2Object.data = venueId;
    } else if (categoryData.type === "offer") {
      btn2Object.data = offerId;
    } else if (categoryData.type === "event") {
      btn2Object.data = eventId;
    } else if (categoryData.type === "activity") {
      btn2Object.data = activityId;
    } else if (categoryData.type === "link") {
      btn2Object.data = categoryData.data;
    } else {
      btn2Object.data = null;
    }

    const backgroundObject = {
      color: categoryData.bgColor || "#3B3B3B", // Use the default value "#ffffff" if bgColor is not set
    };

    let selectedImageForm = new FormData();

    let selectedImageRes = null;

    if (categoryData.image) {
      selectedImageForm.append("image", remoteLogo);
      selectedImageRes = await postRequestForm(
        "comman/img/upload",
        selectedImageForm
      );
      setStartLoader(false);
    }

    let selectedBackgroundImageRes = null;

    if (categoryData.backgroundType == "image") {
      const selectedBackgroundImageForm = new FormData();
      selectedBackgroundImageForm.append("image", selectedBackgroundImage);
      selectedBackgroundImageRes = await postRequestForm(
        "comman/img/upload",
        selectedBackgroundImageForm
      );
    }
    // formData.append("image", selectedImage);
    if (selectedImageRes?.data?.url) {
      categoryData.image = selectedImageRes.data.url;
    } else {
      // Handle the case where the image URL is not available in the response
      console.error("Image URL not found in the response.");
    }

    if (categoryData.backgroundType == "image") {
      if (selectedBackgroundImageRes?.data?.url) {
        categoryData.backgroundImage = selectedBackgroundImageRes.data.url;
      } else {
        // Handle the case where the image URL is not available in the response
        console.error("Image URL not found in the response.");
      }
    } else {
      categoryData.backgroundImage = "";
    }

    categoryData.subId = id;

    const dataObject = {
      title: titleObject,
      subtitle: subTitleObject,
      description: descObject,
      button1: btn1Object,
      button2: btn2Object,
      background: backgroundObject,
      layout: activeTab,
      image: categoryData.image,
    };

    const response = await postRequest(
      "chat/sendInAppNotification",
      dataObject
    );
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/inAppNotification");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...categoryData };
    list[index] = value;
    setCategoryData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>In-app Notifications</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Create Notification</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> In-app Notification </h4>
                <Form onSubmit={createNotificationData}>
                  <Row>
                    <Col lg={6} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-6">
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Title*</Accordion.Header>
                            <Accordion.Body>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Text
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      updateValue("title", e.target.value)
                                    }
                                    value={categoryData?.title}
                                    name="title"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Color
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="color"
                                    onChange={(e) =>
                                      updateValue("titleColor", e.target.value)
                                    }
                                    value={
                                      categoryData?.titleColor || "#ffffff"
                                    }
                                    name="titleColor"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Alignment
                                </label>
                                <div className="col-sm-9">
                                  <select
                                    onChange={(e) =>
                                      updateValue(
                                        "titleAlignment",
                                        e.target.value
                                      )
                                    }
                                    value={
                                      categoryData.titleAlignment || "center"
                                    }
                                    name="titleAlignment"
                                    className="form-select"
                                    defaultValue="center"
                                  >
                                    <option value="">Select</option>
                                    <option value="left">Left</option>
                                    <option value="center">Center</option>
                                    <option value="right">Right</option>
                                  </select>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Sub Title*</Accordion.Header>
                            <Accordion.Body>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Text
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      updateValue("subTitle", e.target.value)
                                    }
                                    value={categoryData?.subTitle}
                                    name="subTitle"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Color
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="color"
                                    onChange={(e) =>
                                      updateValue(
                                        "subTitleColor",
                                        e.target.value
                                      )
                                    }
                                    value={
                                      categoryData?.subTitleColor || "#ffffff"
                                    }
                                    name="subTitleColor"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Alignment
                                </label>
                                <div className="col-sm-9">
                                  <select
                                    onChange={(e) =>
                                      updateValue(
                                        "subTitleAlignment",
                                        e.target.value
                                      )
                                    }
                                    value={
                                      categoryData.subTitleAlignment || "center"
                                    }
                                    name="subTitleAlignment"
                                    defaultValue="center"
                                    className="form-select"
                                  >
                                    <option value="">Select</option>
                                    <option value="left">Left</option>
                                    <option value="center">Center</option>
                                    <option value="right">Right</option>
                                  </select>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>Description</Accordion.Header>
                            <Accordion.Body>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Text
                                </label>
                                <div className="col-sm-9">
                                  <textarea
                                    type="text"
                                    onChange={(e) =>
                                      updateValue("description", e.target.value)
                                    }
                                    value={categoryData?.description}
                                    name="description"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Color
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="color"
                                    onChange={(e) =>
                                      updateValue(
                                        "descriptionColor",
                                        e.target.value
                                      )
                                    }
                                    value={
                                      categoryData?.descriptionColor ||
                                      "#ffffff"
                                    }
                                    name="descriptionColor"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Alignment
                                </label>
                                <div className="col-sm-9">
                                  <select
                                    onChange={(e) =>
                                      updateValue(
                                        "descAlignment",
                                        e.target.value
                                      )
                                    }
                                    value={
                                      categoryData.descAlignment || "center"
                                    }
                                    name="descAlignment"
                                    className="form-select"
                                    defaultValue="center"
                                  >
                                    <option value="">Select</option>
                                    <option value="left">Left</option>
                                    <option value="center">Center</option>
                                    <option value="right">Right</option>
                                  </select>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>Button 1*</Accordion.Header>
                            <Accordion.Body>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Text
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      updateValue("buttonText1", e.target.value)
                                    }
                                    value={
                                      categoryData?.buttonText1 || "Click Me"
                                    }
                                    name="buttonText1"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Background Color
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="color"
                                    onChange={(e) =>
                                      updateValue("btn1Color", e.target.value)
                                    }
                                    value={categoryData?.btn1Color || "#ffffff"}
                                    name="btn1Color"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Text Color
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="color"
                                    onChange={(e) =>
                                      updateValue(
                                        "btn1TextColor",
                                        e.target.value
                                      )
                                    }
                                    value={
                                      categoryData?.btn1TextColor || "#000000"
                                    }
                                    name="btn1TextColor"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Type
                                </label>
                                <div className="col-sm-9">
                                  <select
                                    onChange={(e) =>
                                      updateValue("type1", e.target.value)
                                    }
                                    value={categoryData.type1}
                                    name="type1"
                                    className="form-select"
                                    defaultValue=""
                                  >
                                    <option value="">Select</option>
                                    <option value="link">Link</option>
                                    <option value="venue">Venue</option>
                                    <option value="offer">Offer</option>
                                    <option value="event">Event</option>
                                    <option value="activity">Activity</option>
                                  </select>
                                </div>
                              </div>
                              {categoryData?.type1 == "venue" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Venue
                                  </label>
                                  <div
                                    className="col-sm-9"
                                    onClick={() => toggleModel()}
                                  >
                                    {venueText ? (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseVenueBtn"
                                          onclick={() => toggleModel()}
                                        >
                                          Choose Venue
                                        </button>
                                        <div
                                          style={{
                                            border: "1px solid #ced4da",
                                            width: "16px",
                                          }}
                                        ></div>
                                        <img
                                          src={venueImage}
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            border: "1px solid #ced4da",
                                            borderRight: "none",
                                            borderLeft: "none",
                                          }}
                                        ></img>
                                        <input
                                          type="text"
                                          style={{ borderLeft: "none" }}
                                          class="form-control"
                                          readonly
                                          value={venueText}
                                          id="selectedVenue"
                                          placeholder="No venue chosen"
                                        />
                                      </div>
                                    ) : (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseVenueBtn"
                                          onclick={() => toggleModel()}
                                        >
                                          Choose Venue
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          id="selectedVenue"
                                          placeholder="No venue chosen"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {categoryData?.type1 == "yacht" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Yacht
                                  </label>
                                  <div
                                    className="col-sm-9"
                                    onClick={() => toggleYachtModel()}
                                  >
                                    {yachtText ? (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseyachtBtn"
                                          onclick={() => toggleYachtModel()}
                                        >
                                          Choose Yacht
                                        </button>
                                        <div
                                          style={{
                                            border: "1px solid #ced4da",
                                            width: "16px",
                                          }}
                                        ></div>
                                        <img
                                          src={yachtImage}
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            border: "1px solid #ced4da",
                                            borderRight: "none",
                                            borderLeft: "none",
                                          }}
                                        ></img>
                                        <input
                                          type="text"
                                          style={{ borderLeft: "none" }}
                                          class="form-control"
                                          readonly
                                          value={yachtText}
                                          id="selectedYacht"
                                          placeholder="No yacht chosen"
                                        />
                                      </div>
                                    ) : (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseyachtBtn"
                                          onclick={() => toggleYachtModel()}
                                        >
                                          Choose Yacht
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          value={yachtText}
                                          id="selectedYacht"
                                          placeholder="No yacht chosen"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {categoryData?.type1 == "event" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Event
                                  </label>
                                  <div
                                    className="col-sm-9"
                                    onClick={() => toggleEventModel()}
                                  >
                                    {eventText ? (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseEventBtn"
                                          onclick={() => toggleEventModel()}
                                        >
                                          Choose Event
                                        </button>
                                        <div
                                          style={{
                                            border: "1px solid #ced4da",
                                            width: "16px",
                                          }}
                                        ></div>
                                        <img
                                          src={eventImage}
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            border: "1px solid #ced4da",
                                            borderRight: "none",
                                            borderLeft: "none",
                                          }}
                                        ></img>
                                        <input
                                          type="text"
                                          style={{ borderLeft: "none" }}
                                          class="form-control"
                                          readonly
                                          value={eventText}
                                          id="selectedEvent"
                                          placeholder="No event chosen"
                                        />
                                      </div>
                                    ) : (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseEventBtn"
                                          onclick={() => toggleEventModel()}
                                        >
                                          Choose Event
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          value={eventText}
                                          id="selectedEvent"
                                          placeholder="No event chosen"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {categoryData?.type1 == "activity" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Activity
                                  </label>
                                  <div
                                    className="col-sm-9"
                                    onClick={() => toggleActivityModel()}
                                  >
                                    {activityText ? (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseActivityBtn"
                                          onclick={() => toggleActivityModel()}
                                        >
                                          Choose Activity
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          value={activityText}
                                          id="selectedActivity"
                                          placeholder="No activity chosen"
                                        />
                                      </div>
                                    ) : (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseActivityBtn"
                                          onclick={() => toggleActivityModel()}
                                        >
                                          Choose Activity
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          value={activityText}
                                          id="selectedActivity"
                                          placeholder="No activity chosen"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {categoryData?.type1 == "offer" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Offer
                                  </label>

                                  <div
                                    className="col-sm-9"
                                    onClick={() => toggleOfferModel()}
                                  >
                                    {offerText ? (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseOfferBtn"
                                          onclick={() => toggleOfferModel()}
                                        >
                                          Choose Offer
                                        </button>
                                        <div
                                          style={{
                                            border: "1px solid #ced4da",
                                            width: "16px",
                                          }}
                                        ></div>
                                        <img
                                          src={offerImage}
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            border: "1px solid #ced4da",
                                            borderRight: "none",
                                            borderLeft: "none",
                                          }}
                                        ></img>
                                        <input
                                          type="text"
                                          style={{ borderLeft: "none" }}
                                          class="form-control"
                                          readonly
                                          value={offerText}
                                          id="selectedOffer"
                                          placeholder="No offer chosen"
                                        />
                                      </div>
                                    ) : (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseOfferBtn"
                                          onclick={() => toggleOfferModel()}
                                        >
                                          Choose Offer
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          value={offerText}
                                          id="selectedOffer"
                                          placeholder="No offer chosen"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {categoryData?.type1 == "link" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Link
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="url"
                                      onChange={(e) =>
                                        updateValue("data1", e.target.value)
                                      }
                                      value={categoryData?.data1}
                                      name="title"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              )}

                              {/* {(categoryData?.type1 === "link" ||
                                categoryData?.type1 === "venue" ||
                                categoryData?.type1 === "offer" ||
                                categoryData?.type1 === "event" ||
                                categoryData?.type1 === "activity") && (
                                <>
                                  <div className="row mb-4">
                                    {categoryData?.type1 === "link" && (
                                      <label className="col-sm-3 col-form-label">
                                        Link
                                      </label>
                                    )}
                                    {categoryData?.type1 === "venue" && (
                                      <label className="col-sm-3 col-form-label">
                                        Venue Id
                                      </label>
                                    )}
                                    {categoryData?.type1 === "offer" && (
                                      <label className="col-sm-3 col-form-label">
                                        Offer Id
                                      </label>
                                    )}
                                    {categoryData?.type1 === "event" && (
                                      <label className="col-sm-3 col-form-label">
                                        Event Id
                                      </label>
                                    )}
                                    {categoryData?.type1 === "activity" && (
                                      <label className="col-sm-3 col-form-label">
                                        Activity Id
                                      </label>
                                    )}
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateValue("data1", e.target.value)
                                        }
                                        value={categoryData?.data1}
                                        name="data1"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </>
                              )} */}
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>Button 2</Accordion.Header>
                            <Accordion.Body>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Text
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      updateValue("buttonText2", e.target.value)
                                    }
                                    value={categoryData?.buttonText2}
                                    name="buttonText2"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Color
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="color"
                                    onChange={(e) =>
                                      updateValue("btn2Color", e.target.value)
                                    }
                                    value={categoryData?.btn2Color || "#ffffff"}
                                    name="btn2Color"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Text Color
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="color"
                                    onChange={(e) =>
                                      updateValue(
                                        "btn2TextColor",
                                        e.target.value
                                      )
                                    }
                                    value={
                                      categoryData?.btn2TextColor || "#000000"
                                    }
                                    name="btn2TextColor"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Type
                                </label>
                                <div className="col-sm-9">
                                  <select
                                    onChange={(e) =>
                                      updateValue("type", e.target.value)
                                    }
                                    value={categoryData.type}
                                    name="type"
                                    className="form-select"
                                    defaultValue=""
                                  >
                                    <option value="">Select</option>
                                    <option value="link">Link</option>
                                    <option value="venue">Venue</option>
                                    <option value="offer">Offer</option>
                                    <option value="event">Event</option>
                                    <option value="activity">Activity</option>
                                  </select>
                                </div>
                              </div>
                              {categoryData?.type == "venue" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Venue
                                  </label>
                                  <div
                                    className="col-sm-9"
                                    onClick={() => toggleModel()}
                                  >
                                    {venueText ? (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseVenueBtn"
                                          onclick={() => toggleModel()}
                                        >
                                          Choose Venue
                                        </button>
                                        <div
                                          style={{
                                            border: "1px solid #ced4da",
                                            width: "16px",
                                          }}
                                        ></div>
                                        <img
                                          src={venueImage}
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            border: "1px solid #ced4da",
                                            borderRight: "none",
                                            borderLeft: "none",
                                          }}
                                        ></img>
                                        <input
                                          type="text"
                                          style={{ borderLeft: "none" }}
                                          class="form-control"
                                          readonly
                                          value={venueText}
                                          id="selectedVenue"
                                          placeholder="No venue chosen"
                                        />
                                      </div>
                                    ) : (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseVenueBtn"
                                          onclick={() => toggleModel()}
                                        >
                                          Choose Venue
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          id="selectedVenue"
                                          placeholder="No venue chosen"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {categoryData?.type == "yacht" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Yacht
                                  </label>
                                  <div
                                    className="col-sm-9"
                                    onClick={() => toggleYachtModel()}
                                  >
                                    {yachtText ? (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseyachtBtn"
                                          onclick={() => toggleYachtModel()}
                                        >
                                          Choose Yacht
                                        </button>
                                        <div
                                          style={{
                                            border: "1px solid #ced4da",
                                            width: "16px",
                                          }}
                                        ></div>
                                        <img
                                          src={yachtImage}
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            border: "1px solid #ced4da",
                                            borderRight: "none",
                                            borderLeft: "none",
                                          }}
                                        ></img>
                                        <input
                                          type="text"
                                          style={{ borderLeft: "none" }}
                                          class="form-control"
                                          readonly
                                          value={yachtText}
                                          id="selectedYacht"
                                          placeholder="No yacht chosen"
                                        />
                                      </div>
                                    ) : (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseyachtBtn"
                                          onclick={() => toggleYachtModel()}
                                        >
                                          Choose Yacht
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          value={yachtText}
                                          id="selectedYacht"
                                          placeholder="No yacht chosen"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {categoryData?.type == "event" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Event
                                  </label>
                                  <div
                                    className="col-sm-9"
                                    onClick={() => toggleEventModel()}
                                  >
                                    {eventText ? (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseEventBtn"
                                          onclick={() => toggleEventModel()}
                                        >
                                          Choose Event
                                        </button>
                                        <div
                                          style={{
                                            border: "1px solid #ced4da",
                                            width: "16px",
                                          }}
                                        ></div>
                                        <img
                                          src={eventImage}
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            border: "1px solid #ced4da",
                                            borderRight: "none",
                                            borderLeft: "none",
                                          }}
                                        ></img>
                                        <input
                                          type="text"
                                          style={{ borderLeft: "none" }}
                                          class="form-control"
                                          readonly
                                          value={eventText}
                                          id="selectedEvent"
                                          placeholder="No event chosen"
                                        />
                                      </div>
                                    ) : (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseEventBtn"
                                          onclick={() => toggleEventModel()}
                                        >
                                          Choose Event
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          value={eventText}
                                          id="selectedEvent"
                                          placeholder="No event chosen"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {categoryData?.type == "activity" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Activity
                                  </label>
                                  <div
                                    className="col-sm-9"
                                    onClick={() => toggleActivityModel()}
                                  >
                                    {activityText ? (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseActivityBtn"
                                          onclick={() => toggleActivityModel()}
                                        >
                                          Choose Activity
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          value={activityText}
                                          id="selectedActivity"
                                          placeholder="No activity chosen"
                                        />
                                      </div>
                                    ) : (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseActivityBtn"
                                          onclick={() => toggleActivityModel()}
                                        >
                                          Choose Activity
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          value={activityText}
                                          id="selectedActivity"
                                          placeholder="No activity chosen"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {categoryData?.type == "offer" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Select Offer
                                  </label>

                                  <div
                                    className="col-sm-9"
                                    onClick={() => toggleOfferModel()}
                                  >
                                    {offerText ? (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseOfferBtn"
                                          onclick={() => toggleOfferModel()}
                                        >
                                          Choose Offer
                                        </button>
                                        <div
                                          style={{
                                            border: "1px solid #ced4da",
                                            width: "16px",
                                          }}
                                        ></div>
                                        <img
                                          src={offerImage}
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            border: "1px solid #ced4da",
                                            borderRight: "none",
                                            borderLeft: "none",
                                          }}
                                        ></img>
                                        <input
                                          type="text"
                                          style={{ borderLeft: "none" }}
                                          class="form-control"
                                          readonly
                                          value={offerText}
                                          id="selectedOffer"
                                          placeholder="No offer chosen"
                                        />
                                      </div>
                                    ) : (
                                      <div class="input-group">
                                        <button
                                          class="btn"
                                          style={{
                                            border: "1px solid #ced4da",
                                            background: "#e60489",
                                            color: "#ffff",
                                          }}
                                          type="button"
                                          id="chooseOfferBtn"
                                          onclick={() => toggleOfferModel()}
                                        >
                                          Choose Offer
                                        </button>
                                        <input
                                          type="text"
                                          class="form-control"
                                          readonly
                                          value={offerText}
                                          id="selectedOffer"
                                          placeholder="No offer chosen"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              {categoryData?.type == "link" && (
                                <div className="row mb-4">
                                  <label className="col-sm-3 col-form-label">
                                    Link
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="url"
                                      onChange={(e) =>
                                        updateValue("data", e.target.value)
                                      }
                                      value={categoryData?.data}
                                      name="title"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              )}
                              {/* {(categoryData?.type === "link" ||
                                categoryData?.type === "venue" ||
                                categoryData?.type === "offer" ||
                                categoryData?.type === "event" ||
                                categoryData?.type === "activity") && (
                                <>
                                  <div className="row mb-4">
                                    {categoryData?.type === "link" && (
                                      <label className="col-sm-3 col-form-label">
                                        Link
                                      </label>
                                    )}
                                    {categoryData?.type === "venue" && (
                                      <label className="col-sm-3 col-form-label">
                                        Venue Id
                                      </label>
                                    )}
                                    {categoryData?.type === "offer" && (
                                      <label className="col-sm-3 col-form-label">
                                        Offer Id
                                      </label>
                                    )}
                                    {categoryData?.type === "event" && (
                                      <label className="col-sm-3 col-form-label">
                                        Event Id
                                      </label>
                                    )}
                                    {categoryData?.type === "activity" && (
                                      <label className="col-sm-3 col-form-label">
                                        Activity Id
                                      </label>
                                    )}
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateValue("data", e.target.value)
                                        }
                                        value={categoryData?.data}
                                        name="data"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </>
                              )} */}
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>Image</Accordion.Header>
                            <Accordion.Body>
                              {/* <div className="row mb-4">
                                <label
                                  htmlFor="horizontal-password-input"
                                  className="col-sm-3 col-form-label"
                                >
                                  Image
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      setSelectedImage(e.target.files[0])
                                    }
                                    name="image"
                                    className="form-control"
                                    id="horizontal-password-input"
                                  />
                                  <br />
                                  {previewImage && (
                                    <img
                                      src={previewImage}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                    />
                                  )}
                                  {imageIcon && (
                                    <img
                                      src={imageIcon}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                    />
                                  )}
                                </div>
                              </div> */}
                              <div className="row mb-4">
                                <label
                                  htmlFor="horizontal-password-input"
                                  className="col-sm-3 col-form-label"
                                >
                                  Select Image
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="file"
                                    // onChange={(e) =>
                                    //   setSelectedLogo(e.target.files[0])
                                    // }
                                    onChange={handleLogoChange}
                                    name="image"
                                    className="form-control"
                                    id="horizontal-password-input"
                                  />
                                  <br />
                                  {previewLogo && (
                                    <ReactCrop
                                      crop={crop}
                                      src={previewLogo}
                                      onChange={(newCrop) => setCrop(newCrop)}
                                      onComplete={handleCropComplete}
                                      style={{
                                        maxWidth: "720px",
                                        maxHeight: "600px",
                                      }}
                                    >
                                      <img
                                        ref={imgRef}
                                        src={previewLogo}
                                        onClick={() =>
                                          showLightBox(previewLogo)
                                        }
                                      />
                                    </ReactCrop>
                                  )}

                                  {completedCrop && (
                                    <div>
                                      <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                          border: "1px solid black",
                                          objectFit: "contain",
                                          width: completedCrop.width,
                                          height: completedCrop.height,
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="6">
                            <Accordion.Header>Background</Accordion.Header>
                            <Accordion.Body>
                              <div className="row mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Background Color
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="color"
                                    onChange={(e) =>
                                      updateValue("bgColor", e.target.value)
                                    }
                                    value={categoryData?.bgColor || "#3B3B3B"}
                                    name="bgColor"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-2">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="mt-3 ms-lg-auto">
                      <NotificationPreview
                        notificationDialog={categoryData}
                        onTabChange={handleTabChange}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewImage}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      {openLightbox2 && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox2(false)}
        />
      )}

      {/* modal */}

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />

      {/* modal */}
      <OfferModal
        show={offerModel}
        closeModal={closeOfferModal}
        selectRow={selectOfferRow}
      />
      <YachtModal
        show={yachtModel}
        closeModal={closeYachtModal}
        selectRow={selectYachtRow}
      />
      <EventModal
        show={eventModel}
        closeEventModal={closeEventModal}
        selectRow={selectEventRow}
      />
      <ActivityModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      />
      <FileUpload message="File Uploading" status={startLoader} />
    </React.Fragment>
  );
}
