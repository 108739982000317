import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner, Input, Button, Label } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequest } from "../../components/Common/Utils.js"; // Assuming you have a postRequest function

export default function PolicyEditor() {
  const [policyData, setPolicyData] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    fetchPolicy();
  }, []);

  const fetchPolicy = async () => {
    setLoading(true);
    const response = await postRequest("promoter/policy/get/", {});
    setLoading(false);

    if (response.status === 1) {
      toast.success(response.message);
      setPolicyData(response.data);
    } else {
      toast.error(response.message);
    }
  };

  const handleInputChange = (objPath, value) => {
    setPolicyData(prev => {
      const newData = { ...prev };
      const pathParts = objPath.split('.');
      let curr = newData;
      for (let i = 0; i < pathParts.length - 1; i++) {
        curr = curr[pathParts[i]];
      }
      curr[pathParts[pathParts.length - 1]] = value;
      return newData;
    });
  };

  // Levels Handlers
  const addLevel = () => {
    const newLevels = [...policyData.levels];
    const maxLevel = newLevels.reduce((acc, lvl) => Math.max(acc, lvl.level), 0);
    newLevels.push({
      level: maxLevel + 1,
      action: "frequencyIncrease",
      targetedVenue: "specific",
      costMulti: 0
    });
    setPolicyData(prev => ({ ...prev, levels: newLevels }));
  };

  const updateLevel = (index, field, value) => {
    // Ensure no negative values for costMulti
    if (field === "costMulti" && value < 0) {
      toast.error("Value cannot be negative.");
      return;
    }
    const newLevels = [...policyData.levels];
    newLevels[index][field] = value;
    setPolicyData(prev => ({ ...prev, levels: newLevels }));
  };

  const removeLevel = (index) => {
    const newLevels = [...policyData.levels];
    newLevels.splice(index, 1);
    setPolicyData(prev => ({ ...prev, levels: newLevels }));
  };

  // Validate Penalty Input
  const validatePenaltyInput = (index, field, newValue) => {
    // No negative values allowed
    if (newValue < 0) {
      toast.error("Value cannot be negative.");
      return false;
    }

    // Ensure sequential hours:
    // If this is not the first penalty, check that its fromHour is greater than the previous penalty's toHour
    // if (field === "fromHour" && index > 0) {
    //   const prevPenaltyToHour = policyData.penalties[index - 1].toHour;
    //   if (newValue <= prevPenaltyToHour) {
    //     toast.error(`From Hour must be greater than ${prevPenaltyToHour}.`);
    //     return false;
    //   }
    // }

    return true;
  };

  // Penalties Handlers
  const addPenalty = () => {
    const newPenalties = [...policyData.penalties];
    newPenalties.push({
      fromHour: 0,
      toHour: 0,
      frequencyIncrease: 0,
      banDuration: 0,
      cost: 0
    });
    setPolicyData(prev => ({ ...prev, penalties: newPenalties }));
  };

  const updatePenalty = (index, field, value) => {
    const intValue = isNaN(value) ? 0 : value; // parse the input, default to 0 if not valid number

    const currentPenalty = policyData.penalties[index];
    const oldValue = currentPenalty[field];

    // Check if the newValue is valid
    if (!validatePenaltyInput(index, field, intValue)) {
      // Revert to old value if invalid
      const newPenalties = [...policyData.penalties];
      newPenalties[index][field] = oldValue;
      setPolicyData(prev => ({ ...prev, penalties: newPenalties }));
      return;
    }

    // If toHour is set, ensure it's not less than fromHour
    if (field === "toHour") {
      const fromHour = policyData.penalties[index].fromHour;
      // if (intValue < fromHour) {
      //   toast.error("To Hour cannot be less than From Hour.");
      //   // revert to old value
      //   const newPenalties = [...policyData.penalties];
      //   newPenalties[index][field] = oldValue;
      //   setPolicyData(prev => ({ ...prev, penalties: newPenalties }));
      //   return;
      // }
    }

    const newPenalties = [...policyData.penalties];
    newPenalties[index][field] = intValue;
    setPolicyData(prev => ({ ...prev, penalties: newPenalties }));
  };

  const removePenalty = (index) => {
    const newPenalties = [...policyData.penalties];
    newPenalties.splice(index, 1);
    setPolicyData(prev => ({ ...prev, penalties: newPenalties }));
  };

  const savePolicy = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await postRequest("promoter/policy/update/", policyData);
    setLoading(false);

    if (response.status === 1) {
      fetchPolicy();
    } else {
      toast.error(response.message);
    }
  };

  if (loading || !policyData) {
    return (
      <CardBody style={{height : "100px"}}>
        <Spinner
          color="info"
          style={{
            height: '4rem',
            width: '4rem',
            position : "absolute",
            left: "50%"
          }}
        >
          Loading...
        </Spinner>
      </CardBody>
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row mb-4">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4 className="fw-bold">Update Policy</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Penalty</li>
              <li className="breadcrumb-item">Update</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div> 

      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            <CardBody>
              <Form onSubmit={savePolicy}>              
                <h5 className="fw-bold mb-4">Penalties</h5>
                {policyData.penalties.map((pen, penIndex) => (
                  <div key={penIndex} className="border p-3 mb-4 rounded bg-light">
                    <Row className="mb-3">
                      <Col sm={2}>
                        <Label className="fw-bold">From Hour</Label>
                        <Input
                          type="number"
                          value={pen.fromHour}
                          onChange={(e) => updatePenalty(penIndex, "fromHour", parseInt(e.target.value))}
                          min="0"
                        />
                      </Col>
                      <Col sm={2}>
                        <Label className="fw-bold">To Hour</Label>
                        <Input
                          type="number"
                          value={pen.toHour}
                          onChange={(e) => updatePenalty(penIndex, "toHour", parseInt(e.target.value))}
                          min="0"
                        />
                      </Col>
                      <Col sm={2}>
                        <Label className="fw-bold">Frequency Increase</Label>
                        <Input
                          type="number"
                          value={pen.frequencyIncrease}
                          onChange={(e) => updatePenalty(penIndex, "frequencyIncrease", parseInt(e.target.value))}
                          min="0"
                        />
                      </Col>
                      <Col sm={2}>
                        <Label className="fw-bold">Ban Duration (Hrs)</Label>
                        <Input
                          type="number"
                          value={pen.banDuration}
                          onChange={(e) => updatePenalty(penIndex, "banDuration", parseInt(e.target.value))}
                          min="0"
                        />
                      </Col>
                      <Col sm={2}>
                        <Label className="fw-bold">Cost</Label>
                        <Input
                          type="number"
                          value={pen.cost}
                          onChange={(e) => updatePenalty(penIndex, "cost", parseFloat(e.target.value))}
                          min="0"
                        />
                      </Col>
                      {penIndex === policyData.penalties.length - 1 && (
                        <Col sm={2} className="d-flex align-items-end">
                          <Button color="danger" type="button" onClick={() => removePenalty(penIndex)}>Delete Penalty</Button>
                        </Col>
                      )}
                    </Row>

                    <h6 className="fw-bold mb-3">Levels</h6>
                    {policyData.levels.map((lvl, lvlIndex) => (
                      <div key={lvlIndex} className="border p-3 mb-3 rounded bg-white">
                        <Row className="mb-2">
                          <Col sm={2}><span className="fw-bold">Level:</span> {lvl.level}</Col>
                          <Col sm={2}>
                            <Label className="fw-bold">Action</Label>
                            <Input
                              type="select"
                              value={lvl.action}
                              onChange={(e) => updateLevel(lvlIndex, "action", e.target.value)}
                            >
                              <option value="frequencyIncrease">frequencyIncrease</option>
                              <option value="banUser">banUser</option>
                              <option value="terminate">terminate</option>
                            </Input>
                          </Col>
                          <Col sm={2}>
                            <Label className="fw-bold">Targeted Venue</Label>
                            <Input
                              type="select"
                              value={lvl.targetedVenue}
                              onChange={(e) => updateLevel(lvlIndex, "targetedVenue", e.target.value)}
                            >
                              <option value="specific">specific</option>
                              <option value="all">all</option>
                            </Input>
                          </Col>
                          <Col sm={2}>
                            <Label className="fw-bold">Cost Multiplier</Label>
                            <Input
                              type="number"
                              step="0.1"
                              value={lvl.costMulti}
                              onChange={(e) => updateLevel(lvlIndex, "costMulti", parseFloat(e.target.value))}
                              min="0"
                            />
                          </Col>
                          {lvlIndex === policyData.levels.length - 1 && ( 
                            <Col sm={2} className="d-flex align-items-end">
                              <Button color="danger" type="button" onClick={() => removeLevel(lvlIndex)}>Delete Level</Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}
                  </div>
                ))}

                <div className="mb-4">
                  <Button color="secondary" type="button" onClick={addPenalty} className="me-3">Add Penalty</Button>
                  <Button color="secondary" type="button" onClick={addLevel}>Add Level</Button>
                </div>

                <div className="text-end mt-4">
                  <Button type="submit" color="primary">Save Policy</Button>
                </div>
              </Form>
            </CardBody> 
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
