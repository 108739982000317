import React, { useState, useEffect } from "react";
import { postRequest } from "../Common/Utils.js";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { Row, Col, Card, CardBody, Spinner, Modal, Button } from "reactstrap";
import "./tableCustom.css"; // your custom table styling

const TableComponent = ({ closeUserModal, onSelectUser, withPackages = false }) => {
  const [offerData, setOfferData] = useState([]);
  const [loader, setLoader] = useState(false);

  // Pagination / Filtering
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState("name");

  // Selected Offers (the row-level selection)
  const [selectedOffers, setSelectedOffers] = useState([]);
  // For each Offer ID, we track which package IDs are selected
  const [packSelections, setPackSelections] = useState({});

  useEffect(() => {
    fetchOffers();
  }, [currentPage, sizePerPage, sort]);

  // Fetch offers from the server
  const fetchOffers = async () => {
    setLoader(true);
    try {
      const payload = {
        limit: sizePerPage,
        page: currentPage,
        sortField: sort,
        sortOrder: "asc",
        ...(searchText && { search: searchText }),
        ...(withPackages && { withPackages: true }),
      };
      const response = await postRequest(`venue/offer/list/admin`, payload);
      const data = response.data;
      setOfferData(data.list);
      setTotalCount(data.count);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
    setLoader(false);
  };

  // Column definitions
  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: false,
    },
    {
      dataField: "image",
      text: "Image",
      formatter: (cell, row) => (
        <img
          src={row.image}
          alt="cover img"
          width="50"
          style={{ borderRadius: "5px", cursor: "pointer" }}
        />
      ),
      sort: false,
    },
    {
      dataField: "venueName",
      text: "Venues",
      sort: false,
    },
  ];

  // Toggle a single pack within an Offer
  const togglePackSelection = (offerId, packId, checked) => {
    setPackSelections((prev) => {
      const currentSelected = prev[offerId] || [];
      let newSelected;
      if (checked) {
        // add packId
        newSelected = [...currentSelected, packId];
      } else {
        // remove packId
        newSelected = currentSelected.filter((id) => id !== packId);
      }

      // If user unchecks all packs => also unselect the main offer row
      if (newSelected.length === 0) {
        setSelectedOffers((old) => old.filter((offer) => offer._id !== offerId));
        return { ...prev, [offerId]: [] };
      }

      return { ...prev, [offerId]: newSelected };
    });
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
      }
    }
  };

  // Row selection for the entire Offer
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    style: { background: "#c8e6c9" },
    onSelect: (row, isSelect) => {
      if (isSelect) {
        // Add this offer to the "selectedOffers"
        setSelectedOffers((prev) => [...prev, row]);
        // By default, select ALL packages for this offer
        const allPackIds = (row.pack || []).map((p) => p._id);
        setPackSelections((prev) => ({ ...prev, [row._id]: allPackIds }));
      } else {
        // Remove from selectedOffers
        setSelectedOffers((prev) => prev.filter((o) => o._id !== row._id));
        // Clear the packSelections for this Offer
        setPackSelections((prev) => {
          const updated = { ...prev };
          delete updated[row._id];
          return updated;
        });
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        // Select all offers
        setSelectedOffers(rows);
        // For each row, select all packs
        const newPackSelections = {};
        rows.forEach((r) => {
          newPackSelections[r._id] = (r.pack || []).map((p) => p._id);
        });
        setPackSelections(newPackSelections);
      } else {
        setSelectedOffers([]);
        setPackSelections({});
      }
    },
    selected: selectedOffers.map((offer) => offer._id),
  };

  // Expand row to show the Pack list (title, description, amount)
  const expandRow = {
    renderer: (row) => {
      const packs = row.pack || [];
      if (packs.length === 0) {
        return <div style={{ padding: "10px" }}>No packages available.</div>;
      }

      return (
        <div style={{ padding: "10px 20px" }}>
          <strong>Packages</strong>
          {packs.map((packItem) => {
            // Is this package currently selected?
            const isSelected = (packSelections[row._id] || []).includes(packItem._id);

            return (
              <div key={packItem._id} className="d-flex flex-column my-3" style={{ borderBottom: "1px solid #eee", paddingBottom: "8px" }}>
                {/* Checkbox & Title row */}
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={(e) => togglePackSelection(row._id, packItem._id, e.target.checked)}
                    style={{ marginRight: "8px" }}
                  />
                  <div>
                    <span style={{ fontWeight: "600" }}>
                      {packItem.title}
                    </span>{" "}
                    <span style={{ marginLeft: "6px", color: "#555" }}>
                      (AED {packItem.amount || 0})
                    </span>
                  </div>
                </div>
                {/* Description row */}
                {packItem.description && (
                  <div style={{ marginLeft: "26px", marginTop: "4px", color: "#777" }}>
                    {packItem.description}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expanded: selectedOffers.map((o) => o._id), // optional: auto-expand selected
  };

  // "Add Offers" => pass back the final data structure
  const handleAddOffers = () => {
    const finalPacks = [];
  
    selectedOffers.forEach((offer) => {
      const selectedPackIds = packSelections[offer._id] || [];
  
      // gather only the user-selected packs
      const selectedPackObjs = (offer.pack || []).filter((packItem) =>
        selectedPackIds.includes(packItem._id)
      );
  
      // optionally, if you want to keep an offer reference, you can add
      // an 'offerId' or 'offerTitle' property to each pack
      selectedPackObjs.forEach((packObj) => {
        finalPacks.push({
          ...packObj,
          offerId: offer._id,      // reference to the parent offer
          offerTitle: offer.title, // optional
          offerName: offer.title, // optional
          venueName: offer.venueName, // optional
        });
      });
    });
  
    console.log("Final Packs:", finalPacks);
    onSelectUser(finalPacks);
  };

  return (
    <Row>
      <Col xs="12">
        <Card>
          {loader ? (
            <CardBody className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
              <Spinner color="info" style={{ width: "3rem", height: "3rem" }}>
                Loading...
              </Spinner>
            </CardBody>
          ) : (
            <CardBody>
              <PaginationProvider
                pagination={paginationFactory({
                  page: currentPage,
                  sizePerPage,
                  totalSize: totalCount,
                  custom: true,
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <>
                    {/* Controls: Page Size & Search */}
                    
                    <Row className="mb-3">
                    <div className='d-flex g-3 justify-content-end'>
                      <Col md="6" className="d-flex align-items-center">
                        <label className="me-2">Page Size:</label>
                        <select
                          value={sizePerPage}
                          onChange={(e) => setSizePerPage(Number(e.target.value))}
                          className="form-select"
                          style={{ width: "90px" }}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                        </select>
                      </Col>

                      
                        <input
                          type="search"
                          placeholder="Search"
                          value={searchText}
                          class="form-control"
                          style={{ width: "300px" }}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <Button color="primary" className="btn btn-primary mx-2" onClick={fetchOffers}>
                          Search
                        </Button>
                        {selectedOffers.length > 0 && (
                          <Button color="success" onClick={handleAddOffers}>
                            Add
                          </Button>
                        )}
                      </div>
                       
                    </Row>

                    {/* The Actual Table */}
                    <BootstrapTable
                      onTableChange={handleTableChange}
                      remote
                      keyField="_id"
                      data={offerData}
                      columns={columns}
                      selectRow={selectRow}
                      expandRow={expandRow}
                      classes="table-custom-border" // reference your CSS class
                      {...paginationTableProps}
                    />

                    {/* Pagination */}
                    <Row>
                      <Col className="text-end">
                        <PaginationListStandalone {...paginationProps} />
                      </Col>
                    </Row>
                  </>
                )}
              </PaginationProvider>
            </CardBody>
          )}
        </Card>
      </Col>
    </Row>
  );
};

// Modal wrapper
const MultipleSelectOfferList = ({
  showUser,
  closeUserModal,
  onSelectUser,
  withPackages = false,
}) => {
  return (
    <Modal isOpen={showUser} size="lg" centered>
      <div className="modal-header">
        <h5 className="modal-title">Offer List</h5>
        <button
          onClick={closeUserModal}
          type="button"
          className="close"
        >
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <TableComponent
          closeUserModal={closeUserModal}
          onSelectUser={onSelectUser}
          withPackages={withPackages}
        />
      </div>
    </Modal>
  );
};

export default MultipleSelectOfferList;
