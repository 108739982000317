import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import ReactCrop from "react-image-crop";

import "react-quill/dist/quill.snow.css";

import { postRequestForm, postRequest } from "../../components/Common/Utils.js";

import FileUpload from "../../components/Common/FileUpload";

import { canvasPreview } from "../VenueManagement/canvasPreview.js";
import { useDebounceEffect } from "../VenueManagement/useDebounceEffect.js";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export default function CreatePackage() {
   let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [previewLogo, setPreviewLogo] = useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [logoSelected, setLogoSelected] = useState(0);
  const [remoteLogo, setRemoteLogo] = useState(null);
  const [isPopular, setIsPopular] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [packageData, setPackageData] = useState({
    deal: true,
    membershipPackage: true,
    activity: true,
    discount: true,
    offerPackage: true,
  });
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({
    unit: "px",
    x: 25,
    y: 25,
    width: 250,
    height: 250,
    aspect: 1,
    maxWidth: 250,
    maxHeight: 250,
    minHeight: 250,
    minWidth: 250,
  });

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const blobUrlRef = useRef(""); 

  const showLightBox = (previewLogo) => {
    setPreviewlogo(previewLogo);
    setOpenLightBox(true);
  };

  useEffect(() => {
    console.log("state", state); 
    
    setPackageData({
      promoId: state._id,
      promoCode: state.promoCode,
      title: state.title,
      description: state.description,
      discountPercentage: state.discountPercentage,
      minimumPurchaseAmount: state.minimumPurchaseAmount,
      maximumDiscountAmount: state.maximumDiscountAmount,
      time: state.startDate && state.endDate ? 'custom' : 'unlimited',
      startDate: state.startDate ? new Date(state.startDate).toISOString().slice(0, 10) : null, // Assuming you need date in 'YYYY-MM-DD' format
      endDate: state.endDate ? new Date(state.endDate).toISOString().slice(0, 10) : null,
      customUsageLimitation: state.usageLimitation,
      usageLimitation: state.usageLimitation > 1 ? 'custom' : state.usageLimitation,
      membershipPackage: state.targetTypes.includes('membershipPackage'),
      activity: state.targetTypes.includes('activity'),
      discount: state.targetTypes.includes('discount'),
      deal: state.targetTypes.includes('deal'),
      offerPackage: state.targetTypes.includes('offerPackage')
      // Set other fields from state as needed
    });


    // Assuming bannerImage URL from state is the one to be set
    setRemoteLogo(state.bannerImage);
   //setPreviewLogo(state.bannerImage);
    
  }, []);

  const Createpackage = async (e) => {
    e.preventDefault();
   

    const { promoCode, title, description, discountPercentage, minimumPurchaseAmount, maximumDiscountAmount, startDate, endDate, customUsageLimitation, usageLimitation, membershipPackage, activity, discount, deal, offerPackage } = packageData;

    // Check for required fields and conditions
    if (!promoCode) {
        toast.error("Promo code is required");
        return;
    }
    if (!title) {
        toast.error("Title is required");
        return;
    }
    if (!description) {
        toast.error("Description is required");
        return;
    }    
    if (!discountPercentage || discountPercentage < 5 || discountPercentage > 100) {
        toast.error("Discount percentage must be between 5 and 100");
        return;
    }
    if (!minimumPurchaseAmount || minimumPurchaseAmount < 1) {
        toast.error("Minimum purchase must be at least 1");
        return;
    }
    if (!maximumDiscountAmount || maximumDiscountAmount < 1) {
        toast.error("Maximum discount must be at least 1");
        return;
    }
    if (packageData.time === 'custom' && (!startDate || !endDate)) {
        toast.error("Start date and end date must be selected for custom time");
        return;
    }
    if (packageData.usageLimitation === 'custom' && (!customUsageLimitation || customUsageLimitation < 1)) {
        toast.error("Custom usage limit must be at least 1");
        return;
    }
    if (!membershipPackage && !activity && !discount && !deal && !offerPackage) {
        toast.error("At least one apply-on option must be selected");
        return;
    }
    if (!remoteLogo) {
      toast.error("Banner image is required");
      return;
    }

    let targetTypes = [];
    if (discount) targetTypes.push('discount');
    if (deal) targetTypes.push('deal');
    if (activity) targetTypes.push('activity');
    if (membershipPackage) targetTypes.push('membershipPackage');
    if (offerPackage) targetTypes.push('offerPackage');


    const formData = new FormData();

    let selectedImageRes = null;

    const selectedImageForm = new FormData();
    selectedImageForm.append("backgroundImage", remoteLogo);
    

    if(logoSelected){
      setStartLoader(true);
      const selectedImageRes = await postRequestForm(
        "comman/img/upload",
        selectedImageForm
      );
      setStartLoader(false);
    }   

    const promoCodeData = {
      promoCodeId: packageData.promoId,
      title,
      description,
      bannerImage: selectedImageRes?.data?.url || remoteLogo,
      promoCode,
      discountPercentage,
      minimumPurchaseAmount,
      maximumDiscountAmount,
      usageLimitation: usageLimitation == 'custom' ? customUsageLimitation : usageLimitation, // Adjust based on logic
      startDate: packageData.time === 'custom' ? startDate : null,
      endDate: packageData.time === 'custom' ? endDate : null,
      targetTypes     
  };
   
    setLoading(true);
    const response = await postRequest(
      "subscription/promocode/update",
      promoCodeData
    );

    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/promocode");
      }, 3000);
    } else {
      toast.error(response.message);
    }
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );

        setLogoSelected(logoSelected + 1);
      }
    },
    100,
    [completedCrop]
  );

  useEffect(() => {
    if (!previewCanvasRef.current) {
      return;
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      const file = new File([blob], "bk-image.jpg", { type: "image/jpeg" });
      setRemoteLogo(file);
    });
  }, [logoSelected]);

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);
  };

  const handleLogoChange = (e) => {
    setSelectedLogo(e.target.files[0]);
    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
  };



  const updateSquareCrop = (crop) => {
    const size = Math.max(crop.width, crop.height);
    setCrop({ ...crop, width: size, height: size });
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-10">
          <div className="page-title-box">
            <h4>Promocode Create</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Promocode Management</li>:{" "}
              <li className="breadcrumb-item">Create Promocode</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              onClick={() => history.goBack()}
            >
              <i class="fas fa-chevron-left"></i> Back
            </button>
          </div>
      </div>
      <Row>
      <Col className="col-12 user-management-container">
            <Card  className="custom-shadow-card">
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Code </h4>
                <Form onSubmit={Createpackage}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Promocode
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("promoCode", e.target.value)
                              }
                              value={packageData?.promoCode}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={packageData?.title}
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">                          
                            <textarea onChange={(e) =>
                                updateValue("description", e.target.value)                                
                              }
                              value={packageData?.description}
                              name="subTitle"
                              className="form-control"
                              
                              />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount %
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("discountPercentage", e.target.value)
                              }
                              value={packageData?.discountPercentage}
                              max={100}
                              min={5}
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Minimum Purchase
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("minimumPurchaseAmount", e.target.value)
                              }            
                              value={packageData?.minimumPurchaseAmount}                  
                              min={1}
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Maximum Discount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("maximumDiscountAmount", e.target.value)
                              }
                              value={packageData?.maximumDiscountAmount}                              
                              min={1}
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Usage Limit (Per user)
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("usageLimitation", e.target.value)
                              }
                              value={packageData?.usageLimitation}
                              name="time"
                              class="form-select"
                            >
                              <option value="1">One Time</option>
                              <option value="0">Unlimited</option>
                              <option value="custom">Custom</option>
                            </select>
                          </div>
                        </div>

                        { packageData?.usageLimitation == 'custom' && (

                          <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Custom Usage Limit
                          </label>

                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("customUsageLimitation", e.target.value)
                              }
                              value={packageData?.customUsageLimitation}                              
                              min={1}
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>
                        )}


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Time
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("time", e.target.value)
                              }
                              value={packageData?.time}
                              name="time"
                              class="form-select"
                            >
                              <option value="unlimited">No Limit</option>
                              <option value="custom">Custom Range</option>
                            </select>
                          </div>
                        </div>  

                        { packageData?.time == 'custom' && (
                          <>
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Start Date
                            </label>

                            <div className="col-sm-6">
                              <input
                                type="date"
                                onChange={(e) =>
                                  updateValue("startDate", e.target.value)
                                }
                                value={packageData?.startDate}
                                name="startDate"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              End Date
                            </label>

                            <div className="col-sm-6">
                              <input
                                type="date"
                                value={packageData?.endDate}
                                onChange={(e) =>
                                  updateValue("endDate", e.target.value)
                                }
                                name="endDate"
                                className="form-control"
                              />
                            </div>
                          </div>
                          </>       
                        )}                        

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Apply On
                          </label>
                          <div className="col-sm-6">
                          <FormControlLabel
                              control={
                                <Checkbox
                                  checked={packageData?.membershipPackage}                                  
                                  onChange={(event) =>
                                    updateValue('membershipPackage', event.target.checked)
                                  }
                                  color="primary"
                                  value="false"
                                  name="membershipPackage"
                                />
                              }
                              label="Membership"
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={packageData?.activity}                                  
                                  onChange={(event) =>
                                    updateValue('activity', event.target.checked)
                                  }
                                  color="primary"
                                  value="false"
                                  name="activity"
                                />
                              }
                              label="Activity"
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={packageData?.discount}                                  
                                  onChange={(event) =>
                                    updateValue('discount', event.target.checked)
                                  }
                                  color="primary"
                                  value="false"
                                  name="isPopular"
                                />
                              }
                              label="Discount"
                            />


                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={packageData?.deal}                                  
                                  onChange={(event) =>
                                    updateValue('deal', event.target.checked) 
                                  }
                                  color="primary"
                                  value="false"
                                  name="isPopular"
                                />
                              }
                              label="Deal"
                            />

                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={packageData?.offerPackage}                                  
                                  onChange={(event) =>
                                    updateValue('offerPackage', event.target.checked)
                                  }
                                  color="primary"
                                  value="false"
                                  name="isPopular"
                                />
                              }
                              label="Offer Package"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Banner Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={handleLogoChange}
                              name="logo"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewLogo && (
                              <ReactCrop
                                crop={crop}
                                src={previewLogo}
                                onChange={(newCrop) =>
                                  updateSquareCrop(newCrop)
                                }
                                onComplete={handleCropComplete}
                                style={{
                                  maxWidth: "600px",
                                  maxHeight: "400px",
                                }}
                              >
                                <img
                                  ref={imgRef}
                                  src={previewLogo}
                                  onClick={() => showLightBox(previewLogo)}
                                />
                              </ReactCrop>
                            )}

                            {completedCrop && (
                              <div>
                                <canvas
                                  ref={previewCanvasRef}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                  }}
                                />
                              </div>
                            )}

                            {remoteLogo && (
                                <img
                                  src={remoteLogo}
                                  style={{
                                    maxWidth: "180px",                              
                                  }}
                                />
                            )}

                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      {openLightbox && (
        <Lightbox
          mainSrc={previewLogo}
          onCloseRequest={() => setOpenLightBox(false)}
        />
      )}
      <FileUpload message="Logo & Cover Uploading" status={startLoader} />
    </React.Fragment>
  );
}
